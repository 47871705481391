import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { Row, Col, Spinner, InputGroup, Input, InputGroupText, ListGroup, ListGroupItem, DropdownItem, Badge } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import _debounce from 'lodash/debounce';
import { searchTicker, tickerLookup } from '../../api/index';
import { Icon } from '../Component';
// import { get, post } from 'helpers/api_helper';

const Autocomplete = props => {
    const [search, setsearch] = useState(props.defaultValue ? props.defaultValue : "");
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [options, setOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowOptions && setShowOptions();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [setShowOptions]);

    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const debounceFn = useCallback(_debounce(handleDebounceFn, 300), []);

    async function handleDebounceFn(inputValue) {
        setIsLoadingSearch(true);
        searchTicker({ query: inputValue },
            (res) => {
                let temp = res;
                temp = temp ? (Array.isArray(temp) ? temp : [temp]) : [];
                setIsLoadingSearch(false);
                setOptions(temp);
                setShowOptions(true);
            },
            (err) => {
                console.error(err);
            })
    }


    function onChangeSearch(event) {
        const v = event.target.value;
        setsearch(v);
        if (v !== "") {
            debounceFn(v);
        }
    };

    function onClickOption(event, option) {
        event.preventDefault();
        setsearch(option.symbol)
        setShowOptions(false);
        props.onClickOption(event, option.symbol );
        props.resetSearch && setsearch("")
    };

    return (
        <React.Fragment>
                <div ref={ref} >
                    <div className='form-group'>
                        <Dropdown toggle={() => {
                            options?.length && setActiveIndex(0)
                            options?.length && setShowOptions(!showOptions)
                        }} isOpen={showOptions}>
                            <DropdownToggle
                                style={{
                                    backgroundColor: "unset",
                                    border: "unset",
                                    padding: "unset",
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "ArrowUp" && activeIndex > 0) {
                                        setActiveIndex(activeIndex - 1);
                                    }
                                    if (
                                        e.key === "ArrowDown" &&
                                        activeIndex < options?.length - 1
                                    ) {
                                        setActiveIndex(activeIndex + 1);
                                    }
                                    if (
                                        e.key === "Enter" &&
                                        activeIndex <= options.length - 1
                                    ) {
                                        onClickOption(e, options[activeIndex])
                                    }
                                }}
                            >
                                <div className="form-control-wrap">
                                    <div className="form-icon form-icon-right">
                                        {
                                            isLoadingSearch ?
                                                <span><Spinner color="primary" size="sm" type="grow" /> </span> :
                                                <Icon name="search" />
                                        }
                                    </div>
                                    <Input
                                        value={search}
                                        type="text"
                                        placeholder={`Search ticker...`}
                                        onChange={onChangeSearch}
                                    />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                {options?.slice(0, 10)?.map((item, index) => {
                                    return (
                                        <div key={item?.symbol}>
                                            <DropdownItem onClick={(e) => { onClickOption(e, item) }} active={index === activeIndex}>{item?.symbol}<Badge style={{float:"right"}}>{item?.exchangeShortName}</Badge></DropdownItem>
                                        </div>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
        </React.Fragment>
    );
};


export default Autocomplete;