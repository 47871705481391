import Router from "./route/Index";

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AppProvider } from './contexts/AppContext';
import useAuth from "./hooks/useAuth";
import ThemeProvider from "./layout/provider/Theme";
import FeedbackWidget from "./components/FeedbackWidget";
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-7YTFHT0CJ8"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const articleStructuredData = {
  "@context": "https://schema.org",
  "@type": "WebApplication",
  headline: "superpower algotrading platform",
  name: "traid",
  applicationCategory: "Trading, Investment",
  description: "superpower algotrading platform.",
  image: "https://traid.ninja/android-chrome-192x192.png",
  author: {
    "@type": "Person",
    name: "Leon",
    url: "https://www.linkedin.com/in/bar-avital-613360b1/",
  },
  "operatingSystem": "Desktop, Mobile"
};

// init firebase backend
// const firebase = initFirebaseBackend(firebaseConfig);

const App = () => {
  const { pathname } = useLocation();
  const { getAuthenticatedUser, isLoggedIn } = useAuth();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const setup = async () => {
      try {
        const token = await getAuthenticatedUser();
        if (token)
          setToken(token);
        else
          setToken("");
      } catch (error) {
        setToken("")
      }
    }

    setup();
  }, [isLoggedIn, getAuthenticatedUser])

  useEffect(() => {
    sessionStorage.setItem('lastPage', pathname);
    // ReactGA.event({
    //   action: "page_view",
    //   // label: window.location.pathname + window.location.search,
    //   category: window.location.pathname + window.location.search
    // })
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, [pathname])

  const authMiddleware = new ApolloLink(async (operation, forward) => {
    // add the authorization to the headers
    try {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${token}` || null,
        }
      }));

      return forward(operation);
    } catch (error) {
      console.error(error)
      return forward(operation);
    }
  })

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  const client = new ApolloClient({
    // uri: `${config.SERVER_URL}/graphql`,
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache()
  });

  return (
    <ThemeProvider>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(articleStructuredData),
        }}
      />
      <ApolloProvider client={client}>
        <AppProvider>
          <Router />
          <FeedbackWidget />
        </AppProvider>
      </ApolloProvider>
      {/* <Widget
      // emojis={true}
        subtitle="Let us know what you think"
        handleNewUserMessage={handleNewUserMessage}
      /> */}
    </ThemeProvider>
  );
};
export default App;