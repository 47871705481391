import { DeepChat } from 'deep-chat-react';
import { useEffect, useRef, useState } from 'react';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../components/Component';
import { Badge, Button, Col, Container, Row } from 'reactstrap';
import { presets } from '../app/chat/Presets';
import { Section } from '../../landingApp/layout/section/Section';
import { useTheme, useThemeUpdate } from '../../layout/provider/Theme';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getNextMessage } from '../../api/nemo';

const MESSAGES_LIMIT = 10;

const DeepChatWrapper = ({ landing }) => {
    const [messages, setMessages] = useState(JSON.parse(sessionStorage.getItem('messages')) || []);
    const [state, setState] = useState(JSON.parse(sessionStorage.getItem('chatState')) || {});
    const [isLoading, setIsLoading] = useState(false);
    const [messageCount, setMessageCount] = useState(messages.length || 0);
    const deepChatRef = useRef();
    const themeUpdate = useThemeUpdate();
    useEffect(() => {
        themeUpdate.skin('light');
        return () => {
            const t = sessionStorage.getItem('skinMode') ? sessionStorage.getItem('skinMode') : "dark"
            themeUpdate.skin(t);
        }
    }, []);

    const [welcomeMessage, setWelcomeMessage] = useState(null)

    const onNewMessage = ({ message, isInitial }) => {
        if (deepChatRef) {
            const chatMessages = deepChatRef.current.getMessages();
            // setMessages(allMessages)
            sessionStorage.setItem('messages', JSON.stringify(chatMessages));
            if (!isInitial) {
                if (message.role === "assistant") {
                    setMessages(chatMessages);
                }
            }
        }
    }

    useEffect(() => {
        deepChatRef?.current?.focusInput();
    }, [deepChatRef, messages, messageCount])

    useEffect(() => {
        if (messages.length === 0) {
            getNextMessage({
                messages: [
                    {
                        role: "event",
                        event: { type: "WelcomeUser" }
                    }
                ],
                state
            }, async (res) => {
                setWelcomeMessage(res?.response?.[0]?.content)
                setState(res?.state)
            }, (err) => {
                console.error(err);
            }
            )
        }
    }, [])

    const onClickCleanMessages = (e) => {
        e.preventDefault();
        deepChatRef.current.clearMessages();
        sessionStorage.setItem('messages', JSON.stringify([]))
        sessionStorage.setItem('chatState', JSON.stringify({}))
        setMessages([]);
        setState({});
    }

    return (
        <>
            <Head title="Traid chat"></Head>
            <Section className="">
                <Container>
                    <BlockHead size="sm">
                        <BlockBetween>
                            {landing ? <BlockHeadContent>
                                <BlockTitle tag="h3" page>You AI Trading assistant 🥷🏻</BlockTitle>
                            </BlockHeadContent> : <BlockHeadContent>
                                <BlockTitle tag="h3" page>Traid ninja 🥷🏻</BlockTitle>
                                <p>Your AI trading assistant | <Link to="/">By traid.ninja</Link></p>
                                <BlockDes className="text-soft">
                                </BlockDes>
                            </BlockHeadContent>}
                        </BlockBetween>
                    </BlockHead>
                    <Row className='g-gs'>
                        <Col xs={"12"} lg="6">
                            {MESSAGES_LIMIT - messageCount > 0 ? (
                                <>
                                    {/* <h6 style={{
                                        display: "inline"
                                    }}>You have {MESSAGES_LIMIT - messageCount} messages left! </h6><Link to="/login">Sign up for more</Link> */}
                                </>) : (
                                <>
                                    <h6 style={{
                                        display: "inline"
                                    }}>You have reached the messages limit! </h6><Link to="/login">Sign up now to unlock more ninja power!</Link>
                                </>
                            )
                            }

                            <DeepChat
                                avatars={{
                                    ai: {
                                        src: "android-chrome-512x512.png",
                                        styles: { avatar: { "height": "30px", "width": "30px" } }
                                    }
                                }}
                                ref={deepChatRef}
                                chatStyle={{
                                    width: "100%", height: "calc(100vh - 300px)",
                                    // backgroundColor: theme.skin === "dark" ? "#141c26" : "unset"
                                }}
                                onNewMessage={(data) => {
                                    onNewMessage(data);
                                }}
                                messageStyles={{
                                    "default": {
                                        //   "shared": {"bubble": {"color": "white"}},
                                        //   "ai": {"bubble": {"backgroundColor": "#3cbe3c"}},
                                        "user": { "bubble": { "backgroundColor": "#364a63" } },
                                        //   "bob": {"bubble": {"backgroundColor": "#ffa500"}}
                                    }
                                }}
                                onComponentRender={() => {
                                    setMessageCount(deepChatRef?.current?.getMessages().length)
                                    deepChatRef?.current?.focusInput();
                                }
                                }
                                request={{ url: `${process.env.REACT_APP_NEMO_URL}/get-next-message` }}
                                requestBodyLimits={{ maxMessages: -1 }}
                                requestInterceptor={(details) => {
                                    const allowed = messages.length < 9
                                    if (!allowed) {
                                        new Swal("Sign up for more messages:)")
                                    }
                                    else {
                                        return { ...details, body: { ...details.body, messages: details.body.messages.filter(m => m.role === "user").map(i => ({ ...i, content: i.text })), state } };
                                    }
                                }}
                                responseInterceptor={(response) => {
                                    setState(response.state)
                                    sessionStorage.setItem('chatState', JSON.stringify(response.state));
                                    let t = response.response[0];
                                    return { ...t, text: t.content };
                                }}
                                style={{
                                    display: "block",
                                    width: "100%",
                                    borderRadius: '10px'
                                }}
                                textInput={{ placeholder: { text: 'Ask me anything..' } }}
                                initialMessages={messages}
                            >
                                <div
                                    style={{
                                        width: "200px",
                                        // backgroundColor: "#f3f3f3",
                                        borderRadius: "10px",
                                        padding: "12px",
                                    }}
                                >
                                    {welcomeMessage}
                                </div>
                            </DeepChat>
                        </Col>
                        <Col xs={"12"} lg="6">
                            <Row className='g-1'>
                                <h6>Examples</h6>
                                {presets.map(preset => {
                                    return (
                                        <Button color="gray" style={{
                                            padding: 8,
                                            cursor: "pointer",
                                            display: "block",
                                        }} onClick={(e) => {
                                            deepChatRef.current.submitUserMessage({
                                                role: "user",
                                                text: preset.value
                                            })
                                        }}>
                                            {preset.label}
                                        </Button>
                                    )
                                })}
                            </Row>
                        </Col>
                        <Col xs="12" style={{
                            display: "flex",
                            gap: 4
                        }}>
                            <Button color='secondary' onClick={onClickCleanMessages}><Icon name="trash" style={{ marginRight: 4 }} /> Clear messages</Button>
                            {/* <Button color='secondary' onClick={(e) => {
                                navigate('/');
                            }}><Icon name="signin" style={{ marginRight: 4 }} /> Sign up for more</Button> */}
                        </Col>
                        {!landing && <Col xs="12" style={{}}>

                            <b>Disclaimer</b>
                            <p>

                                The content provided on this website, (collectively referred to as the "Material"), is intended solely for educational purposes related to analysis, trading, and investment techniques. The Material is not to be construed as advice to trade or invest in any financial instrument or class of financial instruments, or to utilize specific trading or investing methods.

                                Any advice presented in the Material is offered for general informational purposes and does not consider any individual's investment objectives, financial situation, or needs. Therefore, users and viewers (collectively referred to as "Users") should not act solely on the information in the Material without assessing its relevance to their financial circumstances. Users who are not adequately qualified to make such assessments on their own should seek professional advice.

                                Please be aware that investing and trading carry inherent risks, including the risk of financial loss. Past performance is not indicative of future results.

                                The decision to engage in investment or trading activities rests solely with the User. We (Traid ninja LTD) explicitly disclaim any and all liability to any person for any actions taken or not taken, and for the outcomes thereof, based on reliance on all or any part of the Material.
                            </p>
                        </Col>}
                    </Row>
                </Container>
            </Section >
        </>
    )
}

export default DeepChatWrapper;