
import { useRef, useState } from "react";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import { Icon } from "./Component";
import { useMyStore } from "../pages/bots/trading-bot-new/ReactFlowBuilder/store";
import { HexColorPicker } from "react-colorful";

const CustomColorPicker = ({ style, color, setColor }) => {
    const ref = useRef(null);
    const [showWidget, setShowWidget] = useState(false);
    useOutsideAlerter(ref, () => setShowWidget(false));
    const c = color || "#798bff"
    return <span style={style} ref={ref}>
        <Icon className="icon-circle" name="dot" onClick={(e) => {
            e.preventDefault();
            setShowWidget(!showWidget)
        }} style={{
            height: 16,
            width: 16,
            color: c,
            backgroundColor: c,
        }} ></Icon>
        {showWidget && (<div style={{
            position: 'absolute',
            zIndex: 100
        }} >
            <HexColorPicker color={c} onChange={color => setColor(color)} />
        </div>
        )}
    </span>
}

export default CustomColorPicker;