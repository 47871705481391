import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Button, Spinner } from "reactstrap";
import {
  BlockBetween,
  BlockDes,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Col,
  Row,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { pricingTableDataV1, pricingTableDataV2 } from "./PricingTableData";
import { cancelStripeSubscription, changeStripeSubsription, getStripeConfig, revertCancelSubsriptionAtPeriodEnd, stripeCheckout } from "../../../api";
import { AppContext } from "../../../contexts/AppContext";
import { ModalDanger } from "../../components/Modals";
import { getStripeClientIdKey } from "../../../utils/Utils";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router";

const PricingTable = ({ }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [stripeConfig, setStripeConfig] = useState(false);
  const navigate = useNavigate();

  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { user } = useAuth();
  useEffect(() => {
    const loadStripeConfig = () => {
      setIsLoading(true)
      getStripeConfig((res) => {
        setStripeConfig(res)
        setIsLoading(false)
      }, (err) => {
        console.error(err)
        setIsLoading(false)
      })
    }

    loadStripeConfig()
  }, []);

  const onSubmit = async ({ event, priceId }) => {
    event.preventDefault();
    console.log("Create sub")
    const res = await stripeCheckout({
      redirectURL: window.location.href,
      priceId: priceId,
      customerId: user[getStripeClientIdKey()],
      customerEmail: user.email,
    }, (res) => {
      window.location = res?.url
    }, (err) => {
      console.log(err);
    })

    return res;
  }
  const onApproveCancel = async ({ event, subscription }) => {
    event.preventDefault();
    const res = await cancelStripeSubscription({
      subscriptionId: subscription.id
    }, (res) => {
      window.location = res?.url
    }, (err) => {
      console.log(err);
    })

    return res;
  }
  const onSubmitUpdate = async ({ event, subscription }) => {
    event.preventDefault();
    console.log("Update sub")
    const res = await revertCancelSubsriptionAtPeriodEnd({
      subscriptionId: subscription.id
    }, (res) => {
      window.location = res?.url
    }, (err) => {
      console.log(err);
    })

    return res;
  }
  const onApproveChange = async ({ event, subscriptionId, itemId, newPriceId }) => {
    event.preventDefault();
    console.log("Change sub")
    const res = await changeStripeSubsription({
      subscriptionId, itemId, newPriceId
    }, (res) => {
      window.location = res?.url
    }, (err) => {
      console.log(err);
    })

    return res;
  }

  const onSubmitCancel = ({ event, subscription }) => {
    const title = "YO!";
    const desc = "Are you sure you want cancel your subsription? A real ninja 🥷🏻 would never do that!";
    const icon = "ni-info";

    setModalData({
      title,
      icon,
      description: desc,
      approveButtonColor: "danger",
      approveButtonText: "Cancel subscription",
      cancelButtonText: "Back",
      onClickDelete: async (id) => {
        await onApproveCancel({ event, subscription })
      }
    });
    toggleModal();
  }

  const onSubmitDowngrade = ({ event, subscriptionId, itemId, newPriceId }) => {
    const title = "YO!";
    const desc = "Are you sure you want downgrade your subsription?";
    const icon = "ni-info";

    setModalData({
      title,
      icon,
      description: desc,
      approveButtonColor: "danger",
      approveButtonText: "Downgrade",
      cancelButtonText: "Back",
      onClickDelete: async (id) => {
        await onApproveChange({ event, subscriptionId, itemId, newPriceId })
      }
    });
    toggleModal();
  }

  const onSubmitUpgrade = ({ event, subscriptionId, itemId, newPriceId }) => {
    const title = "YO!";
    const desc = "Let's unlock some more ninja powers!!!";
    const icon = "ni-check";

    setModalData({
      title,
      icon,
      iconColor: "bg-success",
      description: desc,
      approveButtonColor: "success",
      approveButtonText: "Upgrade",
      cancelButtonText: "Back",
      onClickDelete: async (id) => {
        await onApproveChange({ event, subscriptionId, itemId, newPriceId })
      }
    });
    toggleModal();
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  if (isLoading) return <Spinner />;

  const userSubsription = user?.subscriptions?.data.filter(i => i.status === 'active')?.[0];
  const isCancledAtPeriod = userSubsription?.cancel_at_period_end;

  return (
    <>
      <Head title="Ninja plans"></Head>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockBetween className="g-3">
              <BlockContent>
                <BlockTitle>Which ninja are you?</BlockTitle>
                <BlockDes className="text-soft">
                  <p>Choose your pricing plan and trade like a ninja.</p>
                </BlockDes>
              </BlockContent>
            </BlockBetween>
          </BlockHead>
          <Row className="g-gs">
            {stripeConfig?.prices?.map((item) => {
              return (
                <Col md={6} xxl={3} key={item.id}>
                  <Card className={`card-bordered pricing ${item.tags ? "recommend" : ""}`}>
                    <div className="pricing-head" style={{
                      height: 160
                    }}>
                      {userSubsription?.plan?.id === item.id && <Badge style={{ float: "left" }} >Current plan</Badge>}
                      {userSubsription?.plan?.id === item.id && isCancledAtPeriod && <Badge color="warning" style={{ float: "left" }} >cancled</Badge>}
                      <div className="pricing-title">
                        <h4 className="card-title">{item.product.name}</h4>
                        <div className="pricing-amount">
                          <div className="amount">
                            ${item.unit_amount / 100} <span>/ month</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="pricing-features">
                        <li>
                          <span className="w-50">Max strategies</span> -{" "}
                          <span className="ms-auto">{item?.product?.metadata?.maxStrategies}</span>
                        </li>
                        <li>
                          <span className="w-50">Max backtests</span> -{" "}
                          <span className="ms-auto">{item?.product?.metadata?.maxBacktests}</span>
                        </li>
                        <li>
                          <span className="w-50">Max watchlists</span> - <span className="ms-auto">{item?.product?.metadata?.maxWatchlists}</span>
                        </li>
                        <li>
                          <span className="w-50">AI ninja</span> - <span className="ms-auto">Coming soon</span>
                        </li>
                      </ul>
                      <div className="pricing-action">
                        <Button color={userSubsription ? userSubsription?.plan?.id === item.id ? isCancledAtPeriod ? "info" : "danger" : item?.product?.name === "Basic" ? "warning" : "success" : "primary"} outline
                          onClick={(event) => {
                            if (user) {
                              userSubsription ? userSubsription?.plan?.id === item.id ? isCancledAtPeriod ?
                                onSubmitUpdate({ event, subscription: userSubsription }) :
                                onSubmitCancel({ event, subscription: userSubsription }) : item?.product?.name === "Basic" ?
                                onSubmitDowngrade({ event, newPriceId: item.id, subscriptionId: userSubsription.id, itemId: userSubsription?.items?.data?.[0]?.id }) :
                                onSubmitUpgrade({ event, newPriceId: item.id, subscriptionId: userSubsription.id, itemId: userSubsription?.items?.data?.[0]?.id }) :
                                onSubmit({ event, priceId: item.id })
                            }
                            else {
                              navigate('/login')
                            }
                          }}>
                          {userSubsription ? userSubsription?.plan?.id === item.id ? isCancledAtPeriod ? "Resume subscription" : "Cancel subscription" : item?.product?.name === "Basic" ? "Downgrade" : "Upgrade" : "Select plan"}
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row >
        </Block >
      </Content >
      {showModal && <ModalDanger modalError={showModal} toggleError={toggleModal} modalData={modalData} />}
    </>
  );
};

export default PricingTable;
