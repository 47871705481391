import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Modal, ModalBody, Spinner } from "reactstrap";
import { getCompanyProfile, getHistoricalPriceFull, getQuotes } from "../../../api";
import { getDates, groupBy } from "../../../utils/Utils";
import PortfolioChartCard from "./PortfolioChartCard";
import "../../../pages/portfolios/PortfolioPage.css";

const BacktestChart = ({ backtestData, backtestMode, botData, backtestType }) => {
    const portfolioData = backtestData?.portfolio;
    const navigate = useNavigate();
    const mode = "Backtest";
    const [data, setData] = useState(portfolioData);
    const [quotes, setQuotes] = useState(null);
    const [allSymbols, setAllSymbols] = useState(null);
    const [profiles, setProfiles] = useState(null);
    const [portfolioStats, setPortfolioStats] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [orderAsset, setOrderAsset] = useState("AAPL");
    const [isLoadingQuotes, setIsLoadingQuotes] = useState(null);
    const [isLoadingProfiles, setIsLoadingProfiles] = useState(null);
    const [marketData, setMarketData] = useState([]);
    const [isLoadingMarketData, setIsLoadingMarketData] = useState(false);
    const [isLoadingChartData, setIsLoadingChartData] = useState(false);
    const [showBuySellModal, setShowBuySellModal] = useState(false);

    const allOrders = portfolioData?.positions?.reduce((a, b) => ([...a, ...b.orders]), [])

    useEffect(() => {
        const loadMarketData = async () => {
            setIsLoadingMarketData(true);
            getHistoricalPriceFull({ symbols: allSymbols.join(",") },
                (res) => {
                    setMarketData(res)
                    setIsLoadingMarketData(false)
                }, (err) => {
                    console.error(err)
                    setIsLoadingMarketData(false)
                })
        }
        allSymbols?.length > 0 && loadMarketData()
    }, [allSymbols])

    useEffect(() => {
        setIsLoadingChartData(true)
        const chartDates = getDates(moment().subtract(1, 'weeks'), moment());
        const assetMapper = {};
        let values = [];
        let lastValidPrice = {};
        chartDates.map(date => {
            const pastOrders = allOrders?.filter(o => moment(o.orderDate).format("YYYY-MM-DD") <= date);
            const bySymbols = groupBy(pastOrders, "asset");
            assetMapper[date] = []
            Object.keys(bySymbols).map(symbol => {
                const totalSize = bySymbols[symbol].reduce((acc, i) => (acc + i.sizeValue), 0);
                const costTotal = bySymbols[symbol].reduce((acc, d) => acc + (d.price * d.sizeValue), 0);
                const avgPrice = costTotal / totalSize;
                const symbolChart = marketData?.filter(m => m.symbol === symbol)?.[0]?.historical;
                const symbolProfile = profiles?.filter(m => m.symbol === symbol)?.[0];
                const candle = symbolChart?.find(c => c.date <= date);

                if (candle?.close) {
                    lastValidPrice[symbol] = candle.close
                }

                const recordedPrice = candle?.close || lastValidPrice[symbol]

                assetMapper[date].push({
                    symbol,
                    totalSize,
                    costTotal,
                    marketValue: totalSize * recordedPrice,
                    avgPrice,
                    recordedPrice,
                })
            })
        })
        const chartData = []
        Object.keys(assetMapper).map((date, index) => {
            const portfolioValue = assetMapper[date]?.length ? (portfolioData?.initialFunds + assetMapper[date].reduce((acc, i) => (acc + i.marketValue - i.costTotal), 0)) : portfolioData?.initialFunds
            chartData.push({
                date,
                portfolioValue
            })
        })

        setChartData(chartData)
        setIsLoadingChartData(false)
    }, [marketData])

    const loadQuotes = async () => {
        setIsLoadingQuotes(true)
        getQuotes({ symbols: [orderAsset, ...(data?.positions?.map(i => i.asset) || [])]?.join(","), greeks: true },
            (res) => {
                setQuotes(res);
                setIsLoadingQuotes(false)
            }, (err) => {
                console.error(err)
                setIsLoadingQuotes(false)
            })
    }

    const loadProfiles = async () => {
        setIsLoadingProfiles(true)
        getCompanyProfile({ symbols: portfolioData?.type === "backtest" ? data?.positions?.map(i => i.asset)?.join(",") : data?.positions?.filter(pos => pos.status === "open")?.map(i => i.asset)?.join(","), greeks: true },
            (res) => {
                setProfiles(res);
                setIsLoadingProfiles(false)
            }, (err) => {
                console.error(err)
                setIsLoadingProfiles(false)
            })
    }

    useEffect(() => {
        (portfolioData?.positions?.length > 0) && loadQuotes();
        (data?.positions?.length > 0 && loadProfiles())
    }, [data, orderAsset]);


    useEffect(() => {
        setData(portfolioData)
    }, [portfolioData])

    useEffect(() => {
        const t = new Set([orderAsset, ...(data?.positions?.map(i => i.asset) || [])])
        setAllSymbols([...t])
    }, [orderAsset, data])

    useEffect(() => {
        let temp = {};
        const openPositions = portfolioData?.type === "backtest" ? data?.positions : data?.positions?.filter(pos => pos.status === "open");
        temp.totalOpenPositions = openPositions?.length;
        temp.etfCount = openPositions?.filter(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return profile?.isEtf;
        })?.length

        temp.etfMoney = openPositions?.filter(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return profile?.isEtf;
        })?.map(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return profile?.price * pos.size
        }).reduce((partialSum, a) => partialSum + a, 0);

        temp.fundsCount = openPositions?.filter(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return profile?.isFund;
        })?.length
        temp.fundsMoney = openPositions?.filter(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return profile?.isFund;
        })?.map(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return profile?.price * pos.size
        }).reduce((partialSum, a) => partialSum + a, 0);
        temp.stocksCount = temp.totalOpenPositions - temp.etfCount - temp.fundsCount;
        temp.stocksMoney = openPositions?.filter(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return (!profile?.isFund && !profile?.isEtf);
        })?.map(pos => {
            const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
            return profile?.price * pos.size
        }).reduce((partialSum, a) => partialSum + a, 0);

        // const groupedBySectors = groupBy(profiles, 'sector');
        // const groupedByIndustries = groupBy(profiles, 'industry');
        // temp.sector = openPositions.map(pos => {
        //   const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0];
        //   return ({label: profile?.sector, count: groupedBySectors[profile?.sector]?.length})
        // })
        // temp.industry = openPositions.map(pos => {
        //   const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0];
        //   return ({label: profile?.industry, count: groupedByIndustries[profile?.industry]?.length})
        // })

        const groupedBySectors = groupBy(profiles, 'sector');
        const groupedByIndustries = groupBy(profiles, 'industry');
        temp.sector = Object.keys(groupedBySectors).map(k => {
            return ({ label: k, count: groupedBySectors[k]?.length })
        })
        temp.industry = Object.keys(groupedByIndustries).map(k => {
            return ({ label: k, count: groupedByIndustries[k]?.length })
        })

        const nlv = (portfolioData?.funds + temp.etfMoney +
            temp.fundsMoney +
            temp.stocksMoney);
        const assetTypeData = [
            {
                label: "Cash", count: ((portfolioData?.funds) / nlv) * 100
            },
            { label: "ETFs", count: temp.etfMoney ? ((temp.etfMoney - portfolioData?.funds) / nlv) * 100 : 0 },
            { label: "Funds", count: temp.fundsMoney ? ((temp.fundsMoney - portfolioData?.funds) / nlv) * 100 : 0 },
            { label: "Stocks", count: ((temp.stocksMoney) / nlv) * 100 },
        ]
        temp.assetType = assetTypeData;

        setPortfolioStats(temp)
    }, [data, profiles]);

    const openPositions = data?.positions?.filter(pos => pos.status === "open");

    return (
        <>
            <PortfolioChartCard botData={botData} backtestType={backtestType} backtestMode={backtestMode} isLoadingChartData={isLoadingChartData} mode={mode} backtestData={backtestData} chartData={chartData} quotes={quotes}
                // allMessages={allMessages} 
                portfolioData={portfolioData} />
        </>
    );
};
export default BacktestChart;
