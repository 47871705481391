import React, { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { CardBody, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { Icon, PreviewAltCard, TooltipComponent } from "../../components/Component";
import { Link } from "react-router-dom";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import strings from "../../utils/Strings";

export const DoubleBar = ({ data }) => {
  return (
    <Bar
      className="chartjs-render-monitor"
      data={data}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: '13px',
            },
            titleColor: "#6783b8",
            titleMarginBottom: 6,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: '12px',
            },
            bodySpacing: 4,
            padding: 10,
            footerMarginTop: 0,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            ticks: {
              beginAtZero: true,
              color: "#9eaecf",
              font: {
                size: '11px',
              },
              padding: 10,
              callback: function (value, index, values) {
                return "$ " + value;
              },

            },
            grid: {
              tickMarkLength: 0,
            },
          },
          x: {
            display: true,
            ticks: {
              color: "#9eaecf",
              font: {
                size: '9px',
              },
              source: "auto",
              padding: 10,
              stepSize: 2400,
            },
            grid: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "transparent",
            },
          },
        },
      }}
    />
  );
};

const DCFCalculations = ({ mode, dcfData }) => {

  let category = [];

  category.push({
    name: "advancedDcf",
    text: "Advanced DCF"
  })
  category.push({
    name: "leveredDcf",
    text: "Levered DCF"
  });

  if (!dcfData) {
    return <SpinnerWrapper />
  }

  // const leveredData = dcfData.leveredDcf.reverse().map(i => {
  //   const { enterpriseValue, totalCash, totalDebt, dilutedSharesOutstanding, revenue } = i;
  //   return revenue;
  // })


  // const advancedData = dcfData.advancedDcf.reverse().map(i => {
  //   const { enterpriseValue, totalCash, totalDebt, dilutedSharesOutstanding, revenue } = i;
  //   return revenue;
  // })

  const leveredData = dcfData.leveredDcf.map(i => {
    const { enterpriseValue, freeCashFlow, operatingCashFlow, totalDebt, dilutedSharesOutstanding } = i;
    return ((enterpriseValue + freeCashFlow + operatingCashFlow - totalDebt) / dilutedSharesOutstanding)
  })
  const advancedData = dcfData.advancedDcf.map(i => {
    const { enterpriseValue, totalCash, totalDebt, dilutedSharesOutstanding } = i;
    return ((enterpriseValue + totalCash - totalDebt) / dilutedSharesOutstanding)
  })

  const chartData = {
    labels: [
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2024",
      "2025",
      "2026",
      "2027",
      "2028",
    ],
    dataUnit: "USD",
    datasets: [
      {
        label: "Advanced",
        color: "#8feac5",
        backgroundColor: "#8feac5",
        barPercentage: 0.8,
        categoryPercentage: 0.6,
        data: advancedData,
      },
      {
        label: "Levered",
        color: "#9cabff",
        backgroundColor: "#9cabff",
        barPercentage: 0.8,
        categoryPercentage: 0.6,
        data: leveredData,
      },
    ],
  };

  const stockPrice = dcfData?.dcf?.[0]?.["Stock Price"];
  const advanced = dcfData?.advancedDcf?.[0]?.['equityValuePerShare']
  const levered = dcfData?.leveredDcf?.[0]?.['equityValuePerShare']
  const advancedChange = advanced - stockPrice;
  const leveredChange = levered - stockPrice;

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-3">
        <CardTitle className="card-title">
          <h6 className="title">DCF valuation</h6>
          {/* <p>
            In last {dcfData === "set2" ? "30" : "15"} days buy and sells overview.{" "}
            <Link to={`${process.env.PUBLIC_URL}/invoice-list`} className="link link-sm">
              Detailed Stats
            </Link>
          </p> */}
        </CardTitle>
        <span>
          <TooltipComponent icon="info" direction="top" text={<>
            <p>
              Get the discounted cash flow (DCF) valuation for a company, a method to estimate the value of an investment based on its expected future cash flows.
            </p>
            <p>
              Market Cap = Weigted Average Shares Outstanding Diluted * Stock Price
            </p>
            <p>
              Enterprise Value NB = Market Cap + Long Term Debt + Short Term Debt
            </p>
            <p>
              Equity Value = Enterprise Value NB - Net Debt
            </p>
            <p>
              DCF = Equity Value / Weigted Average Shares Outstanding Diluted
            </p>
            <p>
              Stock Beta = 3-Year Monthly price change of stock relative to the monthly price change of the S&P500 (COV(Rs,RM) / VAR(Rm))
            </p>
          </>} id={"dcfInfo"} />
        </span>
        {/* <div className="card-tools mt-n1 me-n1">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li className={dcfData === "" ? "active" : ""}>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(e) => {
                      e.preventDefault();
                      // setData("");
                    }}
                  >
                    <span>15 Days</span>
                  </DropdownItem>
                </li>
                <li className={dcfData === "set2" ? "active" : ""}>
                  <DropdownItem
                    tag="a"
                    href="#dropdownitem"
                    onClick={(e) => {
                      e.preventDefault();
                      // setData("set2");
                    }}
                  >
                    <span>30 Days</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div> */}
      </div>
      <div className="nk-order-ovwg">
        <Row className="g-4 align-end">
          <Col xxl="8">
            <div className="nk-order-ovwg-ck">
              <DoubleBar data={chartData} />
            </div>
          </Col>
          <Col xxl="4">
            <Row className="g-4">
              <Col xxl="12" sm="6">
                <div className="nk-order-ovwg-data">
                  <div className="info">
                    <strong>
                      Advanced DCF
                    </strong>
                  </div>
                  <div className="amount text-main">
                    {strings.priceDecimal(dcfData.advancedDcf?.[0]?.['equityValuePerShare'])}
                  </div>
                  <span className={advancedChange > 0 ? "change up text-sucess" : "change down text-danger"}>
                    <Icon name={advancedChange > 0 ? "arrow-long-up" : "arrow-long-down"} />
                    {strings.percentSignFormatter(advancedChange / stockPrice * 100)}
                  </span>
                </div>
              </Col>
              <Col xxl="12" sm="6">
                <div className="nk-order-ovwg-data">
                  <div className="info">
                    <strong>
                      Levered DCF
                    </strong>
                  </div>
                  <div className="amount text-main">
                    {strings.priceDecimal(dcfData.leveredDcf?.[0]?.['equityValuePerShare'])}
                  </div>
                  <span className={leveredChange > 0 ? "change up text-sucess" : "change down text-danger"}>
                    <Icon name={leveredChange > 0 ? "arrow-long-up" : "arrow-long-down"} />
                    {strings.percentSignFormatter(leveredChange / stockPrice * 100)}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default DCFCalculations;
