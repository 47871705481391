import { Link } from "react-router-dom"
import Content from "../layout/content/Content"
import { Block, BlockContent, Icon, PreviewCard } from "./Component"

const OpenAccount = () => {

    return (
        <Content>
            <Block>
                <BlockContent>
                    <PreviewCard>
                        <h4 className="nk-block-title"><Icon name="info" className="text-warning" style={{
                            marginRight: 4
                        }} />Registered users only</h4>
                        <div className="nk-block-des">
                            <p style={{
                                fontSize: 18
                            }}>Open your free account now and get some ninja trading powers 🥷🏻</p>
                            <Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-primary btn-lg">
                                Open account
                            </Link>
                        </div>
                    </PreviewCard>
                </BlockContent>
            </Block>
        </Content>
    )
}

export default OpenAccount