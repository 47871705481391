import { useState, useEffect } from 'react';
import moment from 'moment';
import { getChartData } from '../../../../api';

const checkForBullishBearishEvents = ({ data, index }) => {
    const keys = Object.keys(data.bullish);
    for (let i = 0; i < keys.length; i++) {
        let k = keys[i];
        let t = data.bullish[k];
        if (t.includes(index)) {
            return k.replace("Res", "");
        }
    }
    return null
}

export const useData = ({ symbol, interval, from, to }) => {

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [point, setPoint] = useState(false);

    const loadChartData = async ({
        interval = "1 day",
        from = moment().subtract(1, 'years'),
        to = moment(),
    }) => {
        setIsLoading(true)
        getChartData({
            interval: interval,
            symbol,
            from,
            to,
        }, (res) => {
            let t = res;
            setData(t)
            setIsLoading(false)
        }, (err) => {
            console.error(err);
            setIsLoading(false)
        })
    }

    useEffect(() => {
        loadChartData({ symbol, interval, from, to });
    }, [interval])

    if (data?.chartData) {
        data.priceDataPoints = data?.chartData?.map(i => ({
            label: new Date(i.date),
            // x: new Date(i.date),
            y: [i.open, i.high, i.low, i.close], color: i.close < i.open ? "#e85347" : "#1ee0ac", mouseover: function (e) {
                setPoint(e)
            }
        }))
        data.smaDataPoints = data?.chartData?.map(i => ({ label: new Date(i.date), y: i?.['sma_50'] }));
        data.emaDataPoints = data?.chartData?.map(i => ({ label: new Date(i.date), y: i?.['ema_14'] }));
        data.rsiDataPoints = data?.chartData?.map(i => ({ label: new Date(i.date), y: i?.['rsi_20'] }));
        data.macdDataPoints = data?.chartData?.map(i => ({ label: new Date(i.date), y: i?.['macd_12_26_9'] }));
    }
    else {
        data.priceDataPoints = []
        data.smaDataPoints = []
        data.emaDataPoints = []
        data.rsiDataPoints = []
        data.macdDataPoints = []
    }

    return { data, loadChartData, isLoading, point };
}