import React, { memo, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import Autocomplete from '../../../../../components/autocomplete/Autocomplete';
import { Icon, NSComponent, PreviewCard, RSelect, TooltipComponent } from '../../../../../components/Component';
import { useCallback } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Collapse, Row } from 'reactstrap';
import ExpressionSelect from '../../Expressions/ExpressionsSelect';
import LimitConnections from '../Handlers/LimitConnections';
import AssetPrice from '../../Expressions/AssetPrice';
import { ThemeNode } from '../../themes';
import useStore from '../../../../../store/reactFlow';
import { useMyStore } from '../../../../bots/trading-bot-new/ReactFlowBuilder/store';
import Markdown from 'react-markdown';


const attrsOptions = [
  { label: "Close price", value: "close" },
  { label: "Open price", value: "open" },
  { label: "High price", value: "high" },
  { label: "Low price", value: "low" },
  { label: "Close price", value: "close" },
]
const operatorOptions = [
  { label: "Greater than", value: ">" },
  { label: "Less than", value: "<" },
  { label: "Equals", value: "==" },
  { label: "Not equals", value: "!=" },
  { label: "Greater then or equal", value: ">=" },
  { label: "Less then or equal", value: "<=" },
]

function ConditionNode({ id, data, selected }) {
  const { state } = data;

  const { updateNode, toggleIsOpen, tab, getConditionNodeStr } = useMyStore(({ updateNode, toggleIsOpen, tab, getConditionNodeStr }) => ({ updateNode, toggleIsOpen, tab, getConditionNodeStr }));

  const onChangeState = (key, value) => {
    let t = { ...state }
    t[key] = value;
    t.str = getConditionNodeStr({ ...t })
    updateNode({ id, data: { state: t }, symbol: tab })
  }

  return (
    <Card className="card-bordered condition-node" style={{
      borderColor: selected ? "#9d72ff" : ""
    }}>
      <CardHeader>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Icon onClick={() => toggleIsOpen({ id, data, symbol: tab })} name={data?.isOpen ? "minimize-alt" : "maximize-alt"} className={"hide-png"} style={{
            cursor: "pointer",
            alignSelf: "flex-end"
          }} />
          <h5 className="card-title">Condition</h5>
          <Markdown>{getConditionNodeStr({ ...data.state })}</Markdown>
        </div>
      </CardHeader>
      <Collapse
        className="accordion-body"
        isOpen={data?.isOpen}
      >
        <CardBody >

          <form className='nowheel'>
            <Row className='g-2'>
              <Col lg={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    <b>Left hand expression</b>
                  </label>
                  <div className="form-control-wrap" >
                    <ExpressionSelect expression={state.leftHandExpression} setExpression={(state) => {
                      onChangeState("leftHandExpression", state)
                    }} />
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <label className="form-label" htmlFor="phone-no">
                    <b>Operator</b>
                  </label>
                  <div className="form-control-wrap">
                    <RSelect className="nodrag" options={operatorOptions} value={state.operator} onChange={(e) => {
                      onChangeState("operator", e)
                    }} />

                  </div>
                </div>
              </Col>
              <Col lg={4}>

                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    <b>Right hand expression</b>
                  </label>
                  <div className="form-control-wrap">
                    <ExpressionSelect expression={state.rightHandExpression} setExpression={(state) => {
                      onChangeState("rightHandExpression", state)
                    }} />
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Collapse>

      <LimitConnections className="rl" type="source" position={Position.Right} />
      <LimitConnections className="rl" type="target" position={Position.Left} />
    </Card>
  )
}

export default memo(ConditionNode);
