import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import accountReducer from '../store/accountReducer';
import { LOGIN, LOGOUT } from '../store/actions';

// project imports
import { useLocation, useNavigate } from 'react-router';
import { Spinner } from 'reactstrap';
import { userLoggedIn } from '../api';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { handleError } from '../utils/errorHandling';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
};

let analytics;

// firebase initialize
if (!firebase.apps.length) {
    const app = firebase.initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
}

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    isLoggingIn: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    dispatch({ type: "SET_IS_LOGGING_IN", payload: { isLoggingIn: true } })
                    await userLoggedIn({
                        user
                    }, (res) => {
                        const { user, generalData } = res;
                        if (res) {
                            setUserId(analytics, user.uid);
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    user: user,
                                    generalData
                                }
                            });
                            // dispatch({ type: "SET_IS_LOGGING_IN", payload: { isLoggingIn: false } })

                            // navigate(pathname === "/" ? "/home" : pathname)
                        }
                    }, (err) => {
                        // setLoading(false);
                        // setUserLoggingIn(false);
                        handleError();
                    })
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                    pathname !== "/login" && navigate('/');
                }
            }),
        []
    );

    const firebaseEmailPasswordSignIn = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const firebaseRegister = async (email, password) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => {
        firebase.auth().signOut()
        dispatch({ type: LOGOUT });
        navigate('/');
    };

    const resetPassword = async (email) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const getAuthenticatedUser = async () => {
        const token = await firebase.auth()?.currentUser?.getIdToken();
        return token;
    };

    const updateProfile = () => { };
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Spinner />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => { },
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                getAuthenticatedUser,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
