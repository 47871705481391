import React, { useEffect, useState } from "react";
import { RSelect } from "../../../components/Component";

const exitStrategyOptions = [
  {value: "percent", label: "Percent profit"},
  {value: "dollar", label: "Dollar profit"},
  ]
    
const ExitStrategyType = ({exitStrategyType, setExitStrategyType}) => {
  return (
    <div className="form-group" style={{
      width: '100%',
    }}>
      <RSelect isDisabled={!exitStrategyType?.checked} className="nodrag" value={exitStrategyType?.type} options={exitStrategyOptions} onChange={setExitStrategyType} />
    </div>
  );
};
export default ExitStrategyType;