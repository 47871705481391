import OpenAccount from "../components/OpenAccount";
import useAuth from "../hooks/useAuth";

export default function CheckLoginUser({ children }) {
    const { user } = useAuth();

    if (user) {
        return (
            children
        )
    }

    else {
        return <OpenAccount />
    }
}