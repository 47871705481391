import { Link, useLocation } from "react-router-dom";
import { Block, BlockHead, BlockHeadContent, BlockTitle, DataTableRow, Icon, PreviewCard, UserAvatar } from "../../../components/Component"
import strings from "../../../utils/Strings";
import { findUpper } from "../../../utils/Utils";
import { BacktestBalanceChart } from "../../strategies/BacktestBalanceChart";
import BotsDataTable from "./BotsDataTable";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import useAuth from "../../../hooks/useAuth";
import AssetLogo from "../../../components/AssetLogo";
import { useParseBotDataTable } from "../../../hooks/useGetBotStats";
import { WPCharts } from "../../../components/partials/charts/analytics/AnalyticsCharts";
import chroma from "chroma-js";


const parseColumns = ({ onDelete, user, userPage, onCopy }) => ([
    {
        name: "Name",
        id: "name",
        selector: (row) => ({ name: row.name, _id: row._id, color: row.color }),
        grow: 1.5,
        style: { paddingRight: "20px" },
        cell: (row) => (
            <Link to={`${process.env.PUBLIC_URL}/bot/${row._id}`}>
                <div className="user-card mt-2 mb-2" >
                    <UserAvatar style={{
                        background: `${row.color}`,
                    }} theme={row.avatarBg} text={findUpper(row.name)} image={row.image}></UserAvatar>
                    <div className="user-info" style={{
                        display: '-webkit-box',
                        maxWidth: 100,
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                        <span className="tb-lead">
                            {row.name}{" "}
                            {/* <span
                            className={`dot dot-${row.status === "Active" ? "success" : row.status === "Pending" ? "warning" : "danger"
                                } d-md-none ms-1`}
                        ></span> */}
                        </span>
                        {/* <span>{row.email}</span> */}
                    </div>
                </div>
            </Link>
        ),
        sortable: true,
    },
    {
        name: "Assets",
        id: "assets",
        selector: (row) => {
            return row?.symbols
        },
        maxWidth: "140px",
        cell: (row) => (
            <ul className="project-users g-1">
                {row.symbols.slice(0, 2).map((symbol) => {
                    return (
                        <li>
                            <AssetLogo symbol={symbol} />
                        </li>
                    );
                })}
                {row.symbols.length > 2 && (
                    <li>
                        <UserAvatar style={{
                            width: 28, height: 28,
                            fontSize: 12
                        }} theme="light" size="xs" text={`+${row.symbols.length - 2}`} />
                    </li>
                )}
            </ul>
        ),
    },
    {
        name: "Total return",
        minWidth: "120px",
        id: "portfolioChange",
        selector: (row) => {
            return row?.portfolioChange
        },
        cell: (row) => (
            <span className={`change ${row?.portfolioChange > 0 ? "up" : row?.portfolioChange < 0 ? "down" : ""}`}>
                <Icon name={`${row?.portfolioChange > 0 ? "arrow-long-up" : row?.portfolioChange < 0 ? "arrow-long-down" : ""}`}></Icon>
                {strings.percentSignFormatter(row?.portfolioChange)}
            </span>
        ),
        sortable: true,
    },
    {
        name: "Max Drawdown",
        id: "maxDD",
        selector: (row) => row?.maxDD,
        sortable: true,
        cell: (row) => (
            <span className="tb-amount">
                {strings.percentSignFormatter(row?.maxDD)}
            </span>
        ),
    },
    {
        name: "Std. deviation",
        id: "stdDev",
        selector: (row) => row?.stdDev,
        sortable: true,
        cell: (row) => <span className="tb-amount">
            {strings.percentSignFormatter(row?.stdDev)}
        </span>,
    },
    {
        name: "Sharp ratio",
        id: "sharpRatio",
        minWidth: "120px",
        selector: (row) => row?.sharpRatio,
        sortable: true,
        cell: (row) => (
            <span className="tb-amount">
                {strings.decimal(row?.sharpRatio)}
            </span>
        ),
    },
    {
        name: "Calmar ratio",
        id: "calmarRatio",
        minWidth: "120px",
        selector: (row) => row?.calmarRatio,
        sortable: true,
        cell: (row) => (
            <span className="tb-amount">
                {strings.decimal(row?.calmarRatio)}
            </span>
        ),
    },
    {
        name: "Clones",
        id: "clones",
        sortable: true,
        omit: userPage,
        selector: (row) => {
            return row?.numberOfCopies
        },
        cell: (row) => (
            <label> {row?.numberOfCopies}</label>
        ),
    },
    {
        name: "Performance",
        id: "performance",
        grow: 1.5,
        selector: (row) => {
            console.log(row)
            // console.log(user, row)
            return row
        },
        cell: (row) => {
            const color = chroma(row.color);
            const chartData = {
                labels: row?.backtest?.performance?.balanceHistory.map(a => (a.time)),
                // dataUnit: "People",
                datasets: [
                    {
                        label: row.name,
                        lineTension: 0,
                        borderWidth: 2,
                        fill: true,
                        // color: row.color,
                        backgroundColor: 'transparent',
                        borderColor: row.color,
                        pointRadius: "0",
                        data: row?.backtest?.performance?.balanceHistory.map(a => (a.value)),
                    },
                ],
            };

            return <WPCharts data={chartData} />
        },
    },
    {
        name: "Actions",
        id: "actions",
        selector: (row) => {
            return row
        },
        // omit: row => {
        //     return (row?.userUID !== user?.uid)
        // },
        cell: (row) => {
            const editMode = row?.userUID === user?.uid;
            if (editMode) {
                return (
                    <DataTableRow className="nk-tb-col-tools text-end">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-xs btn-trigger btn-icon dropdown-toggle me-n1">
                                <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                    <li onClick={(event) => onDelete(row)}>
                                        <DropdownItem
                                            tag="a"
                                            href="#markasdone"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                            }}
                                        >
                                            <Icon name="delete"></Icon>
                                            <span>Delete</span>
                                        </DropdownItem>
                                    </li>
                                </ul>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </DataTableRow>
                )
            }
            else return (
                <DataTableRow className="nk-tb-col-tools text-end">
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-xs btn-trigger btn-icon dropdown-toggle me-n1">
                            <Icon name="more-h"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                                <li onClick={(event) => onCopy(row)}>
                                    <DropdownItem
                                        tag="a"
                                        href="#markasdone"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                        }}
                                    >
                                        <Icon name="copy"></Icon>
                                        <span>Clone to my bots</span>
                                    </DropdownItem>
                                </li>
                            </ul>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </DataTableRow>
            )
        },
    },
]);


const BotsTable = ({ botsData, onDelete, onCopy }) => {

    const { user } = useAuth();
    const { pathname } = useLocation();
    const userPage = pathname === "/bots"
    const columns = parseColumns({ onDelete, user, userPage, onCopy })

    const parsed = useParseBotDataTable({ botsData })
    return (
        <Block size="sm" >
            <BotsDataTable
                data={parsed}
                defaultSortField="portfolioChange"
                columns={columns}
                pagination
                defaultSortAsc={false}
            />
        </Block>
    )
}

export default BotsTable;