import React from 'react'
import { Container, Row, Col, Card } from 'reactstrap'
import { ImageBlock } from '../../components/images/Images'
import { Section } from '../../layout/section/Section'
import thumbImgOne from '../../images/onePager.png'
import thumbImg from '../../images/treandAnalysis.png'
import thumbImgTwo from '../../images/backtestPage.png'
import { TextBlock, TitleH2 } from '../../components/textBlock/TextBlock'
import { StyledIcon } from '../../components/icon/Icon'
import { ServiceText } from '../../components/service/Service'
import { Analytics, Idea, Badge, Box, Edit, Target, Paint } from '../../components/svg/Icon'

const FeatureFour = (props) => {
    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="flex-row-reverse align-items-center g-gs">
                    <Col lg="6">
                        <TextBlock>
                            <TitleH2>Company analysis</TitleH2>
                            <div className="mt-4 ms-n3 ms-sm-n4">
                                <Row className="justify-content-center gy-gs">
                                    <Col xs="10">
                                        <Card className="service service-inline service-s4 after-bg-danger">
                                            <div className="card-inner">
                                                <StyledIcon className="service-icon styled-icon-s4 styled-icon-5x text-danger">
                                                    <Box />
                                                </StyledIcon>
                                                <ServiceText>
                                                    <h5 className="title">Earning calls</h5>
                                                    <p>Explore company historical earning calls, estimations and actual results.</p>
                                                </ServiceText>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xs="10">
                                        <Card className="service service-inline service-s4 after-bg-primary">
                                            <div className="card-inner">
                                                <StyledIcon className="service-icon styled-icon-s4 styled-icon-5x text-primary">
                                                    <Badge />
                                                </StyledIcon>
                                                <ServiceText>
                                                    <h5 className="title">News and reddit analysis</h5>
                                                    <p>Analysing dozens of news sources and reddit most popular investment subreddits.</p>
                                                </ServiceText>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xs="10">
                                        <Card className="service service-inline service-s4 after-bg-pink">
                                            <div className="card-inner">
                                                <StyledIcon className="service-icon styled-icon-s4 styled-icon-5x text-pink">
                                                    <Idea />
                                                </StyledIcon>
                                                <ServiceText>
                                                    <h5 className="title">DCF caclculations</h5>
                                                    <p>Company discounted cash flow value (intrisic value), advanced dcf with WACC and levered in real time</p>
                                                </ServiceText>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </TextBlock>
                    </Col>
                    <Col lg="6">
                        <ImageBlock className="img-block-s1 pe-lg-6 pe-md-3">
                            <img src={thumbImgOne} alt="feature-img" />
                        </ImageBlock>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

const FeatureFourAlt = (props) => {
    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="align-items-center justify-content-between g-gs">

                    <Col xl="6" lg="7">
                        <TextBlock>
                            <TitleH2 className="text-dark text-center text-lg-start">Instantly create a trading bot and backtest it with no time</TitleH2>
                            <Row className="g-gs justify-content-center text-center">
                                <Col xs="6">
                                    <div className="service service-s4 h-100">
                                        <div className="card-inner">
                                            <StyledIcon className="service-icon styled-icon-4x text-primary">
                                                < Paint />
                                            </StyledIcon>
                                            <ServiceText>
                                                <h5 className="title">Build from scratch</h5>
                                                <p>Create a bot with our no-code, dynamic flow builder</p>
                                            </ServiceText>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="service service-s4 after-bg-danger h-100">
                                        <div className="card-inner">
                                            <StyledIcon className="service-icon styled-icon-4x text-danger">
                                                < Idea />
                                            </StyledIcon>
                                            <ServiceText>
                                                <h5 className="title">Explore ideas</h5>
                                                <p>Get new ideas from our community with thousands of trading bots</p>
                                            </ServiceText>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="service service-s4 after-bg-pink h-100">
                                        <div className="card-inner">
                                            <StyledIcon className="service-icon styled-icon-4x text-pink">
                                                < Analytics />
                                            </StyledIcon>
                                            <ServiceText>
                                                <h5 className="title">Optimization Tools</h5>
                                                <p>Run automatic optimizations to improve your bot performance</p>
                                            </ServiceText>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="service service-s4 after-bg-purple h-100">
                                        <div className="card-inner">
                                            <StyledIcon className="service-icon styled-icon-4x text-purple">
                                                < Target />
                                            </StyledIcon>
                                            <ServiceText>
                                                <h5 className="title">Backtest analysis</h5>
                                                <p>Backtest your bots with more than 30 years of historical data, monitor every step and benchmark your performance</p>
                                            </ServiceText>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TextBlock>
                    </Col>
                    <Col xl="6" lg="5">
                        <ImageBlock className="img-block-s2 ps-xl-6">
                            <img src={thumbImgTwo} alt="feature-img" />
                        </ImageBlock>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export { FeatureFour, FeatureFourAlt }
