import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Col,
  Icon,
  PaginationComponent,
  ProjectCard,
  Row,
  TooltipComponent,
  UserAvatar
} from "../../components/Component";
import { findUpper } from "../../utils/Utils";
// import { UserContext } from "./UserContext";

import { useMutation } from "@apollo/client";
import { storeFlowImage } from "../../api";
import { CREATE_BOT, COPY_BOT, DELETE_BOT, GET_USER_BOTS, GET_PUBLIC_BOTS } from "../../api/graphql";
import useAuth from "../../hooks/useAuth";
// import useStore from "../../store/reactFlow";
import { ModalDanger, ModalSuccess } from "../components/Modals";
import { defaultStrategy, tradingBotNames } from "../strategies/StrategyPolicyBuilder/RuleBuilder/constants";
import AssetLogo from "../../components/AssetLogo";
import useSubscriptionLimit from "../../hooks/useSubscriptionLimit";
import moment from "moment";
import BotsTable from "./trading-bot-new/BotsTable";
import BotFormModal from "./trading-bot-new/BotFormModal";
import BacktestCard from "./trading-bot-new/BacktestCard";

const BotList = ({ botsData, modal, setModal, mode, setMode }) => {
  // const { contextData } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: "",
    symbols: [],
    color: "#aabbcc",
    private: false,
    interval: { value: "1 day", label: "1 day" },
  })
  const [createBotMutationLink] = useMutation(CREATE_BOT);
  const [copyBotMutationLink] = useMutation(COPY_BOT);
  const [deleteStrategyMutationLink] = useMutation(DELETE_BOT);
  const [detail, setDetail] = useState({});
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  // const { strategyFormData: formData, setStrategyFormData: setFormData, setNodes, setEdges, setFlowImage } = useStore(({ strategyFormData: formData, setStrategyFormData: setFormData, setNodes, setEdges, setFlowImage }) => ({ strategyFormData: formData, setStrategyFormData: setFormData, setNodes, setEdges, setFlowImage }))
  const [successModalData, setSuccessModalData] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const toggleSuccess = () => setModalSuccess(!modalSuccess);

  const [createStrategyErrors, setCreateStrategyErrors] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [deleteModalData, setDeleteModalData] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCopyBot, setIsLoadingCopyBot] = useState(false);

  const navigate = useNavigate();
  const { refetch } = useSubscriptionLimit({ type: "bot", filter: user?.uid });

  const closeModal = () => {
    setModal({ add: false })
    // resetForm();
  };

  const closeEditModal = () => {
    setModal({ edit: false })
    // resetForm();
  };

  // submit function to add a new item
  const onCopyBot = async (data) => {
    try {
      setIsLoadingCopyBot(true);
      let submittedData = {
        botId: data._id,
        userUID: user.uid
      };

      const res = await copyBotMutationLink({
        variables: { input: submittedData },
        refetchQueries: [
          {
            query: GET_PUBLIC_BOTS,
            variables: {
              filter: { private: false, copyBotId: null, }
            }
          }
        ]
      },
      );
      const title = "Alrighty!";
      const desc = `We added ${data.name} to your bots!`;
      const successLink = `/bot/${res?.data?.copyBot?._id}`;
      const successDescription = "Let's add some logic and backtests.";
      const successTxt = "Check it out";

      setSuccessModalData({
        title,
        description: desc,
        successLink,
        successDescription,
        successTxt,
      });
      toggleSuccess();

      // resetForm();
      // setNodes([{
      //   id: "explain",
      //   type: "explain",
      //   data: {},
      //   position: { x: 0, y: 0 },
      // }]);
      // setFlowImage(null);
      // setEdges([]);
      // setModal({ add: false });
      setIsLoadingCopyBot(false);
      // navigate(`/bot/${res?.data?.botCreateOne?.record?._id}`)

      refetch();
    } catch (error) {
      console.log(error);
      let desc = "Somehing went wrong! please contact us at support@traid.ninja";
      let approveButtonText = null;
      if (error.message === "Reached strategies limit!") {
        desc = "You are a true ninja 🥷🏻! looks like you need an upgrade.."
        approveButtonText = "Upgrade now"
      }
      setCreateStrategyErrors({
        title: error.message,
        description: desc,
        cancelButtonText: "Back",
        approveButtonText,
        approveButtonColor: "success",
        onClickDelete: () => navigate('/plans')
      });
      setIsLoading(false);
      setShowErrorModal(true);
    }
  };

  // submit function to add a new item
  const onFormSubmit = async (data) => {
    try {
      setIsLoading(true);
      const botName = data?.name === "" ? tradingBotNames[Math.floor(Math.random() * tradingBotNames.length)] : data.name;
      const state = {};
      data?.symbols.map(s => {
        state[s] = {
          nodes: [],
          edges: []
        }
      })
      let submittedData = {
        name: botName,
        color: data.color,
        userUID: user.uid,
        symbols: data.symbols,
        tab: data?.symbols?.length ? data.symbols?.[0] : null,
        interval: { label: '1 day', value: "1 day" },
        state,
        description: data.description,
        flowDescription: "",
        private: data.private
      };

      const res = await createBotMutationLink({
        variables: { record: submittedData },
        refetchQueries: [
          {
            query: GET_USER_BOTS,
            variables: {
              filter: { uid: user.uid }
            }
          }
        ]
      },
      );
      const title = "Alrighty!";
      const desc = "Your bot created successfully!";
      const successLink = `/bot/${res?.data?.botCreateOne?.record?._id}`;
      const successDescription = "Let's add some logic and backtests.";
      const successTxt = "Check it out!";

      setSuccessModalData({
        title,
        description: desc,
        successLink,
        successDescription,
        successTxt,
      });
      toggleSuccess();

      // resetForm();
      // setNodes([{
      //   id: "explain",
      //   type: "explain",
      //   data: {},
      //   position: { x: 0, y: 0 },
      // }]);
      // setFlowImage(null);
      // setEdges([]);
      setModal({ add: false });
      setIsLoading(false);
      navigate(`/bot/${res?.data?.botCreateOne?.record?._id}`)

      refetch();
    } catch (error) {
      console.log(error);
      let desc = "Somehing went wrong! please contact us at support@traid.ninja";
      let approveButtonText = null;
      if (error.message === "Reached strategies limit!") {
        desc = "You are a true ninja 🥷🏻! looks like you need an upgrade.."
        approveButtonText = "Upgrade now"
      }
      setCreateStrategyErrors({
        title: error.message,
        description: desc,
        cancelButtonText: "Back",
        approveButtonText,
        approveButtonColor: "success",
        onClickDelete: () => navigate('/plans')
      });
      setIsLoading(false);
      setShowErrorModal(true);
    }
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = [...botsData]?.sort((a, b) => (b?.botStats?.portfolioChange - a?.botStats?.portfolioChange))?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onClickDelete = (strategy) => {
    const title = "YO!";
    const desc = "Are you sure you want to delete this bot?";

    setDeleteModalData({
      title,
      description: desc,
      approveButtonText: "Delete",
      approveButtonColor: "danger",
      cancelButtonText: "Back",
      idToDelete: strategy?._id,
      onClickDelete: async (id) => {
        await onApproveDelete(id)
      }
    });
    toggleDelete();
  }

  const onApproveDelete = async (id) => {
    const res = await deleteStrategyMutationLink({
      variables: { id },
      refetchQueries: [
        {
          query: GET_USER_BOTS,
          variables: {
            filter: { uid: user?.uid }
          }
        }
      ]
    },
    );
    refetch();
  }

  return (
    <React.Fragment>
      {mode === "cards" ? (<Block>
        <Row className="g-gs">
          {currentItems &&
            currentItems.map((item, idx) => {
              const b = item.botStats;
              const editMode = item?.userUID === user?.uid;
              return (
                <Col sm="6" lg="4" xxl="3" key={item.id}>
                  <ProjectCard>
                    <div className="project-head">
                      <Link className="project-title" to={`${process.env.PUBLIC_URL}/bot/${item._id}`}>
                        <UserAvatar style={{
                          background: `${item.color}`,
                        }} className="sq" text={findUpper(item.name)} />
                        <div className="project-info">
                          <h6 className="title">{item.name}</h6>
                          <span className="sub-text">{item.description}</span>
                        </div>
                      </Link>
                      {editMode ? <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                        >
                          <Icon name="more-h"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li
                            // onClick={() => onEditClick(item.id)}
                            >
                              <DropdownItem
                                tag="a"
                                href="#edit"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  onClickDelete(item)
                                }}
                              >
                                <Icon name="delete"></Icon>
                                <span>Delete</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown> : (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                          >
                            <Icon name="more-h"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li
                              // onClick={() => onEditClick(item.id)}
                              >
                                <DropdownItem
                                  tag="a"
                                  href="#copy"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    onCopyBot(item)
                                  }}
                                >
                                  <Icon name="copy"></Icon>
                                  <span>Clone to my bots</span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </div>
                    {item?.botStats ? (
                      <div className="project-details" style={{
                        // height: 80,
                        // overflowY: 'auto',
                      }}>
                        <BacktestCard botData={item} mode="botCard" />
                      </div>
                    ) : "No backtest data yet"}

                    <ul className="project-users g-2 mb-2">
                      {item?.symbols?.slice(0, 2)?.map((item, idx) => {
                        return (
                          <li key={idx}>
                            <AssetLogo symbol={item} />
                          </li>
                        );
                      })}
                      {item.symbols.length > 2 && (
                        <li>
                          <UserAvatar style={{
                            width: 28, height: 28,
                            fontSize: 12
                          }} theme="light" size="xs" text={`+${item.symbols.length - 2}`} />
                        </li>
                      )}
                    </ul>
                    <div className="project-meta">
                      {b && <Badge className="badge-dim">
                        <Icon name="clock"></Icon>
                        <span>{moment(b?.backtest?.start).format("MM/DD/YYYY")} - {moment(b?.backtest?.end).format("MM/DD/YYYY")}</span>
                      </Badge>}
                      <Badge><Icon name="hot" style={{
                        marginRight: 4
                      }} /> {item?.interval?.label}</Badge>
                    </div>
                  </ProjectCard>
                </Col>
              );
            })}
        </Row>
        <div className="mt-3">
          <PaginationComponent
            itemPerPage={itemPerPage}
            totalItems={botsData?.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </Block>) : (
        <BotsTable onCopy={onCopyBot} onDelete={onClickDelete} botsData={botsData} />
      )}
      <BotFormModal isLoading={isLoading} modal={modal?.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} />
      {/* <StrategyFormModal isLoading={isLoading} modal={modal.edit} modalType="edit" formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit} /> */}
      {successModalData && <ModalSuccess modalSuccess={modalSuccess} toggleSuccess={toggleSuccess} modalData={successModalData} />}
      {deleteModalData && <ModalDanger modalError={modalDelete} toggleError={toggleDelete} modalData={deleteModalData} />}
      {createStrategyErrors && <ModalDanger modalError={showErrorModal} toggleError={() => setShowErrorModal(!showErrorModal)} modalData={createStrategyErrors} />}
    </React.Fragment >
  );
};
export default BotList;
