import React, { useState, createContext, useContext, useEffect } from "react";
import { chatData, addUserData } from "./ChatData";
import { AppContext } from "../../../contexts/AppContext";
import { CREATE_CHAT, CREATE_MESSAGES, GET_USER_CHATS, UPDATE_USER_CHAT } from "../../../api/graphql";
import { Spinner } from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";
import { currentTime, sleep } from "../../../utils/Utils";
import { getNextMessage } from "../../../api/nemo";
import { handleError } from "../../../utils/errorHandling";
import useAuth from "../../../hooks/useAuth";

export const ChatContext = createContext();

export const ChatContextProvider = (props) => {
  const [favData, setFavData] = useState(chatData);
  const [userData, setUserData] = useState(addUserData);
  const [isLoadingNextMessage, setIsLoadingNextMessage] = useState(false)
  const [nemoState, setNemoState] = useState(false)

  const [createChatMutationLink] = useMutation(CREATE_CHAT);
  const [createMessagesMutationLink] = useMutation(CREATE_MESSAGES);
  const [updateChatMutationLink] = useMutation(UPDATE_USER_CHAT);

  const { state: { messageFeed }, dispatch } = useContext(AppContext);
  const { user } = useAuth();
  const { data, error, loading } = useQuery(GET_USER_CHATS, {
    variables: {
      filter: { uid: user.uid }
    }
  });

  useEffect(() => {
    if (data?.userOne) {
      const userChats = data?.userOne?.chats;
      const lastChat = userChats[userChats.length - 1];
      setNemoState(lastChat?.state || {})
    }
  }, [data])

  if (loading) return <Spinner />

  const getTraidNinjaResponse = async ({ userMsg, chatId }) => {
    setIsLoadingNextMessage(true);
    const currentChat = data?.userOne?.chats.filter((item) => {
      return item._id === chatId
    })?.[0];
    const nemoMessages = [
      ...currentChat.convo.map(i => {
        if (i.me) {
          return { content: i.chat[0], role: "user" }
        }
        else {
          return { content: i.chat[0], role: "assistant" }
        }
      }),
      ...messageFeed?.[chatId]?.map(i => {
        if (i.me) {
          return { content: i.chat[0], role: "user" }
        }
        else {
          return { content: i.chat[0], role: "assistant" }
        }
      }) || [],
      { role: "user", content: userMsg?.chat?.[0] }
    ];

    // const getNextMessage = async () => {
    //   await sleep(1000);
    //   return {role: "assistant", content: "OK"};
    // }

    const botResponse = await getNextMessage({ messages: nemoMessages, state: nemoState || {} }, async (res) => {
      let d = new Date()
      const botMsg = {
        chat: [res?.response?.[0]?.content],
        date: d,
        chatId
      }

      dispatch({
        type: "NEW_MESSAGE",
        payload: {
          chatId,
          message: botMsg
        }
      });
      setIsLoadingNextMessage(false);


      const resCreateMessages = await createMessagesMutationLink({
        variables: {
          records: [
            userMsg,
            botMsg
          ]
        },
      },
      );

      const updateChatState = await updateChatMutationLink({
        variables: { record: { state: res.state }, id: chatId },
        refetchQueries: [
          // {
          //   query: GET_USER_CHATS,
          //   // name: "strategyById",
          //   variables: { filter: { uid: user.uid } }
          // }
        ]
      },
      );

      setNemoState(res.state);
    }, err => {
      console.log(err);
      setIsLoadingNextMessage(false);
      handleError(err);
    });
  }

  const createNewChat = async (e) => {
    e.preventDefault();
    const res = await createChatMutationLink({
      variables: {
        record: {
          name: "Traid Ninja",
          userUID: user.uid,
          chatTheme: "purple",
        }
      },
      refetchQueries: [
        {
          query: GET_USER_CHATS,
          variables: {
            filter: { uid: user.uid }
          }
        }
      ]
    },
    );

    dispatch({
      type: "RESET_MESSAGE_FEED"
    })

    return res?.data?.chatCreateOne?.record?._id

  }

  // Universal function for any props
  const propAction = (id, prop) => {
    let data = chat;
    const index = data.findIndex((item) => item._id === id);
    data[index][prop] = true;
    setChat([...data]);
  };

  // Function to add favourite to favourite list
  const favAction = (id) => {
    let data = favData;
    const index = data.findIndex((item) => item._id === id);
    data[index].fav = true;
    setFavData([...data]);
    setChat([...data]);
  };

  // Function to change nickame
  const changeNickname = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item._id === id);
    data[index].nickname = name;
    setChat([...data]);
  };

  // Function to change theme
  const changeTheme = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item._id === id);
    data[index].chatTheme = name;
    setChat([...data]);
  };

  // Removes user from group
  const deleteUser = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item._id === id);
    const group = data.find((item) => item._id === id);
    const user = group.user.filter((user) => user._id !== userId);
    group.user = user;
    data[grouped] = group;
    setChat([...data]);
  };

  // Makes a user admin
  const makeAdmin = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item._id === id);
    const group = data.find((item) => item._id === id);
    group.user.forEach((element) => {
      element.role = "User";
    });
    const userIndex = group.user.findIndex((item) => item._id === userId);
    group.user[userIndex].role = "Admin";
    data[grouped] = group;
    setChat([...data]);
  };

  // Add a user to a chat
  const addUserToChat = (id, object) => {
    let data = chat;
    const group = data.find((item) => item._id === id);
    let newUserGroup = [...group.user, object];
    group.user = newUserGroup;
    setChat(data);
    let newUserData = userData;
    let user = newUserData.filter((el) => el._id !== object._id);
    setUserData(user);
  };

  return (
    <ChatContext.Provider
      value={{
        chatState: [data?.userOne?.chats, () => { }],
        chatData: data?.userOne?.chats,
        fav: [favData],
        userData: userData,
        propAction: propAction,
        favAction: favAction,
        changeNickname: changeNickname,
        changeTheme: changeTheme,
        deleteUser: deleteUser,
        makeAdmin: makeAdmin,
        addUserToChat: addUserToChat,
        createNewChat: createNewChat,
        getTraidNinjaResponse: getTraidNinjaResponse,
        isLoadingNextMessage: isLoadingNextMessage,
        nemoState: nemoState,
        setNemoState
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};
