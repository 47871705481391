import React, { useState } from "react";

const InputSwitch = ({ label, id, checked, onClick }) => {
  const [inputCheck, setCheck] = useState(checked ? true : false);

  return (
    <div onClick={onClick}>
      <input
        type="checkbox"
        className="custom-control-input"
        checked={checked}
        id={id}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default InputSwitch;
