import { Spinner } from "reactstrap"
import Content from "../layout/content/Content"

const SpinnerWrapper = () => {
    return (
        <>
        <Content>
            <Spinner />
        </Content>
        </>
    )
}

export default SpinnerWrapper;