import { Badge } from "reactstrap";
import strings from "./Strings";
import moment from "moment";

export function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = moment(startDate);
  var stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

export const getOrdersDailyPNL = ({ orders, last, prevClose }) => {
  const todayPurchase = orders.filter(o => {
    // Get today's date
    const d = new Date(o.orderDate)
    const today = new Date();
    // call setHours to take the time out of the comparison
    return d.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
  });
  const excludeTodayPurchase = orders.filter(o => {
    // Get today's date
    const d = new Date(o.orderDate)
    const today = new Date();
    // call setHours to take the time out of the comparison
    return d.setHours(0, 0, 0, 0) != today.setHours(0, 0, 0, 0)
  });
  const todayTotalShares = todayPurchase.reduce((acc, d) => acc + d.sizeValue, 0);
  const excludeTodayTotalShares = excludeTodayPurchase.reduce((acc, d) => acc + d.sizeValue, 0);

  const todayTotalPrice = todayPurchase.reduce((acc, d) => acc + (d.price * d.sizeValue), 0);
  const todayAvgPrice = todayTotalPrice / todayTotalShares;

  const newPositionsPNL = ((last - todayAvgPrice) * todayTotalShares);

  const dailyPNL = ((last - prevClose) * excludeTodayTotalShares) + newPositionsPNL

  return dailyPNL;
}

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

export const getChangeBadge = ({ val, ...props }) => {
  const floatVal = parseFloat(val);
  let icon = "";
  let color = "primary";
  if (floatVal < 0) {
    color = "danger"
    icon = <i className="mdi mdi-chevron-down"></i>
  };
  if (floatVal > 0) {
    color = "success";
    icon = <i className="mdi mdi-chevron-up"></i>
  }
  return (
    <Badge {...props}
      color={color}
    >{icon}{strings.percentSignFormatter(val)}</Badge>
  )
}

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  if (!string) return "";
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function Example : 10-02-2004
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  let d = new Date();
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getStripeClientIdKey = () => {
  return process.env.REACT_APP_STRIPE_MODE === 'test' ? 'stripeIdTest' : 'stripeId';
}

export const getThirds = (arr) => {
  let t = [];
  for (let i = 0; i < arr.length - 1; i = i + 3) {
    let tt = []
    if (arr[i] !== undefined) tt.push(arr[i])
    if (arr[i + 1] !== undefined) tt.push(arr[i + 1])
    if (arr[i + 2] !== undefined) tt.push(arr[i + 2])
    t.push([...tt]);
  }
  return t
}

export const mapIndexToLabel = (key) => {
  return {
    "^DJI": { label: "Dow Jones", market: "US" },
    "^VIX": { label: "VIX", market: "US" },
    "^IXIC": { label: "Nasdaq", market: "US" },
    "^GSPC": { label: "S&P 500", market: "US" },
    "^GDAXI": { label: "DAX", market: "Europe" },
    "^FTSE": { label: "FTSE", market: "Europe" },
    "^FCHI": { label: "CAC 40", market: "Europe" },
    "^IBEX": { label: "IBEX35", market: "Europe" },
    "^N225": { label: "Nikkei 225", market: "Asia" },
    "000001.SS": { label: "SSE", market: "Asia" },
    "^HSI": { label: "HSI", market: "Asia" },
    "^NSEI": { label: "NIFTY 50", market: "Asia" },
  }[key]
}

export const standardDeviation = (data) => {
  if (data.length === 0) return 0;

  // Calculate the mean (average)
  const mean = data.reduce((sum, value) => sum + value, 0) / data.length;

  // Calculate the squared differences from the mean
  const squaredDiffs = data.map(value => {
    const diff = value - mean;
    return diff * diff;
  });

  // Calculate the average of the squared differences
  const avgSquaredDiff = squaredDiffs.reduce((sum, value) => sum + value, 0) / data.length;

  // Take the square root of the average squared difference to get the standard deviation
  const standardDeviation = Math.sqrt(avgSquaredDiff);

  // Calculate the standard deviation as a percentage of the mean
  const standardDeviationPercentage = (standardDeviation / mean) * 100;

  return standardDeviationPercentage;
}

export function calculateDailyReturns(balances) {
  if (balances.length < 2) return [];

  let returns = [];
  for (let i = 1; i < balances.length; i++) {
    let dailyReturn = (balances[i] - balances[i - 1]) / balances[i - 1];
    returns.push(dailyReturn);
  }
  return returns;
}

export function calculateSharpeRatio(returns, riskFreeRate = 0) {
  if (returns.length === 0) return 0;

  // Calculate the mean return
  const meanReturn = returns.reduce((sum, value) => sum + value, 0) / returns.length;

  // Calculate the standard deviation of the returns
  const mean = meanReturn;
  const squaredDiffs = returns.map(value => {
    const diff = value - mean;
    return diff * diff;
  });
  const avgSquaredDiff = squaredDiffs.reduce((sum, value) => sum + value, 0) / returns.length;
  const standardDeviation = Math.sqrt(avgSquaredDiff);

  // Calculate the Sharpe Ratio
  const sharpeRatio = (meanReturn - riskFreeRate) / standardDeviation;

  return sharpeRatio;
}

function calculateCAGR(beginningBalance, endingBalance) {
  return (endingBalance / beginningBalance) - 1;
}

export function calculateMaxDrawdown(balances) {
  let maxDrawdown = 0;
  let peak = balances[0];

  for (let balance of balances) {
    if (balance > peak) {
      peak = balance;
    }
    let drawdown = (peak - balance) / peak;
    if (drawdown > maxDrawdown) {
      maxDrawdown = drawdown;
    }
  }
  return maxDrawdown * 100;
}

export const getPositionProfit = (p) => {
  return p?.type === "long" ? ((p?.exit?.price - p?.enter?.price) * (p?.enter?.size)) : ((p?.enter?.price - p?.exit?.price) * (p?.enter?.size))
}

export function calculateCalmarRatio(balances) {
  if (balances.length < 2) return 0;

  const beginningBalance = balances[0];
  const endingBalance = balances[balances.length - 1];

  const CAGR = calculateCAGR(beginningBalance, endingBalance);
  const maxDrawdown = calculateMaxDrawdown(balances);

  if (maxDrawdown === 0) return Infinity; // To avoid division by zero

  return CAGR / maxDrawdown;
}


export const getMarketStatusBadge = ({ val, ...props }) => {
  const floatVal = parseFloat(val);
  let icon = "";
  let color = "primary";
  if (val === "closed") {
    color = "danger"
    // icon = <i className="mdi mdi-chevron-down"></i>
  };
  if (val === "open") {
    color = "success";
    // icon = <i className="mdi mdi-chevron-up"></i>
  }
  return (
    <Badge {...props}
      color={color}
    >{icon}{val}</Badge>
  )
}

export const groupBy = function (xs, key) {
  if (xs)
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  return {}
};

export const getSentimentBadge = ({ label, score, ...props }) => {
  let icon = "";
  let color = "primary";
  if (label?.toLowerCase().includes("bull")) {
    color = "success"
    icon = <i className="mdi mdi-chevron-down"></i>
  };
  if (label?.toLowerCase().includes("bear")) {
    color = "danger";
    icon = <i className="mdi mdi-chevron-up"></i>
  }
  return (
    <Badge {...props}
      color={color}
    >{label} ({strings.decimalToPercent(score)}%)</Badge>
  )
}


export const createGraphqlVariables = (vars) => {
  return {
    input: {
      ...vars
    }
  }
}

export const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

export function* pairwise(arr, func) {
  for (var i = 0; i < arr.length - 1; i = i + 2) {
    yield func(arr[i], arr[i + 1], i)
  }
}

export const parseUserLevel = (level) => {
  switch (level) {
    case "babyNinja": return "Baby ninja"

    default:
      console.log("Unsupported level: " + level);
      return "";
  }
}

export function toArrayBuffer(buffer) {
  console.log(buffer.length)
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return arrayBuffer;
}

export const getCCIcon = (cc) => {
  switch (cc) {
    case "visa": return "visa"
    case "american-express": return "american-express"
    case "mastercard": return "cc-mc"

    default: return "stripe"
  }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const chatGroupedByDate = (array, key) =>
  Object.entries(
    array.reduce((result, { [key]: k, ...rest }) => {
      (result[k] = result[k] || []).push(rest);
      return result;
    }, {})
  ).map(([sentDate, messages]) => ({
    sentDate,
    messages
  }));