import React from "react";
import { Badge, CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";
import { Icon, PreviewCard } from "../../../components/Component";
import strings from "../../../utils/Strings";
import { calculateCalmarRatio, calculateDailyReturns, calculateMaxDrawdown, calculateSharpeRatio, standardDeviation } from "../../../utils/Utils";
import { BacktestBalanceChart } from "../../strategies/BacktestBalanceChart";
import { PortfolioChart } from "../../portfolios/PortfolioChart";
import { useMyStore } from "./ReactFlowBuilder/store";

const PortfolioChartCard = ({ backtestType, botData, setIsLoadingChartData, portfolioData, quotes, allMessages, chartData, mode, backtestData, backtestMode = "full" }) => {

  const statsObj = ["full", "preview"].includes(backtestMode) ? backtestData?.backtestStats : botData?.botStats;
  return (
    <React.Fragment>
      <>
        <div style={{
          height: ["botCard", "preview"].includes(backtestMode) ? 140 : 400
        }}>
          <BacktestBalanceChart
            botData={botData}
            data={backtestData} backtestMode={backtestMode} />
        </div>
        {
          backtestMode === "full" && (
            <CardBody>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 8
              }}>
                <Badge className="badge-dot" style={{
                  color: botData?.color
                }}>{botData?.name}</Badge>
                <Badge className="badge-dot" style={{
                  color: "#3a8dfe"
                }}>SPY</Badge>
              </div>
            </CardBody>
          )
        }
        {backtestMode !== 'full' && <Row className="g-1">
          <Col xs={4}>
            <div>
              <div className="title">{mode === "Portfolio" ? "NLV" : "Total return"}</div>
              {/* <label className="amount form-label">{strings.priceDecimal(nlv - portfolioData?.initialFunds)}</label> */}
              <label className={`change ${statsObj?.portfolioChange > 0 ? "up" : statsObj?.portfolioChange < 0 ? "down" : ""}`}>
                <Icon name={`${statsObj?.portfolioChange > 0 ? "arrow-long-up" : statsObj?.portfolioChange < 0 ? "arrow-long-down" : ""}`}></Icon>
                {strings.percentSignFormatter(statsObj?.portfolioChange)}
              </label>
            </div>
          </Col>
          <Col xs={4}>
            <div>
              <div className="title">{mode === "Portfolio" ? "NLV" : "Today's return"}</div>
              {/* <label className="amount form-label">{strings.priceDecimal(nlv - portfolioData?.initialFunds)}</label> */}
              <label className={`change ${statsObj?.portfolioChange > 0 ? "up" : statsObj?.portfolioChange < 0 ? "down" : ""}`}>
                <Icon name={`${statsObj?.portfolioChange > 0 ? "arrow-long-up" : statsObj?.portfolioChange < 0 ? "arrow-long-down" : ""}`}></Icon>
                {strings.percentSignFormatter(Math.floor(Math.random() * (10 - 1) + 1))}
              </label>
            </div>
          </Col>
          <Col xs={4}>
            {mode === "Backtest" && <div>
              <div className="title">Max drawdown</div>
              <label className="amount form-label">{strings.percentSignFormatter(statsObj?.maxDD)}</label>
            </div>}
          </Col>
          <Col xs={4}>
            {mode === "Backtest" && <div>
              <div className="title">Std. dev</div>
              <label className="amount form-label">{strings.percentSignFormatter(statsObj?.stdDev)}</label>
            </div>}
          </Col>
          <Col xs={4}>
            {mode === "Backtest" && <div>
              <div className="title">Sharp ratio</div>
              <label className="amount form-label">{strings.decimal(statsObj?.sharpRatio)}</label>
            </div>}
          </Col>
          <Col xs={4}>
            {mode === "Backtest" && <div>
              <div className="title">Calmar ratio</div>
              <label className="amount form-label">{strings.decimal(statsObj?.calmarRatio)}</label>
            </div>}
          </Col>
        </Row>}
      </>
    </React.Fragment>
  );
};
export default PortfolioChartCard;
