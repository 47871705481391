import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { Block, Icon, PreviewCard } from "../../../components/Component"
import StockChart from "./CanvaStockChart/CanvaStockChart";
import { useEffect, useState } from "react";
import { ModalDanger } from "../../components/Modals";
import Autocomplete from "../../../components/autocomplete/Autocomplete";
import { useMyStore } from './ReactFlowBuilder/store';

const SYMBOL_LIMIT = 2;


const ResearchPanelCard = ({
    id,
    // symbols, setSymbols, tab, setTab
}) => {
    const [deleteModalData, setDeleteModalData] = useState(null);
    const [modalDelete, setModalDelete] = useState(false);
    const { resetFlow, tab, setTab, symbols, onRemoveAsset, state, setState } = useMyStore();
    const onClickDeleteChat = (id, index) => {
        const title = "YO!";
        const desc = "Are you sure you want to remove this asset?";

        setDeleteModalData({
            title,
            description: desc,
            cancelButtonText: "Cancel",
            idToDelete: id,
            approveButtonColor: "danger",
            approveButtonText: "Delete",
            onClickDelete: async (id, index) => {
                await onApproveDelete(id, index);
            }
        });
        toggleDelete();
    }
    const toggleDelete = () => setModalDelete(!modalDelete);
    const onApproveDelete = async (asset) => {
        onRemoveAsset(asset);
    }

    useEffect(() => {
        if (symbols?.length > 0 && !tab) {
            setTab(symbols?.[0])
        }
    }, [symbols, tab, setTab])

    return (
        <PreviewCard id="bot-research-panel" className="h-100">
            <CardTitle>
                <h6>Research panel</h6>
            </CardTitle>
            {
                symbols?.length > 0 && (
                    <ul className="nav nav-tabs  nav-tabs-xs">
                        {
                            symbols?.map((s, i) => {
                                return (
                                    <li className="nav-item" >
                                        <label
                                            className={`nav-link ${tab === s ? "active" : ""}`}
                                        >
                                            <label
                                                // className="overline-title"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setTab(s)
                                                }
                                                }
                                            >
                                                {s}
                                            </label>
                                            <Button
                                                className="btn-icon"
                                                outline
                                                size="xs"
                                                color={"danger"}
                                                style={{
                                                    marginLeft: 8,
                                                    // color: "text-danger",
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onClickDeleteChat(s, i)
                                                }
                                                }
                                            >
                                                <Icon name="cross" style={{
                                                    fontSize: 10
                                                }} />
                                            </Button>
                                        </label>

                                    </li>
                                )
                            })
                        }
                    </ul>
                )}
            {symbols?.length > 0 && tab && <div style={{
                // height: "calc(100vh - 320px)",
                // height: "1000px",
                // overflowY: "scroll"
            }}>
                {/* {chartData?.bullishCounter} bullish signals in the last week
          {chartData?.bearishCounter} bearish signals in the last week */}

                {<StockChart symbol={tab} />}
            </div>}
            {deleteModalData && <ModalDanger modalError={modalDelete} toggleError={toggleDelete} modalData={deleteModalData} />}
        </PreviewCard>
    )
}

export default ResearchPanelCard