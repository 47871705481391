import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Link } from '../../components/button/Button'
import { TextBlock, TitleH2 } from '../../components/textBlock/TextBlock'
import { BgImage } from '../../layout/ovm/Ovm'
import { Section } from '../../layout/section/Section'
import { Icon } from '../../../components/Component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CtaFive = (props) => {
    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-center text-center">
                    <Col lg="9" md="10">
                        <TextBlock className="is-compact py-3">
                            <TitleH2>Trade like a ninja</TitleH2>
                            <p>Join our growing community and start blasting your trading with some ninja powers</p>
                            <ul className="btns-inline justify-center pt-2">
                                <li>
                                    <Link to="/login" rel="noreferrer" className="btn-xl btn-primary btn-round">Start for free</Link>
                                </li>
                            </ul>
                        </TextBlock>
                    </Col>
                </Row>
            </Container>
            <BgImage className="bg-image bg-overlay after-bg-dark after-opacity-90 overlay-fall bg-image-loaded bg-image-cta-a" />
        </Section>
    )
}

export default CtaFive
