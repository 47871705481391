import { Badge, Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";

const PostFeedVideo = ({ item, index }) => {
    const t = `${item.url.replace('/watch?v=', '/embed/')}${index === 0 ? "?autoplay=1" : ""}`
    console.log(t)
    console.log(`${item.url}`)
    return (
        <div style={{
            // height: 200,
            // width: 400
        }} >
            <CardBody>
                <iframe style={{
                    height: 150,
                    width: 240
                }} className='video'
                    // title='Youtube player'
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    src={t}>
                </iframe>
            </CardBody>
        </div>
    )
};

export default PostFeedVideo;
