import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

// search ticker from marketstack
function searchTicker(params, onSuccess, onFail) {
  const url = `${API_URL}/search-ticker`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

// search ticker from marketstack
function parseFlowSteps(params, onSuccess, onFail) {
  const url = `${API_URL}/parse-flow-steps`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

// search ticker from marketstack
function getLinkToken(params, onSuccess, onFail) {
  const url = `${API_URL}/api/create_link_token`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

// search ticker from marketstack
function setAccessToken(params, onSuccess, onFail) {
  const url = `${API_URL}/api/set_access_token`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

// search ticker from marketstack
function getPlaidItems(params, onSuccess, onFail) {
  const url = `${API_URL}/api/get_plaid_items`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

// search ticker from marketstack
function screenStocks(params, onSuccess, onFail) {
  const url = `${API_URL}/stock-screener`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getMarketIndicesPrices(onSuccess, onFail) {
  const url = `${API_URL}/get-market-indices-prices`;
  return axiosApi
    .get(url)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getHistoricalPriceFull(params, onSuccess, onFail) {
  const url = `${API_URL}/historical-price-full`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getStockPriceChange(params, onSuccess, onFail) {
  const url = `${API_URL}/stock-price-change`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

// search ticker from tradier
function tickerLookup(params, onSuccess, onFail) {
  const url = `${API_URL}/ticker-lookup`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

// search companies from tradier
function searchCompanies(params, onSuccess, onFail) {
  const url = `${API_URL}/search-companies`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function backtest(params, onSuccess, onFail) {
  const url = `${API_URL}/backtest`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getMarketData(onSuccess, onFail) {
  const url = `${API_URL}/get-us-market-data`;
  return axiosApi
    .get(url)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getFileFromS3(params, onSuccess, onFail) {
  const url = `${API_URL}/get-file-from-s3`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getStockPeers(params, onSuccess, onFail) {
  const url = `${API_URL}/stock-peers`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function historicalChart(params, onSuccess, onFail) {
  const url = `${API_URL}/historical-chart`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function endOfDay(params, onSuccess, onFail) {
  const url = `${API_URL}/eod`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getCompanyRatios(params, onSuccess, onFail) {
  const url = `${API_URL}/get-company-ratios`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getCompanyRatings(params, onSuccess, onFail) {
  const url = `${API_URL}/get-company-ratings`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getCompanyFilings(params, onSuccess, onFail) {
  const url = `${API_URL}/sec-filings`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getCompanyInformation(params, onSuccess, onFail) {
  const url = `${API_URL}/get-company-information`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getCompanyProfile(params, onSuccess, onFail) {
  const url = `${API_URL}/company-profile`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getCompanyOutlook(params, onSuccess, onFail) {
  const url = `${API_URL}/company-outlook`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getStockNews(params, onSuccess, onFail) {
  const url = `${API_URL}/get-stock-news`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getStockNewsWithSentiment(params, onSuccess, onFail) {
  const url = `${API_URL}/get-stock-news-with-sentiment`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getDCFCalculations(params, onSuccess, onFail) {
  const url = `${API_URL}/get-dcf-calculations`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getGeneralNews(params, onSuccess, onFail) {
  const url = `${API_URL}/get-general-news`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getIPOCalendar(onSuccess, onFail) {
  const url = `${API_URL}/ipo-calendar`;
  return axiosApi
    .get(url)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function historicalEarningCalendar(params, onSuccess, onFail) {
  const url = `${API_URL}/historical-earning-calendar`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function storeFlowImage(params, onSuccess, onFail) {
  const url = `${API_URL}/store-flow-image`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getFlowImage(params, onSuccess, onFail) {
  const url = `${API_URL}/get-flow-image`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function stripeCheckout(params, onSuccess, onFail) {
  const url = `${API_URL}/create-checkout-session`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getSocialTrending(onSuccess, onFail) {
  const url = `${API_URL}/get-social-trending`;
  return axiosApi
    .get(url)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function addPaymentMethod(params, onSuccess, onFail) {
  const url = `${API_URL}/create-setup-session`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}
function updatePaymentMethod(params, onSuccess, onFail) {
  const url = `${API_URL}/update-payment-method`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function deletePaymentMethod(params, onSuccess, onFail) {
  const url = `${API_URL}/delete-payment-method`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function cancelStripeSubscription(params, onSuccess, onFail) {
  const url = `${API_URL}/cancel-subscription-at-period-end`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function revertCancelSubsriptionAtPeriodEnd(params, onSuccess, onFail) {
  const url = `${API_URL}/revert-cancel-subscription-at-period-end`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function changeStripeSubsription(params, onSuccess, onFail) {
  const url = `${API_URL}/change-subscription`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getStripeConfig(onSuccess, onFail) {
  const url = `${API_URL}/config`;
  return axiosApi
    .get(url)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getEarningCalendar(params, onSuccess, onFail) {
  const url = `${API_URL}/earning-calendar`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

//Tradier
function getQuotes(params, onSuccess, onFail) {
  const url = `${API_URL}/get-quotes`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

//Tradier
function getFullQuotes(params, onSuccess, onFail) {
  const url = `${API_URL}/get-full-quotes`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function timeSales(params, onSuccess, onFail) {
  const url = `${API_URL}/timesales`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getMarketMovers(params, onSuccess, onFail) {
  const url = `${API_URL}/get-market-movers`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function userLoggedIn(params, onSuccess, onFail) {
  const url = `${API_URL}/user-logged-in`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function sendEmail(params, onSuccess, onFail) {
  const url = `${API_URL}/send-email`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

function getChartData(params, onSuccess, onFail) {
  const url = `${API_URL}/get-chart-data`;
  return axiosApi
    .post(url, params)
    .then(response => {
      onSuccess(response?.data);
    })
    .catch(error => {
      onFail(error);
    });
}

export {
  parseFlowSteps, sendEmail, addPaymentMethod, backtest, cancelStripeSubscription, changeStripeSubsription, deletePaymentMethod, endOfDay, getCompanyFilings, getCompanyInformation, getCompanyOutlook, getCompanyProfile, getCompanyRatings, getCompanyRatios, getEarningCalendar, getFileFromS3, getFlowImage, getFullQuotes, getGeneralNews, getHistoricalPriceFull, getIPOCalendar, getLinkToken, getMarketData, getMarketIndicesPrices,
  // fmp
  getChartData, getDCFCalculations, getMarketMovers, getPlaidItems, getQuotes, getSocialTrending, getStockNewsWithSentiment, getStockNews, getStockPeers, getStockPriceChange, getStripeConfig, historicalChart, historicalEarningCalendar, revertCancelSubsriptionAtPeriodEnd, screenStocks, searchCompanies, searchTicker, setAccessToken, storeFlowImage, stripeCheckout, tickerLookup, timeSales, updatePaymentMethod, userLoggedIn
};

