import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap";
import { DELETE_BACKTEST, GET_STRATEGY_BACKTESTS } from "../../api/graphql";
import {
  Block,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Row,
  TooltipComponent
} from "../../components/Component";
import strings from "../../utils/Strings";
import { ModalDanger } from "../components/Modals";

const BacktestList = ({ strategyData }) => {
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [data, setData] = useState(strategyData?.backtests);
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [deleteBacktestMutationLink] = useMutation(DELETE_BACKTEST);

  useEffect(() => {
    setData(strategyData?.backtests)
  }, [strategyData])

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  // useEffect(() => {
  //   if (onSearchText !== "") {
  //     const filteredObject = backtests.filter((item) => {
  //       return item.name.toLowerCase().includes(onSearchText.toLowerCase());
  //     });
  //     setData([...filteredObject]);
  //   } else {
  //     setData([...backtests]);
  //   }
  // }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to declare the state change
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to select all the items of the table
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.check = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function to change the property of an item of the table
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item._id === id);
    newData[index].check = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to fire actions after dropdowm select
  const onActionClick = (e) => {
    if (actionText === "Reject") {
      let newData = data.map((item) => {
        if (item.check === true) item.status = "Rejected";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "Delete") {
      let newData;
      newData = data.filter((item) => item.check !== true);
      setData([...newData]);
    }
  };

  // function to load detail data
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item._id === id);
    setDetail(data[index]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onClickDelete = (backtest) => {
    const title = "YO!";
    const desc = "Are you sure you want to delete this backtest?";

    setDeleteModalData({
      title,
      description: desc,
      cancelButtonText: "Cancel",
      idToDelete: backtest?._id,
      approveButtonColor: "danger",
      approveButtonText: "Delete",
      onClickDelete: async (id) => {
        await onApproveDelete(id)
      }
    });
    toggleDelete();
  }

  const onApproveDelete = async (id) => {
    const res = await deleteBacktestMutationLink({
      variables: { id },
      refetchQueries: [
        {
          query: GET_STRATEGY_BACKTESTS,
          variables: { id: strategyData?._id }
        }
      ]
    },
    );
  }

  return (
    <Block>
      <DataTable>
        {/* <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                    <div className="form-wrap">
                      <RSelect
                        options={bulkActionKycOptions}
                        className="w-130px"
                        placeholder="Bulk Action"
                        onChange={(e) => onActionText(e)}
                      />
                    </div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          color="light"
                          outline
                          disabled={actionText === "" ? true : false}
                          className="btn-dim"
                          onClick={() => onActionClick()}
                        >
                          Apply
                        </Button>
                      </span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText === "" ? true : false}
                          className="btn-dim btn-icon"
                          onClick={() => onActionClick()}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </Button>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  end
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Advanced Filter</span>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Doc Type</label>
                                          <RSelect options={filterDoc} placeholder="Any Type" />
                                        </div>
                                      </Col>
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect options={filterStatus} placeholder="Any Status" />
                                        </div>
                                      </Col>
                                      <Col size="12">
                                        <div className="form-group">
                                          <Button type="button" color="secondary">
                                            Filter
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      className="clickable"
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Reset Filter
                                    </a>
                                    <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                      className="search-back btn-icon toggle-search"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div> */}
        <DataTableBody>
          <DataTableHead>
            <DataTableRow>
              <span>Name</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span>Status</span>
            </DataTableRow>
            <DataTableRow>
              <span>Profit</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span>Initial funds</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span>Interval</span>
            </DataTableRow>
            <DataTableRow size="md">
              <span>Baseline asset</span>
            </DataTableRow>
            <DataTableRow size="md" className="nk-tb-col-tools">&nbsp;</DataTableRow>
          </DataTableHead>

          {currentItems?.length > 0
            ? currentItems?.map((item) => {
              return (
                <DataTableItem key={item._id}>
                  <DataTableRow>
                    <Link to={`${process.env.PUBLIC_URL}/strategy/${strategyData?._id}/backtest/${item._id}`}>
                      <div className="user-card">
                        <div className="user-info">
                          <span className="tb-lead">
                            {item.name}{" "}
                            <span
                              className={`dot dot-${item.status === "done"
                                ? "success"
                                : item.status === "running"
                                  ? "info"
                                  : "danger"
                                } d-md-none ms-1`}
                            ></span>
                          </span>
                          {/* <span>{item._id}</span> */}
                        </div>
                      </div>
                    </Link>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <Badge
                      size="sm"
                      color={
                        item.status === "running"
                          ? "outline-success"
                          : item.status === "done"
                            ? "outline-info"
                            : "outline-danger"
                      }
                      className="badge-dim"
                    >{item.status}</Badge>
                  </DataTableRow>
                  <DataTableRow>
                    {item.status === "running" ? "" : <span className={item?.performance?.totalProfit < 0 ? "text-danger" : "text-success"}>{strings.formatNumber(item?.performance?.totalProfit)}</span>}
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="tb-lead-sub">{strings.formatNumber(item.initialFunds)}</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="tb-lead-sub">{item?.interval?.label}</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="tb-lead-sub">{item.baselineAsset.value}</span>
                  </DataTableRow>

                  <DataTableRow className="nk-tb-col-tools" size="md">
                    <ul className="nk-tb-actions gx-1">
                      <li
                        className="nk-tb-action-hidden"
                        onClick={() => {
                          loadDetail(item._id);
                          setViewModal(true);
                        }}
                      >
                        <TooltipComponent
                          tag="a"
                          containerClassName="btn btn-trigger btn-icon"
                          id={"view" + item._id}
                          icon="eye-fill"
                          direction="top"
                          text="Quick View"
                        />
                      </li>
                      {/* {item.status === "Rejected" ? null : item.status === "Approved" ? (
                              <li className="nk-tb-action-hidden" onClick={() => onRejectClick(item._id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"reject" + item._id}
                                  icon="cross-fill-c"
                                  direction="top"
                                  text="Reject"
                                />
                              </li>
                            ) : (
                              <React.Fragment>
                                <li className="nk-tb-action-hidden" onClick={() => onApproveClick(item._id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"approve" + item._id}
                                    icon="check-fill-c"
                                    direction="top"
                                    text="Approve"
                                  />
                                </li>
                                <li className="nk-tb-action-hidden" onClick={() => onRejectClick(item._id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"reject" + item._id}
                                    icon="cross-fill-c"
                                    direction="top"
                                    text="Reject"
                                  />
                                </li>
                              </React.Fragment>
                            )} */}
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                            <Icon name="more-h"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#view"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    loadDetail(item._id);
                                    setViewModal(true);
                                  }}
                                >
                                  <Icon name="eye"></Icon>
                                  <span>Quick View</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/strategy/${strategyData?._id}/backtest/${item._id}`}
                                >
                                  <Icon name="focus"></Icon>
                                  <span>View Details</span>
                                </Link>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#view"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    onClickDelete(item)
                                  }}
                                >
                                  <Icon name="trash"></Icon>
                                  <span>Delete</span>
                                </DropdownItem>
                              </li>
                              {/* {item.status === "Rejected" ? null : item.status === "Approved" ? (
                                    <li onClick={() => onRejectClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#reject"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="na"></Icon>
                                        <span>Reject User</span>
                                      </DropdownItem>
                                    </li>
                                  ) : (
                                    <React.Fragment>
                                      <li onClick={() => onApproveClick(item._id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#approve"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="check-thick"></Icon>
                                          <span>Approve</span>
                                        </DropdownItem>
                                      </li>
                                      <li onClick={() => onRejectClick(item._id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#suspend"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="na"></Icon>
                                          <span>Suspend User</span>
                                        </DropdownItem>
                                      </li>
                                    </React.Fragment>
                                  )} */}
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </DataTableRow>
                </DataTableItem>
              );
            })
            : null}
        </DataTableBody>
        <div className="card-inner">
          {currentItems?.length > 0 ? (
            <PaginationComponent
              noDown
              itemPerPage={itemPerPage}
              totalItems={data.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">No data found</span>
            </div>
          )}
        </div>
      </DataTable>
      <Modal isOpen={viewModal} toggle={() => setViewModal(false)} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setViewModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="nk-modal-head">
            <h4 className="nk-modal-title title">
              Backtest quick view / <small className="text-primary"> {detail._id}</small>
            </h4>
          </div>
          <div className="nk-tnx-details mt-sm-3">
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text"> ID</span>
                <span className="caption-text">{detail._id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text"> Name </span>
                <span className="caption-text text-break">{detail.name}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Total profit </span>
                <span className="caption-text">{strings.formatNumber(detail?.performance?.totalProfit) || "Still running..."}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Status</span>
                <Badge
                  color={detail.status === "running" ? "success" : detail.status === "done" ? "info" : "danger"}
                  size="md"
                >
                  {detail.status}
                </Badge>
              </Col>
              {/* <Col lg={6}>
                <span className="sub-text">Date</span>
                <span className="caption-text"> {detail.date}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Checked By</span>
                <span className="caption-text"> {detail.checked}</span>
              </Col> */}
            </Row>
          </div>
        </ModalBody>
      </Modal>
      {deleteModalData && <ModalDanger modalError={modalDelete} toggleError={toggleDelete} modalData={deleteModalData} />}
    </Block>
  );
};
export default BacktestList;
