import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Icon,
  Button,
  Col,
  RSelect,
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Spinner,
  Badge,
} from "reactstrap";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import Autocomplete from "../../../components/autocomplete/Autocomplete";
import AssetLogo from "../../../components/AssetLogo";
import { intervalOptions } from "../../strategies/BacktestFormModal";
import { HexColorPicker } from "react-colorful";
import CustomColorPicker from "../../../components/CustomColorPicker";
import { useMyStore } from "./ReactFlowBuilder/store";


const ASSETS_LIMIT = 3;
export const assetData = [
  { value: "AAPL", label: "AAPL" },
  { value: "MSFT", label: "MSFT" },
  { value: "TSLA", label: "TSLA" },
  { value: "SPY", label: "SPY" },
];

const schema = yup
  .object({
    interval: yup.object().required(),
    symbols: yup
      .array().of(yup.string())
      .min(1)
  })
  .required()

const BotFormModal = ({ isLoading, modal, closeModal, onSubmit, formData, setFormData, modalType }) => {
  const { control, reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    reset(formData)
  }, [formData]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'symbols',
    rules: {
      required: true
    }
  })

  const { botPrivate, toggleBotPrivate } = useMyStore();

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="xl">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {modalType === "add" && "Let's create a new bot 🤖"} {modalType === "edit" && "Edit bot"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md="4">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    {...register('name', { required: "This field is required" })}
                    value={formData.name}
                    placeholder="RSI double down"
                    onChange={(e) => {
                      e.preventDefault();
                      setFormData({ ...formData, name: e.target.value })
                    }}
                    className="form-control" />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <label className="form-label">Interval</label>
                  <RSelect
                    // className="form-control form-control-sm"
                    value={formData.interval} onChange={(v) => {
                      setFormData({ ...formData, interval: v })
                    }} options={intervalOptions} />
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <input
                    value={formData.description}
                    placeholder="Buy when RSI crosses 40, double down when crosses 60 and sell when goes below 50"
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="form-control-lg form-control no-resize" />
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <label className="form-label">Color</label>
                  <div className="form-control-wrap">
                    <CustomColorPicker color={formData.color} setColor={color => setFormData({ ...formData, color })} />
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className="form-group">
                  <div className="custom-control custom-control-sm custom-checkbox" >
                    <input
                      type="checkbox"
                      className="form-control custom-control-input"
                      id="private"
                      name="private"
                      checked={formData.private}
                      onChange={(e) => {
                        setFormData({ ...formData, private: e.target.checked })
                      }}
                    />
                    <label className="form-label custom-control-label" htmlFor="private">private</label>
                  </div>
                </div>
              </Col>
              <Col xs="12">
                <div className="form-group">
                  <label className="form-label">{formData?.symbols?.length < ASSETS_LIMIT ? "Add the assets your bot is going to trade with" : `Maximum of ${ASSETS_LIMIT} assets in free plan.`}</label>
                  {formData?.symbols?.length < ASSETS_LIMIT && <Autocomplete
                    resetSearch={true}
                    onClickOption={(event, option) => {
                      if (!formData.symbols.includes(option))
                        setFormData({ ...formData, symbols: [...formData.symbols, option] })
                    }} />}
                </div>
                {errors?.symbols && <span className="text-danger">Add at least one symbol!</span>}
              </Col>
              {formData.symbols.length > 0 && <Col xs="12">
                <h6 className="form-label">Assets</h6>
                <ul className="preview-list">
                  {formData?.symbols?.map((v, i) => {
                    return (
                      <li className="preview-item">
                        <Badge style={{
                          padding: 8,
                        }}>{v}<span style={{
                          color: "primary",
                          cursor: "pointer",
                          marginLeft: 8,
                        }} onClick={() => {
                          let t = [...formData.symbols];
                          t.splice(i, 1);
                          setFormData({ ...formData, symbols: t })
                        }}>
                            {/* <AssetLogo symbol={v} /> */}
                            x</span></Badge>
                      </li>
                    )
                  })}
                </ul>
              </Col>}
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2" style={{
                  float: "right"
                }}>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {isLoading && <Spinner size="sm" type="grow" />}
                      {modalType === "add" && "Create bot"} {modalType === "edit" && "Update bot"}
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody >
    </Modal >
  );
};
export default BotFormModal;
