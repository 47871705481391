import React from "react"
import PropTypes from "prop-types"
import {
    Badge,
    Card,
    CardBody,
} from "reactstrap"
import moment from "moment"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "../../../node_modules/swiper/swiper.scss";
import { average, getSentimentBadge, groupBy, pairwise } from "../../utils/Utils.js"
// import Bubble from "pages/AllCharts/echart/bubblechart"
import strings from "../../utils/Strings.js";
import NewsTrendChart from "../news-trend-chart/NewsTrendChart.js";
import RedditSubmissionCard from "../RedditSubmissionCard.js";

const NewsCard = ({ v, i }) => {
    return (<div className={(i % 2 === 0) ? `bg-light p-3 d-flex mb-3 rounded` : "bg-light p-3 d-flex"}>
        <div className="flex-grow-1">
            {getSentimentBadge({ label: v.sentiment_label, score: v.sentiment_score })}
            <h5 className="font-size-15 mb-2"><a target="_blank" href={v.link} className="text-body">{v.title}</a></h5>
            <p className="mb-0 text-muted">{v.description}</p>
            <p style={{
                float: "right",
                marginTop: "10px",
            }}>{moment(v.pub_date).format("MM/DD/YYYY hh:mm")}</p>
            {v?.nasdaq_tickers !== "" && <p>tickers: {v?.nasdaq_tickers}</p>}
        </div>
    </div>)
}

const groupOverallSentiment = (label) => {
    const lc = label?.toLowerCase();
    if (lc?.includes("bull")) {
        return "bullish";
    }
    if (lc?.includes("bear")) {
        return "bearish";
    }
    return "neutral";
}
const Reddit = ({ data }) => {
    const feed = data?.map(i => ({ ...i, sentiment_label: ((i.sentiment_score < 0.1 && i.sentiment_score > -0.1) ? "neutral" : (i.sentiment_score > 0.1 ? "bullish" : "bearish")) }));

    // customize date for group by
    let feedWithGroupedSentiment = feed?.map(i => {
        return ({ ...i, dateOnly: moment(i.created_utc*1000).format("MM/DD/YYYY") })})
    const groupedBySentiment = feed ? groupBy(feedWithGroupedSentiment, 'sentiment_label') : null;

    const groupedBullish = feed ? groupBy(groupedBySentiment?.['bullish'], 'dateOnly') : {};
    const groupedBearish = feed ? groupBy(groupedBySentiment?.['bearish'], 'dateOnly') : {};
    const groupedNeutral = feed ? groupBy(groupedBySentiment?.['neutral'], 'dateOnly') : {};
    const newSentimentNeutralData = [];
    Object.keys(groupedNeutral).map(k => {
        const avg = strings.decimal(average(groupedNeutral[k].map(i => i.sentiment_score * 90)));
        newSentimentNeutralData.push({x: k, y: parseFloat(avg), z: groupedNeutral[k].length, label: `${groupedNeutral[k].length} posts with ${avg} average score`})
    });
    const newSentimentBullishData = [];
    Object.keys(groupedBullish).map(k => {
        const avg = strings.decimal(average(groupedBullish[k].map(i => i.sentiment_score * 90)));
        newSentimentBullishData.push({x: k, y: parseFloat(avg), z: groupedBullish[k].length, label: `${groupedBullish[k].length} posts with ${avg} average score`})
    });
    const newSentimentBerishData = [];
    Object.keys(groupedBearish).map(k => {
        const avg = strings.decimal(average(groupedBearish[k].map(i => i.sentiment_score * 90)));
        newSentimentBerishData.push({x: k, y: parseFloat(avg), z: groupedBearish[k].length, label: `${groupedBearish[k].length} posts with ${avg} average score`})
    });

    let newsChartData = [
        newSentimentNeutralData,
        newSentimentBerishData,
        newSentimentBullishData,
    ]

    // console.log(newsChartData)

    // newsChartData = newsChartData?.map(i => {
    //     return i.sort(function (a, b) {
    //         var c = new Date(a[0]);
    //         var d = new Date(b[0]);
    //         return c - d;
    //     });
    // })

    return (
        <Card>
            
                <h6 className="card-title mb-3">Top Reddit posts</h6>
                <div>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true,
                    }}
                >
                    <div className="carousel-inner">
                        {
                            feed.map((value, i) => {
                                return (
                                    <SwiperSlide>
                                        <div className="carousel-item active" data-bs-interval="500">
                                        <RedditSubmissionCard item={value} />
                                        </div>
                                    </SwiperSlide>
                                )
                            })

                        }
                    </div>
                </Swiper>
                
                <div className="mt-4">
                    <h6 className="card-title">Trend analysis</h6>
                    <div>
                    <NewsTrendChart data={newsChartData} yLabel="post"/>
                    </div>
                </div>
                </div>
        </Card>
    )
}

export default Reddit
