import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CREATE_BACKTEST, GET_STRATEGY_BACKTESTS } from "../../api/graphql";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewAltCard,
  Row
} from "../../components/Component";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import SubscriptionLimit from "../../components/SubscriptionLimit";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import strings from "../../utils/Strings";
import { ModalDanger } from "../components/Modals";
import BacktestFormModal from "./BacktestFormModal";
import BacktestList from "./BacktestList";

let oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
const initialBacktest = {
  start: oneWeekAgo,
  interval: { value: "15", label: "15 min" },
  end: new Date(),
  baselineAsset: "SPY",
  initialFunds: "10000",
  name: "New backtest2",
  yyy: "TTT"
}

const StrategyPage = () => {
  const { pathname } = useLocation();
  const strategyId = pathname.split('/')[2];
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [formData, setFormData] = useState(initialBacktest);
  const [showFlowImage, setShowFlowImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [createBacktestErrors, setCreateBacktestErrors] = useState(false);
  const [sm, updateSm] = useState(false);
  const [mobileView , setMobileView] = useState(false);
  const { data: strategyData, refetch, fetchMore, loading, error } = useQuery(GET_STRATEGY_BACKTESTS, {
    variables: 
       {id: strategyId}
  });
  const [createBacktestMutationLink] = useMutation(CREATE_BACKTEST);
  const [data, setData] = useState(strategyData?.strategyById);
  const navigate = useNavigate();

  let { kycId } = useParams();

  const closeFlowImageModal = () => {
    setShowFlowImage(false);
  }

  useEffect(() => {
    setData(strategyData?.strategyById)
  })
  useEffect(() => {
    const id = kycId
    if (id !== undefined || null || "") {
      let spUser = data.find((item) => item._id === id);
      // setUser(spUser);
    } else {
      // setUser(data[0]);
    }
  }, [data]);

  // function to reset the form
  const resetForm = () => {
    setFormData(initialBacktest);
  };


  const closeModal = () => {
    setModal({ add: false })
    // resetForm();
  };

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  const onFormSubmit = async (sData) => {
    setIsLoading(true);
    const { name, baselineAsset, interval, description, initialFunds, start, end } = sData;
    let submittedData = {
      name, 
      baselineAsset: { type: 'stock', value: baselineAsset }, 
      description, interval: interval, initialFunds: parseInt(initialFunds.replace(/,/g, "")), start, end,
      strategyId: data?._id
    };

    try {
      const {errors, data: createBacktestData} = await createBacktestMutationLink({
        variables: {
          input: {
              backtest: submittedData,
              strategy: data
          }
        },
        refetchQueries: [
          {
            query: GET_STRATEGY_BACKTESTS,
            // name: "strategyById",
            variables: {id: strategyId}
          }
        ]
      },
      );
      resetForm();
      setModal({ add: false });
      setIsLoading(false);
    } catch (error) {
      let desc = "Somehing went wrong! please contact us at support@traid.ninja"
      let approveButtonText = null;
      if(error.message === "Reached backtests limit!") {
        desc = "You are a true ninja 🥷🏻! looks like you need an upgrade.."
        approveButtonText = "Upgrade now";
      }
        setCreateBacktestErrors({
          title: error.message,
          description: desc,
          cancelButtonText: "Back",
          approveButtonText,
          approveButtonColor: "success",
          onClickDelete: () => navigate('/plans')
        });
        setIsLoading(false);
        setShowErrorModal(true);
    }
  };

  if(loading) return <SpinnerWrapper />

  return (
    <>
      <Head title="Strategy"></Head>
      {data && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle page>
                  Strategies / <strong className="text-primary small">{data.name}</strong>
                </BlockTitle>
                {/* <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Application ID: <span className="text-base">KID000844</span>
                    </li>
                    <li>
                      Submitted At: <span className="text-base">{data.date}</span>
                    </li>
                  </ul>
                </BlockDes> */}
              </BlockHeadContent>
              <BlockHeadContent>
                <Link to={`${process.env.PUBLIC_URL}/strategies`}>
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>
                  <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </Link>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Row className="gy-5">
              <Col lg="5">
                <PreviewAltCard className="card-bordered">
                  <div className="card-title-group pb-3 g-2">
                    <BlockHead>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">General</BlockTitle>
                        <p>General strategy metadata</p>
                      </BlockHeadContent>
                    </BlockHead>
                  </div>
                  <div className="analytic-ov">
                    <div className="data-col">
                      <div className="data-label">Strategy ID</div>
                      <div className="data-value" style={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block" }}>{data._id}</div>
                    </div>
                    <div className="data-col">
                      <div className="data-label">Created At</div>
                      <div className="data-value">{strings.dateTime(data.createdAt)}</div>
                    </div>
                    <div className="data-col">
                      <div className="data-label">Description</div>
                      <div className="data-value" style={{ overflow: "hidden", textOverflow: "ellipsis", display: "inline-block" }}>{data.description}</div>
                    </div>
                  </div>
                </PreviewAltCard>

                <PreviewAltCard className="card-bordered">
                  <BlockHead>
                    <BlockHeadContent>
                      <BlockTitle tag="h5">Flow</BlockTitle>
                      <p>Your streategy on-tick policy</p>
                    </BlockHeadContent>
                  </BlockHead>

                  <img onClick={() => (setShowFlowImage(true))} src={data?.flowImage?.url} />
                </PreviewAltCard>
              </Col>

              <Col lg="7">
                <PreviewAltCard>
                  <SubscriptionLimit  filter={data?._id} type={"backtest"} current={data?.backtests?.length} setModal={() => setModal({ add: true })} />
                  <div className="traffic-channel">
                    {/* <PositionsTable data={data?.performance?.positions || []} isCompact /> */}
                    <BacktestList strategyData={data} />
                    {/* <RecentInvest /> */}
                    {/* <BacktestListNew backtests={data?.backtests}/> */}
                  </div>
                </PreviewAltCard>
              </Col>
            </Row>
          </Block>
        </Content>
      )}
      <BacktestFormModal isLoading={isLoading} modal={modal.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} />
      <Modal fullscreen isOpen={showFlowImage} toggle={closeFlowImageModal} className="modal-dialog-centered" size="xl">
        <ModalHeader close={
          <button className="close" onClick={closeFlowImageModal}>
            <Icon name="cross" />
          </button>
        }>
          Strategy flow
        </ModalHeader>
      <ModalBody>
        <img src={data?.flowImage?.url} />
        </ModalBody>
        </Modal>
        {createBacktestErrors && <ModalDanger modalError={showErrorModal} toggleError={() => setShowErrorModal(!showErrorModal)} modalData={createBacktestErrors} />}
    </>
  );
};
export default StrategyPage;
