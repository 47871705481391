import React, { useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Badge } from "reactstrap";
import useAuth from "../hooks/useAuth";
import strings from "../utils/Strings";

const QuoteStream = ({ prevClose, symbol, last, min }) => {
  const { user } = useAuth();
  const { lastMessage, getWebSocket, lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(`${process.env.REACT_APP_API_WS_URL}?userId=${user?.uid}`, {
    // share: false,
    // filter: () => false,
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    onClose: (e) => {
      console.log(e)
      console.log('WebSocket connection closed.');
    },
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent)
      return true;
    },
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        type: "quoteStream",
        symbol
      })
    }
  }, [readyState])

  const price = (lastJsonMessage?.symbol === symbol && lastJsonMessage?.last) ? lastJsonMessage?.last : last;
  const digits = price < 1 ? 4 : 2

  const change = price - prevClose;
  const changePct = (change / prevClose) * 100;
  return (
    <>
      <h3>{strings.priceDecimal(price, 2)}</h3>
      {<Badge style={{ marginLeft: 4 }}
        color={(changePct < 0 ? "danger" : changePct > 0 ? "success" : "primary")}
      >{strings.priceDecimal(change, 2)} ({changePct ? changePct.toFixed(2) : ""} %)</Badge>}
    </>
  )
};

export default QuoteStream;
