import React, { useState } from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import * as echarts from 'echarts';
import moment from "moment";
import { Line } from "recharts";
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, Legend, CartesianGrid, Tooltip, ResponsiveContainer, Text } from 'recharts';
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

const NewsTrendChart = ({ data, title, yLabel }) => {
  const [opacity, setOpacity] = useState({
    x: 1,
    y: 1,
    z: 1
  });
  const label = {
    rich: {
      a: {
        // fontSize: "1rem",
        fontFamily: '"Poppins", sans-serif',
      },
    },
    emphasis: {
      show: true,
      formatter: function (param) {
        return `{a|${param.data[3]}}`;
      },
      position: "bottom",
    },
  }

  const handleMouseEnter = (o) => {
    const { dataKey } = o;
    let t = { ...opacity }
    Object.keys(t).filter(k => k !== dataKey).map(k => t[k] = 0.5)
    setOpacity(t);
  };

  const handleMouseLeave = (o) => {
    const { dataKey } = o;
    let t = { ...opacity }
    Object.keys(t).filter(k => k !== dataKey).map(k => t[k] = 1)
    setOpacity(t);
  };

  const parseDomain = () => [
    0,
    Math.max(
      Math.max.apply(
        null,
        data[0].map((entry) => entry.value),
      ),
      Math.max.apply(
        null,
        data[1].map((entry) => entry.value),
      ),
      Math.max.apply(
        null,
        data[2].map((entry) => entry.value),
      ),
    ),
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card className="card-bordered text-muted">
          <CardBody>
            <CardTitle>
              <b>{`${moment(payload?.[0]?.payload?.x).format("Do MMM YYYY")}`}</b>
            </CardTitle>
            <CardText>
              {`${payload?.[0]?.payload?.label}`}
            </CardText>
          </CardBody>
        </Card>
      );
    }

    return null;
  };

  const range = [1, 2000];

  const domain = parseDomain();

  return (
    <React.Fragment>
      {/* <ReactEcharts style={{ height: "350px" }} option={optionsData}>
        <Line data={allLabels} />
      </ReactEcharts> */}
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart data={data}>
          <defs>
            <linearGradient id="colorNeutral" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#559bfb" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#559bfb" stopOpacity={0.2} />
            </linearGradient>
            <linearGradient id="colorBullish" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#1ee0ac" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#1ee0ac" stopOpacity={0.2} />
            </linearGradient>
            <linearGradient id="colorBearish" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#e85347" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#e85347" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <XAxis tickLine={false} type="category" dataKey="x" name="stature" />
          <YAxis
          allowDecimals={false}
            label={{ value: `Avg score of ${yLabel}s`, angle: -90, dx: -20 }}
            tickLine={false}
            type="number" dataKey="y" name="weight" />
          <ZAxis domain={domain} range={range} type="number" dataKey="z" name="score" />
          <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
          <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} wrapperStyle={{
            paddingBottom: 32
          }} verticalAlign="top" align="center" />
          <Scatter fillOpacity={opacity.x} dataKey="x" name="neutral" data={data[0].sort((a,b) => a.x - b.x)} fill="url(#colorNeutral)" />
          <Scatter fillOpacity={opacity.y} dataKey="y" name="bearish" data={data[1].sort((a,b) => a.x - b.x)} fill="url(#colorBearish)" />
          <Scatter fillOpacity={opacity.z} dataKey="z" name="bullish" data={data[2].sort((a,b) => a.x - b.x)} fill="url(#colorBullish)" />
        </ScatterChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default NewsTrendChart;
