import React, { Component, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import technicalAnalysisPath from './technicalAnalysis.md';
import { Card, Spinner } from 'reactstrap';
import Content from '../../layout/content/Content';
import { PreviewCard } from '../../components/Component';

const MDFile = ({MDFilePath}) => {
    const path = require(`./${MDFilePath}`);
    const [terms, setTerms] = useState(null);
    useEffect(() => {
        try {
            fetch(path).then((response) => response.text()).then((text) => {
                setTerms(text)
            })   
        } catch (error) {
            setTerms("Error fetching page.")
        }
    }, [MDFilePath]);
    if(!terms) return <Spinner />
    return (
        <Content>
            <PreviewCard>
        <ReactMarkdown className={"markdown"} children={terms} />
        </PreviewCard>
        </Content>
    )
}

export default MDFile