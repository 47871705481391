import { Badge, Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { Icon, PreviewAltCard, PreviewCard, UserAvatar } from "./Component";
import ImageContainer from "./partials/gallery/GalleryImage";
import { Link } from "react-router-dom";
import { findUpper, getSentimentBadge } from "../utils/Utils";
import moment from "moment";
import strings from "../utils/Strings";
import AssetLogo from "./AssetLogo";

const PostFeedCard = ({ item }) => {

    return (
        <Card className="card-bordered" style={{
            // height: 160,
            width: "100%"
        }}>
            <CardBody>
                <Row className={"g-2"}>
                    {/* <Col lg={3}> */}
                    {/* <div style={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center'
                        }}>
                            <img src={item.image} style={{
                                borderRadius: 8
                            }} />
                        </div> */}
                    {/* <UserAvatar theme={"transparent"} className="xl sq"
                        style={{
                            height: "100%"
                        }}
                        // imageStyle={{
                        //     height: "60%"
                        // }}
                        image={item.image} /> */}
                    {/* </Col> */}
                    <Col lg={12}>
                        <div >
                            <div >
                                <span style={{ float: 'right', marginLeft: 4 }}><AssetLogo symbol={item.symbol} /></span>
                                <div className="card-title card-title-sm">
                                    <Link target="_blank" to={item.url}>
                                        <h6 className="title">{item.title}</h6>
                                    </Link>
                                </div>
                            </div>
                            <div style={{
                                maxHeight: 80,
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                                <span>{item.text}</span>
                            </div>
                            <div>

                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 4,
                            // float: "right",
                            marginTop: 8
                        }}>
                            <Badge color="light"><Icon name="clock" />{moment(item.publishedDate).format("MMM DD, hh:mm A")}</Badge>
                            <Badge >{item.site}</Badge>
                            {item.sentiment && <Badge color={item.sentiment === "Positive" ? "success" : item.sentiment === "Negative" ? "danger" : "info"}>{item.sentiment === "Neutral" ? "Neutral" : `${item.sentiment} ${strings.percentSignFormatter(parseInt(item.sentimentScore * 100))}`}</Badge>}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
};

export default PostFeedCard;
