import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { LinkItem, LinkList } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import useAuth from "../../../../hooks/useAuth";
import { parseUserLevel } from "../../../../utils/Utils";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";

const User = () => {
  const theme = useTheme();
  const { logout, user } = useAuth();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar image={user?.photoURL} className="sm" />
          <div className="user-info d-none d-md-block">
            {/* <div className="user-status">{user?.email}</div> */}
            <div className="user-name dropdown-indicator">{user?.displayName}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar theme={theme.skin === "dark" ? "secondary" : "secondary-dim"} image={user?.level ? `/${user?.level}.png` : user?.photoURL} className="sm" />
            <div className="user-info">
              <span className="lead-text">{user?.displayName}</span>
              <span className="sub-text">{user?.email}</span>
              <span ><b>Level: </b>{` ${parseUserLevel(user?.level)}`}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/profile#personal" icon="user-alt" onClick={toggle}>
              Profile
            </LinkItem>
            {/* <LinkItem link="/user-profile-setting" icon="setting-alt" onClick={toggle}>
              Account Setting
            </LinkItem>
            <LinkItem link="/user-profile-activity" icon="activity-alt" onClick={toggle}>
              Login Activity
            </LinkItem> */}
            <li>
              <a className={`dark-switch ${theme.skin === 'dark' ? 'active' : ''}`} href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  const value = theme.skin === 'dark' ? 'light' : 'dark'
                  sessionStorage.setItem('skinMode', value)
                  themeUpdate.skin(value);
                }}>
                {<><em className="icon ni ni-moon"></em><span>Dark Mode</span></>}
              </a>
            </li>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem onClick={async (e) => {
              e.preventDefault();
              await logout();
            }} icon="signout">
              <span>Logout</span>
            </LinkItem>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
