import React, { useContext, useState, useEffect } from "react";
import {
    Button,
    Block,
    Row,
    Col,
    PreviewCard,
    TooltipComponent,
    RSelect,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge, Spinner, CardTitle, CardBody } from "reactstrap";
import { screenStocks } from "../../api";
import { NumericFormat } from "react-number-format";
import Select, { StylesConfig } from 'react-select';


const Filters = ({ filters, setFilters, loadScreener }) => {
    const [data, setData] = useState([]);

    const [smOption, setSmOption] = useState(false);
    const [view, setView] = useState(false);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage] = useState(8);

    //scroll off when sidebar shows
    useEffect(() => {
        view ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [view])

    const toggle = () => {
        setView(!view);
    };

    // filter text
    const onFilterChange = (e) => {
        setFilter(e.target.value);
    };

    // Get current list, pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const { reset, register, handleSubmit, formState: { errors } } = useForm();
    return (
        <PreviewCard>
            <CardTitle>
                <h5>Filters</h5>
                <div className="nk-divider divider xs"></div>

            </CardTitle>
            <Col sm="12">
                <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="type-tooltip"
                    text="Mutual funds are usually actively managed, although passively-managed index funds have become more popular. ETFs are usually passively managed and track a market index or sector sub-index. ETFs can be bought and sold just like stocks, while mutual funds can only be purchased at the end of each trading day."
                />
                <Row>
                    <Col sm="4">
                        <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="isEtf" onChange={(e) => {
                                setFilters({
                                    ...filters,
                                    isEtf: e.target.checked
                                })
                            }} />
                            <label className="custom-control-label form-label" htmlFor="isEtf">
                                ETF
                            </label>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="isFund" onChange={(e) => {
                                setFilters({
                                    ...filters,
                                    isFund: e.target.checked
                                })
                            }} />
                            <label className="custom-control-label form-label" htmlFor="isFund">
                                Fund
                            </label>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="isActivelyTrading" />
                            <label className="custom-control-label form-label" htmlFor="isActivelyTrading" onChange={(e) => {
                                setFilters({
                                    ...filters,
                                    isActivelyTrading: e.target.checked
                                })
                            }}>
                                Actively trading
                            </label>
                        </div>
                    </Col>
                </Row>
            </Col>
            <div className="nk-divider divider xs"></div>
            <Row className={"g-2"}>
                <Col sm="12">
                    <h6><span>Market cap</span></h6>
                    <Row>
                        <Col sm="6">
                            <label className="form-label">From</label>
                            <div className="form-group">
                                <NumericFormat
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    className="form-control"
                                    value={filters.marketCapMoreThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            marketCapMoreThan: values.floatValue
                                        })
                                    }}
                                // value={500000}
                                // placeholder="Leave empty to exclude"
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <label className="form-label">To</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.marketCapLowerThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            marketCapLowerThan: values.floatValue
                                        })
                                    }}
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <h6><span>Price</span></h6>
                    <Row>
                        <Col sm="6">
                            <label className="form-label">From</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.priceMoreThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            priceMoreThan: values.floatValue
                                        })
                                    }}
                                // value={500000}
                                // placeholder="Leave empty to exclude"
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <label className="form-label">To</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.priceLowerThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            priceLowerThan: values.floatValue
                                        })
                                    }}
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <h6><span>Volume</span></h6>
                    <Row>
                        <Col sm="6">
                            <label className="form-label">From</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.volumeMoreThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            volumeMoreThan: values.floatValue
                                        })
                                    }}
                                // value={500000}
                                // placeholder="Leave empty to exclude"
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <label className="form-label">To</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.volumeLowerThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            volumeLowerThan: values.floatValue
                                        })
                                    }}
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <h6><span>Dividend</span></h6>
                    <Row>
                        <Col sm="6">
                            <label className="form-label">From</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    allowedDecimalSeparators={['%']}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.dividendMoreThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            dividendMoreThan: values.floatValue
                                        })
                                    }}
                                // value={500000}
                                // placeholder="Leave empty to exclude"
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <label className="form-label">To</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    allowedDecimalSeparators={['%']}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.dividendLowerThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            dividendLowerThan: values.floatValue
                                        })
                                    }}
                                // isAllowed={(values) => {
                                //   const { floatValue } = values;
                                //   return floatValue >= 5 && floatValue <= 10000;
                                // }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <h6><span>Beta</span><TooltipComponent
                        icon="help-fill"
                        iconClass="card-hint"
                        direction="right"
                        id="beta-tooltip"
                        text="Beta is a measure of a stock's volatility in relation to the overall market. By definition, the market, such as the S&P 500 Index, has a beta of 1.0, and individual stocks are ranked according to how much they deviate from the market. A stock that swings more than the market over time has a beta above 1.0. If a stock moves less than the market, the stock's beta is less than 1.0."
                    /></h6>
                    <Row>
                        <Col sm="6">
                            <label className="form-label">From</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    allowedDecimalSeparators={['%']}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.betadMoreThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            betadMoreThan: values.floatValue
                                        })
                                    }}
                                // value={500000}
                                // placeholder="Leave empty to exclude"
                                // isAllowed={(values) => {
                                //     const { floatValue } = values;
                                //     return (floatValue >= -1 && floatValue <= 1) || floatValue == null;
                                // }}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <label className="form-label">To</label>
                            <div className="form-group">
                                <NumericFormat
                                    className="form-control"
                                    allowedDecimalSeparators={['%']}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    value={filters.betadLowerThan}
                                    onValueChange={(values) => {
                                        setFilters({
                                            ...filters,
                                            betadLowerThan: values.floatValue
                                        })
                                    }}
                                // isAllowed={(values) => {
                                //     const { floatValue } = values;
                                //     return floatValue >= -1 && floatValue <= 1;
                                // }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="12">
                    <div className="form-group">
                        <label className="form-label">
                            <b>Sectors</b>
                        </label>
                        <div style={{
                            float: "right",
                            cursor: "default",
                        }}
                        >
                        </div>
                        <div className="form-control-wrap nodrag">
                            <RSelect
                                closeMenuOnSelect={false}
                                value={filters.sector}
                                options={[{ value: "Consumer Cyclical", label: "Consumer Cyclical" },
                                { value: "Energy", label: "Energy" },
                                { value: "Technology", label: "Technology" },
                                { value: "Industrials", label: "Industrials" },
                                { value: "Financial Services", label: "Financial Services" },
                                { value: "Basic Materials", label: "Basic Materials" },
                                { value: "Communication Services", label: "Communication Services" },
                                { value: "Consumer Defensive", label: "Consumer Defensive" },
                                { value: "Healthcare", label: "Healthcare" },
                                { value: "Real Estate", label: "Real Estate" },
                                { value: "Utilities", label: "Utilities" },
                                { value: "Industrial Goods", label: "Industrial Goods" },
                                { value: "Financial", label: "Financial" },
                                { value: "Services", label: "Services" },
                                { value: "Conglomerates", label: "Conglomerates" },

                                ]}
                                placeholder="Choose specific sectors"
                                isMulti
                                noOptionsMessage={() => null}
                                onChange={(newSectors) => {
                                    setFilters({
                                        ...filters,
                                        sector: newSectors
                                    })
                                    // setState({ tags })
                                    // updateNode(id, { state: { tags } })
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col sm="12">
                    <div className="form-group">
                        <label className="form-label">
                            <b>Industries</b>
                        </label>
                        <div style={{
                            float: "right",
                            cursor: "default",
                        }}
                        >
                        </div>
                        <div className="form-control-wrap nodrag">
                            <RSelect
                                closeMenuOnSelect={false}
                                value={filters.industry}
                                options={
                                    [
                                        { value: "Steel", label: "Steel" },
                                        { value: "Silver", label: "Silver" },
                                        { value: "Other Precious Metals", label: "Other Precious Metals" },
                                        { value: "Gold", label: "Gold" },
                                        { value: "Copper", label: "Copper" },
                                        { value: "Aluminum", label: "Aluminum" },
                                        { value: "Paper, Lumber & Forest Products", label: "Paper, Lumber & Forest Products" },
                                        { value: "Industrial Materials", label: "Industrial Materials" },
                                        { value: "Construction Materials", label: "Construction Materials" },
                                        { value: "Chemicals - Specialty", label: "Chemicals - Specialty" },
                                        { value: "Chemicals", label: "Chemicals" },
                                        { value: "Agricultural Inputs", label: "Agricultural Inputs" },
                                        { value: "Telecommunications Services", label: "Telecommunications Services" },
                                        { value: "Internet Content & Information", label: "Internet Content & Information" },
                                        { value: "Publishing", label: "Publishing" },
                                        { value: "Broadcasting", label: "Broadcasting" },
                                        { value: "Advertising Agencies", label: "Advertising Agencies" },
                                        { value: "Entertainment", label: "Entertainment" },
                                        { value: "Travel Lodging", label: "Travel Lodging" },
                                        { value: "Travel Services", label: "Travel Services" },
                                        { value: "Specialty Retail", label: "Specialty Retail" },
                                        { value: "Luxury Goods", label: "Luxury Goods" },
                                        { value: "Home Improvement", label: "Home Improvement" },
                                        { value: "Residential Construction", label: "Residential Construction" },
                                        { value: "Department Stores", label: "Department Stores" },
                                        { value: "Personal Products & Services", label: "Personal Products & Services" },
                                        { value: "Leisure", label: "Leisure" },
                                        { value: "Gambling, Resorts & Casinos", label: "Gambling, Resorts & Casinos" },
                                        { value: "Furnishings, Fixtures & Appliances", label: "Furnishings, Fixtures & Appliances" },
                                        { value: "Restaurants", label: "Restaurants" },
                                        { value: "Auto - Parts", label: "Auto - Parts" },
                                        { value: "Auto - Manufacturers", label: "Auto - Manufacturers" },
                                        { value: "Auto - Recreational Vehicles", label: "Auto - Recreational Vehicles" },
                                        { value: "Auto - Dealerships", label: "Auto - Dealerships" },
                                        { value: "Apparel - Retail", label: "Apparel - Retail" },
                                        { value: "Apparel - Manufacturers", label: "Apparel - Manufacturers" },
                                        { value: "Apparel - Footwear & Accessories", label: "Apparel - Footwear & Accessories" },
                                        { value: "Packaging & Containers", label: "Packaging & Containers" },
                                        { value: "Tobacco", label: "Tobacco" },
                                        { value: "Grocery Stores", label: "Grocery Stores" },
                                        { value: "Discount Stores", label: "Discount Stores" },
                                        { value: "Household & Personal Products", label: "Household & Personal Products" },
                                        { value: "Packaged Foods", label: "Packaged Foods" },
                                        { value: "Food Distribution", label: "Food Distribution" },
                                        { value: "Food Confectioners", label: "Food Confectioners" },
                                        { value: "Agricultural Farm Products", label: "Agricultural Farm Products" },
                                        { value: "Education & Training Services", label: "Education & Training Services" },
                                        { value: "Beverages - Wineries & Distilleries", label: "Beverages - Wineries & Distilleries" },
                                        { value: "Beverages - Non-Alcoholic", label: "Beverages - Non-Alcoholic" },
                                        { value: "Beverages - Alcoholic", label: "Beverages - Alcoholic" },
                                        { value: "Uranium", label: "Uranium" },
                                        { value: "Solar", label: "Solar" },
                                        { value: "Oil & Gas Refining & Marketing", label: "Oil & Gas Refining & Marketing" },
                                        { value: "Oil & Gas Midstream", label: "Oil & Gas Midstream" },
                                        { value: "Oil & Gas Integrated", label: "Oil & Gas Integrated" },
                                        { value: "Oil & Gas Exploration & Production", label: "Oil & Gas Exploration & Production" },
                                        { value: "Oil & Gas Equipment & Services", label: "Oil & Gas Equipment & Services" },
                                        { value: "Oil & Gas Energy", label: "Oil & Gas Energy" },
                                        { value: "Oil & Gas Drilling", label: "Oil & Gas Drilling" },
                                        { value: "Coal", label: "Coal" },
                                        { value: "Shell Companies", label: "Shell Companies" },
                                        { value: "Investment - Banking & Investment Services", label: "Investment - Banking & Investment Services" },
                                        { value: "Insurance - Specialty", label: "Insurance - Specialty" },
                                        { value: "Insurance - Reinsurance", label: "Insurance - Reinsurance" },
                                        { value: "Insurance - Property & Casualty", label: "Insurance - Property & Casualty" },
                                        { value: "Insurance - Life", label: "Insurance - Life" },
                                        { value: "Insurance - Diversified", label: "Insurance - Diversified" },
                                        { value: "Insurance - Brokers", label: "Insurance - Brokers" },
                                        { value: "Financial - Mortgages", label: "Financial - Mortgages" },
                                        { value: "Financial - Diversified", label: "Financial - Diversified" },
                                        { value: "Financial - Data & Stock Exchanges", label: "Financial - Data & Stock Exchanges" },
                                        { value: "Financial - Credit Services", label: "Financial - Credit Services" },
                                        { value: "Financial - Conglomerates", label: "Financial - Conglomerates" },
                                        { value: "Financial - Capital Markets", label: "Financial - Capital Markets" },
                                        { value: "Banks - Regional", label: "Banks - Regional" },
                                        { value: "Banks - Diversified", label: "Banks - Diversified" },
                                        { value: "Banks", label: "Banks" },
                                        { value: "Asset Management", label: "Asset Management" },
                                        { value: "Asset Management - Bonds", label: "Asset Management - Bonds" },
                                        { value: "Asset Management - Income", label: "Asset Management - Income" },
                                        { value: "Asset Management - Leveraged", label: "Asset Management - Leveraged" },
                                        { value: "Asset Management - Cryptocurrency", label: "Asset Management - Cryptocurrency" },
                                        { value: "Asset Management - Global", label: "Asset Management - Global" },
                                        { value: "Medical - Specialties", label: "Medical - Specialties" },
                                        { value: "Medical - Pharmaceuticals", label: "Medical - Pharmaceuticals" },
                                        { value: "Medical - Instruments & Supplies", label: "Medical - Instruments & Supplies" },
                                        { value: "Medical - Healthcare Plans", label: "Medical - Healthcare Plans" },
                                        { value: "Medical - Healthcare Information Services", label: "Medical - Healthcare Information Services" },
                                        { value: "Medical - Equipment & Services", label: "Medical - Equipment & Services" },
                                        { value: "Medical - Distribution", label: "Medical - Distribution" },
                                        { value: "Medical - Diagnostics & Research", label: "Medical - Diagnostics & Research" },
                                        { value: "Medical - Devices", label: "Medical - Devices" },
                                        { value: "Medical - Care Facilities", label: "Medical - Care Facilities" },
                                        { value: "Drug Manufacturers - Specialty & Generic", label: "Drug Manufacturers - Specialty & Generic" },
                                        { value: "Drug Manufacturers - General", label: "Drug Manufacturers - General" },
                                        { value: "Biotechnology", label: "Biotechnology" },
                                        { value: "Waste Management", label: "Waste Management" },
                                        { value: "Trucking", label: "Trucking" },
                                        { value: "Railroads", label: "Railroads" },
                                        { value: "Aerospace & Defense", label: "Aerospace & Defense" },
                                        { value: "Marine Shipping", label: "Marine Shipping" },
                                        { value: "Integrated Freight & Logistics", label: "Integrated Freight & Logistics" },
                                        { value: "Airlines, Airports & Air Services", label: "Airlines, Airports & Air Services" },
                                        { value: "General Transportation", label: "General Transportation" },
                                        { value: "Manufacturing - Tools & Accessories", label: "Manufacturing - Tools & Accessories" },
                                        { value: "Manufacturing - Textiles", label: "Manufacturing - Textiles" },
                                        { value: "Manufacturing - Miscellaneous", label: "Manufacturing - Miscellaneous" },
                                        { value: "Manufacturing - Metal Fabrication", label: "Manufacturing - Metal Fabrication" },
                                        { value: "Industrial - Distribution", label: "Industrial - Distribution" },
                                        { value: "Industrial - Specialties", label: "Industrial - Specialties" },
                                        { value: "Industrial - Pollution & Treatment Controls", label: "Industrial - Pollution & Treatment Controls" },
                                        { value: "Environmental Services", label: "Environmental Services" },
                                        { value: "Industrial - Machinery", label: "Industrial - Machinery" },
                                        { value: "Industrial - Infrastructure Operations", label: "Industrial - Infrastructure Operations" },
                                        { value: "Industrial - Capital Goods", label: "Industrial - Capital Goods" },
                                        { value: "Consulting Services", label: "Consulting Services" },
                                        { value: "Business Equipment & Supplies", label: "Business Equipment & Supplies" },
                                        { value: "Staffing & Employment Services", label: "Staffing & Employment Services" },
                                        { value: "Rental & Leasing Services", label: "Rental & Leasing Services" },
                                        { value: "Engineering & Construction", label: "Engineering & Construction" },
                                        { value: "Security & Protection Services", label: "Security & Protection Services" },
                                        { value: "Specialty Business Services", label: "Specialty Business Services" },
                                        { value: "Construction", label: "Construction" },
                                        { value: "Conglomerates", label: "Conglomerates" },
                                        { value: "Electrical Equipment & Parts", label: "Electrical Equipment & Parts" },
                                        { value: "Agricultural - Machinery", label: "Agricultural - Machinery" },
                                        { value: "Agricultural - Commodities/Milling", label: "Agricultural - Commodities/Milling" },
                                        { value: "REIT - Specialty", label: "REIT - Specialty" },
                                        { value: "REIT - Retail", label: "REIT - Retail" },
                                        { value: "REIT - Residential", label: "REIT - Residential" },
                                        { value: "REIT - Office", label: "REIT - Office" },
                                        { value: "REIT - Mortgage", label: "REIT - Mortgage" },
                                        { value: "REIT - Industrial", label: "REIT - Industrial" },
                                        { value: "REIT - Hotel & Motel", label: "REIT - Hotel & Motel" },
                                        { value: "REIT - Healthcare Facilities", label: "REIT - Healthcare Facilities" },
                                        { value: "REIT - Diversified", label: "REIT - Diversified" },
                                        { value: "Real Estate - Services", label: "Real Estate - Services" },
                                        { value: "Real Estate - Diversified", label: "Real Estate - Diversified" },
                                        { value: "Real Estate - Development", label: "Real Estate - Development" },
                                        { value: "Real Estate - General", label: "Real Estate - General" },
                                        { value: "Information Technology Services", label: "Information Technology Services" },
                                        { value: "Hardware, Equipment & Parts", label: "Hardware, Equipment & Parts" },
                                        { value: "Computer Hardware", label: "Computer Hardware" },
                                        { value: "Electronic Gaming & Multimedia", label: "Electronic Gaming & Multimedia" },
                                        { value: "Software - Services", label: "Software - Services" },
                                        { value: "Software - Infrastructure", label: "Software - Infrastructure" },
                                        { value: "Software - Application", label: "Software - Application" },
                                        { value: "Semiconductors", label: "Semiconductors" },
                                        { value: "Media & Entertainment", label: "Media & Entertainment" },
                                        { value: "Communication Equipment", label: "Communication Equipment" },
                                        { value: "Technology Distributors", label: "Technology Distributors" },
                                        { value: "Consumer Electronics", label: "Consumer Electronics" },
                                        { value: "Renewable Utilities", label: "Renewable Utilities" },
                                        { value: "Regulated Water", label: "Regulated Water" },
                                        { value: "Regulated Gas", label: "Regulated Gas" },
                                        { value: "Regulated Electric", label: "Regulated Electric" },
                                        { value: "Independent Power Producers", label: "Independent Power Producers" },
                                        { value: "Diversified Utilities", label: "Diversified Utilities" },
                                        { value: "General Utilities", label: "General Utilities" }
                                    ]
                                }
                                placeholder="Choose specific industries"
                                isMulti
                                noOptionsMessage={() => null}
                                onChange={(industries) => {
                                    setFilters({
                                        ...filters,
                                        industry: industries
                                    })
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col sm="12">
                    <div className="form-group">
                        <label className="form-label">
                            <b>Exchanges</b>
                        </label>
                        <div style={{
                            float: "right",
                            cursor: "default",
                        }}
                        >
                        </div>
                        <div className="form-control-wrap nodrag">
                            <RSelect
                                closeMenuOnSelect={false}
                                value={filters.exchnage}
                                options={[
                                    { value: "AMEX", label: "AMEX" },
                                    { value: "AMS", label: "AMS" },
                                    { value: "AQS", label: "AQS" },
                                    { value: "ASE", label: "ASE" },
                                    { value: "ASX", label: "ASX" },
                                    { value: "ATH", label: "ATH" },
                                    { value: "BER", label: "BER" },
                                    { value: "BME", label: "BME" },
                                    { value: "BRU", label: "BRU" },
                                    { value: "BSE", label: "BSE" },
                                    { value: "BUD", label: "BUD" },
                                    { value: "BUE", label: "BUE" },
                                    { value: "CAI", label: "CAI" },
                                    { value: "CBOE", label: "CBOE" },
                                    { value: "CNQ", label: "CNQ" },
                                    { value: "CPH", label: "CPH" },
                                    { value: "DFM", label: "DFM" },
                                    { value: "DOH", label: "DOH" },
                                    { value: "DUS", label: "DUS" },
                                    { value: "DXE", label: "DXE" },
                                    { value: "ETF", label: "ETF" },
                                    { value: "EURONEXT", label: "EURONEXT" },
                                    { value: "HAM", label: "HAM" },
                                    { value: "HEL", label: "HEL" },
                                    { value: "HKSE", label: "HKSE" },
                                    { value: "ICE", label: "ICE" },
                                    { value: "IOB", label: "IOB" },
                                    { value: "ISE", label: "ISE" },
                                    { value: "IST", label: "IST" },
                                    { value: "JKT", label: "JKT" },
                                    { value: "JNB", label: "JNB" },
                                    { value: "JPX", label: "JPX" },
                                    { value: "KLS", label: "KLS" },
                                    { value: "KOE", label: "KOE" },
                                    { value: "KSC", label: "KSC" },
                                    { value: "KUW", label: "KUW" },
                                    { value: "LSE", label: "LSE" },
                                    { value: "MCX", label: "MCX" },
                                    { value: "MEX", label: "MEX" },
                                    { value: "MIL", label: "MIL" },
                                    { value: "MUN", label: "MUN" },
                                    { value: "NASDAQ", label: "NASDAQ" },
                                    { value: "NEO", label: "NEO" },
                                    { value: "NGM", label: "NGM" },
                                    { value: "NIM", label: "NIM" },
                                    { value: "NSE", label: "NSE" },
                                    { value: "NYSE", label: "NYSE" },
                                    { value: "NZE", label: "NZE" },
                                    { value: "OMXSTO", label: "OMXSTO" },
                                    { value: "OSL", label: "OSL" },
                                    { value: "OTC", label: "OTC" },
                                    { value: "PNK", label: "PNK" },
                                    { value: "PRA", label: "PRA" },
                                    { value: "RIS", label: "RIS" },
                                    { value: "SAO", label: "SAO" },
                                    { value: "SAU", label: "SAU" },
                                    { value: "SES", label: "SES" },
                                    { value: "SET", label: "SET" },
                                    { value: "SGO", label: "SGO" },
                                    { value: "SHH", label: "SHH" },
                                    { value: "SHZ", label: "SHZ" },
                                    { value: "SIX", label: "SIX" },
                                    { value: "STO", label: "STO" },
                                    { value: "STU", label: "STU" },
                                    { value: "TAI", label: "TAI" },
                                    { value: "TLV", label: "TLV" },
                                    { value: "TSX", label: "TSX" },
                                    { value: "TSXV", label: "TSXV" },
                                    { value: "TWO", label: "TWO" },
                                    { value: "VIE", label: "VIE" },
                                    { value: "WSE", label: "WSE" },
                                    { value: "XETRA", label: "XETRA" },
                                ]}
                                placeholder="Choose specific exchanges"
                                isMulti
                                noOptionsMessage={() => null}
                                onChange={(industries) => {
                                    setFilters({
                                        ...filters,
                                        exchnage: industries
                                    })
                                    // setState({ tags })
                                    // updateNode(id, { state: { tags } })
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col sm="12">
                    <div className="form-group">
                        <label className="form-label">
                            <b>Countries</b>
                        </label>
                        <div style={{
                            float: "right",
                            cursor: "default",
                        }}
                        >
                        </div>
                        <div className="form-control-wrap nodrag">
                            <RSelect
                                closeMenuOnSelect={false}
                                value={filters.country}
                                options={[
                                    { label: "Afghanistan", value: "AF" },
                                    { label: "Albania", value: "AL" },
                                    { label: "Algeria", value: "DZ" },
                                    { label: "American Samoa", value: "AS" },
                                    { label: "Andorra", value: "AD" },
                                    { label: "Angola", value: "AO" },
                                    { label: "Anguilla", value: "AI" },
                                    { label: "Antarctica", value: "AQ" },
                                    { label: "Antigua and Barbuda", value: "AG" },
                                    { label: "Argentina", value: "AR" },
                                    { label: "Armenia", value: "AM" },
                                    { label: "Aruba", value: "AW" },
                                    { label: "Australia", value: "AU" },
                                    { label: "Austria", value: "AT" },
                                    { label: "Azerbaijan", value: "AZ" },
                                    { label: "Bahamas (the)", value: "BS" },
                                    { label: "Bahrain", value: "BH" },
                                    { label: "Bangladesh", value: "BD" },
                                    { label: "Barbados", value: "BB" },
                                    { label: "Belarus", value: "BY" },
                                    { label: "Belgium", value: "BE" },
                                    { label: "Belize", value: "BZ" },
                                    { label: "Benin", value: "BJ" },
                                    { label: "Bermuda", value: "BM" },
                                    { label: "Bhutan", value: "BT" },
                                    { label: "Bolivia (Plurinational State of)", value: "BO" },
                                    { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
                                    { label: "Bosnia and Herzegovina", value: "BA" },
                                    { label: "Botswana", value: "BW" },
                                    { label: "Bouvet Island", value: "BV" },
                                    { label: "Brazil", value: "BR" },
                                    { label: "British Indian Ocean Territory (the)", value: "IO" },
                                    { label: "Brunei Darussalam", value: "BN" },
                                    { label: "Bulgaria", value: "BG" },
                                    { label: "Burkina Faso", value: "BF" },
                                    { label: "Burundi", value: "BI" },
                                    { label: "Cabo Verde", value: "CV" },
                                    { label: "Cambodia", value: "KH" },
                                    { label: "Cameroon", value: "CM" },
                                    { label: "Canada", value: "CA" },
                                    { label: "Cayman Islands (the)", value: "KY" },
                                    { label: "Central African Republic (the)", value: "CF" },
                                    { label: "Chad", value: "TD" },
                                    { label: "Chile", value: "CL" },
                                    { label: "China", value: "CN" },
                                    { label: "Christmas Island", value: "CX" },
                                    { label: "Cocos (Keeling) Islands (the)", value: "CC" },
                                    { label: "Colombia", value: "CO" },
                                    { label: "Comoros (the)", value: "KM" },
                                    { label: "Congo (the Democratic Republic of the)", value: "CD" },
                                    { label: "Congo (the)", value: "CG" },
                                    { label: "Cook Islands (the)", value: "CK" },
                                    { label: "Costa Rica", value: "CR" },
                                    { label: "Croatia", value: "HR" },
                                    { label: "Cuba", value: "CU" },
                                    { label: "Curaçao", value: "CW" },
                                    { label: "Cyprus", value: "CY" },
                                    { label: "Czechia", value: "CZ" },
                                    { label: "Côte d'Ivoire", value: "CI" },
                                    { label: "Denmark", value: "DK" },
                                    { label: "Djibouti", value: "DJ" },
                                    { label: "Dominica", value: "DM" },
                                    { label: "Dominican Republic (the)", value: "DO" },
                                    { label: "Ecuador", value: "EC" },
                                    { label: "Egypt", value: "EG" },
                                    { label: "El Salvador", value: "SV" },
                                    { label: "Equatorial Guinea", value: "GQ" },
                                    { label: "Eritrea", value: "ER" },
                                    { label: "Estonia", value: "EE" },
                                    { label: "Eswatini", value: "SZ" },
                                    { label: "Ethiopia", value: "ET" },
                                    { label: "Falkland Islands (the) [Malvinas]", value: "FK" },
                                    { label: "Faroe Islands (the)", value: "FO" },
                                    { label: "Fiji", value: "FJ" },
                                    { label: "Finland", value: "FI" },
                                    { label: "France", value: "FR" },
                                    { label: "French Guiana", value: "GF" },
                                    { label: "French Polynesia", value: "PF" },
                                    { label: "French Southern Territories (the)", value: "TF" },
                                    { label: "Gabon", value: "GA" },
                                    { label: "Gambia (the)", value: "GM" },
                                    { label: "Georgia", value: "GE" },
                                    { label: "Germany", value: "DE" },
                                    { label: "Ghana", value: "GH" },
                                    { label: "Gibraltar", value: "GI" },
                                    { label: "Greece", value: "GR" },
                                    { label: "Greenland", value: "GL" },
                                    { label: "Grenada", value: "GD" },
                                    { label: "Guadeloupe", value: "GP" },
                                    { label: "Guam", value: "GU" },
                                    { label: "Guatemala", value: "GT" },
                                    { label: "Guernsey", value: "GG" },
                                    { label: "Guinea", value: "GN" },
                                    { label: "Guinea-Bissau", value: "GW" },
                                    { label: "Guyana", value: "GY" },
                                    { label: "Haiti", value: "HT" },
                                    { label: "Heard Island and McDonald Islands", value: "HM" },
                                    { label: "Holy See (the)", value: "VA" },
                                    { label: "Honduras", value: "HN" },
                                    { label: "Hong Kong", value: "HK" },
                                    { label: "Hungary", value: "HU" },
                                    { label: "Iceland", value: "IS" },
                                    { label: "India", value: "IN" },
                                    { label: "Indonesia", value: "ID" },
                                    { label: "Iran (Islamic Republic of)", value: "IR" },
                                    { label: "Iraq", value: "IQ" },
                                    { label: "Ireland", value: "IE" },
                                    { label: "Isle of Man", value: "IM" },
                                    { label: "Israel", value: "IL" },
                                    { label: "Italy", value: "IT" },
                                    { label: "Jamaica", value: "JM" },
                                    { label: "Japan", value: "JP" },
                                    { label: "Jersey", value: "JE" },
                                    { label: "Jordan", value: "JO" },
                                    { label: "Kazakhstan", value: "KZ" },
                                    { label: "Kenya", value: "KE" },
                                    { label: "Kiribati", value: "KI" },
                                    { label: "Korea (the Democratic People's Republic of)", value: "KP" },
                                    { label: "Korea (the Republic of)", value: "KR" },
                                    { label: "Kuwait", value: "KW" },
                                    { label: "Kyrgyzstan", value: "KG" },
                                    { label: "Lao People's Democratic Republic (the)", value: "LA" },
                                    { label: "Latvia", value: "LV" },
                                    { label: "Lebanon", value: "LB" },
                                    { label: "Lesotho", value: "LS" },
                                    { label: "Liberia", value: "LR" },
                                    { label: "Libya", value: "LY" },
                                    { label: "Liechtenstein", value: "LI" },
                                    { label: "Lithuania", value: "LT" },
                                    { label: "Luxembourg", value: "LU" },
                                    { label: "Macao", value: "MO" },
                                    { label: "Madagascar", value: "MG" },
                                    { label: "Malawi", value: "MW" },
                                    { label: "Malaysia", value: "MY" },
                                    { label: "Maldives", value: "MV" },
                                    { label: "Mali", value: "ML" },
                                    { label: "Malta", value: "MT" },
                                    { label: "Marshall Islands (the)", value: "MH" },
                                    { label: "Martinique", value: "MQ" },
                                    { label: "Mauritania", value: "MR" },
                                    { label: "Mauritius", value: "MU" },
                                    { label: "Mayotte", value: "YT" },
                                    { label: "Mexico", value: "MX" },
                                    { label: "Micronesia (Federated States of)", value: "FM" },
                                    { label: "Moldova (the Republic of)", value: "MD" },
                                    { label: "Monaco", value: "MC" },
                                    { label: "Mongolia", value: "MN" },
                                    { label: "Montenegro", value: "ME" },
                                    { label: "Montserrat", value: "MS" },
                                    { label: "Morocco", value: "MA" },
                                    { label: "Mozambique", value: "MZ" },
                                    { label: "Myanmar", value: "MM" },
                                    { label: "Namibia", value: "NA" },
                                    { label: "Nauru", value: "NR" },
                                    { label: "Nepal", value: "NP" },
                                    { label: "Netherlands (the)", value: "NL" },
                                    { label: "New Caledonia", value: "NC" },
                                    { label: "New Zealand", value: "NZ" },
                                    { label: "Nicaragua", value: "NI" },
                                    { label: "Niger (the)", value: "NE" },
                                    { label: "Nigeria", value: "NG" },
                                    { label: "Niue", value: "NU" },
                                    { label: "Norfolk Island", value: "NF" },
                                    { label: "Northern Mariana Islands (the)", value: "MP" },
                                    { label: "Norway", value: "NO" },
                                    { label: "Oman", value: "OM" },
                                    { label: "Pakistan", value: "PK" },
                                    { label: "Palau", value: "PW" },
                                    { label: "Palestine, State of", value: "PS" },
                                    { label: "Panama", value: "PA" },
                                    { label: "Papua New Guinea", value: "PG" },
                                    { label: "Paraguay", value: "PY" },
                                    { label: "Peru", value: "PE" },
                                    { label: "Philippines (the)", value: "PH" },
                                    { label: "Pitcairn", value: "PN" },
                                    { label: "Poland", value: "PL" },
                                    { label: "Portugal", value: "PT" },
                                    { label: "Puerto Rico", value: "PR" },
                                    { label: "Qatar", value: "QA" },
                                    { label: "Republic of North Macedonia", value: "MK" },
                                    { label: "Romania", value: "RO" },
                                    { label: "Russian Federation (the)", value: "RU" },
                                    { label: "Rwanda", value: "RW" },
                                    { label: "Réunion", value: "RE" },
                                    { label: "Saint Barthélemy", value: "BL" },
                                    { label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
                                    { label: "Saint Kitts and Nevis", value: "KN" },
                                    { label: "Saint Lucia", value: "LC" },
                                    { label: "Saint Martin (French part)", value: "MF" },
                                    { label: "Saint Pierre and Miquelon", value: "PM" },
                                    { label: "Saint Vincent and the Grenadines", value: "VC" },
                                    { label: "Samoa", value: "WS" },
                                    { label: "San Marino", value: "SM" },
                                    { label: "Sao Tome and Principe", value: "ST" },
                                    { label: "Saudi Arabia", value: "SA" },
                                    { label: "Senegal", value: "SN" },
                                    { label: "Serbia", value: "RS" },
                                    { label: "Seychelles", value: "SC" },
                                    { label: "Sierra Leone", value: "SL" },
                                    { label: "Singapore", value: "SG" },
                                    { label: "Sint Maarten (Dutch part)", value: "SX" },
                                    { label: "Slovakia", value: "SK" },
                                    { label: "Slovenia", value: "SI" },
                                    { label: "Solomon Islands", value: "SB" },
                                    { label: "Somalia", value: "SO" },
                                    { label: "South Africa", value: "ZA" },
                                    { label: "South Georgia and the South Sandwich Islands", value: "GS" },
                                    { label: "South Sudan", value: "SS" },
                                    { label: "Spain", value: "ES" },
                                    { label: "Sri Lanka", value: "LK" },
                                    { label: "Sudan (the)", value: "SD" },
                                    { label: "Suriname", value: "SR" },
                                    { label: "Svalbard and Jan Mayen", value: "SJ" },
                                    { label: "Sweden", value: "SE" },
                                    { label: "Switzerland", value: "CH" },
                                    { label: "Syrian Arab Republic", value: "SY" },
                                    { label: "Taiwan (Province of China)", value: "TW" },
                                    { label: "Tajikistan", value: "TJ" },
                                    { label: "Tanzania, United Republic of", value: "TZ" },
                                    { label: "Thailand", value: "TH" },
                                    { label: "Timor-Leste", value: "TL" },
                                    { label: "Togo", value: "TG" },
                                    { label: "Tokelau", value: "TK" },
                                    { label: "Tonga", value: "TO" },
                                    { label: "Trinidad and Tobago", value: "TT" },
                                    { label: "Tunisia", value: "TN" },
                                    { label: "Turkey", value: "TR" },
                                    { label: "Turkmenistan", value: "TM" },
                                    { label: "Turks and Caicos Islands (the)", value: "TC" },
                                    { label: "Tuvalu", value: "TV" },
                                    { label: "Uganda", value: "UG" },
                                    { label: "Ukraine", value: "UA" },
                                    { label: "United Arab Emirates (the)", value: "AE" },
                                    { label: "United Kingdom of Great Britain and Northern Ireland (the)", value: "GB" },
                                    { label: "United States Minor Outlying Islands (the)", value: "UM" },
                                    { label: "United States of America (the)", value: "US" },
                                    { label: "Uruguay", value: "UY" },
                                    { label: "Uzbekistan", value: "UZ" },
                                    { label: "Vanuatu", value: "VU" },
                                    { label: "Venezuela (Bolivarian Republic of)", value: "VE" },
                                    { label: "Viet Nam", value: "VN" },
                                    { label: "Virgin Islands (British)", value: "VG" },
                                    { label: "Virgin Islands (U.S.)", value: "VI" },
                                    { label: "Wallis and Futuna", value: "WF" },
                                    { label: "Western Sahara", value: "EH" },
                                    { label: "Yemen", value: "YE" },
                                    { label: "Zambia", value: "ZM" },
                                    { label: "Zimbabwe", value: "ZW" },
                                    { label: "Åland Islands", value: "AX" },
                                ]}
                                placeholder="Choose specific countries"
                                isMulti
                                noOptionsMessage={() => null}
                                onChange={(countries) => {
                                    setFilters({
                                        ...filters,
                                        country: countries
                                    })
                                    // setState({ tags })
                                    // updateNode(id, { state: { tags } })
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <div className="nk-divider divider xs"></div>
                <Col sm="12">
                    <Button style={{
                        float: "right"
                    }} color="primary" onClick={() => {
                        loadScreener();
                    }}>Filter</Button>
                </Col>
            </Row>
        </PreviewCard>
    )
};
export default Filters;
