import React, { useContext } from "react";
import Chat from "./Chat";
import { ChatContextProvider } from "./ChatContext";
import { useQuery } from "@apollo/client";
import { GET_USER_CHATS } from "../../../api/graphql";
import { AppContext } from "../../../contexts/AppContext";
import { Spinner } from "reactstrap";

const ChatContainer = () => {
  return (
    <ChatContextProvider>
      <Chat/>
    </ChatContextProvider>
  );
};
export default ChatContainer;
