import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { getHistoricalPriceFull } from "../../../../api";
import strings from "../../../../utils/Strings";
import { mapIndexToLabel } from "../../../../utils/Utils";
import Icon from "../../../icon/Icon";
import { WPCharts } from "../../charts/analytics/AnalyticsCharts";

const IndexItem = ({ index }) => {
  const last = index?.historical?.[0];
  const data = [...index?.historical].reverse()
  return (
    <div className="analytic-data analytic-wp-data">
      <div className="analytic-wp-graph">
        <div className="title">
          {mapIndexToLabel(index.symbol)?.label}
        </div>
        <div className="analytic-wp-ck">
          <WPCharts className="analytics-line-small" data={{
            labels: data?.map(i => i.date),
            dataUnit: "price",
            datasets: [
              {
                label: "value",
                lineTension: 0,
                borderWidth: 2,
                fill: true,
                color: "#798bff",
                backgroundColor: "rgba(121, 139, 255, 0.25)",
                borderColor: "#798bff",
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#798bff",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                pointHitRadius: 4,
                data: data?.map(i => i.adjClose),
              },
            ]
          }}></WPCharts>
        </div>
      </div>
      <div className="analytic-wp-text">
        <div className="amount amount-sm">{strings.decimalDigits(last.adjClose, 2)}</div>
        <div className={last.change > 0 ? "change up" : last.change < 0 ? "change down" : "change"}>
          <Icon name={last.change > 0 ? "arrow-long-up" : last.change < 0 ? "arrow-long-down" : "change"}></Icon>{strings.percentSignFormatter(last.changePercent)}
        </div>
        <div className="subtitle"></div>
      </div>
    </div>
  );
}
const MarketOverview = () => {
  const [marketData, setMarketData] = useState([]);
  const [isLoadingMarketData, setIsLoadingMarketData] = useState(false);

  const indexList = {
    usIndices: "^GSPC,^DJI,^VIX,^IXIC",
    europeIndices: "^GDAXI,^FTSE,^FCHI,^IBEX",
    assiaIndices: "^HSI,^NSEI,^N225,000001.SS",
  }

  const [tab, setTab] = useState("usIndices");
  useEffect(() => {
    const loadMarketData = async () => {
      setIsLoadingMarketData(true);
      getHistoricalPriceFull({ symbols: indexList[tab] },
        (res) => {
          setMarketData(res)
          setIsLoadingMarketData(false)
        }, (err) => {
          console.error(err)
          setIsLoadingMarketData(false)
        })
    }
    loadMarketData()
  }, [tab])

  if (isLoadingMarketData) return <Spinner />

  return (
    <React.Fragment>
      <div className="card-title-group mb-1">
        <div className="card-title">
          <h6 className="title">World indices</h6>
        </div>
      </div>
      <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs">
        <li className="nav-item" onClick={() => {
          setIsLoadingMarketData(true);
          setTab("usIndices")
        }
        }>
          <a
            href="#us"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className={`nav-link${tab === "usIndices" ? " active" : ""}`}
          >
            USA
          </a>
        </li>
        <li className="nav-item" onClick={() => {
          setIsLoadingMarketData(true);
          setTab("europeIndices")
        }
        }>
          <a
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className={`nav-link${tab === "europeIndices" ? " active" : ""}`}
            href="#europe"
          >
            Europe
          </a>
        </li>
        <li className="nav-item" onClick={() => {
          setIsLoadingMarketData(true);
          setTab("assiaIndices")
        }
        }>
          <a
            className={`nav-link${tab === "assiaIndices" ? " active" : ""}`}
            onClick={(ev) => {
              ev.preventDefault();
            }}
            href="#asia"
          >
            Asia
          </a>
        </li>
      </ul>
      <div className="tab-content mt-0">
        <div className={`tab-pane${tab === "usIndices" && " active"}`}>
          <div className="invest-ov gy-2">
            {isLoadingMarketData ? <Spinner /> : marketData?.map(i => {
              return (
                <IndexItem index={i} />
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MarketOverview;
