import React, { useContext, useEffect, useState } from "react";
import { CREATE_STRATEGY, GET_USER_BOTS } from "../../api/graphql";
import { useMutation, useQuery } from "@apollo/client";
import BotList from "./BotList";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import useAuth from "../../hooks/useAuth";
import "./index.css";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import SubscriptionLimit from "../../components/SubscriptionLimit";
import { Button } from "reactstrap";
import { Icon } from "../../components/Component";

export const BotsPage = () => {
  const { user } = useAuth();
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [mode, setMode] = useState("list");
  const { data, refetch, fetchMore, loading, error, startPolling, stopPolling } = useQuery(GET_USER_BOTS, {
    variables: {
      filter: { uid: user?.uid, }
    }
  });

  useEffect(() => {
    if (data) {
      const runningBacktests = data?.userOne?.bots?.filter(b => b?.botStats?.backtest?.status === "running");
      if (runningBacktests?.length > 0) {
        startPolling(
          1000 * 10 //10 seconds
        );
      }
      else {
        stopPolling();
      }
    }
  }, [data, startPolling, stopPolling])

  if (loading) return <SpinnerWrapper />
  if (user) {
    return (
      <>
        <Head title="Bots"></Head>
        <Content>
          <SubscriptionLimit filter={user.uid} customTitle="Your bots 🤖" type={"bot"} current={data?.userOne?.bots?.length}
            setModal={() => setModal({ add: true })}
          >
            <li className="nk-block-tools-opt">
              <Button size="md" color={mode === "list" ? "primary" : "outline-light"} className="btn-icon btn-dim" onClick={() => setMode("list")}>
                <Icon name="view-list-wd"></Icon>
              </Button>
            </li>
            <li className="nk-block-tools-opt">
              <Button size="md" color={mode === "cards" ? "primary" : "outline-light"} className="btn-icon btn-dim" onClick={() => setMode("cards")}><Icon name="grid-fill"></Icon></Button>
            </li>
          </SubscriptionLimit>
          <BotList mode={mode} setMode={setMode} modal={modal} setModal={setModal} botsData={data?.userOne?.bots} />
        </Content>
      </>
    )
  }
  else {
    return <></>
  }
};

export default BotsPage;
