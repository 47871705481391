import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon
} from "../../../components/Component";
import useAuth from "../../../hooks/useAuth";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import strings from "../../../utils/Strings";
import Subscriptions from "../../components/subscription/Subscriptions";

const UserDetailsPage = () => {
  const { search } = useLocation();
  const [sideBar, setSidebar] = useState(false);
  const [activeTab, setActiveTab] = useState("personal");
  const [sessionId, setSessionId] = useState(null);
  const { user } = useAuth();
  const [subscriptionChangeModal, setSubscriptionChangeModal] = useState(false);
  const [subscriptionConfirmedModal, setSubscriptionConfirmedModal] = useState(false);
  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false);
  const [subscriptionCancelConfirmedModal, setSubscriptionCancelConfirmedModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(search);

    if (query.get('success')) {
      setSubscriptionConfirmedModal(true);
      setSessionId(query.get('session_id'));
    }
    if (query.get('canceled')) {
      setSubscriptionCancelConfirmedModal(true);
    }
  }, [search]);

  useEffect(() => {
    let tag = window.location.href.split("#")[1];
    if (!tag) {
      tag = "personality"
    }
    setActiveTab(tag);

  }, [window.location.href])
  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };

  useEffect(() => {
    sideBar ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [sideBar])

  const activePlans = user?.subscriptions?.data?.filter(i => i.status === "active");
  const activePlan = activePlans?.[0] || null;
  return (
    <>
      <Head title="Profile"></Head>
      {user && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Users / <strong className="text-primary small">{user?.displayName}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      User ID: <span className="text-base">{user?._id}</span>
                    </li>
                    <li>
                      Last Login: <span className="text-base">{strings.unix(user?.lastLoginAt / 1000)}</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => navigate(-1)}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                    <li className="nav-item">
                      <a
                        className={classNames({ "nav-link": "nav-link", active: activeTab === "personal" })}
                        href="#personal"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          // setActiveTab("personal");
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>Personal</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={classNames({ "nav-link": "nav-link", active: activeTab === "subscription" })}
                        href="#subscription"
                        onClick={(ev) => {
                          // ev.preventDefault();
                          // setActiveTab("subscription");
                        }}
                      >
                        <Icon name="money"></Icon>
                        <span>Subscription</span>
                      </a>
                    </li>
                    {/* <li className="nav-item nav-item-trigger d-xxl-none">
                      <Button className={`toggle btn-icon btn-trigger ${sideBar && "active"}`} onClick={toggle}>
                        <Icon name="user-list-fill"></Icon>
                      </Button>
                    </li> */}
                  </ul>

                  {activeTab.includes("personal") && <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Personal Information</BlockTitle>
                        <p>Basic profile info.</p>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Display name</span>
                            <span className="profile-ud-value">{user?.displayName}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Full Name</span>
                            <span className="profile-ud-value">{user?.name}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Date of Birth</span>
                            <span className="profile-ud-value">{user?.dob}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Mobile Number</span>
                            <span className="profile-ud-value">{user?.phone}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Email Address</span>
                            <span className="profile-ud-value">{user?.email}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Joining Date</span>
                            <span className="profile-ud-value">{strings.date(user?.createdAt)}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Country</span>
                            <span className="profile-ud-value">{user?.country}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    {/* <div className="nk-divider divider md"></div> */}
                  </div>}
                  {activeTab.includes("subscription") && <div className="card-inner">
                    <Block>
                      <Subscriptions paymentMethods={user?.paymentMethods.data} data={user?.subscriptions.data} />
                    </Block>

                    {/* <div className="nk-divider divider md"></div> */}
                  </div>}
                </div>

                {/* <Sidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                      <UserAvatar className="lg" theme="primary" image={user?.photoURL} />
                      <div className="user-info">
                        <Badge color="outline-light" pill className="ucap">{user?.level}</Badge>
                        <h5>{user?.name}</h5>
                        <span className="sub-text">{user?.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">
                      <li>
                        <Button
                          href="#tool"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="shield-off"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#mail"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="mail"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#download"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="download-cloud"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#bookmark"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="bookmark"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon text-danger"
                        >
                          <Icon name="na"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="overline-title-alt mb-2">In Account</div>
                    <div className="profile-balance">
                      <div className="profile-balance-group gx-4">
                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">
                              2,500.00 <small className="currency currency-usd">USD</small>
                            </div>
                          </div>
                          <div className="profile-balance-subtitle">Invested Amount</div>
                        </div>
                        <div className="profile-balance-sub">
                          <span className="profile-balance-plus text-soft">
                            <Icon className="ni-plus"></Icon>
                          </span>
                          <div className="profile-balance-amount">
                            <div className="number">1,643.76</div>
                          </div>
                          <div className="profile-balance-subtitle">Profit Earned</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row className="text-center">
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user?.tasks}</span>
                          <span className="sub-text">Total Order</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user?.projects}</span>
                          <span className="sub-text">Complete</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user?.performed}</span>
                          <span className="sub-text">Progress</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Additional</h6>
                    <Row className="g-3">
                      <Col size="6">
                        <span className="sub-text">User ID:</span>
                        <span>UD003054</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Last Login:</span>
                        <span>{user?.lastLogin} 01:02 PM</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">KYC Status:</span>
                        <span
                          className={`lead-text text-${
                            user?.kycStatus === "success"
                              ? "success"
                              : user?.kycStatus === "pending"
                              ? "info"
                              : user?.kycStatus === "warning"
                              ? "warning"
                              : "secondary"
                          }`}
                        >
                          {user?.kycStatus?.toUpperCase()}
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Register At:</span>
                        <span>Nov 24, 2019</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                      Groups
                    </OverlineTitle>
                    <ul className="g-1">
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          investor
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          support
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          another tag
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Sidebar>
                {sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>} */}
              </div>
            </Card>
          </Block>
        </Content>
      )}

      <Modal isOpen={subscriptionConfirmedModal} size="md" toggle={() => setSubscriptionConfirmedModal(false)}>
        <ModalBody className="modal-body-md text-center">

          <div className="nk-modal">
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
            <h4 className="nk-modal-title">Plan upgraded successfully!</h4>
            <div className="nk-modal-text">
              <p>We sent you a confirmation email <strong>(this may take up to 3 hours to receive)</strong>.</p>
              <p className="sub-text-sm"><a href="/plans">Click here</a> to learn more about subscription plan.</p>
            </div>
            <div className="nk-modal-action-lg">
              <a href="#" className="btn btn-mw btn-light"
                onClick={(ev) => {
                  ev.preventDefault();
                  setSubscriptionConfirmedModal(false);
                }}
              >Return</a>
            </div>
          </div>

        </ModalBody>
        <div className="modal-footer bg-lighter">
          <div className="text-center w-100">
            <p>Earn up to $25 for each friend your refer! <a href="#">Invite friends</a></p>
          </div>
        </div>
      </Modal>

      <Modal isOpen={subscriptionCancelModal} size="md" toggle={() => setSubscriptionCancelModal(false)}>
        <a href="#" className="close"
          onClick={(ev) => {
            ev.preventDefault();
            setSubscriptionCancelModal(false);
          }}
        ><em className="icon ni ni-cross"></em></a>
        <div className="modal-body modal-body-md">
          <h4 className="nk-modal-title title">Cancel Your Subscription</h4>
          <p><strong>Are you sure you want to cancel your subscription?</strong></p>
          <p>If you cancel, you'll lose your all demand. But you can re-subscription your favourite plan any time.</p>
          <div className="form">
            <div className="form-group">
              <label className="form-label">Enter your password to confirm cancellation</label>
              <div className="form-control-wrap">
                <input type="password" className="form-control" placeholder="*********" />
              </div>
              <div className="form-note">
                <span>You'll receieve confirmation email once successfully cancel your plan.</span>
              </div>
            </div>
            <ul className="align-center flex-wrap g-3">
              <li>
                <button className="btn btn-primary"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSubscriptionCancelConfirmedModal(true);
                    setSubscriptionCancelModal(false);
                  }}
                >Cancel Subscription</button>
              </li>
              <li>
                <a href="#" className="btn btn-light"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSubscriptionCancelModal(false);
                  }} >Never mind, don't cancel</a>
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal isOpen={subscriptionCancelConfirmedModal} toggle={() => setSubscriptionCancelConfirmedModal(false)}>
        <ModalBody className="modal-body-md text-center">
          <div className="nk-modal">
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success-dim text-success"></em>
            <h4 className="nk-modal-title">Successfully Cancelled</h4>
            <div className="nk-modal-text">
              <p>You can still enjoy your current subscription untill <strong>{strings.unix(activePlan?.cancel_at)}</strong>. We sent you a confirmation email <strong>(this may take up to 3 hours to receive)</strong>.</p>
              <p className="sub-text-sm"><a href="/plans">Click here</a> to learn more about subscription plan.</p>
            </div>
            <div className="nk-modal-action-lg">
              <a href="#" className="btn btn-mw btn-light"
                onClick={(ev) => {
                  ev.preventDefault();
                  setSubscriptionCancelConfirmedModal(false);
                }} >Return</a>
            </div>
          </div>
        </ModalBody>
        <div className="modal-footer bg-lighter">
          <div className="text-center w-100">
            <p>You can easily re-subscribe to your favorite plan any time.</p>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UserDetailsPage;
