import React from "react";
import { Spinner } from "reactstrap";
import { Icon } from "../../components/Component";
import strings from "../../utils/Strings";
import { calculateMaxDrawdown } from "../../utils/Utils";
import { BacktestBalanceChart } from "../strategies/BacktestBalanceChart";
import { PortfolioChart } from "./PortfolioChart";

const PortfolioChartCard = ({ setIsLoadingChartData, portfolioData, quotes, allMessages, chartData, mode, backtestData }) => {
  const totalAssetsMarketVal = portfolioData?.positions?.reduce((acc, pos) => {
    const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === pos.asset)[0] : quotes?.quotes?.quote
    const last = parseFloat(allMessages?.[pos.asset]?.trade?.last) || q?.last;
    return (acc + (last * pos.size))
  }, 0)
  const maxDD = backtestData ? calculateMaxDrawdown(backtestData?.performance?.balanceHistory?.map(i => i.value)) : null;
  const nlv = portfolioData?.funds + totalAssetsMarketVal;
  const portfolioChange = ((nlv - portfolioData?.initialFunds) / portfolioData?.initialFunds) * 100;
  return (
    <React.Fragment>
      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm">
          <h5>{mode} overview</h5>
          {/* <p>How have your users, sessions, bounce rate metrics trended.</p> */}
        </div>
        <div className="card-tools shrink-0 d-none d-sm-block">
        </div>
      </div>
      <div className="analytic-ov">
        <div className="analytic-data-group analytic-ov-group g-3">
          <div className="analytic-data analytic-ov-data">
            <div className="title">{mode === "Portfolio" ? "NLV" : "Balance"}</div>
            <div className="amount">{strings.priceDecimal(nlv)}</div>
            <div className={`change ${portfolioChange > 0 ? "up" : portfolioChange < 0 ? "down" : ""}`}>
              <Icon name={`${portfolioChange > 0 ? "arrow-long-up" : portfolioChange < 0 ? "arrow-long-down" : ""}`}></Icon> {strings.percentSignFormatter(portfolioChange)}
            </div>
          </div>
          {mode === "Backtest" && <div className="analytic-data analytic-ov-data">
            <div className="title">Max drawdown</div>
            <div className="amount">{strings.percentSignFormatter(maxDD)}</div>
          </div>}
          <div className="analytic-data analytic-ov-data">
            <div className="title">Unrealised P&L</div>
            {/* <div className="amount">{auOverview === "month-1" ? "3.98" : "1.6"}K</div>
            <div className="change up">
              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "47.5" : "80.6"}%
            </div> */}
          </div>
          <div className="analytic-data analytic-ov-data">
            <div className="title">Realised P&L</div>
            {/* <div className="amount">{auOverview === "month-1" ? "28.25" : "10.25"}%</div>
            <div className="change down">
              <Icon name="arrow-long-down"></Icon> {auOverview === "month-1" ? "12.57" : "18.21"}%
            </div> */}
          </div>
          {mode === "Portfolio" && <div className="analytic-data analytic-ov-data">
            <div className="title">Buying power</div>
            <div className="amount">{strings.priceDecimal(portfolioData?.funds)}</div>
            {/* <div className="change down">
              <Icon name="arrow-long-down"></Icon> {auOverview === "month-1" ? "0.35" : "1.21"}%
            </div> */}
          </div>}
        </div>
        <div className="analytic-ov-ck">
          {mode === "Portfolio" ? (
            setIsLoadingChartData ? <Spinner /> : <PortfolioChart data={chartData} />
          ) : (
            <BacktestBalanceChart
              data={backtestData} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default PortfolioChartCard;
