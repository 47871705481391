import React, { useState, useContext } from "react";
import { Badge, Col, Row } from "reactstrap";
export const presets = [
  {value: "What can you do?", label: "What can you do?"},
  {value: "What's the key points from TSLA earning report Q4 2023?", label: "What's the key points from TSLA earning report Q4 2023?"},
  {value: "Compare NVDA 2023 Q3 and Q4 income statement", label: "Compare NVDA 2023 Q3 and Q4 income statement"},
  {value: "Summarize key points from AMZN balance sheet 2023 Q4", label: "Summarize key points from AMZN balance sheet 2023 Q4"},
  {value: "What's the main insights from Microsoft cash flow in 2023 Q3?", label: "What's the main insights from Microsoft cash flow in 2023 Q3?"},
  {value: "Find me some US tech stocks with more than $100M market cap that pays more than 10$ dividend", label: "Find me some US tech stocks with more than $100M market cap that pays more than 10$ dividend"},
  {value: "What's RSI?", label: "What's RSI?"},
]

const Presets = ({ setInput, onTextSubmit}) => {
  return (
<div style={{
  position: "absolute",
  bottom: "16px",
  width: "90%"
}}>
  <Row className="g-1">
{presets.map(i => {
  return (
    <Col xs="12">
    <Badge style={{
      fontSize: 12,
      padding: 4,
      whiteSpace: "pre-line",
      textAlign: "left",
      cursor: "pointer"
    }} onClick={(e) => {
      onTextSubmit(e, i.value);
    }}>{i.label}</Badge>
    </Col>
  )
})}
  </Row>
</div>
  );
};
export default Presets;
