import React, { useEffect, useState, useRef } from 'react';
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  NodeResizer,
} from 'reactflow';
import { drag } from 'd3-drag';
import { select } from 'd3-selection';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { Icon, TooltipComponent } from '../../../../../components/Component';
import LimitConnections from '../Handlers/LimitConnections';
import useStore from '../../../../../store/reactFlow';
import CreatableSelect from 'react-select/creatable';
import lodash from 'lodash';
import { useMyStore } from '../../../../bots/trading-bot-new/ReactFlowBuilder/store';

export default function ConditionGroupNode({
  id,
  sourcePosition = Position.Left,
  targetPosition = Position.Right,
  data,
  selected
}) {
  const { state } = data;
  const rotateControlRef = useRef(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const [inputValue, setInputValue] = React.useState('');
  const { updateNode, toggleIsOpen, tab, getConditionGroupStr } = useMyStore(({ updateNode, toggleIsOpen, tab, getConditionGroupStr }) => ({ updateNode, toggleIsOpen, tab, getConditionGroupStr }));

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        const t = {
          label: inputValue,
          value: inputValue,
        }
        if (!(lodash.some(state.tags, t))) {
          const temp = [...state?.tags, t]
          updateNode({ id, data: { state: { tags: temp, str: getConditionGroupStr({ tags: temp }) } }, symbol: tab })
        }
        setInputValue('');
    }
  };

  const isConnectable = ({ node, connectedEdges }) => {
    const conditionEdge = connectedEdges.filter(edge => edge.source.includes("conditionGroup") && edge.target.includes("condition"))
    const conditionGroupEdge = connectedEdges.filter(edge => edge.source.includes("conditionGroup") && edge.target.includes("conditionGroup"))
    const orderEdge = connectedEdges.filter(edge => edge.source.includes("order") && edge.target.includes("conditionGroup"))
    if (orderEdge.length === 0 || conditionEdge.length === 0 || conditionGroupEdge.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <Card className="card-bordered rule-set-node" style={{
      borderColor: selected ? "#9d72ff" : "",
    }}>
      <CardHeader>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Icon onClick={() => toggleIsOpen({ id, data, symbol: tab })} name={data?.isOpen ? "minimize-alt" : "maximize-alt"} className={"hide-png"} style={{
            cursor: "pointer",
            alignSelf: "flex-end"
          }} />
          <h5 className="card-title">Condition group</h5>
          {getConditionGroupStr({ ...state })}
        </div>
      </CardHeader>
      <Collapse
        className="accordion-body"
        isOpen={data?.isOpen}
      >
        <CardBody>
          <form>
            <div className="form-group">
              <label className="form-label" htmlFor="full-name">
                <b>Tags</b>
              </label>
              <div style={{
                float: "right",
                cursor: "default",
              }}
              >
                <TooltipComponent
                  icon="help-fill"
                  iconClass="card-hint"
                  direction="right"
                  id="conditionGroup-tooltip"
                  text="Tag the condition group to identify enter positions in backtests"
                />
              </div>
              <div className="form-control-wrap nodrag">
                <CreatableSelect inputValue={inputValue} value={state.tags} placeholder="Tag the condition group" isMulti
                  noOptionsMessage={() => null}
                  onChange={(tags) => {
                    const str = getConditionGroupStr({ tags });
                    updateNode({ id, data: { state: { tags, str } }, symbol: tab })
                  }}
                  components={
                    { DropdownIndicator: null }
                  }
                  onKeyDown={handleKeyDown}
                  onInputChange={(newValue) => setInputValue(newValue)}
                />
              </div>
            </div>
          </form>
        </CardBody>
      </Collapse>

      <LimitConnections id="right" className="rl" type="source" position={Position.Right}
        isConnectable={isConnectable}
      />
      <LimitConnections id="bottom" className="tb" type="source" position={Position.Bottom}
        isConnectable={isConnectable}
      />
      <LimitConnections id="top" className={"tb"} type="target" position={Position.Top}
        isConnectable={isConnectable}
      />
      <LimitConnections id="left" className={"rl"} type="target" position={Position.Left}
        isConnectable={isConnectable}
      />
    </Card>
  );
}
