import { MarkerType, addEdge, applyEdgeChanges, applyNodeChanges } from "reactflow";
import { create } from "zustand";
import { defaultConditionGroupNode, defaultConditionNode, defaultExitStrategy, defaultOrderNode } from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/constants";
import strings from "../../../../utils/Strings";
import { GET_BOT_INFO, UPDATE_BOT } from "../../../../api/graphql";
import { useMutation } from "@apollo/client";
import lodash from "lodash";
import { parseFlowSteps } from "../../../../api";



function calculateMenuPosition(event, div, menuWidth = 205, menuHeight = 250) {
  // Get the bounding rectangle of the div
  const rect = div.getBoundingClientRect();

  // Calculate the click position relative to the div
  const clickX = event.clientX - rect.left;
  const clickY = event.clientY - rect.top;

  // Calculate the menu position
  let menuX = clickX;
  let menuY = clickY;

  // Ensure the menu does not overflow the right edge of the div
  if (menuX + menuWidth > rect.width) {
    menuX = rect.width - menuWidth;
  }

  // Ensure the menu does not overflow the bottom edge of the div
  if (menuY + menuHeight > rect.height) {
    menuY = rect.height - menuHeight;
  }

  // Ensure the menu does not overflow the left edge of the div
  if (menuX < 0) {
    menuX = 0;
  }

  // Ensure the menu does not overflow the top edge of the div
  if (menuY < 0) {
    menuY = 0;
  }

  // Calculate the top, bottom, left, and right positions
  const top = menuY;
  const left = menuX;
  const bottom = rect.height - (menuY + menuHeight);
  const right = rect.width - (menuX + menuWidth);

  return { top, bottom, left, right };
}

const sleep = ms => new Promise(r => setTimeout(r, ms));
const parseExitStrategy = (type, exitStrategy) => {
  switch (exitStrategy?.type?.value) {
    case "percent":
      return `${exitStrategy?.value}%`
    case "dollar":
      return `${strings.price(exitStrategy?.value)}`

    default:
      console.error("Unsupported exit strategy: " + type);
  }
}
const parseExpression = (exprssion) => {
  switch (exprssion?.value?.value) {
    case "assetPrice":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.candlesDelta?.type === 'currentCandle' ? "current candle" : `${exprssion?.metadata?.candlesDelta?.value} candles ago`} ${exprssion?.metadata?.priceType.value.includes("change") ? "price " + exprssion?.metadata?.priceType.label : exprssion?.metadata?.priceType.value + " price"}`
    case "trend":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.timeframe.value} ${exprssion?.metadata?.type.value}`
    case "numberValue":
      return `${exprssion?.metadata?.numberValue}`
    case "rsi":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.candlesDelta?.type === 'currentCandle' ? "current candle" : `${exprssion?.metadata?.candlesDelta?.value} candles ago`} ${exprssion.value.name}[${exprssion?.metadata?.rsiPeriod}]`
    case "macd":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.candlesDelta?.type === 'currentCandle' ? "current candle" : `${exprssion?.metadata?.candlesDelta?.value} candles ago`} ${exprssion.value.name}[${exprssion?.metadata?.fastPeriod}][${exprssion?.metadata?.slowPeriod}][${exprssion?.metadata?.signalPeriod}]`
    case "sma":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.candlesDelta?.type === 'currentCandle' ? "current candle" : `${exprssion?.metadata?.candlesDelta?.value} candles ago`} ${exprssion.value.name}[${exprssion?.metadata?.smaPeriod}]`
    case "ema":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.candlesDelta?.type === 'currentCandle' ? "current candle" : `${exprssion?.metadata?.candlesDelta?.value} candles ago`} ${exprssion.value.name}[${exprssion?.metadata?.emaPeriod}]`
    case "adx":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.candlesDelta?.type === 'currentCandle' ? "current candle" : `${exprssion?.metadata?.candlesDelta?.value} candles ago`} ${exprssion.value.name}[${exprssion?.metadata?.adxPeriod}]`
    case "awesomeOscillator":
      return `${exprssion?.metadata?.assetSymbol} ${exprssion?.metadata?.candlesDelta?.type === 'currentCandle' ? "current candle" : `${exprssion?.metadata?.candlesDelta?.value} candles ago`} ${exprssion.value.name}[${exprssion?.metadata?.fastPeriod}][${exprssion?.metadata?.slowPeriod}]`

    default:
      console.error("Unsupported expresssion: " + exprssion.value.value);
  }
}

let id = 0;
const getId = ({ type, symbol }) => `${symbol}_${type}_${id++}`;

let edgeId = 0;
const getEdgeId = (type) => `${type}_${edgeId++}`;

const filter = (node) => {
  const exclusionClasses = ['hide-png'];
  return !exclusionClasses.some((classname) => node.classList?.contains(classname));
}
export const useMyStore = create((set, get) => ({
  changes: [],
  changesIndex: -1,
  menu: null,
  newNodeId: null,
  currentReactFlow: null,
  rfInstance: null,
  flowDescription: null,
  isRunningBacktest: false,

  interval: get()?.botData?.interval || { label: '1 day', value: '1 day' },
  symbols: get()?.botData?.symbols ? get().botData?.symbols : [],
  botName: get()?.botData.name,
  botColor: get()?.botData.color,
  botPrivate: get()?.botData?.private || false,
  state: get()?.botData?.state,
  tab: get()?.botData?.tab,


  saveChange: ({ symbol, nodes = [], edges = [] }) => {
    const rest = {};
    get().symbols.filter(s => s !== symbol).map(s => { rest[s] = get().state[s] });
    const change = {
      [symbol]: {
        active: true,
        nodes: nodes,
        edges: edges
      },
      ...rest
    };
    get().parse();
    let c = [...get().changes] || [];
    if (get().changesIndex < c.length - 1) {
      c.length = get().changesIndex + 1
    }
    c.push({ change })
    set({
      changes: c,
      changesIndex: get().changesIndex + 1,
      // changesIndex: c.length - 1,
    })
  },
  undoChange: () => {
    let c = get().changes;
    const changesIndex = get().changesIndex;
    if (changesIndex > 0) {
      const last = c[changesIndex - 1];
      const currentChange = c?.[changesIndex - 2];
      const allChangeSymbols = last?.change ? Object.keys(last?.change) : [];
      for (const symbol of allChangeSymbols) {
        const lastChange = last?.change?.[symbol];
        if (lastChange.active && (symbol !== get().tab)) {
          get().setTab(symbol)
        }
        if (lastChange?.nodes) {
          get().setNodes({
            undoOrRedo: true,
            nodes: lastChange.nodes,
            symbol: symbol
          });
          const selected = lastChange.nodes.filter(node => node.selected);
          if (selected.length > 0 && currentChange?.nodes?.length !== lastChange?.nodes?.length) {
            setTimeout(() => {
              get()?.currentReactFlow?.fitView({
                nodes: lastChange.nodes,
                duration: 100
              });
            }, 10)
          }
        }
        if (lastChange?.edges) {
          get().setEdges({
            undoOrRedo: true,
            edges: lastChange.edges,
            symbol: symbol
          })
        }
      }
      get().symbols.filter(s => !allChangeSymbols.includes(s)).map(s => {
        get().onRemoveAsset(s)
      })
      set({
        changesIndex: changesIndex - 1
      });
      get().parse();
    }
  },
  redoChange: () => {
    let c = get().changes;
    const changesIndex = get().changesIndex;
    if (changesIndex < c.length - 1) {
      const last = c[changesIndex + 1];
      const currentChange = c[changesIndex]?.change;
      const allChangeSymbols = last?.change ? Object.keys(last?.change) : [];
      for (const symbol of allChangeSymbols) {
        const lastChange = last?.change?.[symbol];
        if (lastChange.active && (symbol !== get().tab)) {
          get().setTab(symbol)
        }
        if (lastChange?.nodes) {
          get().setNodes({
            undoOrRedo: true,
            nodes: lastChange.nodes,
            symbol: symbol
          });
          const selected = lastChange.nodes.filter(node => node.selected);
          if (selected.length > 0 && currentChange?.nodes?.length !== lastChange?.nodes?.length) {
            setTimeout(() => {
              get()?.currentReactFlow?.fitView({
                nodes: lastChange.nodes,
                duration: 100
              });
            }, 10)
          }
        }
        if (lastChange?.edges) {
          get().setEdges({
            undoOrRedo: true,
            edges: lastChange.edges,
            symbol: symbol
          })
        }
      }

      get().symbols.filter(s => !allChangeSymbols.includes(s)).map(s => {
        get().onRemoveAsset(s)
      })
      set({
        changesIndex: changesIndex + 1
      });
      get().parse();
    }
  },
  parse: async () => {
    const state = get().state;
    const allSymbols = state ? Object.keys(state) : [];
    let allNodes = [];
    let allEdges = []
    allSymbols.map(s => {
      allNodes = allNodes.concat(state[s].nodes);
      allEdges = allEdges.concat(state[s].edges);
    })

    await parseFlowSteps({
      nodes: allNodes,
      edges: allEdges,
    }, (res) => {

      // const t = get().parseDesc({ desc: res.desc });
      get().setFlowDescription({ flowDescription: res.desc })
    }, error => {
      console.error(error);
    })
  },
  handleLongPress: ({ event, node, ref, symbol }) => {
    get().onNodeContextMenu({ event, node, ref: get().rfInstance, symbol })
  },
  getBotData: () => {
    return {
      interval: get()?.interval,
      tab: get()?.tab,
      symbols: get()?.symbols,
      botName: get()?.botName,
      botColor: get()?.botColor,
      state: get()?.state,
      flowDescription: get()?.flowDescription,
      botPrivate: get()?.botPrivate,

      botData: get().botData
    }
  },
  setBotData: async ({ botData }) => {
    set({
      interval: botData?.interval || { label: '1 day', value: '1 day' },
      symbols: botData?.symbols ? botData?.symbols : [],
      botName: botData?.name,
      botColor: botData?.color,
      botPrivate: botData?.private,
      state: botData?.state,
      tab: botData?.tab,
      botData,
      changes: []
    })
    get().parse();
  },

  setInterval: ({ interval }) => {
    set({
      interval
    })
  },

  setIsRunningBacktest: (isRunningBacktest) => {
    set({
      isRunningBacktest
    })
  },

  toggleBotPrivate: () => {
    set({
      botPrivate: !Boolean(get().botPrivate)
    })
  },

  setTab: (tab) => {
    set({
      tab
    })
    let nodesToFit = []
    let selected = get().state[tab]?.nodes.filter(node => node.selected);
    if (selected?.length > 0) {
      nodesToFit.push(selected[0]);
    }
    else {
      nodesToFit = get().state[tab]?.nodes
    }

    setTimeout(() => {
      get()?.currentReactFlow?.fitView({
        nodes: nodesToFit,
        duration: 100
      });
    }, 0)
  },

  onRemoveAsset: (asset) => {
    let t = [...get().symbols];
    const index = t.indexOf(asset);
    if (index > -1) { // only splice array when item is found
      t.splice(index, 1); // 2nd parameter means remove one item only
    }
    const s = { ...get().state };
    delete s[asset];


    const changes = get().changes
    const newChanges = [...changes, { change: s }];

    console.log(newChanges)
    console.log(get().changesIndex)
    set({
      symbols: t,
      state: s,
      tab: null,
      newChanges: newChanges,
      changesIndex: newChanges.length - 1
    });

    get().parse();
  },

  setSymbols: (symbols) => {
    set({
      symbols
    })
  },

  setBotName: (v) => {
    set({
      botName: v,
    })
  },

  setBotColor: (v) => {
    set({
      botColor: v,
    })
  },

  setFlowDescription: ({ flowDescription }) => {
    set({
      flowDescription,
    })
  },

  setRfInstance: (rfInstance) => {
    set({
      rfInstance
    })
  },

  setReactFlowInstance: (flow) => {
    set({
      currentReactFlow: flow
    })
  },

  setNewNodeId: ({ id, symbol }) => {
    let t = lodash.cloneDeep(get().state);
    if (symbol in t) {
      t[symbol].newNodeId = id
    }
    else {
      t = {
        ...t,
        [symbol]: { newNodeId: id }
      }
    }
    set({
      state: t
    })
  },

  setMenu: ({ menu, symbol }) => {
    set({
      menu
    })
  },

  parseDesc: ({ desc }) => {
    let str = "```\n";
    // let str = "";
    for (const [index, order] of desc.entries()) {
      str += `${index + 1}. ${order.str}\n`
      if (order?.conditionGroups?.length) {
        str += `${order.logicalType}\n`

        for (const group of order.conditionGroups) {
          if (group.type === "logical") {
            str += `${group.value}\n`
          }
          else if (group.rules) {
            str += "(\n"
            for (const [i, rule] of group.rules.entries()) {
              if (i % 2 === 0) {
                str += `${rule}\n`
              }
              else {
                str += `${rule.value}\n`
              }
            }
            str += ")\n"
          }
        }
      }

      if (order.exitStrategy) {
        str += `\n${order.exitStrategy.str}`
      }
      str += `\n\n`
    }

    str += "\n```"
    return str;

  },
  useSaveBot: () => {
    const [updateBotMutationLink] = useMutation(UPDATE_BOT);
    const data = get().getBotData();

    const save = async () => {

      try {
        const { botData, botColor, botPrivate, botName, symbols, tab, flowDescription, description, interval, state } = data;
        let submittedData = {
          name: botName,
          private: botPrivate,
          symbols, tab, color: botColor, flowDescription: get().parseDesc({ desc: flowDescription }), description, interval: {
            label: interval.label,
            value: interval.value
          }, state
        };

        const res = await await updateBotMutationLink({
          variables: { record: submittedData, id: botData?._id },
          refetchQueries: [
            {
              query: GET_BOT_INFO,
              // name: "strategyById",
              variables: { id: botData?._id }
            }
          ]
        })

        return res;

      } catch (error) {
        console.error(error);
      }
    }

    return save;
  },
  setState: ({ state }) => {
    set({ state });
  },
  setNewFlow: ({ symbol }) => {
    set({
      state: {
        ...get().state,
        [symbol]: {
          nodes: [],
          edges: []
        }
      }
    });

    get().addOrderNode({ symbol })
  },
  resetFlow: ({ symbol }) => {
    get().setNodes({ nodes: [], symbol })
    get().setEdges({ edges: [], symbol })

    get().saveChange({
      symbol,
      nodes: [],
      edges: []
    })
  },

  onNodesChange: ({ changes, symbol }) => {
    const newNodeId = get().state[symbol].newNodeId
    changes.forEach((change) => {
      if (
        change.type === "dimensions" &&
        newNodeId === change.id &&
        change.dimensions &&
        change.dimensions.height > 0 &&
        change.dimensions.width > 0
      ) {
        get().currentReactFlow?.fitView({
          nodes: [{ id: newNodeId }],
          duration: 500
        });

        // reset the focus id so we don't retrigger fit view when the dimensions of this node happen to change
        get().setNewNodeId({ id: null, symbol });
      }
    });
    const newNodes = applyNodeChanges(changes, get().state[symbol].nodes)
    set({
      state: { ...get().state, [symbol]: { ...get().state[symbol], nodes: newNodes } },
    });
  },
  onEdgesChange: ({ changes, symbol }) => {
    set({
      state: { ...get().state, [symbol]: { ...get().state[symbol], edges: applyEdgeChanges(changes, get().state[symbol].edges) } },
    });
  },
  onConnect:
    ({ connection, symbol }) => {
      let type;
      if (connection.source.includes('order') && connection.target.includes('conditionGroup')) {
        type = "conditionGroup";
      }
      else if (connection.source.includes('order') && connection.target.includes('exitStrategy')) {
        type = "exitStrategy";
      }
      else if (connection.source.includes('order') ||
        (connection.source.includes('conditionGroup') && connection.target.includes('condition'))) {
        type = "when";
      }
      else {
        type = "logical"
      }
      const edge = {
        ...connection,
        type,
        markerEnd: { type: MarkerType.ArrowClosed },
        data: {
          type: connection.sourceHandle === "right" ? "enter" : "exit",
          state: type === "when" ? { logicalType: { label: "IF", value: "if" } } : { logicalType: { label: "AND", value: "and" } },
        }
      }
      get().setEdges({ edges: addEdge(edge, get().state?.[symbol]?.edges), symbol });
    },
  setNodes: ({ nodes, symbol, undoOrRedo = false }) => {
    let t = { ...get().state };
    const edges = t[symbol]?.edges
    if (symbol in t) {
      if ("nodes" in t?.[symbol]) {
        t[symbol]["nodes"] = nodes
      }
    }
    else {
      get().setSymbols([...get().symbols, symbol])
      t = {
        ...t,
        [symbol]: {
          ...t[symbol],
          nodes: nodes
        }
      }
    }
    // if (!undoOrRedo) {
    //   const rest = {};
    //   get().symbols.filter(s => s !== symbol).map(s => { rest[s] = get().state[s] });
    //   get().saveChange({
    //     change:
    //     {
    //       [symbol]: {
    //         active: true,
    //         nodes,
    //       },
    //       ...rest
    //     },
    //   })
    // }
    set({ state: t });
  },
  setEdges: ({ edges, symbol, shouldSave = false }) => {
    let t = get().state;
    const nodes = t[symbol].nodes
    if (symbol in t) {
      t[symbol]["edges"] = edges
    }
    else {
      t = {
        ...t,
        [symbol]: {
          ...t[symbol],
          edges: edges
        }
      }
    }
    // if (shouldSave) {
    //   const rest = {};
    //   get().symbols.filter(s => s !== symbol).map(s => { rest[s] = get().state[s] });
    //   get().saveChange({
    //     change:
    //     {
    //       edges,
    //       nodes,
    //       symbol
    //     },
    //     ...rest
    //   })
    // }
    set({ state: t });
    get().parse();
  },
  updateNode: ({ id, data, symbol }) => {
    let t = get().state;
    const newNodes = get().state[symbol].nodes.map(node =>
      node.id === id
        ? { ...node, data: { ...node.data, ...data } }
        : node
    )
    t[symbol].nodes = newNodes;
    get().setNodes({
      nodes: newNodes,
      symbol
    })
    set({ state: t });

    get().saveChange({
      symbol,
      nodes: newNodes,
    })
  },
  updateNodeSelected({ id, selected, symbol }) {
    let t = get().state;
    t[symbol].nodes = get().state[symbol].nodes.map(node =>
      node.id === id
        ? { ...node, selected }
        : node
    )
    set({ state: t });
  },
  updateEdge({ id, data, symbol }) {
    let t = get().state;
    const newEdges = t[symbol]?.edges.map(edge =>
      edge.id === id
        ? { ...edge, data: { ...edge.data, ...data } }
        : edge
    )
    t[symbol].edges = newEdges
    get().setEdges({
      edges: newEdges,
      symbol,
    })
    set({
      state: t
    });
    get().saveChange({
      symbol,
      edges: newEdges
    })
  },
  onDragOver: ({ event, symbol }) => {
    // event.preventDefault();
    // event.dataTransfer.dropEffect = 'move';
    get().setMenu({ menu: null, symbol });

  },
  toggleIsOpen: ({ id, data, symbol }) => {
    const t = !Boolean(data?.isOpen)
    get().updateNode({ id, data: { ...data, isOpen: t }, symbol });
    // let nodes = get().nodes;
    // nodes = nodes.map(node => ({...node, selected: false}))

    // get().updateNodeSelected(id, true)
  },

  addLogicalConditionGroupNode:
    ({ event, parentId, symbol }) => {
      event.preventDefault();
      const nodes = get().state[symbol].nodes;
      const edges = get().state[symbol].edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x : 0,
        y: parentNode?.position?.y ? parentNode?.position?.y + parentNode.height + 120 : 0,
      };

      const newID = getId({ type: "conditionGroup", symbol });
      get().setNewNodeId({ id: newID, symbol })

      const newNode = {
        id: newID,
        type: "conditionGroup",
        position,
        data: {
          state: defaultConditionGroupNode,
          isOpen: true,
          logical: true
        },
      };

      const newNodes = nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode);
      get().setNodes({
        nodes: newNodes,
        symbol
      })

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("logical"),
        type: "logical",
        target: newID,
        source: parentId,
        data: {
          state: { logicalType: { label: "AND", value: "and" } }
        },
        sourceHandle: "bottom"
      };
      const newEdges = edges.concat(newEdge)
      get().setEdges({ edges: newEdges, symbol })

      get().saveChange({
        symbol,
        nodes: newNodes,
        edges: newEdges
      })
    },

  addConditionalExitStrategyNode:
    ({ event, parentId, symbol }) => {
      event.preventDefault();
      const nodes = get().state[symbol].nodes;
      const edges = get().state[symbol].edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x : 0,
        y: parentNode?.position?.y ? (parentNode?.position?.y + 200) : 0,
      };

      const newID = getId({ type: "conditionGroup", symbol });
      get().setNewNodeId({ id: newID, symbol })

      const newNode = {
        id: newID,
        type: "conditionGroup",
        position,
        data: {
          state: { ...defaultConditionGroupNode, str: get().getConditionGroupStr({ ...defaultConditionGroupNode }) },
          isOpen: true,
        },
        selected: true
      };
      const newNodes = nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode)
      get().setNodes({
        nodes: newNodes,
        symbol
      })

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("conditionGroup"),
        type: "conditionGroup",
        data: { type: "exit" },
        target: newID,
        source: parentId,
        sourceHandle: "bottom",
        targetHandle: "top",
      };
      const newEdges = edges.concat(newEdge)
      get().setEdges({ edges: newEdges, symbol })

      get().saveChange({
        symbol,
        nodes: newNodes,
        edges: newEdges
      })
    },

  addConditionGroupNode:
    ({ event, parentId, symbol }) => {
      event.preventDefault();
      let t = get().state[symbol];
      const nodes = t.nodes;
      const edges = t.edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x + parentNode.width + 120 : 0,
        y: parentNode?.position?.y ? parentNode?.position?.y : 0,
      };

      const newID = getId({ type: "conditionGroup", symbol });
      get().setNewNodeId({ id: newID, symbol })

      const newNode = {
        id: newID,
        type: "conditionGroup",
        position,
        data: {
          state: { ...defaultConditionGroupNode, str: get().getConditionGroupStr({ ...defaultConditionGroupNode }), },
          isOpen: true,
        },
        selected: true
      };

      const newNodes = nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode)
      get().setNodes({
        nodes: newNodes,
        symbol
      })

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("conditionGroup"),
        type: "conditionGroup",
        data: { type: "enter" },
        target: newID,
        source: parentId,
        sourceHandle: "right",
        targetHandle: "left",
      };

      const newEdges = edges.concat(newEdge);
      get().setEdges({ edges: newEdges, symbol })

      get().saveChange({
        symbol,
        nodes: newNodes,
        edges: newEdges
      })
    },

  addExitStrategyNode:
    ({ event, parentId, symbol }) => {
      event.preventDefault();
      let t = get().state[symbol];
      const nodes = t.nodes;
      const edges = t.edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x - 30 : 0,
        y: parentNode?.position?.y ? (parentNode?.position?.y + 200) : 0,
      };

      const newID = getId({ type: "exitStrategy", symbol });
      get().setNewNodeId({ id: newID, symbol })

      const initialExitStrategyNode = {
        takeProfit: defaultExitStrategy,
        stopLoss: defaultExitStrategy,
      }

      const newNode = {
        id: newID,
        type: "exitStrategy",
        position,
        data: {
          state: { ...initialExitStrategyNode, str: get().getExitStrategyNodeStr({ ...initialExitStrategyNode }) },
          isOpen: true,
        },
        selected: true
      };
      const newNodes = nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode)
      get().setNodes({
        nodes: newNodes,
        symbol
      })

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("exitStrategy"),
        type: "exitStrategy",
        target: newID,
        source: parentId,
        sourceHandle: "bottom",
      };
      const newEdges = edges.concat(newEdge)
      get().setEdges({ edges: newEdges, symbol })

      get().saveChange({
        symbol,
        nodes: newNodes,
        edges: newEdges
      })
    },

  addOrderNode:
    ({ event, symbol }) => {
      event && event.preventDefault();
      let t = get().state;
      const nodes = t?.[symbol]?.nodes || [];


      const orderNodes = nodes.filter(node => node.type === "order")
      const last = orderNodes[orderNodes.length - 1];

      const position =
      // get().reactFlowInstance?.screenToFlowPosition(
      {
        x: last?.position?.x ? last?.position?.x : 1000,
        y: (orderNodes.length + 1) * 400,
      }
      // );

      const newID = getId({ type: "order", symbol });
      get().setNewNodeId({ id: newID, symbol })

      const newNode = {
        id: newID,
        type: "order",
        position,
        data: {
          state: { ...defaultOrderNode, targetAsset: symbol, str: get().getOrderNodeStr({ ...defaultOrderNode, targetAsset: symbol }) },
          isOpen: true,
        },
        selected: true
      };

      const newNodes = nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode)
      get().setNodes({
        nodes: newNodes,
        symbol
      })

      get().saveChange({
        symbol,
        nodes: newNodes,
      })
    },

  addConditionNode:
    ({ event, parentId, symbol, type = "when" }) => {
      event.preventDefault();
      let t = get().state[symbol];
      const nodes = t.nodes;
      const edges = t.edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x + parentNode.width + 120 : 0,
        y: parentNode?.position?.y ? type === "when" ? parentNode?.position?.y : parentNode?.position?.y : 0,
      };

      const newID = getId({ type: "condition", symbol });
      get().setNewNodeId({ id: newID, symbol })

      const newNode = {
        id: newID,
        type: "condition",
        position,
        data: {
          state: { ...defaultConditionNode, str: get().getConditionNodeStr({ ...defaultConditionNode }) },
          isOpen: true,
        },
        selected: true
      };
      const newNodes = nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode)
      get().setNodes({
        nodes: newNodes,
        symbol
      })

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId(type),
        type: type,
        target: newID,
        source: parentId,
        sourceHandle: "right",
        data: {
          state: type === "when" ? { logicalType: { label: "IF", value: "if" } } : { logicalType: { label: "AND", value: "and" } }
        }
      };
      const newEdges = edges.concat(newEdge)
      get().setEdges({ edges: newEdges, symbol })

      get().saveChange({
        symbol,
        nodes: newNodes,
        edges: newEdges
      })
    },

  onNodeContextMenu:
    ({ event, node, ref, symbol }) => {
      // Prevent native context menu from showing
      event.preventDefault();

      const p = calculateMenuPosition(event, ref?.current)

      get().setMenu({
        menu: {
          ...node,
          ...p
        }, symbol
      });
    },

  // Close the context menu if it's open whenever the window is clicked.
  onPaneClick: ({ e, symbol }) => {
    // e.preventDefault();
    get().setMenu({ menu: null, symbol })
  },

  onNodeClick: ({ e, symbol }) => {
    e.preventDefault();
    get().setMenu({ menu: null, symbol })
  },


  onMoveStart: ({ e, symbol }) => {
    e.preventDefault();
    get().setMenu({ menu: null, symbol })
  },

  duplicateNode: ({ id, symbol }) => {
    let t = get().state;
    const [node] = t[symbol].nodes.filter(n => n.id === id);
    const clone = { ...node }

    const position = {
      x: node.position.x + 100,
      y: node.position.y + 100,
    };

    const newNodes = [...t[symbol].nodes.map(n => ({ ...n, selected: false })), { ...clone, id: `${node.id}-${t[symbol].nodes.length + 1}-copy`, position, selected: true }]
    get().setNodes({ nodes: newNodes, symbol });

    get().saveChange({
      symbol,
      nodes: newNodes,
    })
  },

  deleteNode: ({ id, symbol }) => {
    const newNodes = get().state?.[symbol]?.nodes.filter((node) => node.id !== id)
    const newEdges = get().state?.[symbol]?.edges.filter((edge) => ((edge.source !== id) && (edge.target !== id)))
    get().setNodes({ nodes: newNodes, symbol });
    get().setEdges({ edges: newEdges, symbol });

    get().saveChange({
      symbol,
      nodes: newNodes,
      edges: newEdges
    })
  },
  getOrderNodeStr: ({ sizeType, sizeValue, targetAsset, maximumOpenPositions, actionType, delayBetweenPositions }) => {
    const size = sizeType.value === "percentOfFunds" ? `${sizeValue} percents of available funds` :
      sizeType.value === "percentCurrentPositions" ? `${sizeValue} percents of current positions` :
        sizeType.value === "dollars" ? `${sizeValue}$ worth of` :
          `${sizeValue}`;
    let str = `${actionType.label} ${size} ${targetAsset} shares`
    str += ` ${maximumOpenPositions} maximum positions`
    if (maximumOpenPositions > 1)
      str += ` with ${delayBetweenPositions} candles delay`

    return str;
  },
  getConditionGroupStr: ({ tags }) => {
    let str = `Tags: \n`
    str += tags.map(t => t.label).join(", ");
    return str;
  },
  getConditionNodeStr: ({ leftHandExpression, rightHandExpression, operator }) => {
    const leftHandExpressionParsed = parseExpression(leftHandExpression)
    const rightHandExpressionParsed = parseExpression(rightHandExpression)
    const opParsed = operator.label;

    const str = `${leftHandExpressionParsed} ${opParsed} ${rightHandExpressionParsed}`
    return str;
  },
  getExitStrategyNodeStr: ({ takeProfit, stopLoss }) => {
    const takeProfitParsed = parseExitStrategy("takeProfit", takeProfit)
    const stopLossParsed = parseExitStrategy("stopLoss", stopLoss);

    let str = ""
    if (takeProfit?.checked) {
      str += `Close @${takeProfitParsed} profit \n`
    }
    if (stopLoss?.checked) {
      str += `Close @${stopLossParsed} loss`
    }

    return str;
  },
  getMergedFlow: () => {
    let allSymbols = get().symbols;
    let allNodes = [], allEdges = [];

    allSymbols.map(s => {
      allNodes = allNodes.concat(get().state?.[s].nodes)
      allEdges = allEdges.concat(get().state?.[s].edges)
    })

    return {
      nodes: allNodes,
      edges: allEdges
    }
  }
}));