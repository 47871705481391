import React, { useState, useEffect, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { filterRole, filterStatus, userData } from "./UserData";
import { bulkActionOptions, findUpper } from "../../../utils/Utils";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner,
  Card
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { UserContext } from "./UserContext";
import EditModal from "./EditModal";
import AddModal from "./AddModal";
import { useQuery } from "@apollo/client";
import { GET_COMPANY_FILES } from "../../../api/graphql";
import { getFileFromS3 } from "../../../api";
import moment from "moment";

const FileListCompact = ({companyFilings, setModalData, setShowModal}) => {
  const data = companyFilings;
  const [loadingFile, setLoadingFile] = useState(false)
  const supportedFileTypes = ['10-K', '10-Q', '8-K', '8-Q']

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [sort, setSortState] = useState("");

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data?.filter(i => supportedFileTypes.includes(i.type))?.slice(indexOfFirstItem, indexOfLastItem).sort((a, b) => b.fillingDate - a.fillingDate);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onClickFile = async (file) => {
    setLoadingFile(file.finalLink)
    getFileFromS3({key: file.finalLink},
      (data) => {
        setModalData({html: data, file});
        setShowModal(true);
        setLoadingFile(false)
      }, 
      (err) => {
        console.error(err);
        setLoadingFile(false)
      })
  }

  return <Card>
          <h6>  Company SEC filings </h6>
      <div className="mt-2">
        <DataTable className="card-stretch">
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow>
                <span className="sub-text">Form</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Date</span>
              </DataTableRow>
              <DataTableRow>
                
              </DataTableRow>
            </DataTableHead>
            {/*Head*/}
            {currentItems?.length > 0
              ? currentItems?.map((item) => {
                  return (
                    <DataTableItem key={item.id} onClick={() => onClickFile(item)} style={{
                      cursor: "pointer"
                    }} >
                      <DataTableRow >
                        <span>{item.type}</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>{moment(item.fillingDate).format("MM/DD/YYYY")}</span>
                      </DataTableRow>
                      {loadingFile === item.finalLink ? <DataTableRow>
                        <Spinner size="sm" type="grow"/>
                      </DataTableRow> : <DataTableRow></DataTableRow>}
                    </DataTableItem>
                  );
                })
              : null}
          </DataTableBody>
          <div className="card-inner">
            {currentItems?.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data?.filter(i => supportedFileTypes.includes(i.fileType))?.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
          </div>
        </DataTable>
      </div>
  </Card>;
};
export default FileListCompact;
