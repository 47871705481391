import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from "reactstrap"
import useAuth from '../../../hooks/useAuth'
import Logo from "../../../layout/logo/Logo"
import { Link } from "../../components/button/Button"
import { HeaderCaption, HeaderText, HeaderTitle } from '../../components/headerCaption/HeaderCaption'
import { Header, HeaderContent, HeaderMain, HeaderWrap } from "../../layout/header/Header"
import Menu from '../../layout/menu/Menu'
import MobileMenu from '../../layout/menu/MobileMenu'
import { BannerFiveData, LoggedInMenuData } from './BannerData'
import useAnalyticsEventTracker from '../../../hooks/useAnalyticsEventTracker'
import { useNavigate } from 'react-router'

const BannerFive = (props) => {
    const { logout, user } = useAuth();
    const [toggle, setToggle] = useState(false);
    const [offset, setOffset] = useState(0);
    const [mobileView, setMobileView] = useState(false);
    const navigate = useNavigate();
    const gaEventTracker = useAnalyticsEventTracker('Action Button');

    const onClickActionButton = (e) => {
        e.preventDefault();
        gaEventTracker("click", "header")
        navigate('/login')
    }


    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
        window.scrollTo(0, 0);
        viewChange();
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener('resize', viewChange);
        };
    }, []);

    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 992) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    };


    return (
        <Header className={props.className && props.className} id={props.id && props.id} >
            <HeaderMain className={`header-main-s1 is-sticky is-transparent on-dark ${offset > 0 ? "has-fixed" : ""}`}>
                <Container className='header-container'>
                    <HeaderWrap>
                        <div className={`header-logo ${offset > 0 ? "" : "text-white"}`}>
                            <Logo
                            // dark ={LogoDrak2x}
                            // light ={LogoLight2x}
                            />
                        </div>
                        <div className="header-toggle" onClick={() => setToggle(!toggle)}>
                            <button className={`menu-toggler ${toggle === true ? "active" : ""}`}>
                                <em className="menu-on icon ni ni-menu"></em>
                                <em className="menu-off icon ni ni-cross"></em>
                            </button>
                        </div>
                        <nav className={`header-menu menu ${toggle === true ? "active" : ""} ${mobileView ? "mobile-menu" : ""}`}>
                            {!mobileView ? <Menu className="ms-lg-auto" data={user ? LoggedInMenuData : BannerFiveData} /> : <MobileMenu data={user ? LoggedInMenuData : BannerFiveData} />}
                            {user && <ul className="menu-btns">
                                <li>
                                    <Button onClick={async () => {
                                        await logout();
                                    }} >Logout</Button>
                                </li>
                            </ul>}
                        </nav>
                        {toggle && window.innerWidth < 992 && <div className="header-overlay" onClick={() => setToggle(!toggle)}></div>}
                    </HeaderWrap>
                </Container>
            </HeaderMain>
            <HeaderContent className="my-auto py-6 is-dark">
                <Container className="mt-n4 mt-lg-0">
                    <Row className="flex-lg-row-reverse align-items-center justify-content-between g-gs">
                        <Col lg="6" className='mb-lg-0'>
                            {/* <div className='header-play text-lg-center'>
                                <a onClick={()=> setOpen(true)} href="#0" className="play popup-video">
                                    <StyledIcon className="styled-icon styled-icon-6x styled-icon-s5 text-warning">
                                        <Play />
                                    </StyledIcon>
                                    <div className="play-text">2:58 minutes</div>
                                </a>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="SSo_EIwHSd4" onClose={() => setOpen(false)} />
                            </div> */}
                        </Col>
                        <Col lg="6" style={{ marginTop: -32 }}>
                            <HeaderCaption>
                                {/* <div className='header-rating rating'>
                                    <Rating data={BannerTwoIcon} /> 
                                    <RatingText>14,252 reviews</RatingText>
                                </div> */}
                                <HeaderTitle className="fw-medium">There is a new ninja in town 🥷🏻</HeaderTitle>
                                <HeaderText className="pe-5">
                                    <h5>Superpower algotrading platform. </h5>
                                </HeaderText>
                                <ul className="header-action btns-inline">
                                    <li>
                                        <Button to="#" onClick={onClickActionButton} rel="noreferrer" color="primary" className="btn-primary btn-lg btn-round"><span>Try it For Free!</span></Button>
                                    </li>
                                </ul>
                            </HeaderCaption>
                        </Col>
                    </Row>
                </Container>
            </HeaderContent>
            {/* <BgImage className="bg-image bg-overlay after-bg-dark after-opacity-95 overlay-fall bg-image-loaded bg-image-header-a" /> */}
        </Header>
    )
}

export default BannerFive
