import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router";
import Toggle from "../../../layout/sidebar/Toggle";
import { useTheme, useThemeUpdate } from "../../../layout/provider/Theme";
import { Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import AssetLogo from "../../../components/AssetLogo";
import { useReactFlow } from "reactflow";
import { useTour } from "@reactour/tour";
import { useMyStore } from "./ReactFlowBuilder/store";
import { Icon, PreviewCard } from "../../../components/Component";
import { GET_BOT_INFO, UPDATE_BOT } from "../../../api/graphql";
import { useMutation } from "@apollo/client";

const Header = ({ assets, fixed, className, ...props }) => {
  const { currentReactFlow, useSaveBot, tab, setEdges, setNodes } = useMyStore();
  const [updateBotMutationLink] = useMutation(UPDATE_BOT);
  const [isSaving, setIsSaving] = useState(false);

  const save = useSaveBot();

  const onSave = async () => {
    try {
      setIsSaving(true);
      await save();
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  }

  const theme = useTheme();

  return (

    <>
      <li>
        <Button onClick={onSave}>
          <Icon name="save" />{isSaving && <Spinner type="grow" size="sm" />}
        </Button>
      </li>
      {/* <li>
        <Button onClick={onRestore}>
          <Icon name="undo" />
        </Button>
      </li> */}
    </>
  );
};
export default Header;
