import React, { useEffect, useState } from "react";
import Icon from "../icon/Icon";
import UserAvatar from "../user/UserAvatar";
import { CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Spinner } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../table/DataTable";
import { Link } from "react-router-dom";
import { getChangeBadge } from "../../utils/Utils";
import strings from "../../utils/Strings";
import { getMarketMovers } from "../../api";

const MarketMoversTable = ({ }) => {
  const [activeTab, setActiveTab] = useState('topGainers');
  const [marketMovers, setMarketMovers] = useState([]);
  const [isLoadingMarketMovers, setIsLoadingMarketMovers] = useState(false);

  useEffect(() => {
    const loadMarketMovers = async () => {
      setIsLoadingMarketMovers(true);

      getMarketMovers({ type: activeTab }, (res) => {
        setMarketMovers(res)
        setIsLoadingMarketMovers(false)
      }, (err) => {
        console.error(err)
        setIsLoadingMarketMovers(false)
      })
    }

    loadMarketMovers();
  }, [activeTab]);

  if (isLoadingMarketMovers) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <div className="card-title-group mb-1">
        <div className="card-title">
          <h6 className="title">Market movers</h6>
        </div>
      </div>
      <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs">
        <li className="nav-item" onClick={() => setActiveTab("topGainers")}>
          <a
            href="#topGainers"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className={`nav-link${activeTab === "topGainers" ? " active" : ""}`}
          >
            Top gainers
          </a>
        </li>
        <li className="nav-item" onClick={() => setActiveTab("topLosers")}>
          <a
            href="#topLosers"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className={`nav-link${activeTab === "topLosers" ? " active" : ""}`}
          >
            Top losers
          </a>
        </li>
        <li className="nav-item" onClick={() => setActiveTab("mostActive")}>
          <a
            href="#mostActive"
            onClick={(ev) => {
              ev.preventDefault();
            }}
            className={`nav-link${activeTab === "mostActive" ? " active" : ""}`}
          >
            Most active
          </a>
        </li>
      </ul>
      <div className="tab-content mt-0" style={{
        height: 300,
        overflow: 'auto'
      }}>
        <DataTableBody bodyclass="nk-tb-orders">
          <DataTableHead>
            <DataTableRow size="md" style={{
              maxWidth: 100
            }}>
              <span>Name</span>
            </DataTableRow>
            <DataTableRow >
              <span>Symbol</span>
            </DataTableRow>
            <DataTableRow>
              <span>Change %</span>
            </DataTableRow>
            <DataTableRow>
              <span>Change</span>
            </DataTableRow>
            <DataTableRow>
              <span>Price</span>
            </DataTableRow>
          </DataTableHead>
          {marketMovers?.map((item, idx) => {
            return (
              <DataTableItem key={idx}>
                <DataTableRow size="md" style={{
                  maxWidth: 120,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}>
                  <span >
                    <Link to={`/one-pager/${item?.symbol}`}>
                      {item.name}
                    </Link>
                  </span>
                </DataTableRow>

                <DataTableRow>
                  <span>
                    <Link to={`/one-pager/${item?.symbol}`}>
                      {item.symbol}
                    </Link>
                  </span>
                </DataTableRow>
                <DataTableRow>
                  <span>
                    {getChangeBadge({ val: item.changesPercentage })}
                  </span>
                </DataTableRow>
                <DataTableRow>
                  <span>
                    <Icon className={(item.change > 0 ? "text-success" : item.change < 0 ? "text-danger" : "text-primary")} name={item.change > 0 ? "arrow-up" : item.change < 0 ? "arrow-down" : "dot"} /> {strings.priceDecimal(item.change)}
                  </span>
                </DataTableRow>
                <DataTableRow>
                  <span>
                    {strings.priceDecimal(item.price)}
                  </span>
                </DataTableRow>
              </DataTableItem>
            );
          })}
        </DataTableBody>
      </div>
    </React.Fragment>
  );
};
export default MarketMoversTable;
