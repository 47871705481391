import React, { useEffect, useState } from "react";
import RSI from "./Indicators/RSI";
import MACD from "./Indicators/MACD";
import SMA from "./Indicators/SMA";
import EMA from "./Indicators/EMA";
import AwesomeOscillator from "./Indicators/AwesomeOscillator";
import ADX from "./Indicators/ADX";
import { RSelect } from "../../../../components/Component";
import AssetPrice from "./AssetPrice";
import NumberValue from "./NumberValue";
import Trend from "./Trend";

const indicatorOptions = [
    {
        value: "sma", label: "Simple moving average", element: SMA, name: "SMA",
    },
    {
        value: "ema", label: "Exponential moving average", element: EMA, name: "EMA",
    },
    {
        value: "rsi", label: "Relative strength index", element: RSI, name: "RSI",
    },
    {
        value: "macd", label: "Moving average convergence/divergence", element: MACD, name: "MACD",
    },
    {
        value: "awesomeOscillator", label: "Awesome oscillator", element: AwesomeOscillator, name: "Awesome oscillator",
    },
    {
        value: "adx", label: "Average directional index", element: ADX, name: "ADX",
    },
]

const simpleOptions = [
    { value: "assetPrice", label: "Asset price", element: AssetPrice, name: "Asset price" },
    { value: "trend", label: "Trend", element: Trend, name: "Trend" },
    { value: "numberValue", label: "Number value", element: NumberValue, name: "Number value" },
]

export const groupedData = [
    {
        label: "Simple",
        options: simpleOptions,
    },
    {
        label: "Indicators",
        options: indicatorOptions
    },
];

const getDefaultMetadata = (expression) => {
    switch (expression.value) {
        case "numberValue":
            return {
                numberValue: 0
            }
        case "assetPrice":
            return {
                assetSymbol: "AAPL",
                priceType: {value: "close", label: "close"},
                candlesDelta: {value: 0, type: "currentCandle"}
            }
        case "trend":
            return {
                assetSymbol: "AAPL",
                timeframe: {value: "candles", label: "Candles"},
                type: {value: "up", label: "Up"}
            }
        case "rsi":
            return {
                assetSymbol: "AAPL",
                rsiPeriod: 20,
                candlesDelta: {value: 0, type: "currentCandle"}
            }
        case "sma":
            return {
                assetSymbol: "AAPL",
                smaPeriod: 50,
                candlesDelta: {value: 0, type: "currentCandle"}
            }
        case "ema":
            return {
                assetSymbol: "AAPL",
                emaPeriod: 50,
                candlesDelta: {value: 0, type: "currentCandle"}
            }
        case "adx":
            return {
                assetSymbol: "AAPL",
                adxPeriod: 50,
                candlesDelta: {value: 0, type: "currentCandle"}
            }
        case "awesomeOscillator":
            return {
                assetSymbol: "AAPL",
                fastPeriod: 5,
                slowPeriod: 34,
                candlesDelta: {value: 0, type: "currentCandle"}
            }
        case "macd":
            return {
                assetSymbol: "AAPL",
                signalPeriod: 9,
                fastPeriod: 12,
                slowPeriod: 26,
                candlesDelta: {value: 0, type: "currentCandle"}
            }
    }
}
const ExpressionSelect = ({ expression, setExpression }) => {
    const Element = [
        ...simpleOptions,
        ...indicatorOptions
    ].filter(o => o?.name === expression?.value?.name)[0]?.element;
    return (
        <>
            <RSelect value={expression.value} onChange={(e) => {
                let t = { ...expression };
                t.value = e;
                t.metadata = getDefaultMetadata(e)
                setExpression(t);
            }} className="nodrag" options={groupedData} />
            <div className="card card-bordered">
                <div className="card-inner nodrag">
                    {/* <b>{value?.name} metadata</b> */}
                    {
                        Element && <Element metadata={expression.metadata} setMetadata={(v) => {
                            let t = { ...expression };
                            t.metadata = v;
                            setExpression(t);
                        }} />
                    }
                </div>
            </div>
        </>
    )
};
export default ExpressionSelect;