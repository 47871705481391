import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GET_BACKTEST_BY_ID } from "../../api/graphql";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import PortfolioPage from "../portfolios/PortfolioPage";

function normalizeData(my_array) {
  var max = _.max(my_array); // underscore max and min functions
  var min = _.min(my_array);
  const normalized = my_array?.map(val => val == 0 ? 0 : ((val - min) / (max - min)).toFixed(2))

  return {
    normalized,
    min,
    max
  };
}

const BacktestPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const backtestId = pathname.split('/')[4];
  const { data: backtestData, refetch, fetchMore, loading, error } = useQuery(GET_BACKTEST_BY_ID, {
    variables: {
      id: backtestId
    }
  });
  const [data, setData] = useState(backtestData?.backtestById);
  const [user, setUser] = useState();
  let { kycId } = useParams();

  useEffect(() => {
    setData(backtestData?.backtestById)
  })
  // const allLabels = new Set([...data?.performance?.chart?.map(i => i?.startTime)])
  const spyChart = data?.performance?.chart?.map(i => i?.["SPY"]?.close);
  const balanceChart = data?.performance?.balanceHistory?.map(i => i.value);
  const allBalanceLabels = ((data?.performance?.balanceHistory) ? [...data?.performance?.balanceHistory?.map(i => i.time)] : [])
  const allChartLabels = ((data?.performance?.chart) ? [...data?.performance?.chart?.map(i => i.startTime)] : [])
  const allLabels = Array.from(new Set([...allChartLabels, ...allBalanceLabels]));

  const balanceNormalized = normalizeData(balanceChart)
  const spyNormalized = normalizeData(spyChart);

  if (loading) return <SpinnerWrapper />;

  return <PortfolioPage backtestData={backtestData?.backtestById} portfolioData={backtestData?.backtestById?.portfolio} />

  // return (
  //   <>
  //     <Head title="Backtest"></Head>
  //     {data && (
  //       <Content>
  //         <BlockHead size="sm">
  //           <BlockBetween className="g-3">
  //             <BlockHeadContent>
  //               <BlockTitle page>
  //                 Backtests / <strong className="text-primary small">{data.name}</strong>
  //               </BlockTitle>
  //               {/* <BlockDes className="text-soft">
  //                 <ul className="list-inline">
  //                   <li>
  //                     Application ID: <span className="text-base">KID000844</span>
  //                   </li>
  //                   <li>
  //                     Submitted At: <span className="text-base">{data.date}</span>
  //                   </li>
  //                 </ul>
  //               </BlockDes> */}
  //             </BlockHeadContent>
  //             <BlockHeadContent>
  //               <Link to={`${process.env.PUBLIC_URL}/strategy/${data.strategyId}`}>
  //                 <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
  //                   <Icon name="arrow-left"></Icon>
  //                   <span>Back</span>
  //                 </Button>
  //                 <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
  //                   <Icon name="arrow-left"></Icon>
  //                 </Button>
  //               </Link>
  //             </BlockHeadContent>
  //           </BlockBetween>
  //         </BlockHead>

  //         <Block>
  //           <Row className="g-gs">
  //             <Col lg="5">
  //               <PreviewAltCard className="card-bordered">

  //               <div className="analytic-ov">
  //       <div className="analytic-data-group analytic-ov-group g-3">
  //         <div className="analytic-data analytic-ov-data">
  //           <div className="title">Balance</div>
  //           <div className="amount">{strings.formatNumber(data?.performance?.totalProfit + data?.initialFunds)}</div>
  //           <div className={data?.performance?.totalProfit > 0 ? "change up" : "change down"}>
  //             {`${strings.priceDecimal(data?.performance?.totalProfit)} (${strings.percentSignFormatter(((data?.performance?.totalProfit)/data?.initialFunds)*100)})`} 
  //             <Icon name={data?.performance?.totalProfit > 0 ? "arrow-long-up" : "arrow-down"}></Icon>
  //           </div>
  //         </div>
  //         <div className="analytic-data analytic-ov-data">
  //           <div className="title">Max drawdown</div>
  //           <div className="amount">{strings.percentSignFormatter(maxDD)}</div>
  //         </div>

  //       </div>
  //       <div className="analytic-ov-ck">
  //                 <BacktestBalanceChart data={{
  //                   labels: allLabels,
  //                   datasets: [
  //                     {
  //                       yAxisID: "yBalance",
  //                       label: "Balance",
  //                       color: "#9d72ff",
  //                       borderWidth: 2,
  //                       fill: true,
  //                       lineTension: 0,
  //                       dash: 0,
  //                       borderColor: "#9d72ff",
  //                       backgroundColor: "transparent",
  //                       borderCapStyle: "square",
  //                       pointBorderColor: "transparent",
  //                       pointBackgroundColor: "transparent",
  //                       // pointHoverBackgroundColor: "#fff",
  //                       // pointHoverBorderColor: "#9d72ff",
  //                       pointBorderWidth: 2,
  //                       pointHoverRadius: 4,
  //                       pointHoverBorderWidth: 2,
  //                       pointRadius: 4,
  //                       pointHitRadius: 4,
  //                       data: balanceChart
  //                     },
  //                     {
  //                       yAxisID: "yBenchmark",
  //                       label: "Benchmark",
  //                       color: "#3a8dfe",
  //                       borderWidth: 2,
  //                       fill: true,
  //                       lineTension: 0,
  //                       dash: 0,
  //                       borderColor: "#3a8dfe",
  //                       backgroundColor: "transparent",
  //                       borderCapStyle: "square",
  //                       pointBorderColor: "transparent",
  //                       pointBackgroundColor: "transparent",
  //                       // pointHoverBackgroundColor: "#fff",
  //                       // pointHoverBorderColor: "#3a8dfe",
  //                       pointBorderWidth: 2,
  //                       pointHoverRadius: 4,
  //                       pointHoverBorderWidth: 2,
  //                       pointRadius: 4,
  //                       pointHitRadius: 4,
  //                       data: spyChart
  //                     },
  //                     // {
  //                     //   label: "Asset price",
  //                     //   color: "#3fbd9a",
  //                     //   borderWidth: 2,
  //                     //   fill: true,
  //                     //   lineTension: 0,
  //                     //   dash: 0,
  //                     //   borderColor: "#3fbd9a",
  //                     //   backgroundColor: "rgb(4 144 0 / 20%)",
  //                     //   borderCapStyle: "square",
  //                     //   pointBorderColor: "transparent",
  //                     //   pointBackgroundColor: "transparent",
  //                     //   pointHoverBackgroundColor: "#fff",
  //                     //   pointHoverBorderColor: "#3fbd9a",
  //                     //   pointBorderWidth: 2,
  //                     //   pointHoverRadius: 4,
  //                     //   pointHoverBorderWidth: 2,
  //                     //   pointRadius: 4,
  //                     //   pointHitRadius: 4,
  //                     //   data: data?.performance?.chart?.map(i => normalizeData(i?.["AAPL"]?.close, targetAssetChart))
  //                     // }
  //                   ]
  //                 }} />
  //                 </div>
  //                 </div>
  //               </PreviewAltCard>
  //               <PreviewAltCard className="card-bordered">
  //               <BlockHead>
  //                   <BlockHeadContent>
  //                     <BlockTitle tag="h5">General</BlockTitle>
  //                     <p>Backtest metadata</p>
  //                   </BlockHeadContent>
  //                 </BlockHead>
  //               <ul className="">
  //                   <div className="data-col">
  //                     <div className="data-label">Backtest ID</div>
  //                     <div className="data-value">{data?._id}</div>
  //                   </div>
  //                   <div className="data-col">
  //                     <div className="data-label">Created At</div>
  //                     <div className="data-value">{data?.createdAt}</div>
  //                   </div>
  //                   <div className="data-col">
  //                     <div className="data-label">Interval</div>
  //                     <div className="data-value">{data?.interval?.label}</div>
  //                   </div>
  //                   <div className="data-col">
  //                     <div className="data-label">Status</div>
  //                     <div className="data-value">
  //                       <Badge
  //                         size="sm"
  //                         color={
  //                           data.status === "running"
  //                             ? "outline-success"
  //                             : data.status === "done"
  //                               ? "outline-info"
  //                               : "outline-danger"
  //                         }
  //                         className="badge-dim"
  //                       >
  //                         {data.status}
  //                       </Badge>
  //                     </div>
  //                   </div>
  //                   <div className="data-col">
  //                     <div className="data-label">Total profit</div>
  //                     <div className="data-value">
  //                       <div className="user-card">
  //                         {/* <UserAvatar theme="orange-dim" text={findUpper(data.checked)}></UserAvatar> */}
  //                         <div className="user-info">
  //                           <span className="tb-lead">{strings.formatNumber(data?.performance?.totalProfit)}</span>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </ul>
  //               </PreviewAltCard>
  //             </Col>

  //             <Col lg="7">
  //               <PreviewAltCard>
  //                 <BlockHead>
  //                   <BlockHeadContent>
  //                     <BlockTitle tag="h5">Positions Information</BlockTitle>
  //                     <p>All positions entered in backtest</p>
  //                   </BlockHeadContent>
  //                 </BlockHead>
  //                 <PositionsTable data={data?.performance?.positions || []} isCompact />
  //               </PreviewAltCard>
  //             </Col>
  //           </Row>
  //         </Block>
  //       </Content>
  //     )}
  //   </>
  // );
};
export default BacktestPage;
