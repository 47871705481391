import React from "react"
import { Col, Row, Container } from "reactstrap"
import Logo from "../../../layout/logo/Logo"
import Footer from "../../layout/footer/Footer"
import LogoDrak2x from '../../images/logo-dark2x.png'
import LogoLight2x from '../../images/logo2x.png'
import { SocialIcon } from "../../components/icon/Icon"
import { LinkInline } from "../../components/styledList/StyledList"
import { SocialIconOne, LinkDataThree } from './FooterData'
import { Widget, WidgetTitle } from "../../components/wdiget/Widget"
import { Icon } from "../../../components/Component"


const FooterTwo = (props) => {
    return (
        <Footer className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-between py-4 py-md-6">
                    <Col md="6">
                        <Widget className="widget-about">
                            <Logo to="/IndexThree"
                                dark={LogoDrak2x}
                                light={LogoLight2x}
                            />
                        </Widget>
                    </Col>
                    <Col md="6">
                        <div className="text-white text-end">
                            traid &copy; 2024 | crafted with <Icon name="heart-fill" className={"text-danger"} />
                        </div>
                        {/* <div className="text-white text-end">
                            <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show" target="_blank" rel="noreferrer" >Online Dispute Resolution</a>
                        </div> */}
                    </Col>
                </Row>
                {/* <hr className="hr border-light mb-0 mt-n1"></hr>
                <Row className="g-3 align-items-center justify-content-md-between py-4">
                    <Col md="4" className="d-flex justify-content-md-end">
                        <SocialIcon data={SocialIconOne} />
                    </Col>
                </Row> */}
            </Container>
        </Footer>
    )
}

export default FooterTwo
