import AssetPrice from "../../Expressions/AssetPrice"
import NumberValue from "../../Expressions/NumberValue"

export const defaultExitStrategy = {
  type: { value: "percent", label: "Percent profit" },
  value: 1,
  checked: true
}
export const defaultConditionGroupNode = {
  tags: []
}

export const defaultOrderNode = {
  maximumOpenPositions: 1,
  delayBetweenPositions: 5,
  targetAsset: "AAPL",
  actionType: { value: "long", label: "Buy" },
  sizeType: { label: "Shares", value: "shares" },
  sizeValue: 100,
}


export const defaultConditionNode = {
  operator: { label: "Greater than", value: ">" },
  leftHandExpression: { value: { value: "assetPrice", label: "Asset price", element: AssetPrice, name: "Asset price" }, metadata: { assetSymbol: "AAPL", priceType: { value: "close", label: "close" }, candlesDelta: { value: 0, type: "currentCandle" } } },
  rightHandExpression: { value: { value: "numberValue", label: "Number value", element: NumberValue, name: "Number value" }, metadata: { numberValue: 1 } },
}


export const defaultStrategy = {
  name: "Buy apple on first up candle",
  description: "Simple strategy with some exit rules",
  flow: null
}

export const tradingBotNames = [
  "TradeMaster",
  "QuantumTrader",
  "AlphaBot",
  "ZenTrade",
  "ProfitPulse",
  "MarketMaven",
  "RoboTrader",
  "ApexBot",
  "TradeGenius",
  "StealthTrader",
  "MidasBot",
  "CryptoWhiz",
  "TradeTitan",
  "RoboRanger",
  "TradeSage",
  "ProfitPro",
  "EchoTrader",
  "TradeWiz",
  "CyberTrader",
  "FlashTrade"
];