import React from 'react'
import { Container, Col, Row, Card } from 'reactstrap'
import { Review, ReviewText } from '../../components/review/Review'
import { Section, SectionHead } from '../../layout/section/Section'
import User from '../../components/user/User'
import User1 from '../../images/client/sq-a.jpg'
import User2 from '../../images/client/sq-b.jpg'

const ReviewThree = (props) => {
    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-lg-center text-lg-center">
                    <Col lg="6" md="10">
                        <SectionHead>
                            <h2 className="title">Hear them out</h2>
                            <h6 className="fs-15px">Our ninjas community want to tell you something</h6>
                        </SectionHead>
                    </Col>
                </Row>
                <Row className="g-gs">
                    <Col lg="6" md="6">
                        <Card className="card-shadow">
                            <div className="card-inner card-inner-lg">
                                <Review className="review-s4">
                                    <User className="review-user user-s1" src={User1} name="Michal J." role="Day trader" />
                                    <ReviewText>
                                        <p>Using Traid has revolutionized my trading. With its user-friendly interface and robust features, I can easily create, backtest, and fine-tune strategies for better results. The platform's comprehensive analysis tools save me time and help me make informed decisions. I highly recommend Traid to traders serious about success.</p>
                                    </ReviewText>
                                </Review>
                            </div>
                        </Card>
                    </Col>
                    <Col lg="6" md="6">
                        <Card className="card-shadow">
                            <div className="card-inner card-inner-lg">
                                <Review className="review-s4">
                                    <User className="review-user user-s1" src={User2} name="John D." role="Swing trader" />
                                    <ReviewText>
                                        <p>Traid has been a game-changer for me. Its seamless bot creation and backtesting capabilities give me a competitive edge. The platform's in-depth analysis tools provide valuable insights, making trading decisions easier. I recommend Traid to anyone looking to enhance their trading experience.</p>
                                    </ReviewText>
                                </Review>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default ReviewThree
