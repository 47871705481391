import { Card, CardBody, CardText, CardTitle, Col, Label, Row, Spinner } from "reactstrap"
import { Icon, PreviewAltCard, PreviewCard } from "../../../components/Component";
import { BarChart } from "../../../components/partials/charts/default/Charts";
import { useCallback, useEffect, useState } from "react";
import { StyledIcon } from "../../../landingApp/components/icon/Icon";
import Play from "../../../landingApp/components/svg/Play";
import { useMutation } from "@apollo/client";
import { GET_BOT_INFO, JUST_RUN_BACKTEST } from "../../../api/graphql";
import { useMyStore } from "./ReactFlowBuilder/store";
import moment from "moment";
import { Line } from "react-chartjs-2";
import BacktestChart from "./BacktestChart";
import { BacktestBalanceChart } from "../../strategies/BacktestBalanceChart";
import ReactDatePicker from "react-datepicker";
import { addCommas, removeNonNumeric } from "../../../utils/Utils";
import OrderListNew from "../../portfolios/orders/OrderList";


const BacktestOrders = ({ backtestData }) => {
    const allOrders = backtestData?.portfolio?.positions?.reduce((a, b) => ([...a, ...b.orders]), []);
    return (
        allOrders ? <OrderListNew portfolioId={backtestData?.portfolio?._id} ordersData={allOrders} /> : <></>
    )
}

export default BacktestOrders;