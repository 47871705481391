import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Col, Row, Spinner } from "reactstrap";
import { getQuotes, getStockNews } from "../../api";
import AssetLogo from "../../components/AssetLogo";
import { Icon, PreviewAltCard } from '../../components/Component';
import useAuth from "../../hooks/useAuth";
import strings from "../../utils/Strings";
import PostFeedCard from "../../components/PostFeedCard";
import PostFeedVideo from "../../components/PostFeedVideo";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";

const WatchlistNews = ({ data, itemOnly }) => {
  const [newsData, setNewsData] = useState([]);
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    const loadNews = async () => {
      setIsLoadingNews(true);
      getStockNews({ tickers: data?.symbols, page: 0 },
        (res) => {
          setNewsData(res)
          setIsLoadingNews(false)
        }, (err) => {
          console.error(err)
          setIsLoadingNews(false)
        })
      // }
    }

    loadNews();
  }, [
    data
  ]);

  const articles = newsData.filter(n => n.site !== "youtube.com")
  const vids = newsData.filter(n => n.site === "youtube.com")

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  if (newsData?.length > 0) {
    return (<PreviewAltCard >
      <h6>{data?.name} news</h6>

      <Swiper
        // effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={mobileView ? 1 : 3}
        initialSlide={1}
        spaceBetween={64}
        pagination={true}
        modules={[
          Pagination,
          // EffectCoverflow
        ]}
      >
        {vids?.map((video, i) => {
          return (
            <SwiperSlide>
              <PostFeedVideo item={video} index={i} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Row className="g-2">
        {articles?.map(i => {
          return (
            <Col lg={12}>
              <PostFeedCard item={i} />
            </Col>
          )
        })}
      </Row>
    </PreviewAltCard>)
  }
  else {
    return null
  }
};

export default WatchlistNews;
