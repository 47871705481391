import React, { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Col, Row } from "reactstrap";
import useAuth from "../hooks/useAuth";
import { PreviewAltCard } from "./Component";

const SymbolPrice = ({ symbols }) => {
  const { user } = useAuth();
  const [allMessages, setAllMessages] = useState({});
  const { lastMessage, getWebSocket, lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(`${process.env.REACT_APP_API_WS_URL}?userId=${user.uid}`, {
    // share: false,
    // filter: () => false,
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    onClose: (e) => {
      console.log(e)
      console.log('WebSocket connection closed.');
    },
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent)
      return true;
    },
  });

  useEffect(() => {
    if (lastJsonMessage?.symbol) {
      if (lastJsonMessage.type === 'summary') {
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], summary: lastJsonMessage };
      }
      else
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], trade: lastJsonMessage };
    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      symbols?.length && sendJsonMessage({
        type: "watchlistStream",
        symbols: symbols
      })
    }
  }, [readyState, symbols])

  return (
    <Row className="g-gs">
      {
        symbols.map(s => {
          const symbol = allMessages?.[s];
          const lastRecordedPrice = allMessages[symbol]?.trade ? allMessages[symbol]?.trade?.last : allMessages[symbol]?.summary?.open
          return (
            <Col xs={"3"}>
              <PreviewAltCard>
                {s} {lastRecordedPrice !== "NaN" ? lastRecordedPrice : ""}
              </PreviewAltCard>
            </Col>
          )
        })
      }
    </Row>
  )
};

export default SymbolPrice;
