import React, { memo, useEffect, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import Autocomplete from '../../../../../components/autocomplete/Autocomplete';
import { Icon, NSComponent, PreviewCard, RSelect, TooltipComponent } from '../../../../../components/Component';
import { useCallback } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';
import Action from '../../Action';
import Size from '../../Size';
import LimitConnections from '../Handlers/LimitConnections';
import { ThemeNode } from '../../themes';
import useStore from '../../../../../store/reactFlow';
import { useMyStore } from '../../../../bots/trading-bot-new/ReactFlowBuilder/store';
import useLongPress from '../../../../../hooks/useLongPress';

const actionOptions = [
  { value: "long", label: "Buy" },
  { value: "short", label: "Sell" },
]

export const sizeOptions = [
  { value: "shares", label: "Shares" },
  { value: "dollars", label: "Dollars" },
  { value: "percentOfFunds", label: "% of funds" },
  { value: "percentCurrentPositions", label: "% of current positions" },
]

function OrderNode(node,) {
  const { id, data, selected } = node;
  const { setMenu, handleLongPress, onNodeContextMenu, currentReactFlow, updateNode, toggleIsOpen, tab, getOrderNodeStr } = useMyStore();
  const onChangeState = (key, value) => {
    let t = { ...data.state }
    t[key] = value;
    t.str = getOrderNodeStr({ ...t });
    updateNode({ id, data: { state: t }, symbol: tab })
    // setState(t);
  }

  useEffect(() => {
    // setState(data.state);
  }, [data])
  const onChangeTargetAsset = (event, option) => {
    onChangeState("targetAsset", option)
  }

  const onChangeActionType = (e) => {
    onChangeState("actionType", e)
  }

  const onChangeMaximumOpenPositions = (v) => {
    // data.node.sizeType = v;
    onChangeState("maximumOpenPositions", v)
  }

  const onChangeDelayBetweenPositions = (v) => {
    // data.node.sizeType = v;
    onChangeState("delayBetweenPositions", v)
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 100,
  };
  const longPressEvent = useLongPress((event) => handleLongPress({
    event,
    node,
    symbol: tab,
  }), (e) => {
    e.preventDefault();
    setMenu({
      symbol: tab,
      menu: null
    })
  }, defaultOptions);

  return (
    <Card
      className="card-bordered order" style={{
        borderColor: selected ? "#9d72ff" : ""
      }}>
      <div
      // className='nodrag' {...longPressEvent}
      >
        <CardHeader>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Icon onClick={() => toggleIsOpen({ id, data, symbol: tab })} name={data?.isOpen ? "minimize-alt" : "maximize-alt"} className={"hide-png"} style={{
              cursor: "pointer",
              alignSelf: "flex-end"
            }} />
            <h5 className="card-title">Order</h5>
            {getOrderNodeStr({ ...data.state })}
          </div>
        </CardHeader>
        <Collapse
          className="accordion-body"
          isOpen={data?.isOpen}
        >
          <CardBody>
            <form>
              <div className="form-group">
                <label className="form-label" htmlFor="full-name">
                  <b>Position type</b>
                </label>
                <div style={{
                  float: "right",
                  cursor: "default",
                }}
                >
                  <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="action-tooltip"
                    text="What action you want to take"
                  />
                </div>
                <div className="form-control-wrap">
                  <Action actionType={data.state.actionType} setActionType={onChangeActionType} />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="full-name">
                  <b>Maximum positions</b>
                </label>
                <div style={{
                  float: "right",
                  cursor: "default",
                }}
                >
                  <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="maxOpenPositions-tooltip"
                    text="Set the maximum number of positions to open from this order"
                  />
                </div>
                <div className="form-control-wrap">
                  <NSComponent defaultVal={data.state.maximumOpenPositions} onChange={onChangeMaximumOpenPositions} />
                </div>
              </div>
              {data.state.maximumOpenPositions > 1 && <div className="form-group">
                <label className="form-label" htmlFor="full-name">
                  <b>Delay between positions</b>
                </label>
                <div style={{
                  cursor: "default",
                  float: "right"
                }}
                >
                  <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="delayBetweenPositions-tooltip"
                    text="Set the the number of candles to wait before opening another position - only enabled when maximum positions is greater than 1"
                  />
                </div>
                <div className="form-control-wrap">
                  <NSComponent defaultVal={data.state.delayBetweenPositions} onChange={onChangeDelayBetweenPositions} />
                </div>
              </div>}
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  <b>Allocation</b>
                </label>
                <div style={{
                  float: "right",
                  cursor: "default",
                }}
                >
                  <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="allocation-tooltip"
                    text="Set the amount of shares to buy or sell"
                  />
                </div>
                <div className="form-control-wrap">
                  <Size sizeOptions={sizeOptions} sizeType={data.state.sizeType} setSizeType={(v) => {
                    // data.node.sizeType = v;
                    onChangeState("sizeType", v)
                  }} sizeValue={data.state.sizeValue} setSizeValue={(v) => {
                    // data.node.sizeValue = v;
                    onChangeState("sizeValue", v)
                  }} />
                </div>
              </div>
              {/* <div className="form-group">
              <label className="form-label" htmlFor="phone-no">
                <b>Target asset</b>
              </label>
              <div style={{
                float: "right",
                cursor: "default",
              }}
              >
                <TooltipComponent
                  icon="help-fill"
                  iconClass="card-hint"
                  direction="right"
                  id="targetAsset-tooltip"
                  text="Set the asset you want to buy or sell"
                />
              </div>
              <div className="form-control-wrap">
                <Autocomplete defaultValue={state.targetAsset} onClickOption={onChangeTargetAsset} />
              </div>
            </div> */}
            </form>
          </CardBody>
        </Collapse>
        <LimitConnections id="right" className="rl" type="source" position={Position.Right} />
        <LimitConnections id="bottom" className="tb" type="source" position={Position.Bottom} />
      </div>
    </Card>

  )
}

export default memo(OrderNode);
