import React, { useEffect, useState } from "react";
import Autocomplete from "../../../../../components/autocomplete/Autocomplete";
import CandlesDelta from "../CandlesDelta";

const MACD = ({metadata, setMetadata}) => {
  const {assetSymbol, signalPeriod, fastPeriod, slowPeriod} = metadata;
  const [asset, setAsset] = useState(assetSymbol);
  const [signal, setSignal] = useState(signalPeriod);
  const [fast, setFast] = useState(fastPeriod);
  const [slow, setSlow] = useState(slowPeriod);
  return (
    <>
    <CandlesDelta metadata={metadata} setMetadata={setMetadata} />
    <div className="form-group" style={{
      width: '100%',
    }}>
      <label className="form-label">Asset</label>
      <Autocomplete defaultValue={asset} className="form-control" onClickOption={(e, option) => {
        setAsset(option);
        setMetadata({...metadata, assetSymbol: option})
      }} />
    </div>
     <div className="form-group">
     <label className="form-label">Fast period</label>
     <input
       type="number"
       value={fast}
       placeholder="Fast period"
       onChange={(e) => {
         e.preventDefault();
         setFast(e.target.value)
         setMetadata({...metadata, fastPeriod: e.target.value})
       }}
       className="form-control" />
   </div>
     <div className="form-group">
     <label className="form-label">Slow period</label>
     <input
       type="number"
       value={slow}
       placeholder="Slow period"
       onChange={(e) => {
         e.preventDefault();
         setSlow(e.target.value)
         setMetadata({...metadata, slowPeriod: e.target.value})
       }}
       className="form-control" />
   </div>
     <div className="form-group">
     <label className="form-label">Signal period</label>
     <input
       type="number"
       value={signal}
       placeholder="Signal period"
       onChange={(e) => {
         e.preventDefault();
         setSignal(e.target.value)
         setMetadata({...metadata, signalPeriod: e.target.value})
       }}
       className="form-control" />
   </div>
   </>
  );
};
export default MACD;