import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content.js";
import Head from "../../layout/head/Head.js";
import { Block, BlockBetween, BlockContent, BlockDes, BlockHead, BlockHeadContent, BlockTitle, PreviewAltCard, PreviewCard } from "../../components/Component.js";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import MarketOverview from "../../components/partials/invest/invest-overview/MarketOverview.js";
import "../../../node_modules/swiper/swiper.scss";
import MarketMoversTable from "../../components/marketMovers/MarketMoversTable.js";
import UpcomingEarningCalls from "../../components/UpcomingEarningCalls.js";
import IPOCalendar from "../../components/partials/ipo-calendar/IPOCalendar.js";
import { Helmet } from "react-helmet";

const Market = ({ ...props }) => {
  return (
    <>
      <Head title="Market" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Market overview
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Everything that matters</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Row className="g-gs">
          <Col md="12" lg="6">
            <PreviewAltCard className="h-100">
              <MarketOverview />
            </PreviewAltCard>
          </Col>
          <Col md="12" lg="6">
            <PreviewAltCard className="h-100">
              <MarketMoversTable />
            </PreviewAltCard>
          </Col>
          <Col md={12} lg="6">
            <PreviewAltCard className="h-100">
              <UpcomingEarningCalls />
            </PreviewAltCard>
          </Col>
          <Col md={12} lg="6">
            <PreviewAltCard className="h-100">
              <IPOCalendar />
            </PreviewAltCard>
          </Col>
          {/* <Col md="6" lg="12">
                <Block>
                <PreviewCard className="h-100">
                  <SupportCard />
                </PreviewCard>
                </Block>
              </Col> */}
        </Row>
      </Content>
    </>
  );
};

export default Market;
