import React, { memo, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import Autocomplete from '../../../../../components/autocomplete/Autocomplete';
import { NSComponent, PreviewCard, RSelect } from '../../../../../components/Component';
import { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
  
const operatorOptions = [
  {label: "Greater than", value: ">"},
  {label: "Less than", value: "<"},
  {label: "Equals", value: "=="},
]

function ExplainNode({ id, data }) {
  const [operator, setOperator] = useState(operatorOptions[0])

  return (
    <Row className="g-gs hide-png">
      <Col lg="12">
        <PreviewCard className="h-100 explain">
          <b>YO!</b><br />
          There is no validation yet so follow these rules for your strategy to run:<br /><br />
          
          Start by adding an order node from the top-right panel.<br />
          From there, operate with right click on every node to continue the flow.<br /><br />
          
          <b>1.</b> Flow must have at least one order node.<br />
          <b>2.</b> Order nodes can point to both "Exit strategy" and "Condition group" nodes.<br />
          <b>3.</b> Condition group nodes can point to other "Condition group" and "Condition" nodes.<br />
          <b>4.</b> Condition nodes can only point to other "Condition" node.<br /><br />

          Have fun 🥳
          </PreviewCard>
      </Col>
    </Row>
  )
}

export default memo(ExplainNode);
