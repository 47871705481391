import { create } from 'zustand';
import {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  getNodesBounds,
  getViewportForBounds,
  MarkerType,
} from 'reactflow';
import { toBlob } from 'html-to-image';
import { defaultExitStrategy, defaultConditionGroupNode, defaultOrderNode, defaultConditionNode, defaultStrategy } from '../pages/strategies/StrategyPolicyBuilder/RuleBuilder/constants';
const sleep = ms => new Promise(r => setTimeout(r, ms));

let id = 0;
const getId = (type) => `${type}_${id++}`;

let edgeId = 0;
const getEdgeId = (type) => `${type}_${edgeId++}`;

const filter = (node) => {
  const exclusionClasses = ['hide-png'];
  return !exclusionClasses.some((classname) => node.classList?.contains(classname));
}

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create((set, get) => ({
  strategyFormData: defaultStrategy,
  setStrategyFormData: (strategyFormData) => {
    set({
      strategyFormData
    })
  },
  showFlowModal: false,
  setShowFlowModal: (v) => {
    set({
      showFlowModal: v,
    })
  },
  resetFlow: () => {
    get().setNodes([])
    get().setEdges([])
    get().setFlowImage(null)
  },
  showTraidhubModal: false,
  setShowTraidhubModal: (v) => {
    set({
      showTraidhubModal: v,
    })
  },
  nodes: [{
    id: "explain",
    type: "explain",
    data: {},
    position: { x: 0, y: 0 },
  }],
  edges: [],
  reactFlowInstance: null,
  setReactFlowInstance: (flow) => {
    set({
      reactFlowInstance: flow,
    })
  },
  flowImage: null,
  setFlowImage: (flowImage) => {
    set({
      flowImage,
    })
  },
  newNodeId: null,
  setNewNodeId: (newNodeId) => {
    set({
      newNodeId,
    })
  },
  menu: null,
  setMenu: (menu) => {
    set({ menu: menu })
  },
  setRef: (ref) => {
    set({ ref })
  },
  onNodesChange: (changes) => {
    const newNodeId = get().newNodeId
    changes.forEach((change) => {
      if (
        change.type === "dimensions" &&
        newNodeId === change.id &&
        change.dimensions &&
        change.dimensions.height > 0 &&
        change.dimensions.width > 0
      ) {
        get()?.reactFlowInstance?.fitView({
          nodes: [{ id: newNodeId }],
          duration: 500
        });

        // reset the focus id so we don't retrigger fit view when the dimensions of this node happen to change
        get().setNewNodeId(null);
      }
    });
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect:
    (connection) => {
      let type;
      if (connection.source.includes('order') && connection.target.includes('conditionGroup')) {
        type = "conditionGroup";
      }
      else if (connection.source.includes('order') && connection.target.includes('exitStrategy')) {
        type = "exitStrategy";
      }
      else if (connection.source.includes('order') ||
        (connection.source.includes('conditionGroup') && connection.target.includes('condition'))) {
        type = "when";
      }
      else {
        type = "logical"
      }
      const edge = {
        ...connection,
        type,
        markerEnd: { type: MarkerType.ArrowClosed },
        data: {
          type: connection.sourceHandle === "right" ? "enter" : "exit",
          state: type === "when" ? { logicalType: { label: "IF", value: "if" } } : { logicalType: { label: "AND", value: "and" } },
        }
      }
      get().setEdges(addEdge(edge, get().edges));
    },
  setNodes: (nodes) => {
    set({ nodes });
  },
  setEdges: (edges) => {
    set({ edges });
  },
  updateNode(id, data) {
    set({
      nodes: get().nodes.map(node =>
        node.id === id
          ? { ...node, data: { ...node.data, ...data } }
          : node
      )
    });
  },
  updateNodeSelected(id, selected) {
    set({
      nodes: get().nodes.map(node =>
        node.id === id
          ? { ...node, selected }
          : node
      )
    });
  },
  updateEdge(id, data) {
    set({
      edges: get().edges.map(edge =>
        edge.id === id
          ? { ...edge, data: { ...edge.data, ...data } }
          : edge
      )
    });
  },
  onClickDeleteNode: (id) => {
    set((nodes) => nodes.filter((node) => node.id !== id));
  },
  onDragOver: (event) => {
    // event.preventDefault();
    // event.dataTransfer.dropEffect = 'move';
    get().setMenu(null);

  },
  toggleIsOpen: (id, data) => {
    const t = !Boolean(data.isOpen)
    get().updateNode(id, { isOpen: t });
    // let nodes = get().nodes;
    // nodes = nodes.map(node => ({...node, selected: false}))

    // get().updateNodeSelected(id, true)
  },

  addLogicalConditionGroupNode:
    (event, parentId) => {
      event.preventDefault();
      const nodes = get().nodes;
      const edges = get().edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x : 0,
        y: parentNode?.position?.y ? parentNode?.position?.y + parentNode.height + 120 : 0,
      };

      const newID = getId("conditionGroup");
      get().setNewNodeId(newID)

      const newNode = {
        id: newID,
        type: "conditionGroup",
        position,
        data: {
          state: defaultConditionGroupNode,
          isOpen: true,
          logical: true
        },
      };
      set({ nodes: nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode) });

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("logical"),
        type: "logical",
        target: newID,
        source: parentId,
        data: {
          state: { logicalType: { label: "AND", value: "and" } }
        },
        sourceHandle: "bottom"
      };
      set({ edges: edges.concat(newEdge) })
    },

  addConditionalExitStrategyNode:
    (event, parentId) => {
      event.preventDefault();
      const nodes = get().nodes;
      const edges = get().edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x : 0,
        y: parentNode?.position?.y ? (parentNode?.position?.y + 200) : 0,
      };

      const newID = getId("conditionGroup");
      get().setNewNodeId(newID)

      const newNode = {
        id: newID,
        type: "conditionGroup",
        position,
        data: {
          state: defaultConditionGroupNode,
          isOpen: true,
        },
      };
      set({ nodes: nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode) });

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("conditionGroup"),
        type: "conditionGroup",
        data: { type: "exit" },
        target: newID,
        source: parentId,
        sourceHandle: "bottom",
        targetHandle: "top",
      };
      set({ edges: edges.concat(newEdge) })
    },

  addConditionGroupNode:
    (event, parentId) => {
      event.preventDefault();
      const nodes = get().nodes;
      const edges = get().edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x + parentNode.width + 120 : 0,
        y: parentNode?.position?.y ? parentNode?.position?.y : 0,
      };

      const newID = getId("conditionGroup");
      get().setNewNodeId(newID)

      const newNode = {
        id: newID,
        type: "conditionGroup",
        position,
        data: {
          state: defaultConditionGroupNode,
          isOpen: true,
        },
      };
      set({ nodes: nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode) });

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("conditionGroup"),
        type: "conditionGroup",
        data: { type: "enter" },
        target: newID,
        source: parentId,
        sourceHandle: "right",
        targetHandle: "left",
      };
      set({ edges: edges.concat(newEdge) })
    },

  addExitStrategyNode:
    (event, parentId) => {
      event.preventDefault();
      const nodes = get().nodes;
      const edges = get().edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x - 30 : 0,
        y: parentNode?.position?.y ? (parentNode?.position?.y + 200) : 0,
      };

      const newID = getId("exitStrategy");
      get().setNewNodeId(newID)

      const initialExitStrategyNode = {
        takeProfit: defaultExitStrategy,
        stopLoss: defaultExitStrategy,
      }

      const newNode = {
        id: newID,
        type: "exitStrategy",
        position,
        data: {
          state: initialExitStrategyNode,
          isOpen: true,
        },
      };
      set({ nodes: nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode) });

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId("exitStrategy"),
        type: "exitStrategy",
        target: newID,
        source: parentId,
        sourceHandle: "bottom",
      };
      set({ edges: edges.concat(newEdge) })
    },

  // addOrderNode: 
  //   (event) => {
  //     event.preventDefault();

  //     const nodes = get().nodes;

  //     const orderNodes = nodes.filter(node => node.type === "order")
  //     const last = orderNodes[orderNodes.length - 1];

  //     const position = 
  //     // get().reactFlowInstance?.screenToFlowPosition(
  //       {
  //       x: last?.position?.x ? last?.position?.x : 1000,
  //       y: (orderNodes.length + 1)*400,
  //     }
  //     // );

  //     const newID = getId("order");
  //     get().setNewNodeId(newID)

  //     const newNode = {
  //       id: newID,
  //       type: "order",
  //       position,
  //       data: {
  //         state: defaultOrderNode, 
  //         isOpen: true,
  //       },
  //     };
  //     set({nodes: nodes.map(n => ({...n, data: {...n.data, isOpen: false}, selected: false})).concat(newNode)});
  //   },

  addConditionNode:
    (event, parentId, type = "when") => {
      event.preventDefault();
      const nodes = get().nodes;
      const edges = get().edges;

      const [parentNode] = nodes.filter((node) => node.id === parentId)

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = {
        x: parentNode?.position?.x ? parentNode?.position?.x + parentNode.width + 120 : 0,
        y: parentNode?.position?.y ? type === "when" ? parentNode?.position?.y : parentNode?.position?.y : 0,
      };

      const newID = getId("condition");
      get().setNewNodeId(newID)

      const newNode = {
        id: newID,
        type: "condition",
        position,
        data: {
          state: defaultConditionNode,
          isOpen: true,
        },
      };
      set({ nodes: nodes.map(n => ({ ...n, data: { ...n.data, isOpen: false }, selected: false })).concat(newNode) });

      const newEdge = {
        markerEnd: { type: MarkerType.ArrowClosed },
        id: getEdgeId(type),
        type: type,
        target: newID,
        source: parentId,
        sourceHandle: "right",
        data: {
          state: type === "when" ? { logicalType: { label: "IF", value: "if" } } : { logicalType: { label: "AND", value: "and" } }
        }
      };
      set({ edges: edges.concat(newEdge) })
    },

  onNodeContextMenu:
    (event, node, ref) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = ref.current.getBoundingClientRect();
      get().setMenu({
        ...node,
        top: event.clientY < pane.height - 100 && event.clientY - 63,
        left: event.clientX < pane.width - 100 && event.clientX,
        right: event.clientX >= pane.width - 100 && pane.width - event.clientX,
        bottom: event.clientY >= pane.height - 100 && pane.height - event.clientY,
      });
    },

  // Close the context menu if it's open whenever the window is clicked.
  onPaneClick: (e) => {
    // e.preventDefault();
    get().setMenu(null)
  },

  onNodeClick: (e) => {
    e.preventDefault();
    get().setMenu(null)
  },


  onMoveStart: (e) => {
    e.preventDefault();
    get().setMenu(null)
  },

  duplicateNode: (id) => {
    const nodes = get().nodes;
    const [node] = nodes.filter(n => n.id === id);
    const clone = { ...node }
    const position = {
      x: node.position.x + 100,
      y: node.position.y + 100,
    };

    get().setNodes([...nodes, { ...clone, id: `${node.id}-${nodes.length + 1}-copy`, position, selected: true }]);
  },

  deleteNode: (id) => {
    get().setNodes(get().nodes.filter((node) => node.id !== id));
    get().setEdges(get().edges.filter((edge) => edge.source !== id));
  },
  onClickGetImage: async () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const imageWidth = 900;
    const imageHeight = 500;

    const nodesBounds = getNodesBounds(get().nodes);
    const transform = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    await sleep(500)
    const el = document.querySelector('.react-flow__viewport');
    // const el = get().ref.current;
    const image = await toBlob(el, {
      filter: filter,
      backgroundColor: 'transparent',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
      },
    });
    get().setFlowImage(image);
    return image;
  },
}));

export default useStore;
