import React, { useEffect, useState } from "react";
import Autocomplete from "../../../../components/autocomplete/Autocomplete";
import { Icon, RSelect } from "../../../../components/Component";
import CandlesDelta from "./CandlesDelta";

const AssetPrice = ({ metadata, setMetadata }) => {
  const { assetSymbol, priceType } = metadata;
  const asset = assetSymbol || "AAPL";
  return (
    <>
      <CandlesDelta metadata={metadata} setMetadata={setMetadata} />
      <div className="form-group" style={{
        width: '100%',
      }}>
        <label className="form-label">Asset</label>
        <Autocomplete defaultValue={asset} onClickOption={(e, option) => {
          setMetadata({ ...metadata, assetSymbol: option })
        }} />
      </div>
      <div className="form-group" style={{
        width: '100%',
      }}>
        <label className="form-label">Price type</label>
        <RSelect value={priceType} onChange={(e) => {
          setMetadata({ ...metadata, priceType: e })
        }} className="nodrag" options={[
          { value: "close", label: "close" },
          { value: "open", label: "open" },
          { value: "high", label: "high" },
          { value: "low", label: "low" },
          { value: "change", label: "change" },
          { value: "changePct", label: "change %" },
        ]} />
      </div>
    </>
  );
};
export default AssetPrice;