import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Icon,
  PreviewCard
} from "../../components/Component";
import Head from "../../layout/head/Head";
import Logo from "../../layout/logo/Logo";

import { mdiGoogle } from '@mdi/js';
import { Icon as MdiIcon } from '@mdi/react';
import useAuth from "../../hooks/useAuth";

const Login = () => {
  const [errorVal, setError] = useState("");
  const navigate = useNavigate();
  const { firebaseGoogleSignIn } = useAuth();


  return <>
    <Head title="Login" />
    <Block className="nk-block-middle nk-auth-body  wide-xs">
      <div className="brand-logo pb-4 text-center">
        <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
          <Logo />
        </Link>
      </div>

      <PreviewCard className="card-bordered text-center" bodyClass="card-inner-lg">
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h4">Sign-In with google</BlockTitle>
          </BlockContent>
        </BlockHead>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              <Icon name="alert-circle" /> Unable to login with credentials{" "}
            </Alert>
          </div>
        )}
        <Button
          className={"primary"}
          onClick={async e => {
            e.preventDefault();
            await firebaseGoogleSignIn();
            const query = new URLSearchParams(window.location.search);
            const redirect = query.get('redirect');
            if (redirect) {
              navigate(redirect)
            }
            else {
              navigate('/home')
            }
          }}
        >
          <MdiIcon path={mdiGoogle} size={1} />
        </Button>
      </PreviewCard>
      <p className="text-center mt-1">
        traid © {new Date().getFullYear()} | crafted with <Icon name="heart-fill" style={{ color: "red" }} />
      </p>
    </Block>
    {/* <AuthFooter /> */}
  </>;
};
export default Login;
