import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { GET_USER_LIMIT_COUNTS } from "../api/graphql";
import { AppContext } from "../contexts/AppContext";
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, Icon } from "./Component";
import useAuth from "../hooks/useAuth";
import useSubscriptionLimit from "../hooks/useSubscriptionLimit";


const SubscriptionLimit = ({ customTitle, hideReason, type, setModal, filter, hideAddButton = false, children }) => {
  const { user } = useAuth();

  const { data, loading, errors } = useSubscriptionLimit({ type, filter });

  const [activePlan] = user?.subscriptions?.data?.filter(i => i.status === "active") || []
  let strategiesLimit = activePlan?.plan?.product?.metadata?.maxStrategies || 3;
  let backtestsLimit = activePlan?.plan?.product?.metadata?.maxBacktests || 3;
  let watchlistsLimit = activePlan?.plan?.product?.metadata?.maxWatchlists || 3;
  let reachedLimitTxt;
  let title;
  let limit;
  let desc;
  let btnTxt;

  if (loading) return <Spinner type="grow" size="sm" />;
  const current = data?.[`${type}Count`];

  switch (type) {
    case "strategy":
      title = customTitle ? customTitle : "Strategies";
      reachedLimitTxt = `You have ${current} ${title.toLowerCase()}.`
      desc = `No ${title.toLowerCase()}`;
      btnTxt = `Add ${type}`
      limit = strategiesLimit === "Unlimited" ? 1000 : parseInt(strategiesLimit);
      break;
    case "bot":
      title = customTitle ? customTitle : "Bots";
      reachedLimitTxt = `You have ${current} ${type.toLowerCase()}s.`
      desc = `No ${title.toLowerCase()}`;
      btnTxt = `Create ${type}`
      limit = strategiesLimit === "Unlimited" ? 1000 : parseInt(strategiesLimit);
      break;
    case "portfolioStrategy":
      title = customTitle ? customTitle : "Deployed strategies";
      reachedLimitTxt = `You have ${current} active strategies.`
      desc = "No active strategies"
      btnTxt = `Deploy strategy`
      limit = strategiesLimit === "Unlimited" ? 1000 : parseInt(strategiesLimit);
      break;
    case "portfolio":
      title = customTitle ? customTitle : "Your portfolios";
      reachedLimitTxt = `You have ${current} active portfolios.`
      desc = "No portfolios"
      btnTxt = `Add portfolio`
      limit = strategiesLimit === "Unlimited" ? 1000 : parseInt(strategiesLimit);
      break;
    case "backtest":
      title = customTitle ? customTitle : "Backtests";
      reachedLimitTxt = `You have ${current} ${title.toLowerCase()}.`
      desc = `No ${title.toLowerCase()}`;
      btnTxt = `Add ${type}`
      limit = backtestsLimit === "Unlimited" ? 1000 : parseInt(backtestsLimit);
      break;
    case "watchlist":
      title = customTitle ? customTitle : "Watchlists";
      reachedLimitTxt = `You have ${current} ${title.toLowerCase()}.`
      desc = `No ${title.toLowerCase()}`;
      btnTxt = `Add ${type}`
      limit = watchlistsLimit === "Unlimited" ? 1000 : parseInt(watchlistsLimit);
      break;

    default:
      console.log("Subcription type error " + type);
      break;

  }
  return (
    <BlockHead size="sm">
      <BlockBetween>
        <BlockHeadContent>
          <h5>
            {title}
          </h5>
          <BlockDes className="text-soft" style={{
            flexWrap: 'wrap',
          }}>
            {current === 0 ? <p>{desc}</p> : current >= limit ? (
              <div className="nk-fmg-status-action" style={{
                display: 'flex',
                alignItems: 'center',
              }}>

                <Link to={`${process.env.PUBLIC_URL}/plans`} className="">
                  You have reached your {`${type.toLowerCase()}s`} limit. upgrade now!<Icon name="info-fill" className="text-warning" style={{ marginLeft: 4 }} />
                </Link>
              </div>
            ) : (
              reachedLimitTxt
            )}
          </BlockDes>
        </BlockHeadContent>
        {!hideAddButton ? <BlockHeadContent>
          <ul style={{
            display: 'flex',
            gap: 8,
            alignItems: 'center',
          }} >
            {children}
            {current < limit && <li className="nk-block-tools-opt" onClick={setModal}>
              <Button color="primary">
                <Icon name="plus"></Icon>
                <span className="d-none d-sm-block">{btnTxt}</span>
              </Button>
            </li>}
          </ul>
        </BlockHeadContent> : hideReason}
      </BlockBetween>
    </BlockHead>
  )
}

export default SubscriptionLimit