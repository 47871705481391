// action - state management
import { REGISTER, LOGIN, LOGOUT } from './actions';

// initial state
export const initialState = {
  isLoggedIn: false,
  user: null,
  messageFeed: {}
};

// ==============================|| AUTH REDUCER ||============================== //

export const app = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_MESSAGE_FEED": {
      return {
        ...state,
        messageFeed: {}
      };

    }
    case "NEW_MESSAGE": {
      const { message, chatId } = action.payload;
      const { messageFeed } = state;
      if (chatId in messageFeed) {
        messageFeed[chatId].push(message);
      }
      else {
        messageFeed[chatId] = [message]
      }
      return {
        ...state,
        messageFeed
      };

    }
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};