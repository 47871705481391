import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, Spinner
} from "reactstrap";

//i18n
import { useLocation } from "react-router-dom";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { historicalChart, getCompanyInformation, getCompanyProfile, getFullQuotes, getCompanyRatios, timeSales, historicalEarningCalendar, getCompanyOutlook, getCompanyFilings, getStockPeers, getDCFCalculations } from "../../api";
import StockChart from "../../components/stock-chart/StockChart";
import { Block, Icon, PreviewAltCard } from "../../components/Component";
import FilesView from "../../components/partials/analytics/files-view/FilesView";
import PageViewer from "../../components/partials/analytics/page-view/PageView";
import FileListCompact from "../pre-built/user-manage/FileListCompact";
import CompanyInfo from "../../components/company-info-card/CompanyInfo";
import { useQuery } from "@apollo/client";
import { GET_FEED_BY_SYMBOL, GET_REDDIT_BY_SYMBOL } from "../../api/graphql";
import News from "../../components/news/News";
import Reddit from "../../components/reddit/Reddit";
import EarningsCall from "../../components/EarningsCall";
import CompanyRating from "./CompanyRating";
import moment from "moment";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import DCFCalculations from "./DCFCalculations";
// import WalletStats from "pages/Crypto/CryptoWallet/walletStats";
// import StockChart from "components/StockChart";
// import Overview from "pages/Crypto/CryptoWallet/overview";
// import News from "pages/Crypto/CryptoWallet/news";

const parseStockDataTradier = ({ data }) => {
    const ohlcData = [],
        volumeData = [];

    data.map(c => {
        ohlcData.push([
            c['timestamp'] * 1000,
            parseFloat(c['open']),
            parseFloat(c['high']),
            parseFloat(c['low']),
            parseFloat(c['close']),
        ]);
        volumeData.push([
            c['timestamp'] * 1000,
            parseFloat(c['volume']),
        ])
    });

    return {
        volumeData,
        ohlcData
    }
}

export const parseStockDataMarketStack = ({ data }) => {
    if (!data) return [];
    const ohlcData = [],
        volumeData = [];

    data.reverse().map(c => {
        ohlcData.push([
            c['date'],
            parseFloat(c['open']),
            parseFloat(c['high']),
            parseFloat(c['low']),
            parseFloat(c['close']),
        ]);
        volumeData.push([
            c['date'],
            parseFloat(c['volume']),
        ])
    });
    return {
        volumeData,
        ohlcData
    }
}

const OnePager = props => {
    const { pathname } = useLocation();
    const symbol = props?.symbol ? props?.symbol : pathname.split('/')[2].toUpperCase();



    const [quote, setQuote] = useState(null);
    const [stockData, setStockData] = useState(null);
    const [dcfCalculations, setDCFCalculations] = useState(null);
    const [stockPeers, setStockPeers] = useState(null);
    const [ohlc, setOhlc] = useState(null);

    const [companyOutlook, setCompanyOutlook] = useState(null);
    const [companyFilings, setCompanyFilings] = useState(null);
    const [calendar, setCompanyCalendar] = useState(null);
    const [volume, setVolume] = useState(null);

    const [isLoadingQuote, setIsLoadingQuote] = useState(true);
    const [isLoadingStockData, setIsLoadingStockData] = useState(false);
    const [isLoadingDCFCalculations, setIsLoadingDCFCalculations] = useState(false);
    const [isLoadingStockPeers, setIsLoadingStockPeers] = useState(false);
    const [isLoadingCompanyRatios, setIsLoadingCompanyRatios] = useState(false);
    const [isLoadingCompanyCalendar, setIsLoadingCompanyCalendar] = useState(false);

    const [isLoadingCompanyOutlook, setIsLoadingCompanyOutlook] = useState(false);
    const [isLoadingCompanyFilings, setIsLoadingCompanyFilings] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);

    const { data: feedData, refetch, fetchMore, loading: loadingFeedData, error } = useQuery(GET_FEED_BY_SYMBOL, {
        variables: {
            filter: {
                OR: [

                    { "_operators": { "cs_tickers": { "regex": symbol }, } },
                    { "_operators": { "nasdaq_tickers": { "regex": symbol } } }
                ]
            },
            sort: "PUB_DATE_ASC"
        }
    });

    const { data: redditSubmissionData, loading: loadingRedditSubmissions, error: errorRedditSubmissions } = useQuery(GET_REDDIT_BY_SYMBOL, {
        variables: {
            filter: {
                "tickers": symbol
            },
            sort: "CREATED_UTC_ASC"
        }
    });

    const loadCompanyCalendar = async () => {
        setIsLoadingCompanyCalendar(true)
        //fmp
        historicalEarningCalendar({ symbol: symbol },
            (res) => {
                setCompanyCalendar(res);
                setIsLoadingCompanyCalendar(false)
            }, (err) => {
                console.error(err)
                setIsLoadingCompanyCalendar(false)
            })
    }

    //tradier
    const loadCompanyFilings = async () => {
        setIsLoadingCompanyFilings(true)
        getCompanyFilings({ symbol: symbol, type: '10-K' },
            (res) => {
                setCompanyFilings(res);
                setIsLoadingCompanyFilings(false)
            }, (err) => {
                console.error(err)
                setIsLoadingCompanyFilings(false)
            })
    }

    const loadCompanyOutlook = async () => {
        setIsLoadingCompanyOutlook(true)
        getCompanyOutlook({ symbol: symbol },
            (res) => {
                setCompanyOutlook(res);
                setIsLoadingCompanyOutlook(false)
            }, (err) => {
                console.error(err)
                setIsLoadingCompanyOutlook(false)
            })
    }

    const loadQuotes = async () => {
        setIsLoadingQuote(true)
        //tradier
        getFullQuotes({ symbol: symbol, greeks: true },
            (res) => {
                setQuote(res);
                setIsLoadingQuote(false)
            }, (err) => {
                console.error(err)
                setIsLoadingQuote(false)
            })
    }


    const loadStockPeers = async () => {
        setIsLoadingStockPeers(true);
        //fmp
        getStockPeers({ symbol },
            (res) => {
                console.log(res)
                setStockPeers(res)
                setIsLoadingStockPeers(false)
            }, (err) => {
                console.error(err)
                setIsLoadingStockPeers(false)
            })
    }

    const loadStockData = async () => {
        setIsLoadingStockData(true);
        //fmp
        historicalChart({ symbol, from: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD').toString(), to: moment().endOf('day').format('YYYY-MM-DD').toString() },
            (res) => {
                setStockData(res)
                const { ohlcData, volumeData } = parseStockDataMarketStack({ data: res });
                setOhlc(ohlcData);
                setVolume(volumeData);
                setIsLoadingStockData(false)
            }, (err) => {
                console.error(err)
                setIsLoadingStockData(false)
            })
    }

    const loadDCFData = async () => {
        setIsLoadingDCFCalculations(true);
        getDCFCalculations({ symbol },
            (res) => {
                setDCFCalculations(res)
                setIsLoadingDCFCalculations(false)
            }, (err) => {
                console.error(err)
                setIsLoadingDCFCalculations(false)
            })
    }

    useEffect(() => {

        if (symbol !== null) {
            loadCompanyOutlook();
            loadCompanyFilings();
            loadCompanyCalendar();
            loadStockData();
            loadStockPeers();
            loadQuotes();
            loadDCFData();
        }
    }, [symbol]);

    if (!companyOutlook) {
        return <SpinnerWrapper />
    }

    const companyProfile = companyOutlook?.profile;
    const companyRatios = companyOutlook?.ratios;

    // dcfCalculations.advancedDcf.map(i => {
    //     const { enterpriseValue, totalCash, totalDebt, dilutedSharesOutstanding } = i;
    //     console.log((enterpriseValue + totalCash - totalDebt) / dilutedSharesOutstanding)
    // })
    return (
        <>
            <Head title="One pager" />
            <Content>
                <Block>
                    <Row className="g-gs">
                        <Col lg="6">
                            <Row className="g-gs">
                                <Col md="6" lg="12">
                                    <PreviewAltCard className="h-100">
                                        {(isLoadingStockData || isLoadingQuote) ? <Spinner /> : <StockChart stockPeers={stockPeers} profile={companyProfile} companyRatiosData={companyRatios} quotesData={quote?.[0]} currency={"USD"} symbol={symbol} ohlc={ohlc} volume={volume} stockData={stockData} />}
                                    </PreviewAltCard>
                                </Col>
                                <Col md="6" lg="12">
                                    <PreviewAltCard className="card-full">
                                        {<CompanyInfo loading={isLoadingCompanyOutlook} profile={companyProfile} />}
                                    </PreviewAltCard>
                                </Col>
                                {!companyProfile?.[0]?.isEtf && !companyProfile?.[0]?.isFund && <Col md="6" lg="12">
                                    <PreviewAltCard className="card-full">
                                        <CompanyRating companyRating={companyOutlook?.rating} />
                                    </PreviewAltCard>
                                </Col>}
                                {!companyProfile?.[0]?.isEtf && !companyProfile?.[0]?.isFund && <Col md="6" lg="12">
                                    <PreviewAltCard className="h-100">
                                        {isLoadingCompanyFilings ? <Spinner /> : <FileListCompact companyFilings={companyFilings} setShowModal={setShowModal} setModalData={setModalData} />}
                                    </PreviewAltCard>
                                </Col>}
                                {!companyProfile?.[0]?.isEtf && !companyProfile?.[0]?.isFund && <Col md="6" lg="12">
                                    <PreviewAltCard className="h-100">
                                        {isLoadingCompanyCalendar ? <Spinner /> : calendar && <EarningsCall data={calendar} />}
                                    </PreviewAltCard>
                                </Col>}
                            </Row>
                        </Col>
                        <Col lg="6">
                            <Row className="g-gs">
                                {dcfCalculations && <Col md="6" lg="12"><PreviewAltCard className="h-100">
                                    {isLoadingDCFCalculations ? <Spinner /> : <DCFCalculations dcfData={dcfCalculations} />}
                                </PreviewAltCard></Col>}
                                {feedData?.feedPostMany?.length > 0 && <Col md="6" lg="12">
                                    <PreviewAltCard className="card-borded card-full">
                                        {(loadingFeedData) ? <Spinner /> : <News news={feedData?.feedPostMany} />}
                                    </PreviewAltCard>
                                </Col>}
                                {redditSubmissionData?.redditSubmissionMany?.length > 0 && <Col md="6" lg="12">
                                    <PreviewAltCard className="card-borded card-full">
                                        {(loadingRedditSubmissions) ? <Spinner /> : <Reddit data={redditSubmissionData?.redditSubmissionMany} />}
                                    </PreviewAltCard>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </Block>
            </Content>
            <Modal className="modal-dialog-centered" size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)} >
                <ModalHeader toggle={() => setShowModal(!showModal)} close={
                    <button className="close" onClick={() => setShowModal(false)}>
                        <Icon name="cross" />
                    </button>
                }>
                    {`${modalData?.file?.symbol} | ${modalData?.file?.type}`}
                </ModalHeader>
                <ModalBody>
                    <Row className="gy-3 py-1">
                        <Col lg="12">
                            <PreviewAltCard className="h-100">
                                <div dangerouslySetInnerHTML={{ __html: modalData?.html }} />
                            </PreviewAltCard>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};

export default OnePager;
