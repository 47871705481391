
import { CardBody, CardTitle, Col, Row, Spinner } from "reactstrap";
import { Icon, PreviewCard } from "../../../components/Component";
import strings from "../../../utils/Strings";
import ReactDatePicker from "react-datepicker";
import { addCommas, removeNonNumeric } from "../../../utils/Utils";
import Play from "../../../landingApp/components/svg/Play";
import { StyledIcon } from "../../../landingApp/components/icon/Icon";
import BacktestChart from "./BacktestChart";
import FlowDescription from "./FlowDescription";
import SimulatedReturns from "./SimulatedReturns";
import BacktestPerformance from "./BacktestPerformance";
import BacktestOrders from "./BacktestOrders";
import PortfolioStatistics from "../../portfolios/PortfolioStatistics";
import { useMyStore } from "./ReactFlowBuilder/store";

const BacktestPreview = ({ botData, mode, editMode, onClickRunBacktest, lastFull, }) => {

    const { isRunningBacktest } = useMyStore();
    return (
        <PreviewCard id="bot-backtest-preview" className="h-100">
            <CardTitle>

                <h6 style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
                >
                    Backtest preview
                    {
                        lastFull && editMode && (
                            <span
                                onClick={({ event }) => onClickRunBacktest({ event })}
                                style={{
                                    display: "inline"
                                }}>
                                {(isRunningBacktest || lastFull?.status === "running") ? (
                                    <Spinner type="grow" size="sm" />
                                ) : (
                                    <Icon title="Rerun" name="repeat" className={"text-warning"} />
                                )}
                            </span>
                        )
                    }
                </h6>
            </CardTitle>

            {
                (!lastFull) && (
                    <div style={{
                        minHeight: 200,
                        width: '100%',
                        // height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: "center",
                    }}
                    >
                        {isRunningBacktest ? <Spinner size="sm" type="grow" /> : <StyledIcon onClick={({ event }) => onClickRunBacktest({ event })} className="styled-icon styled-icon-4x styled-icon-s5 text-warning">
                            <Play
                                style={{
                                    cursor: 'pointer',
                                }} />
                        </StyledIcon>}
                    </div>
                )
            }

            {(lastFull?.status === "done") && (
                <>
                    {/* <BacktestBalanceChart
                            data={backtestRes} /> */}
                    <BacktestChart
                        backtestMode={mode}
                        botData={botData}
                        backtestData={lastFull} />
                </>
            )}
        </PreviewCard >
    );
}

export default BacktestPreview;