import React from "react";
import { CardTitle } from "reactstrap";
import { Icon } from "./Component";
import strings from "../utils/Strings";
import moment from "moment";

import { Line, Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarController, BarElement, ArcElement, Tooltip, Filler, Legend, } from "chart.js";
Chart.register(CategoryScale, LinearScale, BarController, BarElement, ArcElement, Tooltip, Filler, Legend );

const EarningsCall = ({ data }) => {
  const getUpcomingCall = () => {
    const today = new Date();
    for (let i = 0; i < data?.length; i++) {
      if (today >= new Date(data[i].date)) {
        return i-1;
      }
    }
  }
  const upcomingIndex = getUpcomingCall();
  const upcoming = data[upcomingIndex];
  const last = data[upcomingIndex + 1];
  let r = [...data]
  r = r.splice(upcomingIndex, 10);
  r.reverse();
  const chartData = {
    labels: r.map(i => i.date),
    dataUnit: "People",
    datasets: [
      {
        label: "EPS",
        type: "bar",
        lineTension: 0,
        borderWidth: 2,
        fill: true,
        color: "#798bff",
        backgroundColor: "rgba(121, 139, 255, 0.25)",
        borderColor: "#798bff",
        borderCapStyle: "square",
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#9d72ff",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        yAxisID: "y1",
        data: r.map(i => i.eps),
      },
      {
        label: "EPS est.",
        borderWidth: 2,
        fill: false,
        borderColor: "#798bff",
        backgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#9d72ff",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        yAxisID: "y1",
        data: r.map(i => i.epsEstimated),
      },
      {
        label: "Revenue",
        color: "#9d72ff",
        type: "bar",
        borderWidth: 2,
        fill: true,
        lineTension: 0,
        dash: 0,
        borderColor: "#9d72ff",
        backgroundColor: "rgba(157, 114, 255, 0.15)",
        borderCapStyle: "square",
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#9d72ff",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        yAxisID: "y",
        data: r.map(i => i.revenue),
      },
      {
        label: "Revenue est.",
        borderWidth: 2,
        fill: false,
        borderColor: "#9d72ff",
        backgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#9d72ff",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        yAxisID: "y",
        data: r.map(i => i.revenueEstimated),
      },
    ],
  };

  const epsChange = ((upcoming?.epsEstimated - last?.epsEstimated) / last?.epsEstimated) * 100;
  const revenueChange = ((upcoming?.revenueEstimated - last?.revenueEstimated) / last?.revenueEstimated) * 100;
  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Earning calls</h6>
        </CardTitle>
        {/* <div className="card-tools">
          <TooltipComponent
            icon="help-fill"
            iconClass="card-hint"
            direction="left"
            id="tooltip-1"
            text="Company earning reports"
          />
        </div> */}
      <div style={{
          // width: '100%',
          display: 'flex',
          justifyContent: 'center',
          float: "right",
          gap: 8
        }}>
          <span style={{
              border: "1px solid #9d72ff",
              backgroundColor: "rgba(157, 114, 255, 0.15)",
              borderRadius: "25%",
              padding: 4,
              fontSize: 10,
          }} 
          >Revenue</span>
          <span style={{
              border: "1px solid #798bff",
              backgroundColor: "rgba(121, 139, 255, 0.25)",
              borderRadius: "25%",
              padding: 4,
              fontSize: 10
          }} 
          >EPS</span>
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sale-data-group flex-md-nowrap g-4">
          <div className="nk-sale-data">
          <span className="sub-title">Upcoming</span>
            <h6>{moment(upcoming?.date).format("MM/DD/YYYY")}</h6>
            <span>Compared to last call</span>
          </div>
          <div className="nk-sale-data">
          <span className="sub-title">Est. EPS</span>
            <h6>
              {upcoming?.epsEstimated}
            </h6>
            <span className={epsChange > 0 ? "change up text-sucess" : "change down text-danger"}>
                <Icon name={epsChange > 0 ? "arrow-long-up" : "arrow-long-down"} />
                {strings.percentSignFormatter(epsChange)}
              </span>
          </div>
          <div className="nk-sale-data">
          <span className="sub-title">Est. Revenue</span>
            <h6>
              {strings.price(upcoming?.revenueEstimated)}
            </h6>
            <span className={revenueChange > 0 ? "change up text-sucess" : "change down text-danger"}>
                <Icon name={revenueChange > 0 ? "arrow-long-up" : "arrow-long-down"} />
                {strings.percentSignFormatter(revenueChange)}
              </span>
          </div>
        </div>
      </div>
      <div className="nk-sales-ck large pt-4">
        {/* <Bar /> */}
        <Line
          data={chartData}
          options={{
            interaction: {
              mode: 'index',
              intersect: false,
            },
            plugins: {
              legend: {
                display: false,
              },
              // tooltip: {
              //   enabled: true,
              //   displayColors: false,
              //   backgroundColor: "#eff6ff",
              //   titleFont: {
              //     size: '13px',
              //   },
              //   titleColor: "#6783b8",
              //   titleMarginBottom: 6,
              //   bodyColor: "#9eaecf",
              //   bodyFont: {
              //     size: '12px',
              //   },
              //   bodySpacing: 4,
              //   padding: 10,
              //   footerMarginTop: 0,
              // },
            },
            maintainAspectRatio: false,
            scales: {
              y: {
                display: false,
                ticks: {
                  beginAtZero: true,
                  color: "#9eaecf",
                  font: {
                    size: '11px',
                  },
                  padding: 8,
                  // stepSize: 2400,
                },
                grid: {
                  color: "rgba(82, 100, 132, 0.2)",
                  tickMarkLength: 0,
                  zeroLineColor: "rgba(82, 100, 132,0.2)",
                },
              },
              y1: {
                display: false,
                position: 'right',
              },
              x: {
                display: false,
                ticks: {
                  color: "#9eaecf",
                  font: {
                    size: '12px',
                  },
                  source: "auto",
                  padding: 0,
                },
                grid: {
                  color: "transparent",
                  tickMarkLength: 0,
                  zeroLineColor: "transparent",
                  offsetGridLines: true,
                },
              },
            },
          }}
        >

        </Line>

      </div>
    </React.Fragment>
  );
};
export default EarningsCall;
