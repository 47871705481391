import { useEffect, useRef, useState } from 'react';
import Head from '../../../layout/head/Head';
import { ReactFlowProvider } from 'reactflow';
import RuleBuilder from '../../strategies/StrategyPolicyBuilder/RuleBuilder';
import Content from '../../../layout/content/Content';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, InputSwitch, PreviewCard } from '../../../components/Component';
import { Badge, Button, Card, CardBody, CardTitle, Col, Input, Row } from 'reactstrap';
import Header from './Header';
import Sidebar from './Sidebar';
import { timeParse } from "d3-time-format";
import { CatWrapper } from './ReactFlowBuilder/CatWrapper';
import ResearchPanelCard from './ResearchPanelCard';
import { TourProvider, useTour } from '@reactour/tour'
import BacktestCard from './BacktestCard';
import './index.css'
import { Link } from 'react-router-dom';
import { useMyStore } from './ReactFlowBuilder/store';
import { parseFlowSteps } from '../../../api';
import Swal from 'sweetalert2';
import FlowDescription from './FlowDescription';
import CustomColorPicker from '../../../components/CustomColorPicker';
import useAuth from '../../../hooks/useAuth';

function scrollToTargetAdjusted(id) {
    var element = document.getElementById(id);
    var headerOffset = 130;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.scrollY - headerOffset;

    return offsetPosition;

}

const TradingBot = ({ botData }) => {
    const [isTourOpen, setIsTourOpen] = useState(false)
    const { symbols, addOrderNode, botName, botColor, setBotColor, state, setFlowDescription, flowDescription, changes, saveChange } = useMyStore();
    const { setIsOpen } = useTour();

    const { user } = useAuth();

    const editMode = botData?.userUID === user?.uid;

    const openTourWelcomeMessage = () => {
        Swal.fire({
            icon: "question",
            title: "Hey ninja",
            html: `
                <h6>Welcome to traid bot builder 👋🏻</h6>
                <label>This is a step by step guide to help you understand how to build a traiding bot with just few clicks!</label>
            `,
            focusConfirm: false,
            confirmButtonText: "Let's go",
        }).then((result) => {
            if (result.isConfirmed) {
                setIsOpen(true);
            }
        });
    };

    useEffect(() => {
        const parse = async () => {
            const allSymbols = state ? Object.keys(state) : [];
            let allNodes = [];
            let allEdges = [];
            allSymbols.map(s => {
                allNodes = allNodes.concat(state[s].nodes);
                allEdges = allEdges.concat(state[s].edges);
            })

            await parseFlowSteps({
                nodes: allNodes,
                edges: allEdges,
            }, (res) => {
                setFlowDescription({ flowDescription: res.desc })
            }, error => {
                console.error(error);
            });
        }
        changes && parse();
    }, [
        changes,
        saveChange,
        // getMergedFlow()?.nodes, getMergedFlow()?.edges
    ]);

    useEffect(() => {
        symbols.map(s => {
            if (!state[s] || !state[s].nodes || state[s].nodes.length === 0) {
                addOrderNode({
                    event: null,
                    symbol: s
                })
            }
        })
    }, [state, addOrderNode, symbols]);

    return (
        <>
            <Head title="Traid bot"></Head>
            <BlockHead>
                <Row className='g-2'>
                    <Col lg={12}>
                        <div style={{
                            position: "fixed",
                            top: 65,
                            zIndex: 9,
                            width: "-webkit-fill-available",
                            // paddingRight: 32,
                            // width: "100%",
                        }}>
                            <Card id="bot-header">
                                <CardBody>
                                    <div style={{
                                        position: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: '-webkit-box',
                                    }}></div>
                                    {/* <BlockHeadSub>Account Wallet</BlockHeadSub> */}
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <BlockHeadContent>
                                            <BlockTitle>
                                                <h5 style={{
                                                    maxWidth: '300px',
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 1,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                    {editMode && <CustomColorPicker style={{ marginRight: 8 }} color={botColor} setColor={setBotColor} />}
                                                    {botName}
                                                </h5>
                                            </BlockTitle>
                                        </BlockHeadContent>
                                        {editMode && (
                                            <><BlockHeadContent className="d-lg-block d-none">
                                                <div style={{
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    gap: 8,
                                                }}>
                                                    <Badge style={{
                                                        cursor: "pointer",
                                                    }} onClick={(e) => {
                                                        e.preventDefault();
                                                        // const elm = document.getElementById('flow-builder-ref');
                                                        const offset = scrollToTargetAdjusted('flow-builder-ref')
                                                        window.scrollTo({
                                                            behavior: 'smooth',
                                                            top: offset
                                                        });
                                                    }}>Flow builder
                                                        <Icon style={{
                                                            marginLeft: 4
                                                        }} name="arrow-right-c" />
                                                    </Badge>
                                                    <Badge style={{
                                                        cursor: "pointer",
                                                    }} onClick={(e) => {
                                                        e.preventDefault();
                                                        // const elm = document.getElementById('backtest-full-ref');
                                                        const offset = scrollToTargetAdjusted('backtest-full-ref')
                                                        window.scrollTo({
                                                            behavior: 'smooth',
                                                            top: offset
                                                        });
                                                    }}>{editMode ? "Backtest panel" : "Bot performance"}
                                                        <Icon style={{
                                                            marginLeft: 4
                                                        }} name="arrow-right-c" />
                                                    </Badge>
                                                </div>
                                            </BlockHeadContent>
                                                <BlockHeadContent>
                                                    <ul className="nk-block-tools gx-3">
                                                        <Header />
                                                        <li>
                                                            <Button
                                                                // className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                                                                onClick={() => openTourWelcomeMessage()}
                                                            >
                                                                <Icon name="help"></Icon>
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </BlockHeadContent>
                                            </>)}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </BlockHead>
            <Content>
                <Block>
                    <div id="home-ref" style={{
                        marginTop: 96
                    }}
                    >
                        {(
                            <div className="h-100">
                                <Row className="g-2">
                                    {editMode && (
                                        <><Col lg={3} style={{}}>
                                            <Sidebar editMode={editMode} botData={botData} />
                                        </Col>

                                            <Col lg={9} style={{}}>
                                                <ResearchPanelCard />
                                            </Col>
                                            <Col lg={3}>
                                                {flowDescription && <FlowDescription editMode={editMode} flowDescription={flowDescription} />}
                                            </Col>
                                            <Col id={"flow-container"} lg={9}>
                                                <div id="flow-builder-ref"></div>
                                                {<CatWrapper />}
                                            </Col>
                                        </>)}
                                    <Col lg={12}>
                                        <div id="backtest-full-ref"></div>
                                        <BacktestCard mode="full" editMode={editMode} botData={botData} />
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                </Block>
            </Content>
        </>
    )
}

export default TradingBot;