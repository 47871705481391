import React, { useState } from 'react'
import classNames from 'classnames';
import { useFileManager, useFileManagerUpdate } from "./Context";
import icons from './Icons';
import { Modal, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";

import CreateFolder from "../modals/CreateFolder";
import Details from "../modals/Details";
import Share from "../modals/Share";
import Copy from "../modals/Copy";
import Move from "../modals/Move";
import useStore from '../../../../store/reactFlow';
import { getFlowImage } from '../../../../api';
import { toArrayBuffer } from '../../../../utils/Utils';

const File = ({ item, fileView, page, onClick }) => {
    const { fileManagerUpdate } = useFileManagerUpdate();

    const [detailModal, setDetailModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [copyModal, setCopyModal] = useState(false);
    const [moveModal, setMoveModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);

    const toggleDetailModal = () => {
        setDetailModal(!detailModal);
    };
    const toggleShareModal = () => {
        setShareModal(!shareModal);
    };
    const toggleCopyModal = () => {
        setCopyModal(!copyModal);
    };
    const toggleMoveModal = () => {
        setMoveModal(!moveModal);
    };
    const toggleCreateModal = () => {
        setCreateModal(!createModal);
    };

    const downloadFile = (file) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:" + file.ext + ";charset=utf-8," + encodeURIComponent(file.name);
        downloadLink.download = file.name;
        downloadLink.click();
    };

    return (
        <>
            <div className="nk-file-item nk-file" onClick={onClick} style={{
                width: 300,
                cursor: "pointer"
            }}>
                <div className="nk-file-info">
                    <div className="nk-file-title">
                        <div className="nk-file-icon">
                            <Icon name={item?.icon} style={{
                                fontSize: 42
                            }} />
                        </div>
                        <div className="nk-file-name">
                            <div className="nk-file-name-text">
                                <h6 className="title">{item.name}</h6>
                                {/* <div className="asterisk">
                                    <a
                                        href="#folder"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            fileManagerUpdate.toggleStarred(item.id);
                                        }}
                                        className={item.starred ? "active" : ""}
                                    >
                                        <Icon className="asterisk-off icon ni ni-star"></Icon>
                                        <Icon className="asterisk-on icon ni ni-star-fill"></Icon>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {(fileView === 'group' || fileView === 'grid') && <ul className="nk-file-desc">
                        <li className="date">{item.description}</li>
                        {/* <li className="size">{item.size} MB</li> */}
                        {/* {(item.access && fileView === 'group') && <li className="members">{item.access.length} Members</li>} */}
                    </ul>}
                </div>
                {fileView === 'list' && <>
                    {(page === undefined) && <div className="nk-file-meta">
                        <div className="tb-lead">{item.description}</div>
                    </div>}
                    {/* {(page === 'recovery') && <div className="nk-file-date">
                        <div className="tb-lead">{item.deleted}</div>
                    </div>} */}
                    {/* <div className="nk-file-members">
                        <div className="tb-lead">{item.access && item.access.length} Members</div>
                    </div> */}
                </>}
                {/* <div className="nk-file-actions">
                    <UncontrolledDropdown>
                        <DropdownToggle tag="a" href="#folder" className="dropdown-toggle btn btn-sm btn-icon btn-trigger"
                            onClick={(ev) => ev.preventDefault()}>
                            <Icon name="more-h"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                                <li>
                                    <DropdownItem tag="a" href="#item" onClick={(ev) => {ev.preventDefault(); setDetailModal(true);}}>
                                        <Icon name="eye"></Icon>
                                        <span>Details</span>
                                    </DropdownItem>
                                </li>
                                <li>
                                    <DropdownItem tag="a" href="#item" onClick={(ev) => {ev.preventDefault(); setShareModal(true);}}>
                                        <Icon name="share"></Icon>
                                        <span>Share</span>
                                    </DropdownItem>
                                </li>
                                <li>
                                    <DropdownItem tag="a" href="#item" onClick={(ev) => {ev.preventDefault(); setCopyModal(true)}}>
                                        <Icon name="copy"></Icon>
                                        <span>Copy</span>
                                    </DropdownItem>
                                </li>
                                <li>
                                    <DropdownItem tag="a" href="#item" onClick={(ev) => {ev.preventDefault(); setMoveModal(true)}}>
                                        <Icon name="forward-arrow"></Icon>
                                        <span>Move</span>
                                    </DropdownItem>
                                </li>
                                <li>
                                    <DropdownItem tag="a" href="#item" onClick={(ev) => {ev.preventDefault(); downloadFile(item)}}>
                                        <Icon name="download"></Icon>
                                        <span>Download</span>
                                    </DropdownItem>
                                </li>
                                <li>
                                    <DropdownItem tag="a" href="#item" onClick={(ev) => {ev.preventDefault(); fileManagerUpdate.toTrash(item.id, !item.deleted)}}>
                                        {item.deleted ? 
                                            <><Icon name="back-arrow"></Icon> <span>Restore</span></> 
                                            : 
                                            <><Icon name="trash"></Icon><span>Delete</span></>
                                        }
                                    </DropdownItem>
                                </li>
                            </ul>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div> */}

                <Modal isOpen={detailModal} size="md" toggle={toggleDetailModal}>
                    <Details file={item} toggle={toggleDetailModal} toggleShare={toggleShareModal} triggerDownload={downloadFile} />
                </Modal>

                <Modal isOpen={shareModal} size="md" toggle={toggleShareModal}>
                    <Share file={item} toggle={toggleShareModal} />
                </Modal>

                <Modal isOpen={copyModal} size="md" toggle={toggleCopyModal}>
                    <Copy file={item} toggle={toggleCopyModal} toggleCreate={toggleCreateModal} />
                </Modal>

                <Modal isOpen={moveModal} size="md" toggle={toggleMoveModal}>
                    <Move file={item} toggle={toggleMoveModal} toggleCreate={toggleCreateModal} />
                </Modal>

                <Modal isOpen={createModal} size="md" toggle={toggleCreateModal}>
                    <CreateFolder toggle={toggleCreateModal} />
                </Modal>
            </div>
        </>
    )
}


const Files = ({ files, fixedView, page }) => {
    const { setFlowImage, setShowTraidhubModal, setNodes, setEdges, setStrategyFormData } = useStore(({ setFlowImage, setShowTraidhubModal, setNodes, setEdges, setShowFlowModal, setStrategyFormData }) => ({ setFlowImage, setShowTraidhubModal, setNodes, setEdges, setShowFlowModal, setStrategyFormData }))
    const { fileManager } = useFileManager();
    const [isLoading, setIsLoading] = useState(false);
    const fileView = fixedView ? fixedView : fileManager.filesView;

    const mainClass = classNames({
        "nk-files": true,
        [`nk-files-view-${fileView}`]: fileView
    });

    const filesList = files;

    const onClickFile = async (item) => {
        setIsLoading(true);
        let flow = {...item.flow};
        const flowImage = await getFlowImage({ fileName: item.fileName },
            (res) => {
                const t = toArrayBuffer(res?.file?.data)
                let blob = new Blob([t]);
                setFlowImage(blob);
                setNodes(flow.nodes)
                setEdges(flow.edges)
                flow.flowImage = blob;
                setStrategyFormData({
                    flow,
                    name: item.name,
                    description: item.description
                });
                setShowTraidhubModal(false);
                setIsLoading(false);
            }, (err) => {
                console.log(err);
                setIsLoading(false);
            })
    }

    return (
        <div className={mainClass}>
            {filesList.length > 0 && <div className="nk-files-head">
                <div className="nk-file-item">
                    {fileView === 'list' && <>
                        <div className="nk-file-info">
                            <div className="tb-head">Name</div>
                            <div className="tb-head"></div>
                        </div>
                        {(page === undefined) && <div className="nk-file-meta">
                            <div className="tb-head">Description</div>
                        </div>}
                        {/* {(page === 'recovery') && <div className="nk-file-date">
                            <div className="tb-head">Deleted Al</div>
                        </div>}
                        <div className="nk-file-members">
                            <div className="tb-head">Members</div>
                        </div>
                        <div className="nk-file-actions">
                        </div> */}
                    </>}
                </div>
            </div>}
            {(fileView === 'list' || fileView === 'grid') &&
                <div className="nk-files-list">
                    {filesList.map((item) => (
                        <File onClick={() => onClickFile(item)} fileView={fileView} item={item} key={item.id} page={page} />
                    ))}
                </div>
            }
            {fileView === 'group' && <>
                <div className="nk-files-group">
                    <h6 className="title border-top-0">Simple</h6>
                    <div className="nk-files-list">
                        {filesList.filter(item => item.type === 'simple').map((item) => (
                            <File onClick={() => {
                                const flow = item.flow;
                                setFlowImage(item.flowImage.url)
                                setNodes(flow.nodes)
                                setEdges(flow.edges)
                                setStrategyFormData(flow);
                                setShowTraidhubModal(false);
                            }} fileView={fileView} item={item} key={item.id} page={page} />
                        ))}
                    </div>
                </div>
                <div className="nk-files-group">
                    <h6 className="title">Indicators</h6>
                    <div className="nk-files-list">
                        {filesList.filter(item => item.type === 'indicator').map((item) => (
                            <File onClick={() => {
                                const flow = item.flow;
                                setFlowImage(item.flowImage.url)
                                setNodes(flow.nodes)
                                setEdges(flow.edges)
                                setStrategyFormData(flow);
                                setShowTraidhubModal(false);
                            }} fileView={fileView} item={item} key={item.id} page={page} />
                        ))}
                    </div>
                </div>
            </>}
            {filesList.length === 0 && <div>No folders or files are available</div>}
        </div>
    )
}

export default Files