import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_NEMO_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

// search ticker from marketstack
function getNextMessage(params, onSuccess, onFail) {
    const url = `${API_URL}/get-next-message`;
    return axiosApi
      .post(url, params)
      .then(response => {
        onSuccess(response?.data);
      })
      .catch(error => {
        onFail(error);
      });
  }

  export {
    getNextMessage
  }