import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Modal, ModalBody, Spinner } from "reactstrap";
import { getCompanyProfile, getHistoricalPriceFull, getQuotes } from "../../api";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewAltCard,
  Row
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getDates, groupBy } from "../../utils/Utils";
import BuySell from "./BuySell";
import useLastChangeStream from "./LastChangeStream";
import PortfolioChartCard from "./PortfolioChartCard";
import "./PortfolioPage.css";
import PortfolioStatistics from "./PortfolioStatistics";
import OrderListNew from "./orders/OrderList";
import PositionDefault from "./positions/PositionDefault";
import StrategyDefault from "./strategies/StrategyDefault";

const PortfolioPage = ({ portfolioData, backtestData }) => {
  const navigate = useNavigate();
  const mode = portfolioData?.type === "backtest" ? "Backtest" : "Portfolio";

  const { pathname } = useLocation();
  const portfolioId = pathname.split('/')[2];
  const [data, setData] = useState(portfolioData);
  const [quotes, setQuotes] = useState(null);
  const [allSymbols, setAllSymbols] = useState(null);
  const [portfolioStats, setPortfolioStats] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [orderAsset, setOrderAsset] = useState("AAPL");
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(null);
  const [marketData, setMarketData] = useState([]);
  const [isLoadingMarketData, setIsLoadingMarketData] = useState(false);
  const [isLoadingChartData, setIsLoadingChartData] = useState(false);
  const [showBuySellModal, setShowBuySellModal] = useState(false);

  const allOrders = portfolioData?.positions?.reduce((a, b) => ([...a, ...b.orders]), [])

  useEffect(() => {
    const loadMarketData = async () => {
      setIsLoadingMarketData(true);
      getHistoricalPriceFull({ symbols: allSymbols.join(",") },
        (res) => {
          setMarketData(res)
          setIsLoadingMarketData(false)
        }, (err) => {
          console.error(err)
          setIsLoadingMarketData(false)
        })
    }
    allSymbols?.length > 0 && loadMarketData()
  }, [allSymbols])

  useEffect(() => {
    setIsLoadingChartData(true)
    const chartDates = getDates(moment().subtract(1, 'weeks'), moment());
    const assetMapper = {};
    let values = [];
    let lastValidPrice = {};
    chartDates.map(date => {
      const pastOrders = allOrders?.filter(o => moment(o.orderDate).format("YYYY-MM-DD") <= date);
      const bySymbols = groupBy(pastOrders, "asset");
      assetMapper[date] = []
      Object.keys(bySymbols).map(symbol => {
        const totalSize = bySymbols[symbol].reduce((acc, i) => (acc + i.sizeValue), 0);
        const costTotal = bySymbols[symbol].reduce((acc, d) => acc + (d.price * d.sizeValue), 0);
        const avgPrice = costTotal / totalSize;
        const symbolChart = marketData?.filter(m => m.symbol === symbol)?.[0]?.historical;
        const symbolProfile = profiles?.filter(m => m.symbol === symbol)?.[0];
        const candle = symbolChart?.find(c => c.date <= date);

        if (candle?.close) {
          lastValidPrice[symbol] = candle.close
        }

        const recordedPrice = candle?.close || lastValidPrice[symbol]

        assetMapper[date].push({
          symbol,
          totalSize,
          costTotal,
          marketValue: totalSize * recordedPrice,
          avgPrice,
          recordedPrice,
        })
      })
    })
    const chartData = []
    Object.keys(assetMapper).map((date, index) => {
      const portfolioValue = assetMapper[date]?.length ? (portfolioData?.initialFunds + assetMapper[date].reduce((acc, i) => (acc + i.marketValue - i.costTotal), 0)) : portfolioData?.initialFunds
      chartData.push({
        date,
        portfolioValue
      })
    })

    setChartData(chartData)
    setIsLoadingChartData(false)
  }, [marketData])

  const loadQuotes = async () => {
    setIsLoadingQuotes(true)
    getQuotes({ symbols: [orderAsset, ...(data?.positions?.map(i => i.asset) || [])]?.join(","), greeks: true },
      (res) => {
        setQuotes(res);
        setIsLoadingQuotes(false)
      }, (err) => {
        console.error(err)
        setIsLoadingQuotes(false)
      })
  }

  useEffect(() => {
    (portfolioData?.positions?.length > 0) && loadQuotes();
  }, [data, orderAsset]);


  useEffect(() => {
    setData(portfolioData)
  }, [portfolioData])

  useEffect(() => {
    const t = new Set([orderAsset, ...(data?.positions?.map(i => i.asset) || [])])
    setAllSymbols([...t])
  }, [orderAsset, data])

  const { allMessages } = useLastChangeStream({
    data: {
      symbols: allSymbols
    }
  })

  const openPositions = data?.positions?.filter(pos => pos.status === "open");

  return (
    <>
      <Head title={mode}></Head>
      {data && (
        <Content>
          <BlockHead>
            {/* <BlockHeadSub>Account Wallet</BlockHeadSub> */}
            <div className="nk-block-between-md g-4">
              <BlockHeadContent>
                <BlockTitle page>
                  {`${mode}s`} / <strong className="text-primary small">{data._id}</strong>
                </BlockTitle>
                {backtestData?.status === "running" && (
                  <>
                    <Badge
                      color={backtestData?.status === "running" ? "success" : backtestData?.status === "done" ? "info" : "danger"}
                      size="md"
                    >
                      {backtestData?.status}
                      <Spinner size="sm" type="grow" style={{ marginLeft: 4 }} />
                    </Badge>
                  </>)}
                {/* <BlockDes>
                <p>Here is the list of your assets / wallets!</p>
              </BlockDes> */}
              </BlockHeadContent>
              <BlockHeadContent>
                <ul className="nk-block-tools gx-3">
                  {mode === "Portfolio" && <li>
                    <Link onClick={(e) => {
                      e.preventDefault();
                      setShowBuySellModal(true)
                    }}
                    >
                      <Button className="d-none d-sm-inline-flex" color="primary">
                        <Icon name="coins"></Icon> <span>Buy / Sell</span>
                      </Button>
                      <Button color="primary" outline className="btn-icon d-inline-flex d-sm-none">
                        <Icon name="coins"></Icon>
                      </Button>
                    </Link>
                  </li>}
                  <li>
                    <Link onClick={() => {
                      navigate(-1)
                    }}>
                      <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                        <Icon name="arrow-left"></Icon>
                        <span>Back</span>
                      </Button>
                      <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                        <Icon name="arrow-left"></Icon>
                      </Button>
                    </Link>
                  </li>
                </ul>
              </BlockHeadContent>
            </div>
          </BlockHead>


          <Block>
            <Row className="g-gs">
              <Col lg="6">
                <PreviewAltCard>
                  <PortfolioChartCard isLoadingChartData={isLoadingChartData} mode={mode} backtestData={backtestData} chartData={chartData} quotes={quotes} allMessages={allMessages} portfolioData={portfolioData} />
                </PreviewAltCard>
                {/* {mode === "Portfolio" && <PreviewAltCard>
                  {allSymbols && <StrategyDefault portfolioData={portfolioData} allSymbols={allSymbols} portfolioId={portfolioId} allStrategies={data?.strategies || []} />}
                </PreviewAltCard>} */}
                {/* <PortfolioStatistics mode={mode} portfolioStats={portfolioStats} /> */}
              </Col>

              <Col lg="6">
                <PreviewAltCard>
                  <PositionDefault portfolioId={portfolioId} quotes={quotes} allMessages={allMessages} positionsData={data?.positions || []} />
                </PreviewAltCard>
                <PreviewAltCard>
                  <OrderListNew portfolioId={portfolioId} ordersData={allOrders} />
                </PreviewAltCard>
                {/* {mode === "Portfolio" && <BuySell
                        asset={orderAsset}
                        setAsset={setOrderAsset}
                        quotes={quotes}
                        allMessages={allMessages}
                        portfolioData={portfolioData}
                        portfolioId={portfolioId} />} */}
              </Col>
            </Row>
          </Block>
          <Modal isOpen={showBuySellModal} toggle={() => { setShowBuySellModal(false) }} className="modal-dialog" size="sm">
            <ModalBody>
              <BuySell
                setShowBuySellModal={setShowBuySellModal}
                asset={orderAsset}
                setAsset={setOrderAsset}
                quotes={quotes}
                allMessages={allMessages}
                portfolioData={portfolioData}
                portfolioId={portfolioId} />
            </ModalBody>

          </Modal>
        </Content>
      )}
    </>
  );
};
export default PortfolioPage;
