import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

import { Chart, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Filler, Legend, LogarithmicScale } from "chart.js";
import strings from "../../utils/Strings";
import { useMyStore } from "../bots/trading-bot-new/ReactFlowBuilder/store";
Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Filler, Legend, LogarithmicScale);

export const BacktestBalanceChart = ({ backtestMode, data, botData = null }) => {
  const spyChart = data?.performance?.chart?.map(i => ((i?.["SPY"]?.close - data?.performance?.chart?.[0]?.["SPY"]?.close) / data?.performance?.chart?.[0]?.["SPY"]?.close * 100.00));
  const balanceChart = data?.performance?.balanceHistory?.map(i => ((i.value - data?.performance?.balanceHistory?.[0]?.value) / data?.performance?.balanceHistory?.[0]?.value * 100.00));
  const allBalanceLabels = ((data?.performance?.balanceHistory) ? [...data?.performance?.balanceHistory?.map(i => i.time)] : [])
  const allChartLabels = ((data?.performance?.chart) ? [...data?.performance?.chart?.map(i => i.startTime)] : [])
  const allLabels = Array.from(new Set([...allChartLabels, ...allBalanceLabels]));

  const { color: botColor, name: botName } = botData || {};
  let datasets = [
    {
      yAxisID: "y",
      label: botName ? botName : "Balance",
      color: botColor ? botColor : "#9d72ff",
      borderWidth: 2,
      fill: true,
      lineTension: 0,
      dash: 0,
      borderColor: botColor ? botColor : "#9d72ff",
      backgroundColor: "transparent",
      borderCapStyle: "square",
      pointBorderColor: "transparent",
      pointBackgroundColor: botColor ? botColor : "#9d72ff",
      // pointHoverBackgroundColor: "#fff",
      // pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 4,
      data: balanceChart
    },
  ]

  if (true) {
    datasets.push({
      yAxisID: "y",
      label: "SPY",
      color: "#3a8dfe",
      borderWidth: 2,
      fill: true,
      lineTension: 0,
      dash: 0,
      borderColor: "#3a8dfe",
      backgroundColor: "transparent",
      borderCapStyle: "square",
      pointBorderColor: "transparent",
      pointBackgroundColor: "#3a8dfe",
      // pointHoverBackgroundColor: "#fff",
      // pointHoverBorderColor: "#3a8dfe",
      pointBorderWidth: 0,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 4,
      data: spyChart
    })
  }
  return (
    <Line
      className="analytics-line-large"
      data={{
        labels: allLabels,
        datasets,
      }}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
            enabled: true,
            displayColors: true,
            // backgroundColor: "#eff6ff",
            // titleFont: {
            //   size: '13px',
            // },
            // titleColor: "#6783b8",
            // titleMarginBottom: 6,
            // bodyColor: "#9eaecf",
            // bodyFont: {
            //   size: '12px',
            // },
            callbacks: {
              label: function (context) {
                let raw = context.raw || 0;
                let label = context.dataset.label || '';
                return `${label}: ${strings.percentSignFormatter(raw)}`
              }
            },
            bodySpacing: 4,
            padding: 10,
            footerMarginTop: 0,
          },
        },
        maintainAspectRatio: false,
        scales: {
          // ticks: {
          //   min: 0,
          // },
          y: {
            display: false,
            position: 'left',
            // type: 'linear',
            // ticks: {
            //   min: 0,
            //   beginAtZero: true
            // }
          },
          // yBenchmark: {
          //   display: false,
          //   position: 'right',
          //   type: 'linear',
          //   ticks: {
          //     min: 0, beginAtZero: true
          //   }
          // },
          x: {
            display: false,
            // ticks: {
            //   color:"#9eaecf", 
            //   font: {
            //     size: '12px',
            //   },
            //   source: "auto",
            //   padding: 0,
            // },
            grid: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "transparent",
              offsetGridLines: true,
            },
          },
        },
      }}
    ></Line >
  );
};
