import {
  BaseEdge,
  EdgeLabelRenderer,
  getStraightPath,
  useReactFlow,
} from 'reactflow';
import { RSelect } from '../../../../../components/Component';
import { useState } from 'react';
import useStore from '../../../../../store/reactFlow';
const logicalOptions = [
  { label: "IF", value: "if" },
  { label: "IF NOT", value: "ifNot" },
]
export default function ConditionGroupEdge({ id, data, sourceX, sourceY, targetX, targetY, markerEnd }) {
  const { updateEdge } = useStore(({ updateEdge }) => ({ updateEdge }));
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge id={id} markerEnd={markerEnd} path={edgePath} />
      <EdgeLabelRenderer>
        <div style={{
          position: 'absolute',
          transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          background: '#364a63',
          color: "white",
          padding: 10,
          borderRadius: 5,
          fontSize: 12,
          fontWeight: 700,
        }}>
          <span>{data.type === "enter" ? "Enter " : "Exit "}</span>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
