import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CREATE_BACKTEST, GET_BOT_INFO } from "../../api/graphql";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewAltCard,
  Row
} from "../../components/Component";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import TradingBot from "./trading-bot-new/TradingBot";
import { useMyStore } from "./trading-bot-new/ReactFlowBuilder/store";
// import { MyProvider } from "../trading-bot-new/ReactFlowBuilder/store";

let oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
const initialBacktest = {
  start: oneWeekAgo,
  interval: { value: "15", label: "15 min" },
  end: new Date(),
  baselineAsset: "SPY",
  initialFunds: "10000",
  name: "New backtest2",
  yyy: "TTT"
}

const BotPage = () => {
  const { pathname } = useLocation();
  const botId = pathname.split('/')[2];
  const { data: botData, loading, error, startPolling, stopPolling } = useQuery(GET_BOT_INFO, {
    variables:
      { id: botId }
  });
  const { setBotData, state, isRunningBacktest } = useMyStore();

  useEffect(() => {
    setBotData({ botData: botData?.botById })
  }, [botData, setBotData])

  useEffect(() => {
    if (botData) {
      const lastBacktest = botData?.botById?.backtests?.[botData?.botById?.backtests?.length - 1];
      if (isRunningBacktest || lastBacktest?.status === "running") {
        console.log("starting polling ")
        startPolling(
          1000 * 10 //10 seconds
        );
      }
      else {
        console.log("stopping polling ")
        stopPolling();
      }
    }
  }, [botData, isRunningBacktest, startPolling, stopPolling])

  if (loading) return <SpinnerWrapper />

  return (
    <div>
      {<TradingBot startPolling={startPolling} stopPolling={stopPolling} botData={botData?.botById} />}
    </div>
  );
};
export default BotPage;
