// material-ui
import ReactHighcharts from 'react-highcharts/ReactHighstock.src'
import React, { useEffect, useState } from 'react';
import { parseStockDataMarketStack } from '../../../one-pager/OnePager';
import CanvasJSReact from '@canvasjs/react-stockcharts';
import "./index.css"
import { formatDate } from '@canvasjs/stockcharts';
import { useData } from './useData';
import { intervalOptions } from '../../../strategies/BacktestFormModal';
import moment from 'moment';
import { Col, Row, Spinner } from 'reactstrap';
import { RSelect } from '../../../../components/Component';
import chroma from 'chroma-js';

import Select, { StylesConfig } from 'react-select';
import { useTheme } from '../../../../layout/provider/Theme';
import { useMyStore } from '../ReactFlowBuilder/store';

const colourStyles = {
    control: (styles, state) => ({
        ...styles, backgroundColor: 'white',
        background: '#fff',
        borderColor: '#9e9e9e',
        // minHeight: '30px',
        // height: '30px',
        boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        // height: '30px',
        padding: '0 6px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        // height: '30px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        console.log({ data, isDisabled, isFocused, isSelected })
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? `${data.color} !important`
                    : isFocused
                        ? `${color.alpha(0.1).css()} !important`
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : `${data.color} !important`,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? `${data.color} !important`
                        : `${color.alpha(0.3).css()} !important`
                    : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: `${color.alpha(0.1).css()} !important`,
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: `${data.color} !important`,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
};



//var CanvasJSReact = require('@canvasjs/react-stockcharts');
const additionalChartsMapper = ({ data = {}, chartValue }) => {
    return {
        "sma_50": {
            type: "line",
            dataPoints: data?.smaDataPoints,
            yValueFormatString: "$#,###.00",
            name: "sma_50",
            color: "orange",
        },
        "ema_14": {
            type: "line",
            dataPoints: data?.emaDataPoints,
            yValueFormatString: "$#,###.00",
            name: "ema_14",
            color: "pink"
        },
        "rsi_20": {
            type: "line",
            dataPoints: data?.rsiDataPoints,
            yValueFormatString: "$#,###.00",
            name: "rsi_20",
            color: "aquamarine"
        },
        "macd_12_26_9": {
            type: "line",
            dataPoints: data?.macdDataPoints,
            yValueFormatString: "$#,###.00",
            name: "macd_12_26_9",
            color: "coral"
        },
        "bullishHammer": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bullishHammerData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bullish hammer",
            markerType: "triangle",
            color: "Magenta"
        },
        "bullishInvertedHammer": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bullishInvertedHammerData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bullish inverted hammer",
            markerType: "triangle",
            color: "CornflowerBlue"
        },
        "bullishHangingMan": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bullishHangingManData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bullish hanging man",
            markerType: "triangle",
            color: "Cyan"
        },
        "bullishShootingStar": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bullishShootingStarData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bullish shooting star",
            markerType: "triangle",
            color: "DarkSeaGreen"
        },
        "bullishEngulfing": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bullishEngulfingData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bullish engulfing",
            markerType: "triangle",
            color: "LightSeaGreen"
        },
        "bullishHarami": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bullishHaramiData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bullish harami",
            markerType: "triangle",
            color: "Olive"
        },
        "bullishKicker": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bullishKickerData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bullish kicker",
            markerType: "triangle",
            color: "indigo"
        },
        "bearishHammer": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bearishHammerData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bearish hammer",
            markerType: "cross",
            color: "OrangeRed"
        },
        "bearishInvertedHammer": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bearishInvertedHammerData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bearish inverted hammer",
            markerType: "cross",
            color: "FireBrick"
        },
        "bearishEngulfing": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bearishEngulfingData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bearish engulfing",
            markerType: "cross",
            color: "Aquamarine"
        },
        "bearishHarami": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bearishHaramiData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bearish harami",
            markerType: "cross",
            color: "MediumVioletRed"
        },
        "bearishKicker": {
            type: "scatter",
            markerSize: 12,
            dataPoints: data?.bearishKickerData?.map(i => ({ ...i, label: new Date(moment(i.label).utc()) })),
            yValueFormatString: "$#,###.00",
            name: "bearish kicker",
            markerType: "cross",
            color: "DarkGoldenRod"
        },
    }[chartValue]
}
const groupedData = [
    {
        label: "Indicators",
        options: [
            { value: "sma_50", label: "SMA", color: additionalChartsMapper({ chartValue: "sma_50" })?.color },
            { value: "ema_14", label: "EMA", color: additionalChartsMapper({ chartValue: "ema_14" })?.color },
            // { value: "rsi_20", label: "RSI", color: additionalChartsMapper({ chartValue: "rsi_20" }).color },
            // { value: "macd_12_26_9", label: "MACD", color: additionalChartsMapper({ chartValue: "macd_12_26_9" }).color },
        ],
    },
    {
        label: "Patterns",
        options: [
            { value: "bullishHammer", label: "Bullish hammer", color: additionalChartsMapper({ chartValue: "bullishHammer" }).color },
            { value: "bullishInvertedHammer", label: "Bullish inverted hammer", color: additionalChartsMapper({ chartValue: "bullishInvertedHammer" }).color },
            { value: "bullishHangingMan", label: "Bullish hanging man", color: additionalChartsMapper({ chartValue: "bullishHangingMan" }).color },
            { value: "bullishShootingStar", label: "Bullish shooting star", color: additionalChartsMapper({ chartValue: "bullishShootingStar" }).color },
            { value: "bullishEngulfing", label: "Bullish engulfing", color: additionalChartsMapper({ chartValue: "bullishEngulfing" }).color },
            { value: "bullishHarami", label: "Bullish harami", color: additionalChartsMapper({ chartValue: "bullishHarami" }).color },
            { value: "bullishKicker", label: "Bullish kicker", color: additionalChartsMapper({ chartValue: "bullishKicker" }).color },
            { value: "bearishHammer", label: "Bearish Hammer", color: additionalChartsMapper({ chartValue: "bearishHammer" }).color },
            { value: "bearishInvertedHammer", label: "Bearish inverted hammer", color: additionalChartsMapper({ chartValue: "bearishInvertedHammer" }).color },
            { value: "bearishEngulfing", label: "Bearish engulfing", color: additionalChartsMapper({ chartValue: "bearishEngulfing" }).color },
            { value: "bearishHarami", label: "Bearish harami", color: additionalChartsMapper({ chartValue: "bearishHarami" }).color },
            { value: "bearishKicker", label: "Bearish kicker", color: additionalChartsMapper({ chartValue: "bearishKicker" }).color },
            // { value: "ema_14", label: "EMA", color: additionalChartsMapper({ chartValue: "ema_14" }).color },
            // { value: "rsi_20", label: "RSI", color: additionalChartsMapper({ chartValue: "rsi_20" }).color },
            // { value: "macd_12_26_9", label: "MACD", color: additionalChartsMapper({ chartValue: "macd_12_26_9" }).color },
        ],
    },
]
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

// ==============================|| CARD - SECONDARY ACTION ||============================== //

const StockChart = ({ symbol }) => {
    const [additionalCharts, setAdditionalCharts] = useState([]);
    const [crosshairPointer, setCrosshairPointer] = useState(null);
    const [sliderMinimum, setSliderMinimum] = useState(moment().subtract(1, 'months'));
    const [from, setFrom] = useState(moment().subtract(1, 'years'));
    const [to, setTo] = useState(moment);
    const { interval } = useMyStore();
    const { data, loadChartData = null, isLoading, point } = useData({ symbol, interval, from });
    const { priceDataPoints, smaDataPoints } = data;
    const theme = useTheme();
    // let priceDataPoints = chartData.chartData.map(i => ({ label: new Date(i.date), y: [i.open, i.high, i.low, i.close] }))
    // let smaDataPoints = chartData.chartData.map(i => ({ label: new Date(i.date), y: i?.['sma_50'] }));
    const options = {
        theme: "light2",
        height: 460,
        rangeSelector: {
            enabled: false
        },
        charts: [{
            zoomEnabled: true,
            title: {
                // text: "Bitcoin Price in USD"
            },
            axisY: {
                labelFontFamily: '"Poppins", sans-serif',
                labelFontSize: 12,
                lineThickness: 0,
                gridThickness: 0,
                tickLength: 0,
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true,
                },
                labelFormatter: function () {
                    return " ";
                }
                // gridDashType: "dash",
            },
            toolbar: {
                // itemBackgroundColor: "#d3d3d3", //Change it to "red"
                // itemBackgroundColorOnHover: "#3e3e3e",
                buttonBorderColor: "#3e3e3e"
            },
            axisX: {
                crosshair: {
                    enabled: true,
                    snapToDataPoint: true,
                    updated: (e) => {
                        setCrosshairPointer(e)
                    },
                    hidden: (e) => {
                        // setCrosshairPointer(null)
                    },
                    labelFormatter: function (e) {
                        return formatDate(e?.chart?.data?.[0]?.dataPoints?.[e.value]?.label, "MMM DD hh:mm");
                    },
                },
                gridThickness: 0,
                lineThickness: 0,
                // interval: 2,
                tickLength: 0,
                labelFontFamily: '"Poppins", sans-serif',
                labelFontSize: 12,
                labelFormatter: function (e) {
                    return formatDate(e.label, "MM/DD")
                },
            },
            // animationEnabled: true,
            // dataPointWidth: 10,
            stemThickness: 0,
            whiskerThickness: 0,
            data: [
                {
                    type: "candlestick",
                    yValueFormatString: "$#,###.##",
                    dataPoints: priceDataPoints,
                    risingColor: "#1ee0ac",
                    fallingColor: "#e85347",
                    // showInLegend: true,
                    name: "candles",
                    color: "rgba(var(--bs-lighter-rgb), var(--bs-bg-opacity)) !important"
                },
                ...additionalCharts.map(c => additionalChartsMapper({ data, chartValue: c.value }))
                // {
                //     name: "close price",
                //     type: "line",
                //     color: "pink",
                //     // showInLegend: true,
                //     dataPoints: priceDataPoints.map(i => ({ label: i.label, y: i.y?.[3] })),
                // },
                // {
                //     type: "line",
                //     dataPoints: smaDataPoints,
                //     // showInLegend: true,
                //     yValueFormatString: "$#,###.00",
                //     name: "sma_50",
                //     visible: false,
                //     color: "orange"
                // },
                // {
                //     type: "scatter",
                //     dataPoints: priceDataPoints.map((i, index) => (index === priceDataPoints.length - 3) || (index === priceDataPoints.length - 2) ? ({ label: i.label, y: i.y?.[3] }) : ({ label: i.label })),
                //     // showInLegend: true,
                //     // toolTipContent: "x: {y}",
                //     name: "patterns",
                //     color: "blue",
                // }
            ],
            toolTip: {
                enabled: false,
                // shared: true,
                borderColor: "transparent",
            },
            legend: {
                enabled: false,
                fontFamily: '"Poppins", sans-serif',
                fontWeight: "lighter",
                fontSize: 14,
                verticalAlign: "top",
                horizontalAlign: "left",
                cursor: "pointer",
                itemclick: function (e) {
                    if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                        e.dataSeries.visible = false;
                    } else {
                        e.dataSeries.visible = true;
                    }
                    e.chart.render();
                }
            },
        }],
        backgroundColor: "transparent",
        navigator: {
            data: [{
                type: "line",
                color: "grey",
                dataPoints: priceDataPoints.map(i => ({ label: formatDate(i.label, "MMM YY"), y: i.y?.[3] })),
            }],
            animationEnabled: true,
            slider: {
                maskInverted: true,
                maskColor: theme?.skin === "dark" ? "#364a63" : "#abb8eb42"
                // minimum: sliderMinimum,
                // maximum: new Date()
            }
        }
    };

    useEffect(() => {
        if (interval) {
            if (interval.label.includes("day")) {
                setFrom(moment().subtract(1, 'years'))
                setSliderMinimum(new Date(moment().subtract(1, 'months')))
            }
            else if (interval.label.includes("1 min")) {
                setFrom(moment().subtract(14, 'days'))
                setSliderMinimum(new Date(moment().subtract(2, 'days')))
            }
            else if (interval.label.includes("min")) {
                setFrom(moment().subtract(2, 'weeks'))
                setSliderMinimum(new Date(moment().subtract(1, 'weeks')))
            }
        }
    }, [interval])

    return (
        <div style={{
            // height: "calc(100vh - 320px)",
            // height: "1200px",
            // overflowY: "scroll"
        }}>
            <Row className="g-2" style={{
                margin: "0px 0px 16px 0px"
            }}>
                <Col lg={4}>
                    <div className="form-group">
                        <label className="form-label"><b>Indicators / patterns</b></label>
                        <RSelect
                            styles={colourStyles}
                            closeMenuOnSelect={false}
                            value={additionalCharts} onChange={(e) => {
                                setAdditionalCharts(e)
                            }} options={groupedData} isMulti />
                    </div>
                </Col>
            </Row>
            <Row className='g-2'>
                <Col lg={12}>
                    {symbol} | {interval?.label} interval
                    {crosshairPointer && <>
                        {` | open: ${crosshairPointer?.axis?.dataSeries?.[0]?.dataPoints?.[crosshairPointer?.value]?.y?.[0]} | `}
                        {`high: ${crosshairPointer?.axis?.dataSeries?.[0]?.dataPoints?.[crosshairPointer?.value]?.y?.[1]} | `}
                        {`low: ${crosshairPointer?.axis?.dataSeries?.[0]?.dataPoints?.[crosshairPointer?.value]?.y?.[2]} | `}
                        {`close: ${crosshairPointer?.axis?.dataSeries?.[0]?.dataPoints?.[crosshairPointer?.value]?.y?.[3]}`}
                    </>}
                </Col>
            </Row>
            {isLoading ? <Spinner /> : (
                <CanvasJSStockChart
                    options={options}
                />
            )}
        </div>
    );
};

export default StockChart;
