import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Badge, Button, Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  Col,
  Icon,
  RSelect,
  Row
} from "../../../components/Component";
import Head from "../../../layout/head/Head";
import strings from "../../../utils/Strings";
import { getDateStructured } from "../../../utils/Utils";
import ScreenDataTable from "../../screener/ScreenDataTable";

const OrderListNew = ({ portfolioId, ordersData }) => {
  const [data, setData] = useState(ordersData);
  const [smOption, setSmOption] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    orderId: "",
    date: new Date(),
    status: "Delivered",
    customer: "",
    purchased: "",
    total: "",
    check: false,
  });
  const [view, setView] = useState({
    add: false,
    details: false,
  });
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = ordersData.filter((item) => {
        return item.orderId.includes(onSearchText);
      });
      setData([...filteredObject]);
    } else {
      setData([...ordersData]);
    }
  }, [onSearchText]);

  // toggle function to view order details
  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
    });
  };

  // selects all the order
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      let temp = { ...item }
      temp.check = e.currentTarget.checked;
      return temp;
    });
    setData([...newData]);
  };

  // selects one order
  const onSelectChange = (e, id) => {
    let newData = [...data];
    let index = newData.findIndex((item) => item._id === id);
    let temp = { ...newData[index], check: e.currentTarget.checked };
    newData[index] = temp
    setData([...newData]);
  };

  // resets forms
  const resetForm = () => {
    setFormData({
      id: null,
      orderId: "",
      date: new Date(),
      status: "Delivered",
      customer: "",
      purchased: "",
      total: "",
      check: false,
    });
  };

  const onFormSubmit = (form) => {
    const { customer, purchased, total } = form;
    let submittedData = {
      id: data.length + 1,
      orderId: "95981",
      date: getDateStructured(formData.date),
      status: formData.status,
      customer: customer,
      purchased: purchased,
      total: total,
      check: false,
    };
    setData([submittedData, ...data]);
    setView({ add: false, details: false });
    resetForm();
  };

  useEffect(() => {
    reset(formData)
  }, [formData]);

  useEffect(() => {
    setData(ordersData)
  }, [ordersData]);

  // function to load detail data
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item.id === id);
    setFormData(data[index]);
  };

  // OnChange function to get the input data
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ add: false, details: false });
    resetForm();
  };

  // function to change to approve property for an item
  const markAsDelivered = (id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].status = "Delivered";
    setData([...newData]);
  };

  // function to delete a Order
  const deleteOrder = (id) => {
    let defaultData = data;
    defaultData = defaultData.filter((item) => item.id !== id);
    setData([...defaultData]);
  };

  // function to delete the seletected item
  const selectorDeleteOrder = () => {
    let newData;
    newData = data.filter((item) => item.check !== true);
    setData([...newData]);
  };

  // function to change the complete property of an item
  const selectorMarkAsDelivered = () => {
    let newData;
    newData = data.map((item) => {
      if (item.check === true) item.status = "Delivered";
      return item;
    });
    setData([...newData]);
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  const portfolioOrderColumns = [
    // {
    //   name: "Excecuted by",
    //   selector: (row) => row.executor,
    //   sortable: false,
    //   cell: (row) => <span>{row.executor} </span>,
    //   // hide: "lg",
    // },
    {
      name: "Asset",
      selector: (row) => row.asset,
      sortable: false,
      cell: (row) => <span>{row.asset} </span>,
      // hide: "lg",
    },
    {
      name: "Time",
      selector: (row) => row.orderDate,
      sortable: false,
      cell: (row) => <span>{moment(row.orderDate).format("MM/DD/YY hh:mm")} </span>,
      // hide: "lg",
    },
    {
      name: "Type",
      selector: (row) => row.actionType,
      sortable: true,
      width: "120px",
      cell: (row) => {

        return (
          <div style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            flexWrap: "wrap"
          }}>
            {row.actionType?.label}
            <div style={{
              fontSize: 16,
              height: 32,
              width: 32,
              marginLeft: 8
            }}
              className={`nk-tnx-type-icon bg-${row.actionType?.value === "long"
                ? "success-dim text-success"
                : row.status === "Upcoming"
                  ? "warning-dim text-warning"
                  : row.status === "Pending"
                    ? "info-dim text-info"
                    : "danger-dim text-danger"
                }`}
            >
              <Icon name={row.actionType?.value === "long" ? "arrow-up-right" : "arrow-down-right"}></Icon>
            </div>
          </div>
        )
      },
      // hide: "lg",
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      cell: (row) => {
        return (
          <span >{strings.priceDecimal(row.price)}</span>
        )
      },
      // hide: "lg",
    },
    {
      name: "Shares",
      selector: (row) => ({ sizeValue: row.sizeValue }),
      sortable: true,
      cell: (row) => {
        return (
          <span>{row.sizeValue}</span>
        )
      },
    },
    {
      name: "Cost",
      selector: (row) => ({ sizeValue: row.sizeValue, price: row.price }),
      sortable: true,
      cell: (row) => <span>{strings.priceDecimal(row.price * row.sizeValue)}</span>,
      // hide: "lg",
    },
    {
      name: "Tags",
      selector: (row) => (row.reasons),
      sortable: true,
      cell: (row) => {
        return (
          <div className="date" style={{
            paddingLeft: 0
          }}>{row?.reasons?.map(i => {
            return (<Badge>{i}</Badge>)
          })}</div>
        )
      },
      // hide: "lg",
    },
  ];


  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <h6>Order history</h6>
          </BlockHeadContent>
          {/* <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: smOption ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by orderId"
                          onChange={(e) => onFilterChange(e)}
                        />
                      </div>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          Status
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => ev.preventDefault()}>
                                <span>New Items</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => ev.preventDefault()}>
                                <span>Featured</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem tag="a" href="#dropdownitem" onClick={(ev) => ev.preventDefault()}>
                                <span>Out of Stock</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Order</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent> */}
        </BlockBetween>
      </BlockHead>

      <Block>
        <ScreenDataTable fileName={`${portfolioId}_orders`} searchField={"asset"} data={data} columns={portfolioOrderColumns} pagination actions />
      </Block>

      <Modal isOpen={view.add} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a href="#cancel" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
            ></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Add Order</h5>
            <div className="mt-4">
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <Row className="g-3">
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="customer">
                        Customer Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          {...register('customer', {
                            required: "This field is required",
                          })}
                          onChange={(e) => setFormData({ ...formData, customer: e.target.value })}
                          value={formData.customer} />
                        {errors.customer && <span className="invalid">{errors.customer.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="date">
                        Date of order
                      </label>
                      <div className="form-control-wrap">
                        <DatePicker
                          selected={formData.date}
                          className="form-control"
                          onChange={(date) => setFormData({ ...formData, date: date })}
                        />
                        {errors.date && <span className="invalid">{errors.date.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="purchased">
                        Purchased Product
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          {...register('purchased', { required: "This is required" })}
                          value={formData.purchased}
                          onChange={(e) => setFormData({ ...formData, purchased: e.target.value })} />
                        {errors.purchased && <span className="invalid">{errors.purchased.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="total">
                        Total Price
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          className="form-control"
                          {...register('total', { required: "This is required" })}
                          value={formData.total}
                          onChange={(e) => setFormData({ ...formData, total: e.target.value })} />
                        {errors.total && <span className="invalid">{errors.total.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="status">
                        Status
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="status"
                          options={[
                            { value: "On Hold", label: "On Hold" },
                            { value: "Delivered", label: "Delivered" },
                          ]}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                          value={{ value: formData.status, label: formData.status }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col size="12">
                    <Button color="primary" type="submit">
                      <Icon className="plus"></Icon>
                      <span>Add Order</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={view.details} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a href="#cancel" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
            ></Icon>
          </a>
          <div className="nk-tnx-details mt-sm-3">
            <div className="nk-modal-head mb-3">
              <h5 className="title">Order Details</h5>
            </div>
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Order Id</span>
                <span className="caption-text">{formData.orderId}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Status</span>
                <span
                  className={`dot bg-${formData.status === "Delivered" ? "success" : "warning"} d-sm-none`}
                ></span>
                <Badge
                  className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
                  color={
                    formData.status === "Delivered" ? "success" : "warning"
                  }
                >
                  {formData.status}
                </Badge>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Customer</span>
                <span className="caption-text">{formData.customer}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Purchased Product</span>
                <span className="caption-text">{formData.purchased}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Total Price</span>
                <span className="caption-text">{formData.total}</span>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderListNew;
