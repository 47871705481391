import { useRef, useState } from "react";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import { Button, CloseButton, Col, Form, Label, Row, Spinner } from "reactstrap";
import { Icon } from "./Component";
import { sendEmail } from "../api";
import useAuth from "../hooks/useAuth";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";
import { useForm } from "react-hook-form";
// import { usePopper } from "react-popper";
// import Head from "next/head";

export default function FeedbackWidget() {
    const { user } = useAuth();
    const [formData, setFormData] = useState({
        email: user?.email ? user.email : "",
        message: ""
    })
    const { register, handleSubmit, formState: { errors } } = useForm();
    const formClass = classNames({
        "form-validate": true,
        // "is-alter": alter,
    });

    const [showWidget, setShowWidget] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [text, setText] = useState("");
    const [email, setEmail] = useState(null);
    const [feedbackType, setFeedbackType] = useState("Feedback")

    const ref = useRef(null);
    useOutsideAlerter(ref, () => setShowWidget(false));
    //   const { styles, attributes } = usePopper(referenceElement, popperElement, {
    //     modifiers: [{ name: "offset", options: { offset: [10, 10] } }],
    //   });

    const fieldToast = (message) => {
        return toast.error(message, {
            position: "bottom-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
            // closeButton: <CloseButton />,
        });
    };

    const onClickSendFeedback = (event) => {
        event.preventDefault();
        const { email, message } = formData;
        try {
            const e = user ? user.email : email;
            if (!e || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.exec(e)) {
                return fieldToast("Please enter a valid email.");
            }
            if (message !== "") {
                setSendingEmail(true)
                sendEmail({
                    from: user.email,
                    text: message,
                    subject: `USER FEEDBACK: ${feedbackType}`
                }, (res) => {
                    setSendingEmail(false)
                    setShowWidget(false);
                    Swal.fire({
                        title: "Thank you for your feedback!",
                        focusConfirm: false,
                    });
                },
                    (err) => {
                        console.error(err)
                        setSendingEmail(false)
                    }
                )
            }
            else {
                return fieldToast("You forgot something..");
            }
        } catch (error) {
            console.error(error);
            setSendingEmail(false)
        }
    }
    return (
        <>
            <Button
                onClick={() => setShowWidget(!showWidget)}
                ref={setReferenceElement}
                style={{
                    position: 'fixed',
                    bottom: 10,
                    right: 10,
                    zIndex: 999
                }}
            >
                Feedback
            </Button>
            <Row>
                <Col>
                    {showWidget && (
                        <div
                            className="bg-white p-4 shadow-lg"
                            ref={ref}
                            style={{
                                // width: 300,
                                // height: 300,
                                borderRadius: 4,
                                position: 'fixed',
                                bottom: 10,
                                right: 10,
                                zIndex: 9999
                            }}
                        //   style={styles.popper}
                        //   {...attributes.popper}
                        >
                            <ToastContainer>
                            </ToastContainer>
                            <form>
                                <h6 for="feedback" className="mb-2">How can we improve?</h6>
                                <div className="flex flex-col space-y-2">
                                    {!user && <div className="form-group">
                                        <Label className="form-label" htmlFor="fv-email">
                                            Email
                                        </Label>
                                        <div className="form-control-wrap">
                                            <input
                                                type="email"
                                                id="fv-email"
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                className="form-control" />
                                        </div>
                                    </div>}
                                    <div className="form-group">
                                        <ul className="custom-control-group">
                                            <li>
                                                <div className="custom-control custom-radio custom-control-pro no-control checked">
                                                    <input type="radio" name="feedbackType" className="custom-control-input" id="btnIconRadioNc3" defaultChecked />
                                                    <label className="custom-control-label" htmlFor="btnIconRadioNc3" onClick={() => setFeedbackType("Feedback")}>
                                                        <Icon name="question-alt"></Icon>
                                                        <span>Feedback</span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="custom-control custom-radio custom-control-pro no-control checked">
                                                    <input type="radio" name="feedbackType" className="custom-control-input" id="btnIconRadioNc1" />
                                                    <label className="custom-control-label" htmlFor="btnIconRadioNc1" onClick={() => setFeedbackType("Bug")}>
                                                        <Icon name="bugs"></Icon>
                                                        <span>Bug</span>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="custom-control custom-radio custom-control-pro no-control checked">
                                                    <input type="radio" name="feedbackType" className="custom-control-input" id="btnIconRadioNc2" onClick={() => setFeedbackType("Feature")} />
                                                    <label className="custom-control-label" htmlFor="btnIconRadioNc2">
                                                        <Icon name="bulb"></Icon>
                                                        <span>Feature</span>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="form-group">
                                        <Label className="form-label" htmlFor="fv-message">
                                            Message
                                        </Label>
                                        <div className="form-control-wrap">
                                            <textarea
                                                type="textarea"
                                                className="form-control form-control-sm mb-2"
                                                id="fv-message"
                                                value={formData.message}
                                                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                                placeholder="Write your message" />
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    style={{
                                        float: "right"
                                    }}
                                    onClick={onClickSendFeedback}
                                >
                                    Send
                                    {sendingEmail && <Spinner type="grow" size="sm" />}
                                </Button>
                            </form>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
}