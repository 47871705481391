import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from '../hooks/useAuth';
import { Spinner } from 'reactstrap';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggingIn } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // useEffect(() => {
  //   if (isLoggingIn) {
  //     if (pathname !== "/login" || pathname !== "")
  //       return <Spinner />
  //   }
  // }, [isLoggingIn, pathname, navigate]);
  if (isLoggingIn) {
    if (pathname !== "/login" || pathname !== "")
      return <Spinner />
  }
  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
