import { Link } from "react-router-dom";
import { BacktestBalanceChart } from "../pages/strategies/BacktestBalanceChart";
import strings from "../utils/Strings";
import { DataTableHead, DataTableItem, DataTableRow, Icon, UserAvatar } from "./Component";
import { WPCharts } from "./partials/charts/analytics/AnalyticsCharts";
import { Badge } from "reactstrap";
import { findUpper } from "../utils/Utils";
import chroma from "chroma-js";
import AssetLogo from "./AssetLogo";
import { useMobileView } from "../hooks/useMobileView";


const TrendedBots = ({ data }) => {
    const problemBreak = useMobileView({ breakPoint: 1300 });
    const mobileView = useMobileView({ breakPoint: 680 });
    return (
        <div className="nk-tb-list is-loose traffic-channel-table">
            <DataTableHead>
                <DataTableRow className="nk-tb-channel" style={{
                    width: "unset"
                }}>
                    <span>Name</span>
                </DataTableRow>
                {/* <DataTableRow className="nk-tb-sessions">
                    <span>Assets</span>
                </DataTableRow> */}
                <DataTableRow className="nk-tb-sessions">
                    <span>Total return</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-change">
                    <span>Max drawdown</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-change">
                    <span>Std. dev</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-trend tb-col-sm text-end">
                    <span>Performance</span>
                </DataTableRow>
            </DataTableHead>
            {data.map((item) => {

                const color = chroma(item.color);
                const chartData = {
                    labels: item.botStats?.backtest?.performance?.balanceHistory.map(a => (a.time)),
                    // dataUnit: "People",
                    datasets: [
                        {
                            label: item.name,
                            lineTension: 0,
                            borderWidth: 2,
                            fill: true,
                            color: item.color,
                            backgroundColor: color.alpha(0.25).css(),
                            backgroundColor: "transparent",
                            borderColor: item.color,
                            pointRadius: "0",
                            data: item.botStats?.backtest?.performance?.balanceHistory.map(a => (a.value)),
                        },
                    ],
                };
                return (
                    <DataTableItem className="nk-tb-item" key={item.id}>
                        <DataTableRow className="nk-tb-channel" style={{
                            width: "60px"
                        }}>
                            <Link to={`/bot/${item?._id}`}>
                                <UserAvatar style={{
                                    background: `${item.color}`,
                                }} className="sq" text={findUpper(item.name)} />
                            </Link>
                        </DataTableRow>
                        {/* <DataTableRow className="nk-tb-sessions">
                            <span className="tb-sub tb-amount">
                                <ul className="project-users g-1">
                                    {item.symbols.slice(0, 1).map((symbol) => {
                                        return (
                                            <li>
                                                <AssetLogo symbol={symbol} />
                                            </li>
                                        );
                                    })}
                                    {item.symbols.length > 1 && (
                                        <li>
                                            <UserAvatar style={{
                                                width: 28, height: 28,
                                                fontSize: 12
                                            }} theme="light" size="xs" text={`+${item.symbols.length - 1}`} />
                                        </li>
                                    )}
                                </ul>
                            </span>
                        </DataTableRow> */}
                        <DataTableRow className="nk-tb-sessions">
                            <span className="tb-sub tb-amount">
                                <span>
                                    <label className={`change ${item?.botStats?.portfolioChange > 0 ? "up" : item?.botStats?.portfolioChange < 0 ? "down" : ""}`}>
                                        <Icon name={`${item?.botStats?.portfolioChange > 0 ? "arrow-long-up" : item?.botStats?.portfolioChange < 0 ? "arrow-long-down" : ""}`}></Icon>
                                        {strings.percentSignFormatter(item?.botStats?.portfolioChange)}
                                    </label>
                                </span>
                            </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-change">
                            <span className="tb-sub">
                                <span>
                                    <label className="">{strings.percentSignFormatter(item?.botStats?.maxDD)}</label>
                                </span>
                            </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-change">
                            <span className="tb-sub">
                                <span>
                                    <label>{strings.percentSignFormatter(item?.botStats?.stdDev)}</label>
                                </span>
                            </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-trend text-end">
                            <div className="traffic-channel-ck ms-auto" style={{
                                width: mobileView ? 'auto' : problemBreak ? 'auto' : '130px'
                            }}>
                                <WPCharts data={chartData} />
                            </div>
                        </DataTableRow>
                    </DataTableItem>
                );
            })}
        </div>
    )
}

export default TrendedBots;