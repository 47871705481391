import { Badge, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { useMyStore } from './ReactFlowBuilder/store';
import { useEffect, useState } from "react";
import { parseFlowSteps } from "../../../api";
import Markdown from 'react-markdown'
import { Icon, InputSwitch, PreviewCard, RSelect } from "../../../components/Component";
import { intervalOptions } from "../../strategies/BacktestFormModal";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from "remark-gfm";
import FlowDescription from "./FlowDescription";
import Autocomplete from "../../../components/autocomplete/Autocomplete";
import { HexColorPicker } from "react-colorful";
import CustomColorPicker from "../../../components/CustomColorPicker";

const SYMBOL_LIMIT = 3;
const GeneralCard = ({ }) => {
    const { botPrivate, toggleBotPrivate, botColor, setBotColor, interval, setInterval, symbols, setSymbols, botName, setBotName, setTab, setNewFlow } = useMyStore()

    return (
        <PreviewCard id="bot-general-card" className="h-100">
            <CardTitle>

                <h6>General</h6>
            </CardTitle>
            {/* <CardBody> */}
            <Row className={"g-2"}>
                <Col lg="12">
                    <div className="form-group">
                        <div className="custom-control custom-control-sm custom-checkbox" >
                            <input
                                type="checkbox"
                                className="form-control custom-control-input"
                                id="private"
                                name="private"
                                checked={botPrivate}
                                onChange={(e) => toggleBotPrivate()}
                            />
                            <label className="custom-control-label" htmlFor="private">private</label>
                        </div>
                    </div>
                </Col>
                <Col lg="8" md={6}>
                    <div className="form-group">
                        <label className="form-label">Bot name</label>
                        <input
                            type="text"
                            // {...register('name', { required: "This field is required" })}
                            value={botName}
                            placeholder="Give your bot a name"
                            onChange={(e) => {
                                e.preventDefault();
                                setBotName(e.target.value)
                            }}
                            className="form-control" />
                        {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                    </div>
                </Col>
                {/* <Col lg={4} md="6">
                    <CustomColorPicker />
                </Col> */}
                <Col lg="4" md={6}>
                    <div className="form-group">
                        <label className="form-label">Version</label>
                        <input
                            type="text"
                            // {...register('name', { required: "This field is required" })}
                            value={"V1"}
                            // onChange={(e) => {
                            //     e.preventDefault();
                            //     setBotName(e.target.value)
                            // }}
                            disabled
                            className="form-control" />
                        {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                    </div>
                </Col>
                < Col lg={12} >
                    <div className="form-group">
                        <label className="form-label">Interval</label>
                        <RSelect
                            // className="form-control form-control-sm"
                            value={interval} onChange={(v) => {
                                setInterval({ interval: v })
                            }} options={intervalOptions} />
                    </div>
                </Col >
                <Col lg="8">
                    <div className="form-group">
                        <label className="form-label">{symbols?.length < SYMBOL_LIMIT ? "Add assets to trade with" : `Maximum of ${SYMBOL_LIMIT} assets in free plan.`}</label>
                        {symbols?.length < SYMBOL_LIMIT && <Autocomplete
                            resetSearch={true}
                            onClickOption={(event, option) => {
                                if (!symbols.includes(option)) {
                                    setSymbols([...symbols, option]);
                                    setTab(option);
                                    setNewFlow({ symbol: option })
                                }
                            }}
                        />}
                    </div>
                </Col>
            </Row >
            {/* </CardBody> */}
        </PreviewCard >
    )
}

export default GeneralCard