import React, { memo, useEffect, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import Autocomplete from '../../../../../components/autocomplete/Autocomplete';
import { Icon, InputSwitch, NSComponent, RSelect, TooltipComponent } from '../../../../../components/Component';
import { useCallback } from 'react';
import useStore from '../../../../../store/reactFlow';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import LimitConnections from '../Handlers/LimitConnections';
import ExitStrategyType from '../../ExitStrategyType';
import { defaultExitStrategy } from '../constants';
import strings from '../../../../../utils/Strings';
import { useMyStore } from '../../../../bots/trading-bot-new/ReactFlowBuilder/store';

const operatorOptions = [
  { label: "Greater than", value: ">" },
  { label: "Less than", value: "<" },
  { label: "Equals", value: "==" },
]

function ExitStrategyNode({ id, data, selected }) {
  const { state } = data;
  const { updateNode, toggleIsOpen, deleteNode, tab, getExitStrategyNodeStr } = useMyStore(({ updateNode, toggleIsOpen, deleteNode, tab, getExitStrategyNodeStr }) => ({ updateNode, toggleIsOpen, deleteNode, tab, getExitStrategyNodeStr }));

  useEffect(() => {
    if (!state?.takeProfit?.checked && !state?.stopLoss?.checked) {
      deleteNode({ id, symbol: tab });
    }
  }, [state]);

  const onChangeState = (key, value) => {
    let t = { ...state }
    t[key] = value;
    t.str = getExitStrategyNodeStr({ ...t });
    updateNode({ id, data: { state: t }, symbol: tab })
  }

  const onChangeStrategyTypeState = (key, changes) => {
    let t = { ...state };
    t[key] = { ...t[key], ...changes }
    onChangeState(key, t[key])
  }

  return (
    <Card className="card-bordered order" style={{
      borderColor: selected ? "#9d72ff" : ""
    }}>
      <CardHeader>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Icon onClick={() => toggleIsOpen({ id, data, symbol: tab })} name={data?.isOpen ? "minimize-alt" : "maximize-alt"} className={"hide-png"} style={{
            cursor: "pointer",
            alignSelf: "flex-end"
          }} />
          <h5 className="card-title">Exit strategy</h5>
          {getExitStrategyNodeStr({ ...data.state })}
        </div>
      </CardHeader>
      <Collapse
        className="accordion-body"
        isOpen={data?.isOpen}
      >
        <CardBody>

          <form>
            <div className="invest-ov gy-2">
              <div className="form-group">
                <div className="custom-control custom-switch nodrag" style={{
                  marginBottom: 8
                }}>
                  <InputSwitch id="takeProfit" label={<b>Take profit</b>} checked={data?.state?.takeProfit?.checked} onClick={(e) => {
                    e.preventDefault();
                    onChangeStrategyTypeState("takeProfit", { checked: !data?.state?.takeProfit?.checked })
                  }} />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    <b>Profit type</b>
                  </label>
                  <div className="form-control-wrap">
                    <ExitStrategyType exitStrategyType={data?.state?.takeProfit}
                      setExitStrategyType={(type) => onChangeStrategyTypeState("takeProfit", { type })}
                    />
                  </div>
                </div>
                <div className="form-group nodrag">
                  <label className="form-label">
                    <b>Value</b>
                  </label>
                  <NSComponent disabled={!data?.state?.takeProfit?.checked} defaultVal={data?.state?.takeProfit?.value} onChange={(value) => {
                    onChangeStrategyTypeState("takeProfit", { value })
                  }} />
                </div>
              </div>
            </div>
            <div className="invest-ov gy-2">
              <div className="form-group">
                <div className="custom-control custom-switch" style={{
                  marginBottom: 8
                }}>
                  <InputSwitch id="stopLoss" onClick={(e) => {
                    onChangeStrategyTypeState("stopLoss", { checked: !data?.state?.stopLoss?.checked })
                  }} label={<b>Stop loss</b>} checked={data?.state?.stopLoss?.checked} />
                </div>
                <div style={{
                  float: "right",
                  cursor: "default",
                }}
                >
                  <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="stopLoss-tooltip"
                    text="Close the position when loosing"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    <b>Loss type</b>
                  </label>
                  <div className="form-control-wrap">
                    <ExitStrategyType exitStrategyType={data?.state?.stopLoss}
                      setExitStrategyType={(type) => onChangeStrategyTypeState("stopLoss", { type })}
                    />
                  </div>
                </div>
                <div className="form-group nodrag">
                  <label className="form-label">
                    <b>Value</b>
                  </label>
                  <NSComponent disabled={!data?.state?.stopLoss?.checked} defaultVal={data?.state?.stopLoss?.value} onChange={(value) => {
                    onChangeStrategyTypeState("stopLoss", { value })
                  }} />
                </div>
              </div>
            </div>
          </form>
        </CardBody>
      </Collapse>

      {/* <LimitConnections type="source" position={Position.Right} isConnectable={({ node, connectedEdges }) => {
        for (let i = 0; i < connectedEdges.length; i++) {
          if (connectedEdges[i].source === node.id) return false;
        }
        return true;
      }} />
      <LimitConnections type="target" position={Position.Left} isConnectable={({ node, connectedEdges }) => {
        for (let i = 0; i < connectedEdges.length; i++) {
          if (connectedEdges[i].target === node.id) return false;
        }
        return true;
      }} /> */}
      <LimitConnections className="rl" type="target" position={Position.Top} />
    </Card>
  )
}

export default memo(ExitStrategyNode);
