
import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content.js";
import Head from "../../layout/head/Head.js";
import { Block, BlockBetween, BlockContent, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewAltCard, PreviewCard } from "../../components/Component.js";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import "../../../node_modules/swiper/swiper.scss";
import { GET_PUBLIC_BOTS } from "../../api/graphql.js";
import { useQuery } from "@apollo/client";
import SpinnerWrapper from "../../components/SpinnerWrapper.js";
import useAuth from "../../hooks/useAuth.js";
import TrendedBots from "../../components/TrendedBots.js";
import PostFeedCard from "../../components/PostFeedCard.js";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { getStockNewsWithSentiment } from "../../api/index.js";
import { getThirds } from "../../utils/Utils.js";
import "./index.css";
import { Helmet } from "react-helmet";

const Home = ({ ...props }) => {
  const { data, refetch, fetchMore, loading, error } = useQuery(GET_PUBLIC_BOTS, {
    variables: {
      filter: { private: false, copyBotId: null },

    },
    limit: 10
  });

  const { user } = useAuth()
  const [newsData, setNewsData] = useState([]);
  const [isLoadingNews, setIsLoadingNews] = useState(false);

  useEffect(() => {
    const loadNews = async () => {
      setIsLoadingNews(true);
      getStockNewsWithSentiment({ page: 0 },
        (res) => {
          setNewsData(res)
          setIsLoadingNews(false)
        }, (err) => {
          console.error(err)
          setIsLoadingNews(false)
        })
      // }
    }

    loadNews();
  }, [
    // newsType
  ]);

  const thirds = getThirds(newsData);

  if (loading) return <SpinnerWrapper />

  const filtered = data?.botMany?.filter(b => (b?.botStats && b?.botStats?.portfolioChange > 0)).sort((a, b) => (b.botStats.portfolioChange - a.botStats.portfolioChange))

  return (
    <>
      <Head title="Home" />
      <Helmet>
        <script src="https://www.googletagmanager.com/gtag/js?id=G-7YTFHT0CJ8" async />
      </Helmet>
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-7YTFHT0CJ8');
        `
        }]}
      />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {user ? `Welcome ${user?.displayName} 🥷🏻` : `Welcome 🥷🏻`}
              </BlockTitle>
              {/* <BlockDes className="text-soft">
                <p>New day, new ideas</p>
              </BlockDes> */}
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Row className="g-gs">
          <Col lg={6}>
            <Card className="card-bordered h-100">
              <div className="card-inner mb-n2">
                <div className="card-title-group align-start pb-3 g-2">
                  <div className="card-title card-title-sm">
                    <h6 className="title">Trending bots</h6>
                    <p>Get some ideas from the best.</p>
                  </div>
                </div>
              </div>
              {filtered && <TrendedBots data={filtered} />}
            </Card>
          </Col>
          <Col lg="6">

            <PreviewAltCard className="card-full">
              <div className="card-title card-title-sm">
                <h6 className="title">Top news</h6>
              </div>
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                }}
              >
                <div className="carousel-inner">
                  {thirds?.map((value, i) => {
                    return (
                      <SwiperSlide>
                        <div className="carousel-item active" data-bs-interval="500">
                          {value.map(i => ({ ...i, sentiment_label: ((i.sentiment_score < 0.1 && i.sentiment_score > -0.1) ? "neutral" : (i.sentiment_score > 0.1 ? "bullish" : "bearish")) })).map(v => {
                            return (
                              <div className="mb-2">
                                <PostFeedCard item={v} />
                              </div>
                            )
                          })
                          }

                        </div>
                      </SwiperSlide>
                    )
                  })}
                </div>
              </Swiper>

            </PreviewAltCard>

          </Col>
        </Row>
      </Content>
    </>
  );
};

export default Home;
