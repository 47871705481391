import { useRef } from 'react';
import Cat from './Cat';
import { ReactFlowProvider } from 'reactflow';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { useMyStore } from './store';
import { PreviewCard } from '../../../../components/Component';

export const CatWrapper = ({ }) => {
    const { tab, setTab } = useMyStore()

    return (
        <PreviewCard id="react-flow-builder-card" style={{
            height: "calc(100vh - 130px)"
        }}>
            <CardTitle>
                <h6>{tab} flow builder</h6>
            </CardTitle>
            {tab && (
                <ReactFlowProvider>
                    <Cat symbol={tab} addCustomOrderNode={tab} />
                </ReactFlowProvider>
            )}
        </PreviewCard>
    )
}