import { useEffect, useState } from "react";
import { useTheme } from "../layout/provider/Theme";


const AssetLogo = ({ symbol, style }) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(true);

  // keep track whether imageurl returns valid result
  const [isValid, setIsValid] = useState(null);
  const imageUrl = `https://financialmodelingprep.com/image-stock/${symbol}.png`;
  // use effect to check for access to imageUrl,
  // run the effect when the component mounts
  useEffect(() => {
    fetch(imageUrl).then(res => {
      setIsValid(res.status === 200);
      setLoading(false);
    }).catch(err => { });
  }, []);

  return (
    <>
      {
        !isValid ? (<></>) : (
          !loading && (
            <img src={imageUrl} style={{
              height: 30,
              width: 30,
              padding: 4,
              borderRadius: 16,
              ...style
            }}
              className={theme?.skin === "dark" ? 'bg-secondary-dim' : 'bg-primary-dim'}
            />
          )
        )
      }
    </>
  )
}

export default AssetLogo;