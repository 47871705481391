import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import News from "../news/News";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";

import { useTheme, useThemeUpdate } from '../provider/Theme';
import Autocomplete from "../../components/autocomplete/Autocomplete";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { Button, Spinner } from "reactstrap";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const Header = ({ fixed, className, ...props }) => {

  const theme = useTheme();
  const navigate = useNavigate();
  const themeUpdate = useThemeUpdate();

  const { user, isLoggingIn } = useAuth();

  const onClickOption = (event, option) => {
    navigate(`/one-pager/${option}`);
  }

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap" style={{
          height: '64px'
        }}>
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1"
              icon="menu"
              click={themeUpdate.sidebarVisibility}
            />
          </div>
          <div className="nk-header-brand d-xl-none">
            <Logo />
          </div>
          <div className="nk-header-news">
            <Autocomplete onClickOption={onClickOption} />
          </div>
          <div className="nk-header-tools">
            {isLoggingIn ? <Spinner size="sm" type="grow" /> : user ? < ul className="nk-quick-nav">
              <li className="user-dropdown">
                <User />
              </li>
              {/* <li className="notification-dropdown me-n1">
                <Notification />
              </li> */}
            </ul> : (
              <ul className="nk-quick-nav">
                <li className="user-dropdown">
                  <Button onClick={() => {
                    navigate('/login');
                  }}>Open free account</Button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};
export default Header;
