import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Icon,
  Button,
  Col,
  RSelect,
} from "../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Badge,
  Spinner
} from "reactstrap";
import { useFieldArray, useForm } from "react-hook-form";
import Autocomplete from "../../components/autocomplete/Autocomplete";

const FormModal = ({ modal, closeModal, onSubmit, formData, setFormData, modalType, isLoading }) => {

  useEffect(() => {
    reset(formData)
  }, [formData]);

  const { control, reset, register, handleSubmit, formState: { errors } } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'symbols',
    rules: {
      required: true
    }
  })


  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" && "Add watchlist"} {modalType === "edit" && "Update watchlist"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    {...register('name', { required: "This field is required" })}
                    value={formData.name}
                    placeholder="Enter name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="form-control" />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col xs="12">
                <div className="form-group">
                  <label className="form-label">{formData?.symbols?.length < 5 ? "Search to add tickers" : "Maximum of 5 tickers per watchlist at the moment."}</label>
                  {formData?.symbols?.length < 5 && <Autocomplete
                    resetSearch={true}
                    onClickOption={(event, option) => {
                      if (!formData.symbols.includes(option))
                        setFormData({ ...formData, symbols: [...formData.symbols, option] })
                    }} />}
                </div>
              </Col>
              <Col xs="12">
                <h6 className="form-label">Tickers</h6>
                <ul className="preview-list">
                  {formData?.symbols?.map((v, i) => {
                    return (
                      <li className="preview-item">
                        <Badge style={{
                          padding: 8,
                        }}>{v}<span style={{
                          color: "primary",
                          cursor: "pointer",
                          marginLeft: 8,
                        }} onClick={() => {
                          let t = [...formData.symbols];
                          t.splice(i, 1);
                          setFormData({ ...formData, symbols: t })
                        }}>x</span></Badge>
                      </li>
                    )
                  })}
                  <li className="preview-item">
                    {errors?.symbols?.length === 0 && <span className="text-danger">Add at least one ticker!</span>}
                  </li>
                </ul>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {isLoading && <Spinner size="sm" type="grow" />}
                      {modalType === "add" && "Add watchlist"} {modalType === "edit" && "Update watchlist"}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FormModal;
