import React, { useContext, useState, useEffect } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";

import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import {
  BlockHead,
  BlockDes,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Button,
  Block,
  Row,
  Col,
  PaginationComponent,
  PreviewAltCard,
  PreviewCard,
  NSComponent,
  InputSwitch,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge, Spinner, CardTitle, CardBody } from "reactstrap";
import { useQuery } from "@apollo/client";
import { GET_ALL_FEED_POSTS } from "../../api/graphql";
import { screenStocks } from "../../api";
import AssetLogo from "../../components/AssetLogo";
import strings from "../../utils/Strings";
import { dataTableColumns, stockScreenColumns } from "../components/table/TableData";
import ScreenDataTable from "./ScreenDataTable";
import "./screener.css";
import { NumericFormat } from "react-number-format";
import Filters from "./Filters";

const defaultScreenFilters = {
  marketCapMoreThan: 1000000,
  marketCapLowerThan: 100000000,
  priceMoreThan: null,
  priceLowerThan: null,
  betaMoreThan: null,
  betaLowerThan: null,
  volumeMoreThan: null,
  volumeLowerThan: null,
  dividendMoreThan: null,
  dividendLowerThan: null,
  isEtf: false,
  isFund: false,
  isActivelyTrading: false,
  sector: [],
  industry: [],
  country: [],
  exchange: [],
  limit: null,
}

const Screener = () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(defaultScreenFilters);
  
  const [smOption, setSmOption] = useState(false);
  const [view, setView] = useState(false);
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(8);
  const [files, setFiles] = useState([]);

  const [isLoadingScreener, setIsLoadingScreener] = useState(false);
  const loadScreener = async () => {
    setIsLoadingScreener(true);
    const formatted = {
      ...filters,
      sector: filters.sector.map(i => i.value).join(','),
      industry: filters.industry.map(i => i.value).join(','),
      exchange: filters.exchange.map(i => i.value).join(','),
      country: filters.country.map(i => i.value).join(','),
    }
    screenStocks(formatted,
      (res) => {
        setData(res)
        setIsLoadingScreener(false)
      }, (err) => {
        console.error(err)
        setIsLoadingScreener(false)
      })
  }
  useEffect(() => {
    loadScreener();
  }, []);

  //scroll off when sidebar shows
  useEffect(() => {
    view ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [view])

  const toggle = () => {
    setView(!view);
  };

  // filter text
  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  return <>
    <Head title="Screener"></Head>
    <Content>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>Stock screener</BlockTitle>
          <BlockDes className="text-soft">
                <p>Let's find you the stock of your dreams</p>
              </BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <Row className={"g-gs"}>
          <Col lg={4}>
            <Filters filters={filters} setFilters={setFilters} loadScreener={loadScreener} />
          </Col>
          <Col lg={8}>
            {!isLoadingScreener ? <ScreenDataTable fileName="stock-screener" searchField="companyName" data={data} columns={stockScreenColumns} pagination actions /> : <Spinner />}
          </Col>
        </Row>
      </Block>
      {view && <div className="toggle-overlay" onClick={toggle}></div>}
    </Content>
  </>;
};
export default Screener;
