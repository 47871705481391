import React, { useCallback, useRef, forwardRef } from 'react';
import { useReactFlow } from 'reactflow';
import useStore from '../../../../store/reactFlow';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter';
import { useMyStore } from '../../../bots/trading-bot-new/ReactFlowBuilder/store';

const ContextMenu = ({ }) => {
    const {
        duplicateNode,
        deleteNode,
        setMenu,
        menu,
        addExitStrategyNode,
        addConditionGroupNode,
        addLogicalConditionGroupNode,
        addConditionNode,
        addConditionalExitStrategyNode,
        tab,
    } = useMyStore(({ duplicateNode,
        deleteNode,
        setMenu,
        menu,
        addExitStrategyNode,
        addConditionGroupNode,
        addLogicalConditionGroupNode,
        addConditionNode,
        addConditionalExitStrategyNode,
        tab,
    }) => ({
        duplicateNode,
        deleteNode,
        setMenu,
        menu,
        addExitStrategyNode,
        addConditionGroupNode,
        addLogicalConditionGroupNode,
        addConditionNode,
        addConditionalExitStrategyNode,
        tab,
    }));
    const {
        id,
        top,
        left,
        right,
        bottom,
        ...props
    } = menu;

    return (
        <div
            style={{ top, left, right, bottom }}
            className="dropdown-preview context-menu"
            {...props}
        // ref={ref}
        >
            <DropdownMenu >
                {
                    props.type === "order" && (
                        <ul className="link-check">

                            <li><span>Order actions</span></li>
                            <li><a href="#links" onClick={(ev) => {
                                ev.preventDefault();
                                addConditionGroupNode({ event: ev, parentId: id, symbol: tab })
                                setMenu({ menu: null, symbol: tab })
                            }}>Add enter position conditions</a></li>
                            <li><a href="#links" onClick={(ev) => {
                                ev.preventDefault();
                                addExitStrategyNode({ event: ev, parentId: id, symbol: tab })
                                setMenu({ menu: null, symbol: tab })
                            }}>Add simple exit strategy</a></li>
                            <li><a href="#links" onClick={(ev) => {
                                ev.preventDefault();
                                addConditionalExitStrategyNode({ event: ev, parentId: id, symbol: tab })
                                setMenu({ menu: null, symbol: tab })
                            }}>Add conditional exit strategy</a></li>
                        </ul>
                    )
                }
                {
                    props.type === "conditionGroup" && (
                        <ul className="link-check">

                            <li><span>Condition group actions</span></li>
                            <li><a href="#links" onClick={(ev) => {
                                ev.preventDefault();
                                addConditionNode({ event: ev, parentId: id, symbol: tab })
                                setMenu({ menu: null, symbol: tab })
                            }}>Add condition</a></li>
                            <li><a href="#links" onClick={(ev) => {
                                ev.preventDefault();
                                addLogicalConditionGroupNode({ event: ev, parentId: id, symbol: tab })
                                setMenu({ menu: null, symbol: tab })
                            }}>Add condition group</a></li>
                        </ul>
                    )
                }
                {
                    props.type === "condition" && (
                        <ul className="link-check">

                            <li><span>Condition actions</span></li>
                            <li><a href="#links" onClick={(ev) => {
                                ev.preventDefault();
                                addConditionNode({ event: ev, parentId: id, symbol: tab, type: "logical" })
                                setMenu({ menu: null, symbol: tab })
                            }}>Add condition</a></li>
                        </ul>
                    )
                }
                <ul className="link-check">
                    <li><span>Node actions</span></li>
                    <li><a href="#links" onClick={(ev) => {
                        ev.preventDefault();
                        duplicateNode({ id, symbol: tab })
                        setMenu({ menu: null, symbol: tab })
                    }}>Duplicate</a></li>
                    <li><a href="#links" onClick={(ev) => {
                        ev.preventDefault();
                        deleteNode({ id, symbol: tab })
                        setMenu({ menu: null, symbol: tab })
                    }}>Delete</a></li>
                </ul>
            </DropdownMenu>
        </div>
    );
};

export default ContextMenu;