import React, { useState } from "react";
import Icon from "../../../icon/Icon";
import Button from "../../../button/Button";
import { UncontrolledDropdown, CardTitle, DropdownMenu, DropdownItem, DropdownToggle, ModalHeader, Modal, ModalBody, Row, Col } from "reactstrap";
import { faChess } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StrategyCenter = ({addStrategy}) => {
  const [showExplainModal, setShowExplainModal] = useState(false);
  const toggle = () => {
    setShowExplainModal(!showExplainModal);
  };
  return (
    <React.Fragment>
      <div className="card-inner-group">
        <div className="card-inner card-inner-md">
          <div className="card-title-group mt-1 mb-1">
            <CardTitle>
              <h6>Let's traid</h6>
            </CardTitle>
            <div className="card-tools">
              <span className="title" style={{
                cursor: 'pointer'
              }} onClick={() => {
                setShowExplainModal(true);
              }}><Icon name="help-fill" /> What's a strategy?</span>
              
            </div>
            {/* <div className="card-tools me-n1">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="setting"></Icon>
                        <span>Action Settings</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="notify"></Icon>
                        <span>Push Notification</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> */}
          </div>
        </div>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              {/* <span className="nk-menu-icon" onClick={addStrategy} style={{
                fontSize: 24,
                paddingRight: 12,
                position: "absolute",
                left: 0,
                cursor: 'pointer'
              }} ><FontAwesomeIcon icon="chess" /></span> */}
              <Icon name="bulb-fill"></Icon>
              <div onClick={addStrategy} style={{
            cursor: 'pointer'
          }}  className="title">Create a startegy</div>
              <p>
                Create a strategy from <strong>scratch</strong> or choose a template from our <strong>traid hub</strong>. We've templated more than <strong>10 new</strong> strategies to get you up and running in no time.
              </p>
            </div>
            {/* <Button className="btn-icon btn-trigger me-n2">
              <Icon name="forward-ios"></Icon>
            </Button> */}
          </div>
        </div>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
            <Icon name="meter" />
              <div className="title">Backtest</div>
              <p>
                Backtest your strategies over the past <strong>30 years</strong> with just few click
              </p>
            </div>
            {/* <Button className="btn-icon btn-trigger me-n2">
              <Icon name="forward-ios"></Icon>
            </Button> */}
          </div>
        </div>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="layers-fill" />
              <div className="title">Optimize</div>
              <p>
                Optimize your strategy with our AI ninja
              </p>
              <p><strong>coming soon...</strong></p>
            </div>
            {/* <Button disabled className="btn-icon btn-trigger me-n2">
              <Icon name="forward-ios"></Icon>
            </Button> */}
          </div>
        </div>
      </div>
      <Modal isOpen={showExplainModal} toggle={toggle} className="modal-md">
      <ModalHeader close={
          <button className="close" onClick={() => setShowExplainModal(false)}>
            <Icon name="cross" />
          </button>
        }>
          What is an investment strategy? 💰
        </ModalHeader>
      <ModalBody>
        <Row xs={12}>
          <Col>

<p>Investment strategy is like a game plan for how you're going to invest your money to make it grow over time. It involves deciding things like:</p>

<ol>
  <li><b>Goals:</b> What do you want to achieve with your investments? Whether it's saving for retirement, buying a house, or just growing your wealth, your goals will guide your strategy.</li>
  
  <li><b>Risk Tolerance:</b> How comfortable are you with the possibility of losing money? Some investments are riskier than others, so your strategy will depend on how much risk you're willing to take.</li>
  
  <li><b>Asset Allocation:</b> This is about spreading your money across different types of investments, like stocks, bonds, real estate, or even cash. Diversifying helps manage risk.</li>
  
  <li><b>Time Horizon:</b> How long do you plan to invest for? Your time horizon will affect the types of investments you choose and how you manage them.</li>
  
  <li><b>Backtesting:</b> Backtesting involves testing a trading strategy on historical data to see how it would have performed in the past. It helps investors assess the viability and potential risks of their strategies before implementing them in real markets.</li>
</ol>
<br/>
<br/>

<p><b>This is where <span className="text-primary">traid 🥷🏻</span></b> comes in!</p>
<p>We've created an e2e platform designed to streamline this process for non-coders, enabling everyone to chase their dreams 🍻</p>
</Col>
        </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default StrategyCenter;
