import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";
import PlanS1 from "../../../images/icons/plan-s1.svg";
import PlanS2 from "../../../images/icons/plan-s2.svg";
import PlanS3 from "../../../images/icons/plan-s3.svg";
import { buyAndHoldMAGMA } from "./flows/Flows";

const data = {
  navigation : [
    {
      id: 0,
      text: "Home",
      icon: "home-alt",
      link: "",
    },
    // {
    //   id: 1,
    //   text: "Files",
    //   icon: "file-docs",
    //   link: "/files",
    //   sub: ["New Files", "This Month", "Older Files"],
    // },
    // {
    //   id: 2,
    //   text: "Starred",
    //   icon: "star",
    //   link: "/starred",
    // },
    // {
    //   id: 3,
    //   text: "Shared",
    //   icon: "share-alt",
    //   link: "/shared",
    // },
    // {
    //   id: 4,
    //   text: "Recovery",
    //   icon: "trash",
    //   link: "/recovery",
    // },
    // {
    //   id: 5,
    //   text: "Settings",
    //   icon: "setting",
    //   link: "/settings",
    // },
  ],
  users: [
    { id:'uid001', name: "Illiash Hossain", theme: "purple" },
    { id:'uid002', name: "Abu Bin", theme: "blue" },
    { id:'uid003', name: "Hao Limae", theme: "purple", displayPicture: User },
    { id:'uid004', name: "Kimberly May", theme: "purple", displayPicture: User2 },
    { id:'uid005', name: "Courier Kay", theme: "purple", displayPicture: User4 },
  ],
  plans:[
    {
      id: 'planid01',
      title: "Starter",
      logo: PlanS1,
      desc: "If you are a small business amn please select this plan",
      amount: 99,
      memory: 25,
      userNumber: 1,
      tags: false,
    },
    {
      id: 'planid02',
      title: "Pro",
      logo: PlanS2,
      desc: "If you are a small business amn please select this plan",
      amount: 299,
      userNumber: 5,
      memory: 50,
      tags: true,
    },
    {
      id: 'planid03',
      title: "Enterprise",
      logo: PlanS3,
      desc: "If you are a small business amn please select this plan",
      amount: 599,
      userNumber: 20,
      memory: 75,
      tags: false,
    },
    {
      id: 'planid04',
      title: "Premium",
      logo: PlanS1,
      desc: "If you are a small business amn please select this plan",
      amount: 999,
      memory: 100,
      userNumber: "Unlimited",
      tags: false,
    },
  ],
  folderTypes : [
    {
      id: 1,
      value: "general",
      label: "General",
    },
    {
      id: 2,
      value: "shared",
      label: "Shared",
    },
    {
      id: 3,
      value: "secure",
      label: "Secure",
    },
  ],
  dateFormat:[
    {
      id: 0,
      label: "MM/DD/YYYY",
      value: "MM/DD/YYYY",
    },
    {
      id: 1,
      label: "DD/MM/YYYY",
      value: "DD/MM/YYYY",
    },
    {
      id: 2,
      label: "YYYY/MM/DD",
      value: "YYYY/MM/DD",
    },
  ],
  languageOptions : [
    {
      id: 0,
      label: "English (United States)",
      value: "English (United States)",
    },
    {
      id: 1,
      label: "English (United Kingdom)",
      value: "English (United Kingdom)",
    },
    {
      id: 2,
      label: "French",
      value: "French",
    },
    {
      id: 3,
      label: "Spanish",
      value: "Spanish",
    },
    {
      id: 4,
      label: "Chinese",
      value: "Chinese",
    },
    {
      id: 5,
      label: "Bangla",
      value: "Bangla",
    },
  ],
  timezoneFormat : [
    {
      id: 0,
      label: "Bangladesh (GMT +6)",
      value: "Bangladesh (GMT +6)",
    },
    {
      id: 1,
      label: "United Kingdom (GMT +0)",
      value: "United Kingdom (GMT +0)",
    },
    {
      id: 2,
      label: "Spain (GMT +1)",
      value: "Spain (GMT +1)",
    },
    {
      id: 6,
      label: "China (GMT +8)",
      value: "China (GMT +8)",
    },
    {
      id: 4,
      label: "Australia (GMT +9)",
      value: "Australia (GMT +9)",
    },
    {
      id: 3,
      label: "Brazil (GMT -3)",
      value: "Brazil (GMT -3)",
    },
    {
      id: 5,
      label: "United States (GMT -8)",
      value: "United States (GMT -8)",
    },
  ],
}
export default data;

export const files = [
  {
    id: 'buyAndHoldMAGMA',
    name: 'Buy and hold MAGMA',
    ext: 'zip',
    time: '02:07 PM',
    description: 'Simple strategy that buys META, AMZN, AAPL, MSFT and GOOG evenly.',
    icon: 'coins',
    size: 87,
    type: 'simple',
    starred: true,
    access:['uid001', 'uid003', 'uid005'],
    flow: buyAndHoldMAGMA
  },
  {
    id: 'scalpingSPY',
    name: 'Scalping SPY with trend',
    ext: 'zip',
    time: '02:07 PM',
    description: 'Buy SPY when last 2 days down and 4 last candles up, take 2% profit, stop at 2% loss.',
    icon: 'bar-c',
    size: 87,
    type: 'simple',
    starred: true,
    access:['uid001', 'uid003', 'uid005']
  },
  {
    id: 'nvidiaRSI',
    name: 'NVDA RSI',
    ext: 'zip',
    time: '02:07 PM',
    description: 'Buy NVDA when RSI between 40 to 50 and sell between 80 to 90.',
    icon: 'line-chart',
    size: 87,
    type: 'indicator',
    starred: true,
    access:['uid001', 'uid003', 'uid005']
  },
]