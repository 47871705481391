import React, { memo, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import Autocomplete from '../../../../../components/autocomplete/Autocomplete';
import { NSComponent, RSelect } from '../../../../../components/Component';
import { useCallback } from 'react';
  
const operatorOptions = [
  {label: "Greater than", value: ">"},
  {label: "Less than", value: "<"},
  {label: "Equals", value: "=="},
]

function ValueNode({ id, data }) {
  const [value, setValue] = useState(0)

  return (
    <>
      <div>
        <input type="number" value={value} onChange={(e) => setValue(e.target.value)} />
      </div>
      <Handle type="target" position={Position.Left} /> 
    </>
  )
}

export default memo(ValueNode);
