import { useEffect, useState } from "react";

export const useMobileView = ({ breakPoint = 990 }) => {
    const [mobileView, setMobileView] = useState(false);
    const viewChange = () => {
        if (window.innerWidth < breakPoint) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    };

    useEffect(() => {
        viewChange();
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
        };
    }, []);

    return mobileView
}