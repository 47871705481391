import { useMutation, useQuery } from "@apollo/client";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { CREATE_WATCHLIST, DELETE_WATCHLIST, GET_USER_WATCHLISTS, UPDATE_WATCHLIST } from "../../api/graphql";
import { Block, Icon, PreviewAltCard } from "../../components/Component";
import SpinnerWrapper from "../../components/SpinnerWrapper";
import SubscriptionLimit from "../../components/SubscriptionLimit";
import useAuth from "../../hooks/useAuth";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { ModalDanger } from "../components/Modals";
import FormModal from "./FormModal";
import WatchlistTable from "./WatchlistTable";
import { getStockNews } from "../../api";
import WatchlistNews from "./WatchlistNews";

const initialWatchlist = {
  name: "Your watchlist",
  symbols: []
}
const Watchlists = ({ ...props }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState(initialWatchlist);
  const [editFormData, setEditFormData] = useState(initialWatchlist);
  const [createWatchlistMutationLink] = useMutation(CREATE_WATCHLIST);
  const [deleteWatchlistMutationLink] = useMutation(DELETE_WATCHLIST);
  const [updateWatchlistMutationLink] = useMutation(UPDATE_WATCHLIST);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [createWatchlistErrors, setCreateWatchlistErrors] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { data, refetch, fetchMore, loading, error } = useQuery(GET_USER_WATCHLISTS, {
    variables: {
      filter: { uid: user?.uid }
    }
  });
  const watchlists = data?.userOne?.watchlists;

  const onDeleteClick = (backtest) => {
    const title = "YO!";
    const desc = "Are you sure you want to delete this backtest?";

    setDeleteModalData({
      title,
      description: desc,
      cancelButtonText: "Back",
      idToDelete: backtest?._id,
      approveButtonColor: "danger",
      approveButtonText: "Delete",
      onClickDelete: async (id) => {
        await onApproveDelete(id)
      }
    });
    toggleDelete();
  }

  const onApproveDelete = async (id) => {
    try {
      setIsLoading(true);

      const res = await deleteWatchlistMutationLink({
        variables: { id: id },
        refetchQueries: [
          {
            query: GET_USER_WATCHLISTS,
            variables: {
              filter: { uid: user?.uid }
            }
          }
        ]
      },
      );
      sessionStorage.removeItem("lastActiveWatchlist")
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }


  const onEditClick = (item) => {
    setEditFormData({
      name: item.name,
      symbols: item.symbols,
      id: item._id
    });
    setModal({ edit: true })
  };

  const onFormSubmit = async (sData) => {
    try {
      setIsLoading(true);
      const { name, symbols } = sData;
      let submittedData = {
        name, symbols,
        userUID: user?.uid,
      };

      const res = await createWatchlistMutationLink({
        variables: { record: submittedData },
        refetchQueries: [
          {
            query: GET_USER_WATCHLISTS,
            variables: {
              filter: { uid: user?.uid }
            }
          }
        ]
      },
      );
      // setActivetab(res?.data?.watchlistCreateOne?.record?._id)
      resetForm();
      setIsLoading(false);
      setModal({ add: false });
      sessionStorage.setItem("lastActiveWatchlist", res?.data?.watchlistCreateOne?.record?._id)
    } catch (error) {
      let desc = "Somehing went wrong! please contact us at support@traid.ninja"
      if (error.message === "Reached watchlists limit!") {
        desc = "You are a true ninja 🥷🏻! looks like you need an upgrade.."
      }
      setCreateWatchlistErrors({
        title: error.message,
        description: desc,
        cancelButtonText: "Back",
        approveButtonText: "Upgrade now",
        approveButtonColor: "success",
        onClickDelete: () => navigate('/plans')
      });
      setIsLoading(false);
      setShowErrorModal(true);
    }
  };

  const resetForm = () => {
    setFormData(initialWatchlist);
  };

  // submit function to update a new item
  const onEditSubmit = async (sData) => {
    try {
      setIsLoading(true);
      const { name, symbols, id } = sData;
      let submittedData = {
        name, symbols
      };

      const res = await updateWatchlistMutationLink({
        variables: { record: submittedData, id },
        refetchQueries: [
          {
            query: GET_USER_WATCHLISTS,
            variables: {
              filter: { uid: user?.uid }
            }
          }
        ]
      },
      );

      setIsLoading(false);
      setModal({ edit: false });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setModal({ add: false })
    // resetForm();
  };

  const closeEditModal = () => {
    setModal({ edit: false })
    // resetForm();
  };

  useEffect(() => {
    if (data?.userOne?.watchlists) {
      setActivetab(sessionStorage.getItem("lastActiveWatchlist") || data?.userOne?.watchlists?.[0]?._id)
    }
  }, [data])



  const [activeTab, setActivetab] = useState(null);
  const toggle = (a) => {
    sessionStorage.setItem("lastActiveWatchlist", a)
    setActivetab(a)
  };
  if (loading) return <SpinnerWrapper />

  return (
    <>
      <Head title="Watchlists" />
      <Content>
        <Block>
          <SubscriptionLimit filter={user?.uid} type={"watchlist"} current={watchlists?.length} setModal={() => setModal({ add: true })} />
          <Row className="g-gs">
            <Col sm="12" lg="4">
              <PreviewAltCard bodyClass="d-flex flex-column">
                {watchlists?.length ? <Nav tabs style={{ overflowX: 'auto', overflowY: 'hidden' }} className=" d-flex flex-nowrap flex-row">
                  {watchlists?.map((w, i) => {
                    return (
                      <NavItem className='d-flex  flex-column'>
                        <NavLink
                          tag={"a"}
                          href="#tab"
                          className={classNames({ active: activeTab === w._id })}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle(w._id);
                          }}
                        >
                          {w.name}
                        </NavLink>
                      </NavItem>
                    )
                  })}
                </Nav> : <p>Add your first watchlist</p>}
                {<TabContent activeTab={activeTab}>
                  {watchlists?.map((w, i) => {
                    return activeTab === w._id ? (
                      <TabPane tabId={w._id}>
                        <Row className="g-gs">
                          <Col xs={12}>
                            <WatchlistTable data={w} />
                          </Col>
                          <Col xs={12}>
                            <div style={{
                              display: "flex",
                              gap: 8
                            }}>
                              <Button onClick={(e) => {
                                onEditClick(w)
                              }} color="primary">
                                <Icon name="edit" />
                                <span>Edit</span>
                              </Button>
                              <Button color="primary" onClick={(e) => {
                                onDeleteClick(w);
                              }}>
                                <Icon name="trash" />
                                <span>Delete</span>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    ) : <></>
                  })}
                </TabContent>}
              </PreviewAltCard>
            </Col>
            <Col xs={12} lg={8}>
              {data?.userOne?.watchlists && <WatchlistNews data={data?.userOne?.watchlists?.filter(w => w?._id === activeTab)?.[0]} />}
            </Col>
          </Row>
        </Block>
        <FormModal isLoading={isLoading} modal={modal.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} />
        <FormModal isLoading={isLoading} modal={modal.edit} modalType="edit" formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit} />
        {deleteModalData && <ModalDanger modalError={modalDelete} toggleError={toggleDelete} modalData={deleteModalData} />}
        {createWatchlistErrors && <ModalDanger modalError={showErrorModal} toggleError={() => setShowErrorModal(!showErrorModal)} modalData={createWatchlistErrors} />}
      </Content>
    </>
  )
};

export default Watchlists;
