import React from "react"

const Play = (props) =>{
    return(
        <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
            <path d="M436.2 178.3c-7.5-4.7-17.4-2.4-22.1 5.1-4.7 7.5-2.4 17.4 5.1 22.1 17.5 10.9 28 29.8 28 50.4s-10.5 39.5-28 50.4L155.7 470.7c-18.6 11.6-41.1 12.2-60.3 1.5-19.2-10.6-30.6-30.1-30.6-52V91.7c0-21.9 11.4-41.3 30.6-52 19.1-10.6 41.7-10.1 60.3 1.5l153.4 95.6c7.5 4.7 17.4 2.4 22.1-5.1 4.7-7.5 2.4-17.4-5.1-22.1L172.7 14c-28.6-17.9-63.3-18.7-92.9-2.4C50.3 28.1 32.7 58 32.7 91.7v328.6c0 33.7 17.6 63.7 47.1 80 14.1 7.8 29.3 11.7 44.5 11.7 16.7 0 33.4-4.7 48.4-14l263.5-164.3c27-16.8 43.1-45.9 43.1-77.7s-16.1-60.8-43.1-77.7z"></path>
        </svg>
    )
}

export default Play
