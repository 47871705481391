import {
  BaseEdge,
  EdgeLabelRenderer,
  getStraightPath,
  useReactFlow,
} from 'reactflow';
import { RSelect } from '../../../../../components/Component';
import { useState } from 'react';
import useStore from '../../../../../store/reactFlow';
import { useMyStore } from '../../../../bots/trading-bot-new/ReactFlowBuilder/store';

const logicalOptions = [
  { label: "AND", value: "and" },
  { label: "OR", value: "or" },
]

export default function LogicalEdge({ id, sourceX, sourceY, targetX, targetY, data, markerEnd }) {
  const { updateEdge, tab } = useMyStore(({ updateEdge, tab }) => ({ updateEdge, tab }));
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        <div className='nodrag' style={{
          position: 'absolute',
          transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          pointerEvents: 'all',
          width: 100,
          zIndex: 1001
        }}>
          <RSelect
            className="nodrag" options={logicalOptions} value={data.state.logicalType} onChange={(e) => {
              updateEdge({ id, data: { state: { ...data.state, logicalType: e } }, symbol: tab })
            }} />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
