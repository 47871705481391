
import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content.js";
import Head from "../../layout/head/Head.js";
import { Block, BlockBetween, BlockContent, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewAltCard, PreviewCard } from "../../components/Component.js";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import "../../../node_modules/swiper/swiper.scss";
import MarketMoversTable from "../../components/marketMovers/MarketMoversTable.js";
import UpcomingEarningCalls from "../../components/UpcomingEarningCalls.js";
import IPOCalendar from "../../components/partials/ipo-calendar/IPOCalendar.js";
import { Helmet } from "react-helmet";
import { GET_PUBLIC_BOTS } from "../../api/graphql.js";
import { useQuery } from "@apollo/client";
import SpinnerWrapper from "../../components/SpinnerWrapper.js";
import BotList from "../bots/BotList.js";
import useAuth from "../../hooks/useAuth.js";

const Explore = ({ ...props }) => {
  const { user } = useAuth();
  const { data, refetch, fetchMore, loading, error } = useQuery(GET_PUBLIC_BOTS, {
    variables: {
      filter: {
        private: false, copyBotId: null,
        "_operators": {
          "userUID": {
            "ne": user.uid
          }
        }
      }
    }
  });

  const [mode, setMode] = useState("list")

  if (loading) return <SpinnerWrapper />

  const filtered = data?.botMany?.filter(b => (b?.botStats?.backtest?.status === "done"))

  return (
    <>
      <Head title="Explore" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Explore 💡
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>New day, new ideas</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul style={{
                display: 'flex',
                gap: 8,
                alignItems: 'center',
              }}>
                <li className="nk-block-tools-opt">
                  <Button size="md" color={mode === "list" ? "primary" : "outline-light"} className="btn-icon btn-dim" onClick={() => setMode("list")}>
                    <Icon name="view-list-wd"></Icon>
                  </Button>
                </li>
                <li className="nk-block-tools-opt">
                  <Button size="md" color={mode === "cards" ? "primary" : "outline-light"} className="btn-icon btn-dim" onClick={() => setMode("cards")}><Icon name="grid-fill"></Icon></Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Row className="g-gs">
          <BotList botsData={filtered} mode={mode} />
        </Row>
      </Content>
    </>
  );
};

export default Explore;
