import ReactFlow, { Background, BackgroundVariant, Controls, MiniMap, Panel, useReactFlow } from "reactflow";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import ContextMenu from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/ContextMenu";
import ConditionNode from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Nodes/ConditionNode";
import OrderNode from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Nodes/OrderNode";
import ExitStrategyNode from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Nodes/ExitStrategyNode";
import ConditionGroupNode from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Nodes/ConditionGroupNode";
import ExplainNode from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Nodes/ExplainNode";
import LogicalEdge from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Edges/LogicalEdge";
import WhenEdge from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Edges/WhenEdge";
import ExitStrategyEdge from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Edges/ExitStrategyEdge";
import ConditionGroupEdge from "../../../strategies/StrategyPolicyBuilder/RuleBuilder/Edges/ConditionGroupEdge";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { useTheme } from "../../../../layout/provider/Theme";
import { useMyStore } from './store';

const nodeTypes = {
    condition: ConditionNode,
    order: OrderNode,
    exitStrategy: ExitStrategyNode,
    conditionGroup: ConditionGroupNode,
    explain: ExplainNode,
};

const edgeTypes = {
    'logical': LogicalEdge,
    'when': WhenEdge,
    'exitStrategy': ExitStrategyEdge,
    'conditionGroup': ConditionGroupEdge,
};

const Cat = ({ symbol, addCustomOrderNode }) => {
    const menuRef = useRef(null);
    const { deleteNode, setMenu, rfInstance, setRfInstance, onNodeClick, onMoveStart, onNodeContextMenu, menu, setReactFlowInstance, state, onNodesChange, onEdgesChange, onConnect, onDrop, onDragOver, onPaneClick, } = useMyStore();
    const ref = useRef(null);
    const [mobileView, setMobileView] = useState(false);
    const nodes = state?.[symbol]?.nodes || []
    const edges = state?.[symbol]?.edges || []
    const viewChange = () => {
        if (window.innerWidth < 990) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    };
    useOutsideAlerter(menuRef, () => setMenu({ menu: null, symbol }));
    const theme = useTheme();

    const isValidConnection = (connection) => {
        if (connection.source.includes("order_") && (connection.target.includes("conditionGroup_")) && ((connection?.sourceHandle === "right" && connection?.targetHandle === "left") || (connection?.sourceHandle === "bottom" && connection?.targetHandle === "top"))) {
            return true;
        }
        else if (connection.source.includes("order_") && (connection.target.includes("exitStrategy_"))) {
            return true;
        }
        else if (connection.source.includes("conditionGroup_") && (connection.target.includes("conditionGroup_"))) {
            return true;
        }
        else if (connection.source.includes("conditionGroup_") && (connection.target.includes("condition_") && connection?.sourceHandle === "right")) {
            return true;
        }
        else if (connection.source.includes("condition_") && connection.target.includes("condition_")) {
            return true;
        }

        return false;
    };

    const onNodesDelete =
        ({ deleted, symbol }) => {
            console.log(deleted,)
            // deleteNode({ id: deleted.id, symbol })
        }

    useEffect(() => {
        if (ref) {
            setRfInstance(ref);
        }
    }, [ref, setRfInstance])

    return (
        <div id="bot-flow-builder-container">
            <div className="dndflow">
                {/* <Sidebar /> */}
                <div className='reactflow-wrapper'>
                    <ReactFlow
                        ref={ref}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={changes => onNodesChange({ changes, symbol })}
                        onEdgesChange={changes => onEdgesChange({ changes, symbol })}
                        onConnect={connection => onConnect({ connection, symbol })}
                        onInit={setReactFlowInstance}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        isValidConnection={isValidConnection}
                        // panOnScroll
                        // selectionOnDrag
                        // panOnDrag={panOnDrag}
                        onPaneClick={e => onPaneClick({ e, symbol })}
                        fitView
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onNodeContextMenu={(event, node) => onNodeContextMenu({ event, node, ref, symbol })}
                        // onNodeClick={(event, node) => onNodeContextMenu({ event, node, ref, symbol })}
                        onNodeClick={e => mobileView ? () => { } : onNodeClick({ e, symbol })}
                        onMoveStart={e => onMoveStart({ e, symbol })}
                        onNodesDelete={(deleted) => onNodesDelete({ deleted, symbol })}
                    >
                        <Background
                            variant={BackgroundVariant.Dots}
                            style={{ backgroundColor: theme?.skin === "dark" ? "#141c26" : "white" }}
                        />
                        <MiniMap />
                        <Controls />
                        {/* <Panel style={{
                            width: "90%"
                        }}>
                            <Button onClick={(event) => addOrderNode({ event, symbol })}>
                                Add order node
                            </Button>
                        </Panel> */}
                        {menu &&
                            <ContextMenu symbol={symbol} ref={menuRef} onClick={onPaneClick} />
                        }
                    </ReactFlow>
                </div>
            </div >
        </div>
    );
};

export default Cat;