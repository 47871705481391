

const CandlesDelta = ({ metadata, setMetadata }) => {
    const { candlesDelta } = metadata;
    return (
        <div className="form-group">
            <div className="form-group">
                <div className="form-control-wrap">
                    <div className="input-group" style={{
                        cursor: "pointer"
                    }} onClick={(e) => {
                        // e.preventDefault()
                        setMetadata({ ...metadata, candlesDelta: { type: "currentCandle", value: 0 } })
                    }}>
                        <div className="custom-control custom-radio">
                            <input
                                type="radio"
                                className="custom-control-input"
                                checked={candlesDelta?.value == 0}
                                style={{
                                    pointerEvents: "none"
                                }}
                            />
                            <label className="custom-control-label" htmlFor="customRadio1">
                                Current candle
                            </label>
                        </div>
                        {/* <div className="input-group-prepend" >
              <div className="input-group-text">
                <input
                style={{
                  pointerEvents: "none"
                }}
                className="custom-control custom-radio"
                  type="radio"
                  checked={candlesDelta.value == 0}
                defaultChecked={true} />
              </div>
            </div>
            <input
            readOnly="readOnly"
              type="text"
              value={"Current candle"} className="form-control" style={{
                cursor: "pointer"
              }} /> */}
                    </div>
                </div>
            </div>

            <div className="form-group">
            <div className="form-control-wrap">
                <div className="input-group" style={{
                    cursor: "pointer"
                }} onClick={(e) => {
                    e.preventDefault()
                    if (candlesDelta.value === 0 || candlesDelta.value === "") {
                        setMetadata({ ...metadata, candlesDelta: { type: "nCandlesAgo", value: 1 } })
                    }
                }}>
                    <div className="custom-control custom-radio" style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                        <input
                            type="radio"
                            className="custom-control-input"
                            checked={candlesDelta?.value > 0}
                            style={{
                                pointerEvents: "none"
                            }}
                        />
                        <label className="custom-control-label" htmlFor="customRadio1">
                            Candles ago
                        </label>
                        <input type="text"
                                className="form-control"
                                style={{
                                    display: 'inline-block',
                                    maxWidth: "50px",
                                    marginLeft: 8
                                }} value={Math.max(1, candlesDelta?.value)} onChange={(e) => {
                                    e.preventDefault()
                                    setMetadata({ ...metadata, candlesDelta: { type: "nCandlesAgo", value: e.target.value } })
                                }}

                            />
                    </div>
                    {/* <div className="input-group-prepend" >
            <div className="input-group-text">
              <input
              className="custom-control custom-radio"
                checked={candlesDelta.value > 0}
                type="radio"
                style={{
                  pointerEvents: "none"
                }}
                />
            </div>
          </div>
          <input type="text"
          className="form-control"
          style={{
            maxWidth: "50px",
          }} value={Math.max(1, candlesDelta.value)} onChange={(e) => {
            e.preventDefault()
            setMetadata({ ...metadata, candlesDelta: { type: "nCandlesAgo", value: e.target.value } })
          }} 
          
          />
          <input
            type="text"
            readOnly="readOnly"
            value={"Candles ago"} className="form-control" style={{
              cursor: "pointer"
            }} /> */}
                </div>
            </div>
            </div>
        </div>
    )
}

export default CandlesDelta;