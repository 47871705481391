import { Badge, Button, Card, Col, Row } from "reactstrap";
import { Icon, PreviewCard, UserAvatar } from "./Component";
import ImageContainer from "./partials/gallery/GalleryImage";
import { Link } from "react-router-dom";
import { getSentimentBadge } from "../utils/Utils";
import moment from "moment";

const RedditSubmissionCard = ({ item }) => {
    return (
        <Col md={12}>
            <Card className="card-bordered gallery" style={{
            maxHeight: 300
        }}>
                <Link to={item.url} target="_blank" style={{
                    textDecoration: "none"
                }}>
                    {/* <ImageContainer img={StockA} /> */}
                    <div className="gallery-body card-inner align-center justify-between flex-wrap g-2" >
                        <Row>
                            <Col xs={12}>
                                {/* <UserAvatar image={AvatarA}></UserAvatar> */}
                                <div>
                                    <span className="lead-text">{item.title}</span>
                                    <span className="sub-text">{`${item.selftext.substring(0, 1000)}...`}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col xs={12} >
                                <div className="project-meta" style={{
                                    width: "100%",
                                }}>
                                    <ul className="project-users g-1">
                                        <li>
                                            <Badge color="info">{item.subreddit}</Badge>
                                        </li>
                                        <li>
                                            <Badge>upvote: {item.upvote_ratio}</Badge>
                                        </li>
                                        <li>
                                            <span>{getSentimentBadge({ label: item.sentiment_label, score: item.sentiment_score })}</span>
                                        </li>
                                    </ul>
                                </div>
                                    <span className="sub-text" style={{
                        fontSize: "12px", marginTop: 8
                    }}>Published at: {moment(item.created_utc*1000).format("MM/DD/YYYY hh:mm")}</span>
                            </Col>
                        </Row>
                    </div>
                </Link>
            </Card>
        </Col>
    );
};

export default RedditSubmissionCard;
