import React, { useEffect, useState } from "react";
import { RSelect } from "../../../components/Component";

export const actionOptions = [
  {value: "long", label: "Buy"},
  {value: "short", label: "Sell"},
  ]
    
const Action = ({actionType, setActionType}) => {
  return (
    <div className="form-group" style={{
      width: '100%',
    }}>
      <RSelect className="nodrag" value={actionType} options={actionOptions} onChange={setActionType} />
    </div>
  );
};
export default Action;