import { CardBody, Col, Row } from "reactstrap";
import { PreviewCard } from "../../../components/Component";
import strings from "../../../utils/Strings";

const SimulatedReturns = ({ lastFull }) => {


    return (
        <PreviewCard>
            <h6>Simulated returns</h6>
            <CardBody>
                <Row className="g-2">
                    <Row className="g-1">
                        <Col lg={3}>
                            <label className="title">Initial funds: </label >
                        </Col>
                        <Col lg={3}>
                            <h6>{strings.price(lastFull?.initialFunds)}</h6>
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col lg={3}>
                            <label className="title">Final balance: </label >
                        </Col>
                        <Col lg={3}>
                            <h6>{strings.price(lastFull?.initialFunds + lastFull?.performance?.totalProfit)} (<span className={lastFull?.performance?.totalProfit >= 0 ? "text-success" : "text-danger"}>+{strings.price(lastFull?.performance?.totalProfit)}</span>)</h6>
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col lg={3}>
                            <label className="title">Fees:</label >
                        </Col>
                        <Col lg={3}>
                        </Col>
                    </Row>
                </Row>
            </CardBody>
        </PreviewCard>
    );
}

export default SimulatedReturns;