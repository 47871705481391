import React, { useState } from 'react';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewCard, TooltipComponent } from '../../../components/Component';
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, Row } from 'reactstrap';
import GeneralCard from './GeneralCard';
import { useMyStore } from './ReactFlowBuilder/store';
import BacktestCard from './BacktestCard';

const Sidebar =
  ({
    botData,
    editMode
  }) => {

    const { setTab, symbols, setSymbols, flowDescription } = useMyStore()

    return (
      <Row className='h-100' style={{
        gap: 16
      }}>
        <Col lg={12}>
          <GeneralCard
            setTab={setTab}
          />
        </Col>
        <Col lg={12} style={{}}>
          <BacktestCard botData={botData} mode="preview" editMode={editMode} />
        </Col>
      </Row>
    );
  };

export default Sidebar;