import { useEffect, useState } from "react"
import { Icon, PreviewAltCard } from "../../components/Component"
import { Card, CardBody, Progress, Spinner } from "reactstrap";
import { getCompanyRatings } from "../../api";
import strings from "../../utils/Strings";

const CompanyRating = ({ companyRating }) => {

    const data = companyRating?.[0];
    if (!data) return <h6>No ratings at the moment</h6>

    const filledStars = Math.floor(data?.ratingScore);
    const halfStar = 5 - filledStars > 0;
    const emptyStars = Math.max(5 - data?.ratingScore - 1, 0);

    const parseColor = (label) => {
        switch (label) {

            case "Neutral":
                return "teal";
            case "Buy":
                return "info"
            case "Strong Buy":
                return "success"
            case "Sell":
                return "warning"
            case "Strong Sell":
                return "danger"

            default: return "teal"
        }
    }
    return (
        <>
            <Card className="card-borded">
                <div className="card-title" >
                    <h6>Analyst ratings</h6>
                </div>
                <div className="d-flex align-center gy-3">
                    <div className="progress-amount me-4">
                        <h1 className="title">{strings.decimalDigits(data?.ratingScore, 1)}</h1>
                        <ul className="rating">
                            {Array(filledStars).fill(0).map(i => (<Icon name="star-fill"></Icon>))}
                            {halfStar && <Icon name="star-half-fill"></Icon>}
                            {Array(emptyStars).fill(0).map(i => (<Icon name="star"></Icon>))}
                        </ul>
                        <span className="sub-text mt-1">
                            Rating: {data?.rating}
                        </span>
                    </div>
                    <div className="rating-progress-bar gy-1 w-50">
                        <div className="progress-rating">
                            <div className="progress-rating me-2" style={{ width: 40 }}>DCF</div>
                            <Progress value={data?.ratingDetailsDCFScore * 2 * 10} color={parseColor(data?.ratingDetailsDCFRecommendation)} className="progress-lg"></Progress>
                        </div>
                        <div className="progress-rating">
                            <div className="progress-rating me-2" style={{ width: 40 }}>DE</div>
                            <Progress value={data?.ratingDetailsDEScore * 2 * 10} color={parseColor(data?.ratingDetailsDERecommendation)} className="progress-lg"></Progress>
                        </div>
                        <div className="progress-rating">
                            <div className="progress-rating me-2" style={{ width: 40 }}>PB</div>
                            <Progress value={data?.ratingDetailsPBScore * 2 * 10} color={parseColor(data?.ratingDetailsPBRecommendation)} className="progress-lg"></Progress>
                        </div>
                        <div className="progress-rating">
                            <div className="progress-rating me-2" style={{ width: 40 }}>PE</div>
                            <Progress value={data?.ratingDetailsPEScore * 2 * 10} color={parseColor(data?.ratingDetailsPERecommendation)} className="progress-lg"></Progress>
                        </div>
                        <div className="progress-rating">
                            <div className="progress-rating me-2" style={{ width: 40 }}>ROA</div>
                            <Progress value={data?.ratingDetailsROAScore * 2 * 10} color={parseColor(data?.ratingDetailsROARecommendation)} className="progress-lg"></Progress>
                        </div>
                        <div className="progress-rating">
                            <div className="progress-rating me-2" style={{ width: 40 }}>ROE</div>
                            <Progress value={data?.ratingDetailsROEScore * 2 * 10} color={parseColor(data?.ratingDetailsROERecommendation)} className="progress-lg"></Progress>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default CompanyRating;