import React from 'react'
import { Card, Col, Container, Row } from 'reactstrap'
import { Link } from '../../components/button/Button'
import { TextBlock, TitleH3 } from '../../components/textBlock/TextBlock'
import { Section, SectionHead } from '../../layout/section/Section'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PricingFour = (props) => {
    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-center text-tenter">
                    <Col xl="7" lg="9">
                        <SectionHead className="text-center mb-1">
                            <h2 className="title text-dark mb-0">Which <FontAwesomeIcon icon="user-ninja" /> are You?</h2>
                            <b>Start now | Cancel anytime</b>
                        </SectionHead>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl="10">
                        <Row className="align-items-center g-0">
                            <Col md="5">
                                <Card className="card-shadow round-xl bg-dark is-dark pb-4 pb-md-0">
                                    <div className="card-inner card-inner-xl">
                                        <TextBlock>
                                            <h3 className="mb-3" style={{ color: "#c4cefe" }}>Pro</h3>
                                            <ul className="list list-nostyle fs-15px mb-1">
                                                <li>Company one pager</li>
                                                <li>Unlimited bots</li>
                                                <li>Unlimited backtests</li>
                                                <li>Unlimited watchlists</li>
                                                <li>AI ninja</li>
                                            </ul>
                                            <ul className="btns-inline pt-4">
                                                <li><Link to="login?redirect=/plans" rel="noreferrer" className="btn-round btn-xl btn-primary fs-16px">19.99$ / month</Link></li>
                                            </ul>
                                        </TextBlock>
                                    </div>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card style={{
                                    background: "#2c3782",
                                    color: "white",
                                }}
                                    className="card-shadow card-bd-right-3px round-xl ms-lg-n7 ms-md-n5 mx-4 me-md-0 mt-md-0 mt-n4">
                                    <div className="card-inner card-inner-lg">
                                        <div className="text-block is-compact pe-3">
                                            <h4 className="title" style={{
                                                color: "white"
                                            }}>Basic</h4>
                                            <ul className="list list-nostyle fs-15px">
                                                <li>Company one pager</li>
                                                <li>3 bots</li>
                                                <li>10 backtests per bot</li>
                                                <li>10 watchlists</li>
                                                <li>AI ninja</li>
                                                {/* <li>Optimiaztion tools</li>
                                            <li>Ninja AI chat</li> */}
                                            </ul>
                                            <ul className="btns-inline pt-2">
                                                <li><Link to="login?redirect=/plans" rel="noreferrer" className="btn btn-lg btn-round btn-secondary"> <span>9.99$ / month</span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card className="card-shadow card-bd-right-3px round-xl ms-md-n7 ms-md-n5 mx-6 me-md-0 mt-n2">
                                    <div className="card-inner card-inner-md">
                                        <div className="text-block is-compact pe-3">
                                            <h5 className="title text-info">Free</h5>
                                            <ul className="list list-nostyle fs-15px">
                                                <li>Company one pager</li>
                                                <li>3 bots</li>
                                                <li>3 backtests per bot</li>
                                                <li>3 watchlists</li>
                                                <li>AI ninja</li>
                                                {/* <li>Optimiaztion tools</li>
                                            <li>Ninja AI chat</li> */}
                                            </ul>
                                            <ul className="btns-inline pt-2">
                                                <li><Link to="login?redirect=/home" rel="noreferrer" className="btn btn-lg btn-round btn-info"> <span>Free</span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default PricingFour
