import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import { Section, SectionContant, SectionHead } from '../../layout/section/Section'
import { Service, ServiceIcon, ServiceText } from "../../components/service/Service"
import { Paper, Responsive, Paint, Idea } from '../../components/svg/Icon'
import { svgData } from '../../../pages/components/crafted-icons/NioIconData'

const reportsIcon = svgData.filter(i => i.name === "Reports");
const aiChatIcon = svgData.filter(i => i.name === "Help/Support");
const strategiesIcon = svgData.filter(i => i.name === "Customize");
const backtestsIcon = svgData.filter(i => i.name === "Settings");

const ServiceFive = (props) => {
    return (
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-center text-center">
                    <Col md="8" xl="7">
                        <SectionHead>
                            <h2 className="title text-dark">Next-gen algotrading platform</h2>
                            <h6>That gives you trading superpowers!</h6>
                        </SectionHead>
                    </Col>
                </Row>
                <SectionContant>
                    <Row className="gy-gs justify-content-center text-center">
                        <Col xs="10" sm="6" lg="3">
                            <Service className="card-full service-s4 after-bg-info">
                                <div className="card-inner">
                                    <ServiceIcon className="styled-icon styled-icon-6x text-info" >
                                        <div style={{
                                            height: 72,
                                            // backgroundColor: "red",
                                            // color: "red"
                                        }}>{reportsIcon?.[0]?.svg}</div>
                                    </ServiceIcon>
                                    <ServiceText>
                                        <h5 className="title text-dark">Create a trading bot</h5>
                                        <p>The most intuitive, no-code bot builder in the market! Bring your ideas to live with dozens of indicators and trading patterns.</p>
                                    </ServiceText>
                                </div>
                            </Service>
                        </Col>
                        <Col xs="10" sm="6" lg="3">
                            <Service className="card-full service-s4">
                                <div className="card-inner">
                                    <ServiceIcon className="styled-icon styled-icon-6x text-primary" >
                                        <div style={{
                                            height: 72,
                                        }}>
                                            <Idea />
                                        </div>
                                    </ServiceIcon>
                                    <ServiceText>
                                        <h5 className="title text-dark">Explore ideas</h5>
                                        <p>Explore new ideas from our community with thousands of trading bots that already making money!</p>
                                    </ServiceText>
                                </div>
                            </Service>
                        </Col>
                        <Col xs="10" sm="6" lg="3">
                            <Service className="card-full service-s4 after-bg-success">
                                <div className="card-inner">
                                    <ServiceIcon className="styled-icon styled-icon-6x text-primary" >
                                        <div style={{
                                            height: 72,
                                        }}>{backtestsIcon?.[0]?.svg}</div>
                                    </ServiceIcon>
                                    <ServiceText>
                                        <h5 className="title text-dark">Backtest</h5>
                                        <p>Choose the period and initial funds for your backtest and evaluate your trading bot with more than 30 years of historical data!</p>
                                    </ServiceText>
                                </div>
                            </Service>
                        </Col>
                    </Row>
                </SectionContant>
            </Container>
        </Section>
    )
}

export default ServiceFive
