import React, { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  UncontrolledDropdown
} from "reactstrap";
import { CREATE_PORTFOLIO_STRATEGY, GET_PORTFOLIO_BY_ID, GET_USER_LIMIT_COUNTS, GET_USER_STRATEGIES, RUN_PORTFOLIO_STRATEGY, UPDATE_PORTFOLIO_STRATEGY } from "../../../api/graphql";
import {
  Block,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  RSelect,
  Row,
  TooltipComponent
} from "../../../components/Component";
import SubscriptionLimit from "../../../components/SubscriptionLimit";
import { intervalOptions } from "../../strategies/BacktestFormModal";

import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import strings from "../../../utils/Strings";
import { ModalDanger } from "../../components/Modals";

const filterStatus = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "stopped", label: "Stopped" },
];

const StrategyDefault = ({ allStrategies, portfolioId, allSymbols, portfolioData }) => {
  const [tablesm, updateTableSm] = useState(false);
  const [activeStrategies, setActiveStrategies] = useState(allStrategies?.filter(s => s.status === "active"));
  const [onSearch, setonSearch] = useState(true);
  const [smOption, setSmOption] = useState(true);
  const [runningStrategies, setRunningStrategies] = useState({});

  const [onSearchText, setSearchText] = useState("");
  const { user } = useAuth();
  const [data, setData] = useState(allStrategies);
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [disablePortfolioStrategyMutationLink] = useMutation(UPDATE_PORTFOLIO_STRATEGY);
  const [newDeployedStrategies, setNewDeployedStrategies] = useState([]);
  const [filtersData, setFiltersData] = useState(null);
  const [interval, setInterval] = useState(intervalOptions[0]);

  const { data: userStrategies, loading: loadingUserStrategies } = useQuery(GET_USER_STRATEGIES, {
    variables: {
      filter: { uid: user?.uid }
    }
  });
  const [createPortfolioStrategyMutationLink] = useMutation(CREATE_PORTFOLIO_STRATEGY);
  const [showDeployStrategyModal, setShowDeployStrategyModal] = useState(false);

  useEffect(() => {
    setData(allStrategies)
    setActiveStrategies(allStrategies?.filter(s => s.status === 'active'));
  }, [allStrategies])

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        return (
          (item.strategy.name.toLowerCase().includes(onSearchText.toLowerCase())) ||
          (item.strategy.description.toLowerCase().includes(onSearchText.toLowerCase())) ||
          (item.status.toLowerCase().includes(onSearchText.toLowerCase()))
        )
      });
      setData([...filteredObject]);
    } else {
      setData([...allStrategies]);
    }
  }, [onSearchText]);

  // Changing state value when filtering
  useEffect(() => {
    if (filtersData) {
      const filteredObject = allStrategies.filter((item) => {
        const s = Object.keys(filtersData).map(i => {
          return item[i] === filtersData[i]?.value;
        }).join("&&");
        return eval(s);
      });
      setData([...filteredObject]);
    } else {
      setData([...allStrategies]);
    }
  }, [filtersData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to declare the state change
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to select all the items of the table
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.check = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function to change the property of an item of the table
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item._id === id);
    newData[index].check = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to fire actions after dropdowm select
  const onActionClick = (e) => {
    if (actionText === "Reject") {
      let newData = data.map((item) => {
        if (item.check === true) item.status = "Rejected";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "Delete") {
      let newData;
      newData = data.filter((item) => item.check !== true);
      setData([...newData]);
    }
  };

  // function to load detail data
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item._id === id);
    setDetail(data[index]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onClickDisable = (item) => {
    const title = "YO!";
    const desc = "Are you sure you want to disable this strategy?";

    setDeleteModalData({
      title,
      description: desc,
      cancelButtonText: "Cancel",
      idToDelete: item?._id,
      approveButtonColor: "danger",
      approveButtonText: "Stop",
      onClickDelete: async (id) => {
        await onApproveDisable(id)
      }
    });
    toggleDelete();
  }

  const onApproveDisable = async (id) => {
    const submittedData = {
      status: "stopped",
      stoppedAt: new Date()
    }
    const res = await disablePortfolioStrategyMutationLink({
      variables: { record: submittedData, id },
      refetchQueries: [
        {
          query: GET_PORTFOLIO_BY_ID,
          variables: {
            id: portfolioId
          }
        },
        {
          query: GET_USER_LIMIT_COUNTS,
          variables: {
            "backtestFilter": {
              "strategyId": portfolioId
            },
            "portfolioStrategyFilter": {
              "portfolioId": portfolioId,
              "status": "active"
            },
            "strategyFilter": {
              "userUID": portfolioId
            },
            "watchlistFilter": {
              "userUID": portfolioId
            }
          }
        },
      ]
    },
    );
  }

  const onFormSubmit = async (form) => {

    for (let i = 0; i < newDeployedStrategies.length; i++) {
      const s = newDeployedStrategies[i];
      const submittedData = {
        interval,
        strategyId: s.strategyId,
        portfolioId,
        status: "active",
        deployedAt: new Date()
      }
      const res = await createPortfolioStrategyMutationLink({
        variables: {
          record: submittedData
        },
        refetchQueries: [
          {
            query: GET_PORTFOLIO_BY_ID,
            variables: {
              id: portfolioId
            }
          },
          {
            query: GET_USER_LIMIT_COUNTS,
            variables: {
              "backtestFilter": {
                "strategyId": portfolioId
              },
              "portfolioStrategyFilter": {
                "portfolioId": portfolioId,
                "status": "active"
              },
              "strategyFilter": {
                "userUID": portfolioId
              },
              "watchlistFilter": {
                "userUID": portfolioId
              }
            }
          },
        ]
      },
      );
    }

    setNewDeployedStrategies([]);
  };

  const [runPortfolioStrategyMutationLink] = useMutation(RUN_PORTFOLIO_STRATEGY);
  const runActiveStrategies = async () => {
    const now = new Date();
    const activeStrategies = allStrategies?.filter(s => s.status === "active");

    for (let i = 0; i < activeStrategies.length; i++) {
      const strategy = activeStrategies[i];
      setRunningStrategies({ ...runningStrategies, [strategy?.strategyId]: true })
      const submittedData = {
        allSymbols,
        portfolioStrategyId: strategy._id,
        flow: strategy?.strategy?.flow,
        portfolioId,
        from: strategy?.lastRunAt ? strategy?.lastRunAt : strategy?.deployedAt,
        to: new Date(),
        initialFunds: portfolioData?.funds,
        interval: strategy?.interval
      }
      const { errors, data: runStrategyData } = await runPortfolioStrategyMutationLink({
        variables: {
          input: submittedData
        },
        refetchQueries: [
          {
            query: GET_PORTFOLIO_BY_ID,
            variables: {
              id: portfolioId
            }
          },
        ]
      },
      );
      setRunningStrategies({ ...runningStrategies, [strategy?.strategyId]: false })
    }
  }

  useEffect(() => {
    allSymbols && runActiveStrategies()
  }, []);

  return (
    <React.Fragment>
      <SubscriptionLimit hideReason="All strategies deployed" hideAddButton={activeStrategies?.length === userStrategies?.userOne?.strategies?.length} filter={portfolioId} type={"portfolioStrategy"} current={activeStrategies?.length} setModal={() => setShowDeployStrategyModal(true)} />
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group">
              <div className="card-tools">
                {/* <div className="form-inline flex-nowrap gx-3">
                    <div className="form-wrap">
                      <RSelect
                        options={bulkActionOptions}
                        className="w-130px"
                        placeholder="Bulk Action"
                        onChange={(e) => onActionText(e)}
                      />
                    </div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          disabled={actionText !== "" ? false : true}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={(e) => onActionClick(e)}
                        >
                          Apply
                        </Button>
                      </span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                    </div>
                  </div> */}
              </div>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <div className="toggle-wrap">
                      <Button
                        className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                        onClick={() => updateTableSm(true)}
                      >
                        <Icon name="menu-right"></Icon>
                      </Button>
                      <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                        <ul className="btn-toolbar gx-1">
                          <li className="toggle-close">
                            <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                              <Icon name="arrow-left"></Icon>
                            </Button>
                          </li>
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                <div className="dot dot-primary"></div>
                                <Icon name="filter-alt"></Icon>
                              </DropdownToggle>
                              <DropdownMenu
                                end
                                className="filter-wg dropdown-menu-xl"
                                style={{ overflow: "visible" }}
                              >
                                <div className="dropdown-head">
                                  <span className="sub-title dropdown-title">Filter strategies</span>
                                  <div className="dropdown">
                                    <a
                                      href="#more"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      className="btn btn-sm btn-icon"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </a>
                                  </div>
                                </div>
                                <div className="dropdown-body dropdown-body-rg">
                                  <Row className="gx-6 gy-3">
                                    <Col size="6">
                                      <div className="form-group">
                                        <label className="overline-title overline-title-alt">Status</label>
                                        <RSelect value={filtersData?.status ? filtersData.status : filterStatus[0]} options={filterStatus} onChange={(i) => {
                                          const t = i?.value === "all" ? null : { status: i }
                                          setFiltersData(t)
                                        }} placeholder="Any Status" />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                {/* <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                    <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a>
                                  </div> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                          {/* <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li> */}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by strategy name, description or status"
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody>
            <DataTableHead>
              <DataTableRow>
                <span>Name</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span>Desc</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span>Status</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span>Interval</span>
              </DataTableRow>
              <DataTableRow>
                <span>Deployed at</span>
              </DataTableRow>
              <DataTableRow>
                <span>Last run</span>
              </DataTableRow>
              <DataTableRow>
                <span>Profit</span>
              </DataTableRow>
              <DataTableRow size="md" className="nk-tb-col-tools">&nbsp;</DataTableRow>
            </DataTableHead>

            {currentItems?.length > 0
              ? currentItems?.map((item) => {
                return (
                  <DataTableItem key={item._id}>
                    <DataTableRow>
                      <Link to={`${process.env.PUBLIC_URL}/strategy/${allStrategies?.strategyId}`}>
                        <div className="user-card">
                          <div className="user-info">
                            <span className="tb-lead">
                              {item?.strategy?.name}{" "}
                              <span
                                className={`dot dot-${item.status === "active"
                                  ? "success"
                                  : item.status === "done"
                                    ? "info"
                                    : "danger"
                                  } d-md-none ms-1`}
                              ></span>
                            </span>
                            {/* <span>{item._id}</span> */}
                          </div>
                        </div>
                      </Link>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="tb-lead-sub">{item?.strategy?.description}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <Badge
                        size="sm"
                        color={
                          item.status === "active"
                            ? "outline-success"
                            : item.status === "done"
                              ? "outline-info"
                              : "outline-danger"
                        }
                        className="badge-dim"
                      >{item.status}</Badge>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="tb-lead-sub">{item?.interval?.label}</span>
                    </DataTableRow>
                    <DataTableRow>
                      {<span>{strings.dateTime(item?.deployedAt)}</span>}
                    </DataTableRow>
                    <DataTableRow>
                      {runningStrategies[item?.strategy?._id] ? <Spinner type="grow" size="sm" /> : <span>{strings.dateTime(item?.lastRunAt)}</span>}
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span className="tb-lead-sub">{strings.priceDecimal(item?.profit)}</span>
                    </DataTableRow>

                    <DataTableRow className="nk-tb-col-tools" size="md">
                      <ul className="nk-tb-actions gx-1">
                        {item.status === "active" && <li
                          className="nk-tb-action-hidden"
                          onClick={() => {
                            loadDetail(item._id);
                            onClickDisable(item);
                          }}
                        >
                          <TooltipComponent
                            tag="a"
                            containerClassName="btn btn-trigger btn-icon"
                            id={"view" + item._id}
                            icon="cross-c"
                            direction="top"
                            text="Stop"
                          />
                        </li>}
                        {/* {item.status === "Rejected" ? null : item.status === "Approved" ? (
                              <li className="nk-tb-action-hidden" onClick={() => onRejectClick(item._id)}>
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"reject" + item._id}
                                  icon="cross-fill-c"
                                  direction="top"
                                  text="Reject"
                                />
                              </li>
                            ) : (
                              <React.Fragment>
                                <li className="nk-tb-action-hidden" onClick={() => onApproveClick(item._id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"approve" + item._id}
                                    icon="check-fill-c"
                                    direction="top"
                                    text="Approve"
                                  />
                                </li>
                                <li className="nk-tb-action-hidden" onClick={() => onRejectClick(item._id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"reject" + item._id}
                                    icon="cross-fill-c"
                                    direction="top"
                                    text="Reject"
                                  />
                                </li>
                              </React.Fragment>
                            )} */}
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                {/* <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#view"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        loadDetail(item._id);
                                        setViewModal(true);
                                      }}
                                    >
                                      <Icon name="eye"></Icon>
                                      <span>Quick View</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/strategy/${allStrategies?._id}/backtest/${item._id}`}
                                    >
                                      <Icon name="focus"></Icon>
                                      <span>View Details</span>
                                    </Link>
                                  </li> */}
                                {item.status === "active" && <li>
                                  <DropdownItem
                                    tag="a"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      onClickDisable(item)
                                    }}
                                  >
                                    <Icon name="cross-c"></Icon>
                                    <span>Stop</span>
                                  </DropdownItem>
                                </li>}
                                {/* {item.status === "Rejected" ? null : item.status === "Approved" ? (
                                    <li onClick={() => onRejectClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#reject"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="na"></Icon>
                                        <span>Reject User</span>
                                      </DropdownItem>
                                    </li>
                                  ) : (
                                    <React.Fragment>
                                      <li onClick={() => onApproveClick(item._id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#approve"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="check-thick"></Icon>
                                          <span>Approve</span>
                                        </DropdownItem>
                                      </li>
                                      <li onClick={() => onRejectClick(item._id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#suspend"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="na"></Icon>
                                          <span>Suspend User</span>
                                        </DropdownItem>
                                      </li>
                                    </React.Fragment>
                                  )} */}
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
              : null}
          </DataTableBody>
          <div className="card-inner">
            {currentItems.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
          </div>
        </DataTable>
      </Block>
      <Modal isOpen={showDeployStrategyModal} toggle={() => { setShowDeployStrategyModal(false) }} className="modal-dialog" size="lg">
        <ModalHeader>
          <h5>Deploy startegies to {portfolioId}</h5>

        </ModalHeader>
        <ModalBody>
          <Row className={"g-gs"}>
            <Col xs="12">
              <label className="preview-title">Your strategies</label>
              {loadingUserStrategies ? <Spinner /> : <ul className="custom-control-group custom-control-vertical w-100">
                {userStrategies?.userOne?.strategies?.filter(s => allStrategies.filter(ds => ds.strategyId === s._id && ds.status === "active").length === 0)?.map(s => {
                  return (
                    <li>
                      <div className="custom-control custom-control-sm custom-checkbox custom-control-pro">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={s?._id}
                          name={s?.name}
                          checked={(newDeployedStrategies.filter(ds => ds.strategyId === s._id).length > 0)}
                          onClick={(e) => {
                            let t = [...newDeployedStrategies];
                            const exist = t.filter(ds => ds.strategyId === s._id)?.[0];
                            if (exist) {
                              t = t.filter(ds => ds.strategyId !== exist.strategyId);
                            }
                            else {
                              t.push({
                                strategyId: s._id,
                                portfolioId
                              });
                            }
                            setNewDeployedStrategies(t);
                          }}
                        />
                        <label className="custom-control-label" htmlFor={s?._id}>
                          <div className="user-card">
                            <div className="user-info">
                              <span className="lead-text">{s?.name}</span>
                              <span className="sub-text">{s?.description}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </li>
                  )
                })}
              </ul>}
            </Col>

            <Col md="6">
              <div className="form-group">
                <label style={{
                  display: "block"
                }} className="form-label">Interval (in minutes)</label>
                <ul className="custom-control-group">
                  {
                    intervalOptions.map((v, i) => (
                      <li>
                        <div className="custom-control custom-radio custom-control-pro no-control">
                          <input type="radio" className="custom-control-input" name="btnRadio" id={v.value} defaultChecked={i === 0} />
                          <label className="custom-control-label" htmlFor={v.value} onClick={(e) => {
                            setInterval(v)
                          }}>
                            {v.label}
                          </label>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={async (e) => {
            if (newDeployedStrategies.length > 0)
              await onFormSubmit(e);

            setShowDeployStrategyModal(false);
          }} >
            Deploy
          </Button>
        </ModalFooter>

      </Modal>
      {deleteModalData && <ModalDanger modalError={modalDelete} toggleError={toggleDelete} modalData={deleteModalData} />}
    </React.Fragment>
  );
};
export default StrategyDefault;
