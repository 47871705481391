import React, { useEffect, useState } from "react";
import Icon from "../../icon/Icon";
import { orderActivityData } from "./OrderData";
import { Badge, CardTitle, Spinner } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../table/DataTable";
import { Link } from "react-router-dom";
import { getIPOCalendar } from "../../../api";
import AssetLogo from "../../AssetLogo";
import strings from "../../../utils/Strings";
import moment from "moment";

const IPOCalendar = () => {
  const [ipo,setIPOs] = useState([]);
  const [isLoadingEarningCalendar, setIsLoadingEarningCalendar] = useState(false);
  useEffect(() => {
    const loadEarningCalendar = async () => {
      setIsLoadingEarningCalendar(true);

      getIPOCalendar(
        (res) => {
          setIPOs(res.reverse())
        setIsLoadingEarningCalendar(false)
      }, (err) => {
        console.error(err)
        setIsLoadingEarningCalendar(false)
      })
    }

    loadEarningCalendar();
  }, []);

  
  if(isLoadingEarningCalendar){
    return <Spinner />
  }

  return (
    <React.Fragment>
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <span className="me-2">Upcoming IPOs</span>{" "}
            </h6>
          </CardTitle>
        </div>
        <div style={{maxHeight: 300, overflowY: "auto", overflowX: "hidden"}}>
      <DataTableBody>
        <DataTableHead>
          <DataTableRow>
            <span>Company</span>
          </DataTableRow>
          <DataTableRow>
            <span>Exchange</span>
          </DataTableRow>
          <DataTableRow>
            <span>Symbol</span>
          </DataTableRow>
          <DataTableRow >
            <span>Date</span>
          </DataTableRow>
          <DataTableRow >
            <span>Price range</span>
          </DataTableRow>
          <DataTableRow >
            <span>Market cap</span>
          </DataTableRow>
        </DataTableHead>
        {ipo.map((item) => {
          return (
            <DataTableItem key={item.symbol}>
              <DataTableRow className="nk-tb-orders-type">
                <span className="tb-lead">{item.company}</span>
              </DataTableRow>
              <DataTableRow >
                <span className="tb-sub">{item.exchange}</span>
              </DataTableRow>
              <DataTableRow >
                <Badge className="tb-sub">{item.symbol}</Badge>
              </DataTableRow>
              <DataTableRow >
                <span className="tb-sub">{moment(item.date).format("MM/DD")}</span>
              </DataTableRow>
              <DataTableRow >
                <span className="tb-sub">{item.priceRange}</span>
              </DataTableRow>
              <DataTableRow >
                <span className="tb-sub tb-amount">
                  {strings.price(item.marketCap)}
                </span>
              </DataTableRow>
            </DataTableItem>
          );
        })}
      </DataTableBody>
      </div>
    </React.Fragment>
  );
};
export default IPOCalendar;
