import React, { useEffect, useState } from "react";
import Icon from "./icon/Icon";
import { CardTitle, Spinner } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import strings from "../utils/Strings";
import { getEarningCalendar } from "../api";

const UpcomingEarningCalls = ({}) => {
  const [earnings,setEarnings] = useState([]);
  const [isLoadingEarningCalendar, setIsLoadingEarningCalendar] = useState(false);
  useEffect(() => {
    const loadEarningCalendar = async () => {
      setIsLoadingEarningCalendar(true);

      getEarningCalendar({},
        (res) => {
          setEarnings(res)
        setIsLoadingEarningCalendar(false)
      }, (err) => {
        console.error(err)
        setIsLoadingEarningCalendar(false)
      })
    }

    loadEarningCalendar();
  }, []);

  if(isLoadingEarningCalendar) return <Spinner />
  return (
    <React.Fragment>
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Upcoming earning calls</h6>
          </CardTitle>
      </div>
          <h6 className="timeline-head">This week</h6>
      <div className="card-inner" style={{maxHeight: 300, overflow: "auto"}}>
        <div className="timeline">
          <ul className="timeline-list">
            {earnings.map((item, index) => {
              return (
                <li className="timeline-item" key={`${item.symbol}_${index}`}>
                  <div className={`timeline-status bg-primary ${item.time === "bmo" ? "is-outline" : ""}`}></div>
                  <div className="timeline-date" style={{width: 70}}>
                    {moment(item.date).format("MM/DD")} <Icon name="alarm-alt"></Icon>
                  </div>
                  <div className="timeline-data" style={{
                    width: "50%"
                  }}>
                    <Link to={`/one-pager/${item.symbol}`} >
                    <h6 className="timeline-title">{item.symbol}</h6>
                    </Link>
                    <div className="timeline-des">
                      <p>{item.subtitle}</p>
                      <span className="time">{item.time === "bmo" ? "Before market open" : "After market closed"}</span>
                    </div>
                  </div>
                  <div className="timeline-data" style={{
                    width: "50%"
                  }}>
                    <div className="timeline-des">
                      <p><b>Est. EPS:</b> <h6>{strings.priceDecimal(item.epsEstimated)}</h6></p>
                      <p><b>Est. revenue:</b> <h6>{strings.price(item.revenueEstimated)}</h6></p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
export default UpcomingEarningCalls;
