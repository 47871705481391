import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  Icon,
  Button,
  Col,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import { teamList } from "./StrategiesData";
import {
  Modal,
  ModalBody,
  Form,
  Spinner,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";
import { useFieldArray, useForm } from "react-hook-form";
import Editor from "@monaco-editor/react";
import Autocomplete from "../../components/autocomplete/Autocomplete";
import { useTheme } from "../../layout/provider/Theme";
import StrategyPolicyBuilder from "./StrategyPolicyBuilder";
import InvestmentStrategyBuilder from "./StrategyPolicyBuilder/InvestmentStrategyBuilder";
import CustomInvestmentStrategyBuilder from "./StrategyPolicyBuilder/InvestmentStrategyBuilder";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const assetData = [
  { value: "AAPL", label: "AAPL" },
  { value: "MSFT", label: "MSFT" },
  { value: "TSLA", label: "TSLA" },
  { value: "SPY", label: "SPY" },
];

const schema = yup
  .object({
    name: yup.string().required(),
    flow: yup.object().shape({
      nodes: yup.array().required()
    })
  })
  .required()

const StrategyFormModal = ({ isLoading, modal, closeModal, onSubmit, formData, setFormData, modalType }) => {
  const { control, reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    reset(formData)
  }, [formData]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'flow',
    rules: {
      required: true
    }
  })
  
  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="xl">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" && "Let's create a new strategy"} {modalType === "edit" && "Edit strategy"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      {...register('name', { required: "This field is required" })}
                      value={formData.name}
                      placeholder="Strategy name"
                      onChange={(e) => {
                        e.preventDefault();
                        setFormData({ ...formData, name: e.target.value })
                      }}
                      className="form-control" />
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </div>
                </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <input
                    value={formData.description}
                    placeholder="Your description"
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="form-control-lg form-control no-resize" />
                </div>
              </Col>
              <Col size="12">
              <div className="form-group">
                  <label className="form-label">Strategy flow</label>
                  <span style={{
                  marginLeft: 4
                }}
                >
              <TooltipComponent
                icon="help-fill"
                iconClass="card-hint"
                direction="right"
                id="strategyFlow-tooltip"
                text="Compose the strategy logic that will run every tick"
              />
              </span>
                  <div>
                        <Row className="g-gs">
                        <CustomInvestmentStrategyBuilder flow={formData?.flow} setFlow={(flow) => setFormData({ ...formData, flow })}/>
                        </Row>
                      </div>
                  </div>
                  {errors?.flow && <span className="text-danger">Must create strategy flow!</span>}
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                    {isLoading && <Spinner size="sm" type="grow" />}
                      {modalType === "add" && "Add strategy"} {modalType === "edit" && "Update strategy"}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default StrategyFormModal;
