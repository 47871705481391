import React, { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import AssetLogo from "../../components/AssetLogo";
import { Icon } from '../../components/Component';
import useAuth from "../../hooks/useAuth";
import strings from "../../utils/Strings";

const LastChangeStreamItem = ({ i, tick, quote, onClick }) => {
  const last = parseFloat(tick?.trade?.last) || quote?.last;
  const prevClose = quote?.prevclose;
  const change = last - prevClose;
  const changePct = (change / prevClose) * 100;
  return (
    <div className="analytic-data analytic-wp-data" onClick={onClick} style={{
      cursor: 'pointer'
    }}>
      <div className="analytic-wp-graph" style={{
      }}>
        <div className="title" >
          {i}
        </div>
        <div className="analytic-wp-ck">
          <AssetLogo style={{}} symbol={i} />
        </div>
      </div>
      <div className="analytic-wp-text">
        <div className="amount amount-sm">{strings.priceDecimal(last, 2)}</div>
        <div className={changePct > 0 ? "change up" : changePct < 0 ? "change down" : "change"}>
          <Icon name={changePct > 0 ? "arrow-long-up" : changePct < 0 ? "arrow-long-down" : "change"}></Icon>{strings.percentSignFormatter(changePct)}
        </div>
        <div className="subtitle"></div>
      </div>
    </div>
  )
}
const useLastChangeStream = ({ data, itemOnly }) => {
  const { user } = useAuth();
  const [allMessages, setAllMessages] = useState({});

  const { lastMessage, getWebSocket, lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(`${process.env.REACT_APP_API_WS_URL}?userId=${user.uid}`, {
    // share: false,
    // filter: () => false,
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    onClose: (e) => {
      console.log(e)
      console.log('WebSocket connection closed.');
    },
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent)
      return true;
    },
  });

  useEffect(() => {
    if (lastJsonMessage?.symbol) {
      if (lastJsonMessage.type === 'summary') {
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], summary: lastJsonMessage };
      }
      else
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], trade: lastJsonMessage };
    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      data?.symbols?.length && sendJsonMessage({
        type: "watchlistStream",
        symbols: data?.symbols
      })
    }
  }, [readyState])

  return {
    allMessages,
  }
};

export default useLastChangeStream;
