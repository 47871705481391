import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Col, Row, Spinner } from "reactstrap";
import { getQuotes } from "../../api";
import AssetLogo from "../../components/AssetLogo";
import { Icon, PreviewCard } from '../../components/Component';
import useAuth from "../../hooks/useAuth";
import strings from "../../utils/Strings";

const StockPeersItem = ({ i, tick, quote, onClick }) => {
  const last = parseFloat(tick?.trade?.last) || quote?.last;
  const prevClose = quote?.prevclose;
  const change = last - prevClose;
  const changePct = (change / prevClose) * 100;
  return (
    <Col className="analytic-data analytic-wp-data" xs="6" lg={4} onClick={onClick} style={{
      cursor: 'pointer'
    }}>
      <PreviewCard>
        <div className="analytic-wp-graph" style={{
        }}>
          <div className="title" >
            {i}
          </div>
          <div className="analytic-wp-ck">
            <AssetLogo style={{}} symbol={i} />
          </div>
          <div className="amount amount-sm">{strings.priceDecimal(last, 2)}</div>
          <div className={changePct > 0 ? "change up" : changePct < 0 ? "change down" : "change"}>
            <Icon name={changePct > 0 ? "arrow-long-up" : changePct < 0 ? "arrow-long-down" : "change"}></Icon>{strings.percentSignFormatter(changePct)}
          </div>
        </div>
      </PreviewCard>
    </Col>
  )
}
const StockPeers = ({ symbols }) => {
  const { user } = useAuth();
  const [allMessages, setAllMessages] = useState({});
  const [quotes, setQuotes] = useState(null);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(null);
  const navigate = useNavigate();

  const { lastMessage, getWebSocket, lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(`${process.env.REACT_APP_API_WS_URL}?userId=${user?.uid}`, {
    // share: false,
    // filter: () => false,
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    onClose: (e) => {
      console.log(e)
      console.log('WebSocket connection closed.');
    },
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent)
      return true;
    },
  });

  useEffect(() => {
    const loadQuotes = async () => {
      setIsLoadingQuotes(true)
      getQuotes({ symbols: symbols.join(","), greeks: true },
        (res) => {
          console.log(res)
          setQuotes(res);
          setIsLoadingQuotes(false)
        }, (err) => {
          console.error(err)
          setIsLoadingQuotes(false)
        })
    }

    (symbols !== null) && loadQuotes();
  }, [symbols]);

  useEffect(() => {
    if (lastJsonMessage?.symbol) {
      if (lastJsonMessage.type === 'summary') {
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], summary: lastJsonMessage };
      }
      else
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], trade: lastJsonMessage };
    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      symbols?.length && sendJsonMessage({
        type: "watchlistStream",
        symbols: symbols
      })
    }
  }, [readyState, symbols])

  if (isLoadingQuotes) return <Spinner />;
  return (
    <div >
      <h6>Stock peers</h6>
      <div style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "200px"
      }}>
        <Row className="g-gs">
          {symbols?.map(i => {
            const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === i)[0] : quotes?.quotes?.quote
            return (
              <StockPeersItem onClick={(() => {
                navigate(`/one-pager/${i}`)
              })} i={i} tick={allMessages[i]} quote={q} />
            )
          })}
        </Row>
      </div>
    </div>
  )
};

export default StockPeers;
