import React, { useEffect, useState } from "react";
import { NSComponent } from "../../../../components/Component";

const NumberValue = ({ metadata, setMetadata }) => {
  const { numberValue } = metadata;
  const value = numberValue || 0;
  return (
    <div className="form-group" style={{
      width: '100%',
    }}>
      <label className="form-label">Value</label>
      <NSComponent defaultVal={value} onChange={(value) => {
        setMetadata({ numberValue: value })
      }} />
    </div>
  );
};
export default NumberValue;