export const buyAndHoldMAGMA = {
    "name": "Buy and hold MAGMA",
    "description": "Simple strategy that buys META, AMZN, AAPL, MSFT and GOOG evenly.",
    "flow": {
        "nodes": [
            {
                "id": "order_0",
                "type": "order",
                "position": {
                    "x": "1000",
                    "y": "400"
                },
                "data": {
                    "state": {
                        "maximumOpenPositions": "1",
                        "delayBetweenPositions": "5",
                        "targetAsset": "AAPL",
                        "actionType": {
                            "value": "long",
                            "label": "Buy"
                        },
                        "sizeType": {
                            "value": "percentOfFunds",
                            "label": "% of funds"
                        },
                        "sizeValue": "20"
                    },
                    "isOpen": false
                },
                "width": "240",
                "height": "143",
                "selected": false,
                "dragging": false
            },
            {
                "id": "order_0-3-copy",
                "type": "order",
                "position": {
                    "x":"1272.0058823529412",
                    "y":"399.66323529411767"
                },
                "data": {
                    "state": {
                        "maximumOpenPositions": "1",
                        "delayBetweenPositions": "5",
                        "targetAsset": "AMZN",
                        "actionType": {
                            "value": "long",
                            "label": "Buy"
                        },
                        "sizeType": {
                            "value": "percentOfFunds",
                            "label": "% of funds"
                        },
                        "sizeValue": "20"
                    },
                    "isOpen": false
                },
                "width": "240",
                "height": "143",
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x":"1272.0058823529412",
                    "y":"399.66323529411767"
                }
            },
            {
                "id": "order_0-3-copy-4-copy",
                "type": "order",
                "position": {
                    "x":"1539.485294117647",
                    "y":"399.32647058823534"
                },
                "data": {
                    "state": {
                        "maximumOpenPositions": "1",
                        "delayBetweenPositions": "5",
                        "targetAsset": "GOOG",
                        "actionType": {
                            "value": "long",
                            "label": "Buy"
                        },
                        "sizeType": {
                            "value": "percentOfFunds",
                            "label": "% of funds"
                        },
                        "sizeValue": "20"
                    },
                    "isOpen": false
                },
                "width": "240",
                "height": "143",
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x": "1539.485294117647",
                    "y": "399.32647058823534"
                }
            },
            {
                "id": "order_0-3-copy-4-copy-5-copy",
                "type": "order",
                "position": {
                    "x": "1803.9470588235295",
                    "y": "398.2352941176472"
                },
                "data": {
                    "state": {
                        "maximumOpenPositions": "1",
                        "delayBetweenPositions": "5",
                        "targetAsset": "META",
                        "actionType": {
                            "value": "long",
                            "label": "Buy"
                        },
                        "sizeType": {
                            "value": "percentOfFunds",
                            "label": "% of funds"
                        },
                        "sizeValue": "20"
                    },
                    "isOpen": false
                },
                "width": "240",
                "height": "143",
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x": "1803.9470588235295",
                    "y": "398.2352941176472"
                }
            },
            {
                "id": "order_0-3-copy-4-copy-5-copy-6-copy",
                "type": "order",
                "position": {
                    "x": "2063.8823529411766",
                    "y": "401.6705882352942"
                },
                "data": {
                    "state": {
                        "maximumOpenPositions": "1",
                        "delayBetweenPositions": "5",
                        "targetAsset": "MSFT",
                        "actionType": {
                            "value": "long",
                            "label": "Buy"
                        },
                        "sizeType": {
                            "value": "percentOfFunds",
                            "label": "% of funds"
                        },
                        "sizeValue": "20"
                    },
                    "isOpen": false
                },
                "width": "240",
                "height": "143",
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x": "2063.8823529411766",
                    "y": "401.6705882352942"
                }
            }
        ],
        "edges": []
    }
}