// material-ui
import ReactHighcharts from 'react-highcharts/ReactHighstock.src'
import moment from 'moment'
import React, { useEffect, useState } from 'react';
import { Icon } from '../Component';
import strings from '../../utils/Strings';
import { Badge, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import QuoteStream from '../QuoteStream';
import { LineChart, Line, Tooltip, YAxis, ResponsiveContainer, XAxis, CartesianGrid, ReferenceLine, Label } from 'recharts';
import AssetLogo from '../AssetLogo';
import SymbolPrice from '../SymbolPrice';
import StockPeers from '../../pages/stock-peers/StockPeers';
import useAuth from '../../hooks/useAuth';

const StockChart = ({ profile, stockPeers, stockData, quotesData, currency, symbol, ohlc }) => {
  const [auOverview, setAuOverview] = useState("month-1");
  const options = { style: 'currency', currency: currency };
  const numberFormat = new Intl.NumberFormat('en-US', options);
  const [today, setDate] = React.useState(null); // Save the current date to be able to trigger an update
  const { generalData } = useAuth();
  const max = ohlc?.reduce((a, b) => Math.max(a, b[4]), 0) || 100;
  const min = ohlc?.reduce((a, b) => Math.min(a, b[4]), 100000000000) || 0;

  const firstCandle = ohlc[0];
  const lastCandle = ohlc[ohlc.length - 1];
  const chartStroke = firstCandle?.[4] > lastCandle?.[4] ? "#e85347" : "#1ee0ac";

  // const price = `$${this.props.price}`;
  // const price = currencyFormatter.format(this.props.price);
  const ranges = ['1D', '1M', '3M', '1Y', '2Y', '5Y'];
  const rangeButtons = ranges.map((range, i) => {
    return <button
      key={i}
      className="range-buttons"
      width={400}
      onClick={() => this.updateRange(range)}>
      {range}
    </button>
  });

  const groupingUnits = [
    [
      'minute',
      [1]
    ],
    [
      'hour',
      [1]
    ],
    [
      'day',
      [1]
    ],
    [
      'week',
      [1]
    ],
    // [
    //     'month',
    //     [1, 3, 6]
    // ]
  ];


  React.useEffect(() => {
    const run = () => {
      let currentTime = moment();
      let opening = moment('9:30am', 'h:mma')
      let closing = moment('4:30pm', 'h:mma')
      const inTradiningHours = currentTime.isAfter(opening) && currentTime.isBefore(closing);
      const date = inTradiningHours ? `${moment().format("dddd, Do MMM YYYY, h:mm")}` : `Closed | ${moment().format("dddd, Do MMM YYYY, h:mm")}`
      setDate(date);
    }
    const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      run();
    }, 1000);
    run();
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card className="card-bordered text-muted">
          <CardBody>
            <CardTitle>
              <b>{`${moment(label).format("Do MMM YYYY, h:mm")}`}</b>
            </CardTitle>
            <CardText>
              <b>open: </b>{`${payload[0].payload.open}`} | <b>high: </b>{`${payload[0].payload.high}`} | <b>low: </b>{`${payload[0].payload.low}`} | <b>close: </b>{`${payload[0].payload.close}`}
            </CardText>
          </CardBody>
        </Card>
      );
    }

    return null;
  };
  const { price: last, volume: quotesVolume, yearHigh, yearLow, dayLow: low, dayHigh: high, previousClose } = quotesData || {};

  const volatile = (high - low) / previousClose > .5 ? "NUTS" : (high - low) / previousClose > .1 ? "crazy day" : false
  const isEtf = profile?.[0]?.isEtf;

  const industryPE = generalData?.industriesPE?.filter(i => i.industry === profile?.industry)?.[0]?.pe;
  const peIndustryChange = ((quotesData?.pe - industryPE) / Math.abs(industryPE)) * 100
  const meaningfulChange = peIndustryChange >= 2 || peIndustryChange <= -2

  console.log(industryPE)
  console.log(peIndustryChange)
  return (
    <>
      <div className="card-title-group pb-3 g-2">
        <div style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }}>
          <div >
            {<div className="text-muted">
              <h5>{`${symbol}`}</h5>
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                {/* <h3>{strings.priceDecimal(last, 2)}</h3> */}
                <QuoteStream min={min} symbol={symbol} last={last} prevClose={previousClose} />
              </div>
              <label>{today}</label>
            </div>}
          </div>
          <div>
            {isEtf && <Badge style={{ marginRight: 8 }}>ETF</Badge>}
            <AssetLogo symbol={symbol} />
          </div>
        </div>
        {/* <div className="card-tools shrink-0 d-none d-sm-block">
          <ul className="nav nav-switch-s2 nav-tabs bg-white">
            <li className="nav-item">
              <a
                href="#navitem"
                className={auOverview === "day-7" ? "nav-link active" : "nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  setAuOverview("day-7");
                }}
              >
                7 D
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#navitem"
                className={auOverview === "month-1" ? "nav-link active" : "nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  setAuOverview("month-1");
                }}
              >
                1 M
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="analytic-ov text-muted">
        <div className="analytic-data-group analytic-ov-group g-2">
          <div className="analytic-data analytic-ov-data">
            <b>Day range</b>
            <div className="p">{strings.priceDecimal(low)}-{strings.priceDecimal(high)}</div>
          </div>
          <div className="analytic-data analytic-ov-data">
            <b>52 week range</b>
            <div className="p">{strings.priceDecimal(yearLow)}-{strings.priceDecimal(yearHigh)}</div>
            {/* <div className="change up">
              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
            </div> */}
          </div>
          <div className="analytic-data analytic-ov-data">
            <b>Volume</b>
            <div className="p">{strings.priceFormat(quotesVolume)}</div>
            {/* <div className="change up">
              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "47.5" : "80.6"}%
            </div> */}
          </div>
          <div className="analytic-data analytic-ov-data">
            <b>Market cap</b>
            <div className="p">{strings.priceFormat(profile?.mktCap)}</div>
            {/* <div className="change down">
              <Icon name="arrow-long-down"></Icon> {auOverview === "month-1" ? "12.57" : "18.21"}%
            </div> */}
          </div>
          <div className="analytic-data analytic-ov-data">
            <div style={{
              display: "inline-block"
            }}><b>PE{<Badge style={{
              display: "inline",
              marginLeft: 8
            }} className={
              !meaningfulChange ?
                "text-info" :
                peIndustryChange > 0 ?
                  "text-danger" :
                  "text-success"
            }>
              {
                (
                  (!meaningfulChange) ?
                    "Same as industry" :
                    peIndustryChange > 0 ?
                      `${strings.decimal(peIndustryChange)}% above industry` :
                      `${strings.decimal(peIndustryChange)}% below industry`)}
            </Badge>}</b></div>
            <div className="p">{strings.decimal(quotesData?.pe)} </div>
            {/* <div className="change down">
              <Icon name="arrow-long-down"></Icon> {auOverview === "month-1" ? "0.35" : "1.21"}%
            </div> */}
          </div>
        </div>
        <div className="stock-chart">
          {/* <ReactHighcharts containerProps={{ style: { height: "100%", width: "100%" } }} config={configPrice2}></ReactHighcharts> */}
          <ResponsiveContainer width="100%" height={260}>
            <LineChart data={stockData} margin={{ top: 25, bottom: 25 }}>
              <Line
                type="linear"
                dataKey="close"
                strokeWidth={2} stroke={chartStroke}
                dot={false}
                isAnimationActive={true}
              />
              <Tooltip content={<CustomTooltip />} />
              <YAxis
                hide={true}
                domain={[min, max]}
              />
              <XAxis
                // scale="time"
                // type="number"
                // type="date"
                hide={true}
                dataKey={"date"}
              />
              <ReferenceLine y={previousClose} strokeDasharray="2 2">
                <Label fontSize={12} position="insideTopRight">{`Prev. close ${previousClose}`}</Label>
              </ReferenceLine>
            </LineChart>
          </ResponsiveContainer>
        </div>
        {
          stockPeers?.[0]?.peersList && <StockPeers symbols={stockPeers?.[0]?.peersList} />
        }
      </div>
    </>
  );
};

export default StockChart;
