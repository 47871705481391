import React from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import {
  Form,
  Label,
  Modal,
  ModalBody,
  Spinner
} from "reactstrap";
import {
  Button,
  Col,
  Icon
} from "../../components/Component";
import Autocomplete from "../../components/autocomplete/Autocomplete";

export const intervalOptions = [
  { value: "1 min", label: "1 min" },
  { value: "5 min", label: "5 min" },
  { value: "15 min", label: "15 min" },
  { value: "30 min", label: "30 min" },
  { value: "1 hour", label: "1 hour" },
  { value: "1 day", label: "1 day" },
];

const BacktestFormModal = ({ isLoading, modal, closeModal, onSubmit, formData, setFormData, modalType }) => {
  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="xl" >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" && "Let's backtest your strategy"} {modalType === "edit" && "Edit backtest"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    {...register('name', { required: "This field is required" })}
                    value={formData.name}
                    placeholder="Backtest name"
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="form-control" />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label style={{
                    display: "block"
                  }} className="form-label">Interval (in minutes)</label>
                  <ul className="custom-control-group">
                    {
                      intervalOptions.map((v, i) => (
                        <li>
                          <div className="custom-control custom-radio custom-control-pro no-control">
                            <input type="radio" className="custom-control-input" name="btnRadio" id={v.value} defaultChecked={i === 0} />
                            <label className="custom-control-label" htmlFor={v.value} onClick={(e) => {
                              setFormData({ ...formData, interval: v })
                            }}>
                              {v.label}
                            </label>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <Label>Choose date timeframe</Label>
                  <div className="form-control-wrap">
                    <div className="input-daterange date-picker-range input-group">
                      <DatePicker
                        selected={formData.start}
                        onChange={(e) => setFormData({ ...formData, start: e })}
                        selectsStart
                        startDate={formData.start}
                        endDate={formData.end}
                        wrapperClassName="start-m"
                        className="form-control"
                      />{" "}
                      <div className="input-group-addon">TO</div>
                      <DatePicker
                        selected={formData.end}
                        onChange={(e) => setFormData({ ...formData, end: e })}
                        startDate={formData.start}
                        endDate={formData.end}
                        selectsEnd
                        minDate={formData.start}
                        wrapperClassName="end-m"
                        className="form-control"
                      />
                    </div>
                  </div>
                  {/* <div className="form-note">
                    Date Format <code>mm/dd/yyyy</code>
                  </div> */}
                </div>

              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Initial funds</label>
                  <div className="form-control-wrap">
                    <div className="form-text-hint">
                      <span className="overline-title">Usd</span>
                    </div>
                    <input
                      {...register('initialFunds', { required: "This field is required" })}
                      value={addCommas(removeNonNumeric(formData.initialFunds))}
                      type="text"
                      placeholder="Set initial funds for the strategy"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setFormData({ ...formData, initialFunds: e.target.value })
                      }}
                      className="form-control-lg form-control no-resize" />
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Baseline asset</label>
                  <Autocomplete
                    defaultValue="SPY"
                    onClickOption={(event, option) => {
                      setFormData({ ...formData, baselineAsset: option })
                    }}
                  />
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <input
                    value={formData.description}
                    placeholder="Your description"
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="form-control-lg form-control no-resize" />
                  {errors.description && <span className="invalid">{errors.description.message}</span>}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" onClick={(e) => {
                      e.preventDefault();
                      onSubmit(formData)
                    }}>
                      {isLoading && <Spinner size="sm" type="grow" />}
                      {modalType === "add" && "Run backtest"} {modalType === "edit" && "Update strategy"}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default BacktestFormModal;
