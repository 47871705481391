import React, { useContext, useState } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import Toggle from "./Toggle";

import { useTheme, useThemeUpdate } from '../provider/Theme';
import useAuth from "../../hooks/useAuth";

const MenuHeading = ({ heading }) => {
  return (
    <div className="nk-menu-heading">
      <h6 className="overline-title text-primary-alt">{heading}</h6>
    </div>
  );
};

const Sidebar = ({ fixed, className, ...props }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const [mouseEnter, setMouseEnter] = useState(false);

  const handleMouseEnter = () => setMouseEnter(true);
  const handleMouseLeave = () => setMouseEnter(false);

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "nk-sidebar-active": theme.sidebarVisibility,
    "nk-sidebar-mobile": theme.sidebarMobile,
    "is-compact": theme.sidebarCompact,
    "has-hover": theme.sidebarCompact && mouseEnter,
    [`is-light`]: theme.sidebar === "white",
    [`is-${theme.sidebar}`]: theme.sidebar !== "white" && theme.sidebar !== "light",
    [`${className}`]: className,
  });

  return (
    <>
      <div className={classes} id="main-sidebar">
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-menu-trigger">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon d-xl-none me-n2" icon="arrow-left" click={themeUpdate.sidebarVisibility} />
            <Toggle
              className={`nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex ${theme.sidebarCompact ? "compact-active" : ""
                }`}
              click={themeUpdate.sidebarCompact}
              icon="menu"
            />
          </div>
          <div className="nk-sidebar-brand">
            <Logo />
          </div>
        </div>
        <div className="nk-sidebar-content" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <SimpleBar className="nk-sidebar-menu">
            {/* {(
            <React.Fragment>
              <div className="nk-sidebar-widget" style={{ paddingTop: 0}}>
                <div className="user-account-info between-center">
                  <div className="user-account-main">
                    <h6 className="overline-title-alt">Portfolio</h6>
                    <div className="user-balance">
                      2.014095 <small className="currency currency-btc">BTC</small>
                    </div>
                    <div className="user-balance-alt">
                      18,934.84 <span className="currency currency-btc">BTC</span>
                    </div>
                  </div>
                  <a href="#chart" onClick={(ev) => ev.preventDefault()} className="btn btn-white btn-icon btn-light">
                    <Icon name="line-chart"></Icon>
                  </a>
                </div>
                <ul className="user-account-data gy-1">
                  <li>
                    <div className="user-account-label">
                      <span className="sub-text">Profits (7d)</span>
                    </div>
                    <div className="user-account-value">
                      <span className="lead-text">
                        + 0.0526 <span className="currency currency-btc">BTC</span>
                      </span>
                      <span className="text-success ms-2">
                        3.1% <Icon name="arrow-long-up"></Icon>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="user-account-label">
                      <span className="sub-text">Deposit in orders</span>
                    </div>
                    <div className="user-account-value">
                      <span className="sub-text">
                        0.005400 <span className="currency currency-btc">BTC</span>
                      </span>
                    </div>
                  </li>
                </ul>
                <div className="user-account-actions">
                  <ul className="g-3">
                    <li>
                      <Button color="primary" size="lg">
                        <span>Deposit</span>
                      </Button>
                    </li>
                    <li>
                      <Button color="warning" size="lg">
                        <span>Withdraw</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </React.Fragment>
          )} */}
            {/* <div style={{paddingTop: "2.5rem"}}> */}
            <MenuHeading heading="Menu" />
            <Menu />
            {/* </div> */}
            {/* {(
            <div className="nk-sidebar-widget">
              <div className="widget-title">
                <h6 className="overline-title">
                  Your portfolios
                </h6>
                <Link to={`${process.env.PUBLIC_URL}/portfolios`}  className="link">
                  View All
                </Link>
              </div>
              <ul className="wallet-list">
                {user?.portfolios?.map(p => {
                  return (
                    <li className={`wallet-item`}>
                  <Link to={`${process.env.PUBLIC_URL}/portfolios/1`} className={`${theme?.skin === "dark" ? "is-dark" : "is-light"}`}>
                    <div className="wallet-icon">
                      <Icon name={p?.icon || "briefcase"}></Icon>
                    </div>
                    <div className="wallet-text">
                      <h6 className="wallet-name">{p?.name}</h6>
                      <span className="wallet-balance">
                        {strings.price(p?.funds)}
                      </span>
                    </div>
                  </Link>
                </li>
                  )
                })}
              </ul>
            </div>
          )} */}
          </SimpleBar>
        </div>
      </div>
      {theme.sidebarVisibility && <div
        onClick={themeUpdate.sidebarVisibility}
        className="nk-sidebar-overlay"></div>}
    </>
  );
};
export default Sidebar;
