const Target = (props) =>{
    return(
        <svg fill="currentColor" viewBox="0 0 512 512" {...props}>
            <path d="M240.6 177.3c-51.6 0-93.6 42-93.6 93.6s42 93.6 93.6 93.6c51.6 0 93.6-42 93.6-93.6 0-22.3-7.9-42.9-21-59l28.9-28.9c20.5 23.6 32.9 54.3 32.9 87.9 0 74.1-60.3 134.4-134.4 134.4-74.1 0-134.4-60.3-134.4-134.4 0-74.1 60.3-134.4 134.4-134.4 33.6 0 64.4 12.4 87.9 32.9l-28.9 28.9c-16.1-13.1-36.6-21-59-21zm-6.8 100.4c1.9 1.9 4.3 2.8 6.8 2.8s4.9-.9 6.8-2.8l52.1-52.1c9.7 12.6 15.5 28.3 15.5 45.3 0 41-33.4 74.4-74.4 74.4-41 0-74.4-33.4-74.4-74.4 0-41 33.4-74.4 74.4-74.4 17.1 0 32.8 5.8 45.3 15.5l-52.1 52.1c-3.7 3.7-3.7 9.8 0 13.6zm200.9-16.4h-13.5c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6h13.5c-4.9 99.5-85 179.6-184.5 184.5v-13.5c0-5.3-4.3-9.6-9.6-9.6s-9.6 4.3-9.6 9.6V465c-99.5-4.9-179.6-85-184.5-184.5H60c5.3 0 9.6-4.3 9.6-9.6s-4.3-9.6-9.6-9.6H46.5c4.9-99.5 85-179.6 184.5-184.5v13.5c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6V76.8c46.4 2.3 88.5 20.9 120.8 50.1l-28.9 28.9c-27.1-23.9-62.6-38.5-101.5-38.5-84.7 0-153.6 68.9-153.6 153.6s69 153.5 153.6 153.5c84.7 0 153.6-68.9 153.6-153.6 0-38.9-14.5-74.4-38.5-101.5l28.9-28.9c29.3 32.4 47.9 74.5 50.1 120.9zm8.6-230.4l2.4 26.3c.4 4.6 4.1 8.3 8.7 8.7l26.3 2.4-34 34-33.7-3.8-3.7-33.6 34-34zm5.8 90.9c.4 0 .7.1 1.1.1 2.5 0 5-1 6.8-2.8l51.7-51.7c2.6-2.6 3.5-6.6 2.2-10.1-1.3-3.5-4.4-6-8.2-6.3L464 47.5l-3.5-38.7c-.3-3.7-2.8-6.9-6.3-8.1-3.5-1.3-7.4-.4-10.1 2.2l-51.7 51.7c-2.1 2.1-3.1 5-2.7 7.8l4.6 41.2-9.7 9.7c-35.8-32.7-82.7-53.5-134.4-55.8V39.3c0-5.3-4.3-9.6-9.6-9.6S231 34 231 39.3v18.2c-110.1 5-198.8 93.6-203.7 203.8H9.1c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6h18.2c4.9 110.1 93.6 198.8 203.7 203.7v18.2c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6v-18.2c110.1-4.9 198.8-93.6 203.7-203.7h18.2c5.3 0 9.6-4.3 9.6-9.6s-4.3-9.6-9.6-9.6h-18.2c-2.3-51.7-23-98.6-55.8-134.4l9.7-9.7 41.3 4.6z"></path>
        </svg>
    )
}


export default Target
