import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Spinner } from "reactstrap";
import { getQuotes } from "../../api";
import AssetLogo from "../../components/AssetLogo";
import { Icon } from '../../components/Component';
import useAuth from "../../hooks/useAuth";
import strings from "../../utils/Strings";

const WatchlistTableItem = ({ i, tick, quote, onClick }) => {
  const last = parseFloat(tick?.trade?.last) || quote?.last;
  const prevClose = quote?.prevclose;
  const change = last - prevClose;
  const changePct = (change / prevClose) * 100;
  return (
    <div className="analytic-data analytic-wp-data" onClick={onClick} style={{
      cursor: 'pointer'
    }}>
      <div className="analytic-wp-graph" style={{
      }}>
        <div className="title" >
          {i}
        </div>
        <div className="analytic-wp-ck">
          <AssetLogo style={{}} symbol={i} />
        </div>
      </div>
      <div className="analytic-wp-text">
        <div className="amount amount-sm">{strings.priceDecimal(last, 2)}</div>
        {changePct !== 0 && <div className={changePct > 0 ? "change up" : changePct < 0 ? "change down" : "change"}>
          <Icon name={changePct > 0 ? "arrow-long-up" : changePct < 0 ? "arrow-long-down" : "change"}></Icon>{strings.percentSignFormatter(changePct)}
        </div>}
        <div className="subtitle"></div>
      </div>
    </div>
  )
}
const WatchlistTable = ({ data, itemOnly }) => {
  const { user } = useAuth();
  const [allMessages, setAllMessages] = useState({});
  const [quotes, setQuotes] = useState(null);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(null);
  const navigate = useNavigate();

  const { lastMessage, getWebSocket, lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(`${process.env.REACT_APP_API_WS_URL}?userId=${user?.uid}`, {
    // share: false,
    // filter: () => false,
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    onClose: (e) => {
      console.log(e)
      console.log('WebSocket connection closed.');
    },
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent)
      return true;
    },
  });

  useEffect(() => {
    const loadQuotes = async () => {
      setIsLoadingQuotes(true)
      getQuotes({ symbols: data?.symbols.join(","), greeks: true },
        (res) => {
          console.log(res)
          setQuotes(res);
          setIsLoadingQuotes(false)
        }, (err) => {
          console.error(err)
          setIsLoadingQuotes(false)
        })
    }

    (data?.symbols !== null) && loadQuotes();
  }, [data]);

  useEffect(() => {
    if (lastJsonMessage?.symbol) {
      if (lastJsonMessage.type === 'summary') {
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], summary: lastJsonMessage };
      }
      else
        allMessages[lastJsonMessage?.symbol] = { ...allMessages[lastJsonMessage?.symbol], trade: lastJsonMessage };
    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      data?.symbols?.length && sendJsonMessage({
        type: "watchlistStream",
        symbols: data?.symbols
      })
    }
  }, [readyState, data?.symbols])

  if (isLoadingQuotes) return <Spinner />;

  if (itemOnly) {
    return (
      <div style={{
        margin: "16px 0px"
      }}>
        {data?.symbols?.map(i => {
          const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === i)[0] : quotes?.quotes?.quote
          return (
            <WatchlistTableItem onClick={(() => {
              navigate(`/one-pager/${i}`)
            })} i={i} tick={allMessages[i]} quote={q} />
          )
        })}
      </div>
    )
  }
  return (
    <div className="invest-ov gy-2">
      {data?.symbols?.map(i => {
        const q = Array.isArray(quotes?.quotes?.quote) ? quotes?.quotes?.quote?.filter(q => q.symbol === i)[0] : quotes?.quotes?.quote
        return (
          <WatchlistTableItem onClick={(() => {
            navigate(`/one-pager/${i}`)
          })} i={i} tick={allMessages[i]} quote={q} />
        )
      })}
    </div>
  )
};

export default WatchlistTable;
