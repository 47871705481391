import Swal from 'sweetalert2';
   
export const handleError = () => {
    Swal.fire({
        title: "Something went wrong :(",
        text: "If this happenes again, please let us know at support@traid.ninja",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "Reload",
        confirmButtonText: "Back home",
        confirmButtonColor: "#8CD4F5",
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.replace('/');
        }
        else if (result.isDismissed) {
            window.location.reload();

        }
    });
};

export default {
    handleError
}