import { TourProvider } from "@reactour/tour";

const TourWrapper = ({ children }) => {
    //     const disableBody = (target) => disableBodyScroll(target)
    //   const enableBody = (target) => enableBodyScroll(target)
    const steps = [
        {
            selector: '#bot-header',
            content: () => (
                <>
                    <h6>This is the bot header</h6>
                    <label>You can change here the bot color and save your bot after making changes.</label>
                </>
            )
        },
        {
            selector: '#bot-general-card',
            content: () => (
                <>
                    <h6>This is the configuration panel ⚙️</h6>
                    <label>You can change here your bot name, the interval its going to trade in and add more assets.</label>
                </>
            )
        },
        {
            selector: '#bot-research-panel',
            content: () => (
                <>
                    <h6>This is your research panel 📈</h6>
                    <label>After adding assets, you can research and draft your bot strategy for each of them.</label>
                    <label>Check what make the stock rise, what make it falls, and more importantly - when it all happenes.</label>
                </>
            ),
        },
        {
            selector: '.react-flow',
            content: () => (
                <>
                    <h6>This is your bot flow builder 👷‍♂️</h6>
                    <label>For every asset, you start with an order node and move on with our context menu (right click on every node) to see your options.</label>
                </>
            ),
        },
        {
            selector: '#bot-flow-description-card',
            content: () => (
                <>
                    <h6>This is your bot strategy flow🤖</h6>
                    <label>It will be filling up automatically as you build your bot flow to visualize better your strategy.</label>
                </>
            )
        },
        {
            selector: '#bot-backtest-preview',
            content: () => (
                <>
                    <h6>Backtest glance</h6>
                    <label>This is a backtest preview window.</label>
                    <label>When making some changes - run some quick backtest to see how your bot performs.</label>
                </>
            ),
        },
        {
            selector: '#bot-backtest-full',
            content: () => (
                <>
                    <h6>Backtest panel</h6>
                    <label>This is your backtest panel.</label>
                    <label>A detailed view of your backtests, if you will 🙃</label>
                    <label>You can see here the backtest performance, simulated returns and transactions.</label>
                    <label>You can also change the backtest period and initial funds.</label>
                </>
            ),
        },
    ]

    const styles = {
        // maskWrapper: (base) => ({
        //     ...base,
        //     color: 'red',
        // }),
        highlightedArea: (base, { x, y }) => ({
            ...base,
            x: x + 10,
            y: y + 10,
        }),
        badge: (base) => ({ ...base, backgroundColor: 'white', color: 'black' }),
        // dot: (base) => ({ ...base, backgroundColor: 'grey' })
    }

    return (
        <TourProvider
            styles={styles}
            steps={steps}
            dot="showNumber"
            onClickHighlighted={(e) => {
                e.stopPropagation()
                console.log('No interaction')
            }}
            disableInteraction
        // afterOpen={disableBody} beforeClose={enableBody}
        >
            {children}
        </TourProvider>
    )
}

export default TourWrapper;