import React, { useEffect, useState } from "react";
import Autocomplete from "../../../../components/autocomplete/Autocomplete";
import { NSComponent, RSelect } from "../../../../components/Component";

const Trend = ({metadata, setMetadata}) => {
    const {timeframe, type, assetSymbol} = metadata;

  return (
    <>
    <div className="form-group" style={{
      width: '100%',
    }}>
        <label className="form-label">Asset</label>
      <Autocomplete defaultValue={assetSymbol} onClickOption={(e, option) => {
        setMetadata({...metadata, assetSymbol: option})
      }} />
    </div>
    <div className="form-group" style={{
      width: '100%',
    }}>
        <label className="form-label">Timeframe</label>
        <RSelect value={timeframe} onChange={(e) => {
                setMetadata({...metadata, timeframe: e})
            }} className="nodrag" options={[
              {value: "candles", label: "Candles"},
              {value: "days", label: "Days"},
            ]} />
    </div>
    <div className="form-group" style={{
      width: '100%',
    }}>
        <label className="form-label">Type</label>
        <RSelect value={type} onChange={(e) => {
                setMetadata({...metadata, type: e})
            }} className="nodrag" options={[
              {value: "up", label: "Up"},
              {value: "down", label: "Down"},
            ]} />
    </div>
    </>
  );
};
export default Trend;