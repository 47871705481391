import React, { useState } from 'react';
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewCard, TooltipComponent } from '../../../components/Component';
import { Badge, Button, Card, CardBody, CardHeader, CardTitle, Col, Form, Row } from 'reactstrap';
import { useMyStore } from './ReactFlowBuilder/store';

const SymbolDesc = ({ symbolDesc, symbolIndex }) => {
  return (
    <Col lg={12}>
      <Button color="gray" style={{
        textWrap: "wrap",
        textAlign: "left",
        // marginBottom: 8
      }}>{`${symbolIndex}. ${symbolDesc.str}`}</Button>
      <CardBody>
        {
          symbolDesc?.conditionGroups?.length > 0 && symbolDesc?.conditionGroups?.map((cg, cgIndex) => {
            if (cg.type === "logical") {
              return <Badge pill color="warning" className="cg-and-or-operator" style={{
                marginRight: 4
              }}>{cg.value}</Badge>
            }
            return (
              <>
                <Badge pill style={{
                  textWrap: "wrap"
                }} color="warning" className="cg-to-condition-if-operator">
                  {cg.logicalType}
                </Badge>
                <CardBody>
                  {cg.rules.map((rule, index) => {
                    if (index % 2 === 0) {
                      return (
                        <Badge color="outline-gray" style={{
                          textWrap: "wrap"
                        }} >{rule}<br /></Badge>
                      )
                    } else {
                      return (<><Badge pill color="warning" style={{
                        textWrap: "wrap",
                        marginBottom: 4,
                        marginTop: 4,
                      }} className="coinditions-and-or-operator">{rule.value}</Badge><br /></>)
                    }
                  })}
                </CardBody>
              </>
            )
          })
        }
      </CardBody>
    </Col>
  );
}

const FlowDescription =
  ({
    flowDescription, editMode
  }) => {
    const { botName, setTab, undoChange, redoChange, changes, changesIndex } = useMyStore();

    return (
      <PreviewCard id="bot-flow-description-card">
        <CardTitle>
          <h6>{botName} flow</h6>
          {editMode && <div style={{
            display: 'flex',
            gap: 8
          }}>
            <Button disabled={changesIndex <= 0} size="sm" onClick={() => undoChange()} >undo <Icon style={{
              marginLeft: 8
            }} name="undo" /></Button>
            <Button disabled={changesIndex === changes.length - 1} size="sm" onClick={() => redoChange()} >redo <Icon style={{
              marginLeft: 8
            }} name="redo" /></Button>
          </div>}
        </CardTitle>
        <Row className="g-2">
          {(flowDescription?.length > 0) ? flowDescription?.map((f, i) => (
            <div style={{
              // border: tab === f.symbol ? "1px solid" : ""
            }}
              onClick={(e) => {
                setTab(f.symbol)
              }}
            >
              <Col lg={12}>
                <Row className="g-2">
                  <SymbolDesc symbolDesc={f} symbolIndex={i + 1} />
                </Row>
              </Col>
            </div>
          )
          ) : null}
        </Row>
      </PreviewCard>
    );
  };

export default FlowDescription;