const BannerOneData = [
    {
        text: 'Landing',
        to: 'landing',
        subMenu: [
            {
                text: 'Landing Page- v1',
                to: '/',
            }, {
                text: 'Landing Page- v2',
                to: '/indextwo',
            }, {
                text: 'Landing Page- v3',
                to: '/indexthree'
            },
            {
                text: 'Landing Page- v4',
                to: '/indexfour'
            },
            {
                text: 'Landing Page- v5',
                to: '/indexfive'
            },
            {
                text: 'Landing Page- v6',
                to: '/indexsix'
            },
        ]
    },
    {
        text: 'Pages',
        to: 'landing',
        subMenu: [
            {
                text: 'Auth Pages',
                to: 'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    },
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    },
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-reset"
                    }
                ]
            }, {
                text: 'Error Pages',
                to: 'errors',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/errors/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/errors/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/errors/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/errors/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text: 'Home',
        to: '#home'
    },
    {
        text: 'Service',
        to: '#service'
    },
    {
        text: 'Reviews',
        to: '#reviews'
    },
    {
        text: 'Features',
        to: '#feature'
    },
    {
        text: 'Faqs',
        to: '#faqs'
    }
]
const BannerTwoData = [
    {
        text: 'Landing',
        to: 'landing',
        subMenu: [
            {
                text: 'Landing Page- v1',
                to: '/',
            }, {
                text: 'Landing Page- v2',
                to: '/indextwo',
            }, {
                text: 'Landing Page- v3',
                to: '/indexthree'
            },
            {
                text: 'Landing Page- v4',
                to: '/indexfour'
            },
            {
                text: 'Landing Page- v5',
                to: '/indexfive'
            },
            {
                text: 'Landing Page- v6',
                to: '/indexsix'
            },
        ]
    },
    {
        text: 'Pages',
        to: 'landing',
        subMenu: [
            {
                text: 'Auth Pages',
                to: 'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    },
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    },
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-reset"
                    }
                ]
            }, {
                text: 'Error Pages',
                to: 'errors',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/errors/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/errors/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/errors/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/errors/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text: 'Home',
        to: '#home'
    },
    {
        text: 'Service',
        to: '#service'
    },
    {
        text: 'Features',
        to: '#feature'
    },
    {
        text: 'Pricing',
        to: '#pricing'
    },
    {
        text: 'Reviews',
        to: '#reviews'
    },
]

const BannerTwoIcon = [
    { icon: 'star-fill' },
    { icon: 'star-fill' },
    { icon: 'star-fill' },
    { icon: 'star-fill' },
    { icon: 'star-fill' }
]

const BannerThreeData = [
    {
        text: 'Landing',
        to: 'landing',
        subMenu: [
            {
                text: 'Landing Page- v1',
                to: '/',
            }, {
                text: 'Landing Page- v2',
                to: '/indextwo',
            }, {
                text: 'Landing Page- v3',
                to: '/indexthree'
            },
            {
                text: 'Landing Page- v4',
                to: '/indexfour'
            },
            {
                text: 'Landing Page- v5',
                to: '/indexfive'
            },
            {
                text: 'Landing Page- v6',
                to: '/indexsix'
            },
        ]
    },
    {
        text: 'Pages',
        to: 'landing',
        subMenu: [
            {
                text: 'Auth Pages',
                to: 'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    },
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    },
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-reset"
                    }
                ]
            }, {
                text: 'Error Pages',
                to: 'errors',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/errors/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/errors/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/errors/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/errors/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text: 'Home',
        to: '#home'
    },
    {
        text: 'Products',
        to: '#product'
    },
    {
        text: 'Plugins',
        to: '#plugin'
    },
    {
        text: 'Pricing',
        to: '#pricing'
    },
    {
        text: 'Reviews',
        to: '#reviews'
    },
]
const BannerFourData = [
    {
        text: 'Landing',
        to: 'landing',
        subMenu: [
            {
                text: 'Landing Page- v1',
                to: '/',
            }, {
                text: 'Landing Page- v2',
                to: '/indextwo',
            }, {
                text: 'Landing Page- v3',
                to: '/indexthree'
            },
            {
                text: 'Landing Page- v4',
                to: '/indexfour'
            },
            {
                text: 'Landing Page- v5',
                to: '/indexfive'
            },
            {
                text: 'Landing Page- v6',
                to: '/indexsix'
            },
        ]
    },
    {
        text: 'Pages',
        to: 'landing',
        subMenu: [
            {
                text: 'Auth Pages',
                to: 'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    },
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    },
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-reset"
                    }
                ]
            }, {
                text: 'Error Pages',
                to: 'errors',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/errors/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/errors/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/errors/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/errors/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text: 'Home',
        to: '#home'
    },
    {
        text: 'Features',
        to: '#features'
    },
    {
        text: 'Pricing',
        to: '#pricing'
    },
    {
        text: 'Story',
        to: '#story'
    },
]

const BannerFiveData = [
    {
        text: 'AI Chat',
        to: 'traid-chat',
    },
    {
        text: 'Login',
        to: 'login',
    },
]

const LoggedInMenuData = [
    {
        text: 'Home',
        to: 'home',
    },
    {
        text: 'AI Chat',
        to: 'traid-chat',
    },
]

const BannerSixData = [
    {
        text: 'Landing',
        to: 'landing',
        subMenu: [
            {
                text: 'Landing Page- v1',
                to: '/',
            }, {
                text: 'Landing Page- v2',
                to: '/indextwo',
            }, {
                text: 'Landing Page- v3',
                to: '/indexthree'
            },
            {
                text: 'Landing Page- v4',
                to: '/indexfour'
            },
            {
                text: 'Landing Page- v5',
                to: '/indexfive'
            },
            {
                text: 'Landing Page- v6',
                to: '/indexsix'
            },
        ]
    },
    {
        text: 'Pages',
        to: 'landing',
        subMenu: [
            {
                text: 'Auth Pages',
                to: 'auth',
                subMenu: [
                    {
                        text: "Login / Signin",
                        to: "/pages/auths/auth-login"
                    },
                    {
                        text: "Register / Signup",
                        to: "/pages/auths/auth-register",
                    },
                    {
                        text: "Forgot Password",
                        to: "/pages/auths/auth-reset"
                    }
                ]
            }, {
                text: 'Error Pages',
                to: 'errors',
                subMenu: [
                    {
                        text: "404 Classic",
                        to: "/pages/errors/404-classic",
                    },
                    {
                        text: "404 Modern",
                        to: "/pages/errors/404-modern",
                    },
                    {
                        text: "504 Classic",
                        to: "/pages/errors/504-classic",
                    },
                    {
                        text: "504 Modern",
                        to: "/pages/errors/504-modern",
                    },
                ]
            },
        ]
    },
    {
        text: 'Home',
        to: '#home'
    },
    {
        text: 'Features',
        to: '#feature'
    },
    {
        text: 'Previews',
        to: '#previews'
    },
    {
        text: 'Reviews',
        to: '#reviews'
    },
]

export { BannerOneData, BannerTwoData, BannerThreeData, BannerFourData, BannerFiveData, BannerSixData, BannerTwoIcon, LoggedInMenuData }
