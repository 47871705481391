
import { CardBody, CardTitle, Col, Label, Row, Spinner } from "reactstrap";
import { Icon, PreviewCard } from "../../../components/Component";
import strings from "../../../utils/Strings";
import ReactDatePicker from "react-datepicker";
import { addCommas, removeNonNumeric } from "../../../utils/Utils";
import Play from "../../../landingApp/components/svg/Play";
import { StyledIcon } from "../../../landingApp/components/icon/Icon";
import BacktestChart from "./BacktestChart";
import FlowDescription from "./FlowDescription";
import SimulatedReturns from "./SimulatedReturns";
import BacktestPerformance from "./BacktestPerformance";
import BacktestOrders from "./BacktestOrders";
import PortfolioStatistics from "../../portfolios/PortfolioStatistics";
import { useMyStore } from "./ReactFlowBuilder/store";
import { useEffect, useState } from "react";
import moment from "moment";
import PositionDefault from "../../portfolios/positions/PositionDefault";
import BacktestPositions from "../BacktestPositions";

const BacktestFullview = ({ botData, formData, setFormData, mode, editMode, onClickRunBacktest, lastFull, }) => {
    const { flowDescription, isRunningBacktest, interval } = useMyStore();
    const [minDate, setMinDate] = useState(moment().subtract(5, "years"))
    const onRangeChange = ({ date, type }) => {
        setFormData({ ...formData, [type]: date });
    };

    useEffect(() => {
        switch (interval.value) {
            case "1 min":
                setMinDate(moment().subtract(3, "months"));
                break;
            case "15 min":
                setMinDate(moment().subtract(1, "years"));
                break;
            default:
                setMinDate(moment().subtract(5, "years"));
                break;
        }
    }, [interval])

    // useEffect(() => {
    //     if (!isRunningBacktest && ((!botData?.backtests) || (botData?.backtests?.length === 0))) {
    //         onClickRunBacktest({});
    //     }

    // }, [botData, onClickRunBacktest, mode]);
    return (
        <PreviewCard id="bot-backtest-full">
            <CardTitle>

                <h6 style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
                >
                    Backtest panel
                    {
                        lastFull && editMode && (
                            <span
                                onClick={({ event }) => onClickRunBacktest({ event })}
                                style={{
                                    display: "inline"
                                }}>
                                {(isRunningBacktest || lastFull?.status === "running") && <Spinner type="grow" size="sm" />}
                                < Icon title="Rerun" name="repeat" className={"text-warning"} />
                            </span>
                        )
                    }
                </h6>
            </CardTitle>
            <Row className="g-1">
                {(mode === "full" && editMode) && (
                    <>
                        <Col lg={5}>
                            <div className="form-group">
                                <div className="form-note">
                                    Period
                                </div>
                                <div className="form-control-wrap">
                                    <div className="input-daterange date-picker-range input-group">
                                        <ReactDatePicker
                                            selected={formData.start}
                                            onChange={date => onRangeChange({ date, type: "start" })}
                                            selectsStart
                                            startDate={formData.start}
                                            endDate={formData.end}
                                            wrapperClassName="start-m"
                                            className="form-control date-picker"
                                            minDate={new Date(minDate)}
                                            maxDate={new Date(moment().subtract(1, "days"))}
                                        />{" "}
                                        <ReactDatePicker
                                            selected={formData.end}
                                            onChange={date => onRangeChange({ date, type: "end" })}
                                            startDate={formData.start}
                                            endDate={formData.end}
                                            selectsEnd
                                            minDate={formData.start}
                                            wrapperClassName="end-m"
                                            className="form-control date-picker"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <div className="form-note">
                                        Funds
                                    </div>
                                    <input
                                        //   {...register('initialFunds', { required: "This field is required" })}
                                        value={addCommas(removeNonNumeric(formData.initialFunds))}
                                        type="text"
                                        onChange={(e) => {
                                            setFormData({ ...formData, initialFunds: e.target.value })
                                        }}
                                        className="form-control-sm form-control no-resize backtest-input"
                                    />
                                </div>
                            </div>
                        </Col>
                    </>
                )}
                <div>
                    {
                        (!lastFull) && (
                            <Col lg={12}>
                                <div style={{
                                    minHeight: mode === "preview" ? 200 : 400,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: "center",
                                }}
                                >
                                    {isRunningBacktest ? <Spinner size="sm" type="grow" /> : <StyledIcon onClick={({ event }) => onClickRunBacktest({ event })} className="styled-icon styled-icon-4x styled-icon-s5 text-warning">
                                        <Play
                                            style={{
                                                cursor: 'pointer',
                                            }} />
                                    </StyledIcon>}
                                </div>
                            </Col>
                        )
                    }

                    {(lastFull?.status === "done") && (
                        <>
                            <Col lg={12}>
                                {/* <BacktestBalanceChart
                            data={backtestRes} /> */}
                                <BacktestChart
                                    backtestMode={"full"}
                                    botData={botData}
                                    backtestData={lastFull} />
                            </Col>
                            {mode === "full" && <Row className="g-gs" style={{
                                marginTop: 64,
                                display: 'flex',
                                justifyContent: 'center',
                            }} >
                                <Col lg={10}>
                                    <Row className="g-gs" style={{
                                        // borderTop: '1px solid',
                                        // borderRight: '1px solid',
                                        // borderLeft: '1px solid',
                                        // borderTopLeftRadius: 16,
                                        // borderTopRightRadius: 16,
                                    }}>
                                        {!editMode && <Col lg={12}>
                                            {flowDescription && <FlowDescription editMode={editMode} flowDescription={flowDescription} />}
                                        </Col>}
                                        <Col lg={12}>
                                            <SimulatedReturns lastFull={lastFull} />
                                        </Col>
                                        <Col lg={12}>
                                            <PreviewCard>
                                                <BacktestPerformance backtestData={lastFull} />
                                            </PreviewCard>
                                        </Col>
                                        {/* <Col lg={12}>
                                            <PreviewCard>
                                                <BacktestPositions positionsData={lastFull?.portfolio?.positions || []} />
                                            </PreviewCard>
                                        </Col> */}
                                        <Col lg={8}>
                                            <PreviewCard>
                                                <BacktestOrders backtestData={lastFull} />
                                            </PreviewCard>
                                        </Col>
                                        <Col lg={4}>
                                            <PreviewCard>
                                                <PortfolioStatistics mode="Bot" portfolioData={lastFull?.portfolio} />
                                            </PreviewCard>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}
                        </>
                    )}
                </div>
            </Row>
        </PreviewCard >
    );
}

export default BacktestFullview;