export const useParseBotDataTable = ({ botsData }) => {

    const allBots = [];
    if (botsData) {
        botsData.map(bot => {
            allBots.push({
                _id: bot?._id,
                color: bot.color,
                name: bot.name,
                symbols: bot.symbols,
                interval: bot.interval.label,
                portfolioChange: bot?.botStats?.portfolioChange,
                maxDD: bot?.botStats?.maxDD,
                stdDev: bot?.botStats?.stdDev,
                sharpRatio: bot?.botStats?.sharpRatio,
                calmarRatio: bot?.botStats?.calmarRatio,
                backtest: bot?.botStats?.backtest,
                userUID: bot?.userUID,
                numberOfCopies: bot.numberOfCopies
            })
        })
    }

    return allBots;

}