import React from 'react'
import { useFileManager } from "../components/Context";
import QuickAccess from '../components/QuickAccess';
import ViewFilter from '../components/ViewFilter';
import Files from '../components/Files';
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../../components/Component";
import { useQuery } from '@apollo/client';
import { GET_COMPANY_FILES, STRATEGY_MANY } from '../../../../api/graphql';
import { Spinner } from 'reactstrap';

const Home = () => {

  const {fileManager} = useFileManager();
  
  const quickView = [ ...fileManager.files.filter((item) => item.starred === true && !item.deleted)]
  const allFiles = [ ...fileManager.files.filter(item => !item.deleted)]

  const { data, refetch, fetchMore, loading, error } = useQuery(STRATEGY_MANY, { 
    variables: {
      "filter": {
        "template": true
      }
    }
  });

  if(loading) return <Spinner />
  const files = data?.strategyMany;
  return (
    <>
        {/* {quickView.length > 0 && <QuickAccess files={quickView} />} */}
        <Block className="nk-fmg-listing">
            <BlockHead size="xs">
              <BlockBetween className="g-2">
                <BlockHeadContent>
                  <BlockTitle tag="h6">Top strategies</BlockTitle>
                </BlockHeadContent>
                <BlockHeadContent>
                  <ViewFilter />
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Files files={files} />
        </Block>
    </>
  )
}

export default Home