import { memo, createContext, useReducer } from 'react';
import { initialState, app } from "../store/reducers/app.js";


export const AppContext = createContext();

export const AppProvider = ({children}) => {
  const [state, dispatch] = useReducer(app, initialState);
  
  return (
    <AppContext.Provider
            value={{
              state,
              dispatch
            }}
          >
             {children}
          </AppContext.Provider>
  );
}