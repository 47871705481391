import React, { useEffect, useState } from "react";
import Autocomplete from "../../../../../components/autocomplete/Autocomplete";
import CandlesDelta from "../CandlesDelta";

const SMA = ({metadata, setMetadata}) => {
  const {assetSymbol, smaPeriod} = metadata;
const [asset, setAsset] = useState(assetSymbol);
const [period, setPeriod] = useState(smaPeriod);
  return (
    <>
    <div className="form-group" style={{
      width: '100%',
    }}>
      <CandlesDelta metadata={metadata} setMetadata={setMetadata} />
      <label className="form-label">Asset</label>
      <Autocomplete defaultValue={asset} className="form-control" onClickOption={(e, option) => {
        setAsset(option);
        setMetadata({...metadata, assetSymbol: option})
      }} />
    </div>
     <div className="form-group">
     <label className="form-label">Period</label>
     <input
       type="number"
       value={period}
       placeholder="SMA period"
       onChange={(e) => {
        e.preventDefault();
        setPeriod(e.target.value)
        setMetadata({...metadata, smaPeriod: e.target.value})
      }}
       className="form-control" />
   </div>
   </>
  );
};
export default SMA;