import React from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Card } from "reactstrap";
import { addPaymentMethod, deletePaymentMethod, updatePaymentMethod } from "../../../api";
import { Col, Row } from "../../../components/Component";
import useAuth from "../../../hooks/useAuth";
import strings from "../../../utils/Strings";
import { getCCIcon, getStripeClientIdKey } from "../../../utils/Utils";
import { svgData } from "../../components/crafted-icons/NioIconData";
const helpSupportIcon = svgData.filter((icon) => icon.slug === "help-support")[0]

const Subscriptions = ({ paymentMethods, data }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [item] = data?.filter(i => i.status === "active")

    const onClickUpdatePaymentMethod = async (subscriptionId, paymentMethodId) => {
        console.log("Update default payment method")
        const res = await updatePaymentMethod({
            subscriptionId,
            paymentMethodId
        }, (res) => {
            window.location = res?.url
        }, (err) => {
            console.log(err);
        })

        return res;
    }

    if (!item) {
        return <Card key={0} className="card-bordered sp-plan">
            <Row className="no-gutters">
                <Col md="8">
                    <div className="sp-plan-info card-inner">
                        <Row className="gx-0 gy-3">
                            <Col xl="9" sm="8">
                                <div className="sp-plan-name">
                                    <h5 className="title">{"Free plan"}</h5>
                                    <p>You are currently on our free plan.<br />
                                        Upgrade now to unlock more ninja power!
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="sp-plan-desc card-inner">
                        <Row>
                            <Col col="6" lg="3">
                                <p className="cc-pay"><h6 className="title">Max strategies</h6> 3</p>
                            </Col>
                            <Col col="6" lg="3">
                                <p className="cc-pay"><h6 className="title">Max backtests per strategy</h6> 3</p>
                            </Col>
                            <Col col="6" lg="3">
                                <p className="cc-pay"><h6 className="title">Max watchlists</h6> 3</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md="4">
                    {
                        <div className="sp-plan-action card-inner">
                            <div className="sp-plan-btn">
                                <a className="btn btn-primary" onClick={() => navigate(`${process.env.PUBLIC_URL}/plans`)}><span>Upgrade now</span></a>
                            </div>
                            <div className="sp-plan-note text-md-center">
                                {/* <p>Next Billing on <span>{item.nextbilling}</span></p> */}
                            </div>
                        </div>
                    }
                </Col>
            </Row>
        </Card>
    }

    const onClickAddPaymentMethod = async (e) => {
        e.preventDefault();
        const res = await addPaymentMethod({
            redirectURL: window.location.href,
            customerId: user[getStripeClientIdKey()],
        }, (res) => {
            res?.url ? window.location = res?.url : console.log(res)
        }, (err) => {
            console.log(err);
        })
    }

    const onClickDeletePaymentMethod = async (p) => {
        const res = await deletePaymentMethod({
            redirectURL: window.location.href,
            paymentMethodId: p.id,
        }, (res) => {
            res?.url ? window.location = res?.url : console.log(res)
        }, (err) => {
            console.log(err);
        })
    }

    const isCancledAtPeriod = item.cancel_at_period_end;
    const ccIcon = getCCIcon(item?.default_payment_method?.card?.brand)
    return (
        <React.Fragment>
            <Card key={0} className="card-bordered sp-plan">
                <Row className="no-gutters">
                    <Col md="8">
                        <div className="sp-plan-info card-inner">
                            <Row className="gx-0 gy-3">
                                <Col xl="9" sm="8">
                                    <div className="sp-plan-name">
                                        <h5 className="title text-primary">{item.plan.product.name} <Badge color={isCancledAtPeriod ? 'warning' : item.status == 'active' ? 'success' : 'light'} className="rounded-pill">{isCancledAtPeriod ? "cancled" : item.status}</Badge></h5>
                                        {isCancledAtPeriod && <div>{`You cancled your subscription on `}<b>{`${strings.unix(item?.canceled_at)}`}</b></div>}
                                        {isCancledAtPeriod && <div>{`Your subscription will end at `}<b>{`${strings.unix(item?.cancel_at)}`}</b></div>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="sp-plan-desc card-inner gy-3">
                            <Row>
                                <Col col="6" lg="3">
                                    <p className="cc-pay"><h6 className="title">Started on</h6> {strings.unix(item.start_date)}</p>
                                </Col>
                                <Col col="6" lg="3">
                                    <p className="cc-pay"><h6 className="title">Recurring</h6> {isCancledAtPeriod ? "Cancled" : 'Yes'}</p>
                                </Col>
                                <Col col="6" lg="3">
                                    <p className="cc-pay"><h6 className="title">Price</h6> {strings.priceDecimal(item.plan.amount / 100)}</p>
                                </Col>
                            </Row>
                            <Row style={{
                                marginTop: 16
                            }}>
                                <Col col="6" lg="3">
                                    <p className="cc-pay"><h6 className="title">Max strategies</h6> {item?.plan?.product?.metadata?.maxStrategies} </p>
                                </Col>
                                <Col col="6" lg="3">
                                    <p className="cc-pay"><h6 className="title">Max backtests per strategy</h6> {item?.plan?.product?.metadata?.maxBacktests}</p>
                                </Col>
                                <Col col="6" lg="3">
                                    <p className="cc-pay"><h6 className="title">Max watchlists</h6> {item?.plan?.product?.metadata?.maxBacktests}</p>
                                </Col>
                            </Row>

                            {/* <Row style={{
                                marginTop: 16
                            }}>
                                <Col xs={12}>
                                    <div className="sp-plan-payopt">
                                        <div className="cc-pay">
                                            <h6 className="title">Payment details</h6>
                                            <ul className="cc-pay-method">
                                                <li>

                                                    <UncontrolledDropdown className="cc-pay-dd" style={{width: '100%'}} direction="down">
                                                        <DropdownToggle tag="a" className="btn btn-white btn-outline-light dropdown-toggle dropdown-indicator">
                                                            <Icon name={ccIcon}></Icon>
                                                            <span><span className="cc-pay-star">**** **** ****</span> {item?.default_payment_method?.card?.last4}</span>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-auto">
                                                            <ul className="link-list-plain">
                                                                {paymentMethods.map(i => {
                                                                    const icon = getCCIcon(i?.card?.brand)
                                                                    return (
                                                                        <li onClick={(e) => onClickUpdatePaymentMethod(item.id, i.id)}>
                                                                            <a className="cc-pay-item">
                                                                                <Icon name={icon}></Icon>
                                                                                <span className="cc-pay-item-name">
                                                                                    <span><span className="cc-pay-star">**** **** ****</span> {i?.card?.last4}{i?.card?.last4 === item?.default_payment_method?.card?.last4 ? <Badge style={{
                                                                                        float: "right",
                                                                                        alignSelf: "center"
                                                                                    }}>active</Badge> : <Icon onClick={(e) => onClickDeletePaymentMethod(i)} style={{
                                                                                        float: "right",
                                                                                        cursor: "pointer",
                                                                                        alignSelf: "center"
                                                                                    }} name="trash" className="text-danger" />}</span>
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    )
                                                                })}
                                                                <li onClick={onClickAddPaymentMethod}>
                                                                    <a style={{ 
                                                                        cursor: 'pointer'
                                                                    }} className="cc-pay-item">
                                                                        <span className="cc-pay-item-name">
                                                                            <span className="cc-pay-item-method-new">Add New Credit Card</span>
                                                                        </span>
                                                                        </a>
                                                                </li>
                                                            </ul>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                                {isCancledAtPeriod && item.status !== "active" && <p style={{
                                                    alignSelf: 'center'
                                                }}><Icon name="info-fill" style={{
                                                    marginRight: 4
                                                }} />Upgrade / resume your subscription to change payment method</p>}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
                        </div>
                    </Col>
                    <Col md="4">
                        {
                            <div className="sp-plan-action card-inner">
                                <div className="sp-plan-btn">
                                    <a className="btn btn-primary" onClick={(e) => onClickAddPaymentMethod(e)}><span>Update plan</span></a>
                                </div>
                                <div className="sp-plan-note text-md-center">
                                    {isCancledAtPeriod ? "Upgrade / resume your subscription now" : <p>Next Billing on <span>{strings.unix(item.current_period_end)}</span></p>}
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    );
};

export default Subscriptions;