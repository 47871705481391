import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GET_USER_LIMIT_COUNTS, UPDATE_BOT } from "../api/graphql";

export default function useSubscriptionLimit({ type, filter }) {
    let portfolioStrategyFilter = {
        "status": "active"
    };
    if (type === "portfolioStrategy") {
        portfolioStrategyFilter["portfolioId"] = filter
    }

    let portfolioFilter = {
        "type": "paper"
    };
    if (type === "portfolio") {
        portfolioFilter["userUID"] = filter
    }

    const { data, loading, errors, refetch } = useQuery(GET_USER_LIMIT_COUNTS, {
        variables: {
            "backtestFilter": {
                "strategyId": filter
            },
            "portfolioStrategyFilter": portfolioStrategyFilter,
            "strategyFilter": {
                "userUID": filter
            },
            "botFilter": {
                "userUID": filter
            },
            "watchlistFilter": {
                "userUID": filter
            },
            "portfolioFilter": portfolioFilter
        }
    });

    return {
        data,
        refetch,
        loading
    }
}