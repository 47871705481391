import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { CardBody } from "reactstrap";
import { PreviewAltCard } from "../../components/Component";
import { getCompanyProfile } from "../../api";
import { groupBy } from "lodash";


const TCDoughnut = ({ data, className }) => {
  const parsed = {
    labels: data.map(i => i.label),
    legend: false,
    datasets: [
      {
        backgroundColor: ["#798bff", "#b8acff", "#ffa9ce", "#f9db7b"].splice(0, data.length + 1),
        borderColor: "#fff",
        data: data.map(i => i.count),
      },
    ],
  };
  return (
    <Doughnut
      className={className}
      data={parsed}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: '13px',
            },
            titleColor: "#6783b8",
            titleMarginBottom: 6,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: '12px',
            },
            bodySpacing: 4,
            padding: 10,
            footerMarginTop: 0,
          },
        },
        rotation: -1.5,
        cutoutPercentage: 70,
        maintainAspectRatio: false,
      }}
    ></Doughnut>
  );
};

const PortfolioStatistics = ({ mode, portfolioData }) => {
  const [selectedCategory, setSelectedCategory] = useState(mode === "Portfolio" ? 'assetType' : 'sector');
  const [doughnutData, setDoughnutData] = useState(null);
  const [portfolioStats, setPortfolioStats] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(null);

  const loadProfiles = async () => {
    setIsLoadingProfiles(true)
    getCompanyProfile({ symbols: portfolioData?.type === "backtest" ? portfolioData?.positions?.map(i => i.asset)?.join(",") : portfolioData?.positions?.filter(pos => pos.status === "open")?.map(i => i.asset)?.join(","), greeks: true },
      (res) => {
        setProfiles(res);
        setIsLoadingProfiles(false)
      }, (err) => {
        console.error(err)
        setIsLoadingProfiles(false)
      })
  }

  useEffect(() => {
    (portfolioData?.positions?.length > 0 && loadProfiles())
  }, [portfolioData]);

  useEffect(() => {
    setDoughnutData(portfolioStats?.[selectedCategory]);
  }, [selectedCategory, portfolioStats])

  useEffect(() => {
    let temp = {};
    const openPositions = portfolioData?.type === "backtest" ? portfolioData?.positions : portfolioData?.positions?.filter(pos => pos.status === "open");
    temp.totalOpenPositions = openPositions?.length;
    temp.etfCount = openPositions?.filter(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return profile?.isEtf;
    })?.length

    temp.etfMoney = openPositions?.filter(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return profile?.isEtf;
    })?.map(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return profile?.price * pos.size
    }).reduce((partialSum, a) => partialSum + a, 0);

    temp.fundsCount = openPositions?.filter(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return profile?.isFund;
    })?.length
    temp.fundsMoney = openPositions?.filter(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return profile?.isFund;
    })?.map(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return profile?.price * pos.size
    }).reduce((partialSum, a) => partialSum + a, 0);
    temp.stocksCount = temp.totalOpenPositions - temp.etfCount - temp.fundsCount;
    temp.stocksMoney = openPositions?.filter(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return (!profile?.isFund && !profile?.isEtf);
    })?.map(pos => {
      const profile = profiles?.filter(p => p.symbol === pos.asset)?.[0] || null;
      return profile?.price * pos.size
    }).reduce((partialSum, a) => partialSum + a, 0);

    const groupedBySectors = groupBy(profiles, 'sector');
    const groupedByIndustries = groupBy(profiles, 'industry');
    temp.sector = Object.keys(groupedBySectors).map(k => {
      return ({ label: k, count: groupedBySectors[k]?.length })
    })
    temp.industry = Object.keys(groupedByIndustries).map(k => {
      return ({ label: k, count: groupedByIndustries[k]?.length })
    })

    const nlv = (portfolioData?.funds + temp.etfMoney +
      temp.fundsMoney +
      temp.stocksMoney);
    const assetTypeData = [
      {
        label: "Cash", count: ((portfolioData?.funds) / nlv) * 100
      },
      { label: "ETFs", count: temp.etfMoney ? ((temp.etfMoney - portfolioData?.funds) / nlv) * 100 : 0 },
      { label: "Funds", count: temp.fundsMoney ? ((temp.fundsMoney - portfolioData?.funds) / nlv) * 100 : 0 },
      { label: "Stocks", count: ((temp.stocksMoney) / nlv) * 100 },
    ]
    temp.assetType = assetTypeData;

    setPortfolioStats(temp)
  }, [portfolioData, profiles]);


  let category = [];
  if (mode === "Portfolio") {
    category.push({
      name: "assetType",
      text: "Asset type"
    })
  }

  category.push({
    name: "sector",
    text: "Sectors"
  })
  category.push({
    name: "industry",
    text: "Industries"
  })
  return (
    <div>
      <h6 className="title">{mode} diversity</h6>
      <div className="card-inner">
        <ul className="filter-button-group mb-4 pb-1">
          {category?.map((item, index) =>
            <li key={index}>
              <button
                className={`filter-button ${selectedCategory === item.name && "active"}`}
                onClick={() => setSelectedCategory(item.name)}
                type="button"
              > {item.text} </button>
            </li>
          )}
        </ul>
      </div>
      <div>
        {doughnutData && <TCDoughnut data={doughnutData} state={"30"} className="analytics-doughnut"></TCDoughnut>}
      </div>
    </div>
  );
};
export default PortfolioStatistics;
