import { Card, CardBody, CardText, CardTitle, Col, Label, Row, Spinner } from "reactstrap"
import { Icon, PreviewAltCard, PreviewCard } from "../../../components/Component";
import { BarChart } from "../../../components/partials/charts/default/Charts";
import { useCallback, useEffect, useState } from "react";
import { StyledIcon } from "../../../landingApp/components/icon/Icon";
import Play from "../../../landingApp/components/svg/Play";
import { useMutation } from "@apollo/client";
import { GET_BOT_INFO, JUST_RUN_BACKTEST } from "../../../api/graphql";
import { useMyStore } from "./ReactFlowBuilder/store";
import moment from "moment";
import { Line } from "react-chartjs-2";
import BacktestChart from "./BacktestChart";
import { BacktestBalanceChart } from "../../strategies/BacktestBalanceChart";
import ReactDatePicker from "react-datepicker";
import { addCommas, removeNonNumeric } from "../../../utils/Utils";
import BacktestOrders from "./BacktestOrders";
import PortfolioStatistics from "../../portfolios/PortfolioStatistics";
import strings from "../../../utils/Strings";


const BacktestPerformance = ({ backtestData, mode = "Backtest" }) => {

    return (
        <>
            <h6>Performance</h6>
            <Row className="g-1">
                <Col xs={4}>
                    <div>
                        <div className="title">{mode === "Portfolio" ? "NLV" : "Total return"}</div>
                        {/* <label className="amount form-label">{strings.priceDecimal(nlv - portfolioData?.initialFunds)}</label> */}
                        <label className={`change ${backtestData?.backtestStats?.portfolioChange > 0 ? "up" : backtestData?.backtestStats?.portfolioChange < 0 ? "down" : ""}`}>
                            <Icon name={`${backtestData?.backtestStats?.portfolioChange > 0 ? "arrow-long-up" : backtestData?.backtestStats?.portfolioChange < 0 ? "arrow-long-down" : ""}`}></Icon>
                            {strings.percentSignFormatter(backtestData?.backtestStats?.portfolioChange)}
                        </label>
                    </div>
                </Col>
                <Col xs={4}>
                    <div>
                        <div className="title">{mode === "Portfolio" ? "NLV" : "Today's return"}</div>
                        {/* <label className="amount form-label">{strings.priceDecimal(nlv - portfolioData?.initialFunds)}</label> */}
                        <label className={`change ${backtestData?.backtestStats?.portfolioChange > 0 ? "up" : backtestData?.backtestStats?.portfolioChange < 0 ? "down" : ""}`}>
                            <Icon name={`${backtestData?.backtestStats?.portfolioChange > 0 ? "arrow-long-up" : backtestData?.backtestStats?.portfolioChange < 0 ? "arrow-long-down" : ""}`}></Icon>
                            {strings.percentSignFormatter(Math.floor(Math.random() * (10 - 1) + 1))}
                        </label>
                    </div>
                </Col>
                <Col xs={4}>
                    {mode === "Backtest" && <div>
                        <div className="title">Max drawdown</div>
                        <label className="amount form-label">{strings.percentSignFormatter(backtestData?.backtestStats?.maxDD)}</label>
                    </div>}
                </Col>
                <Col xs={4}>
                    {mode === "Backtest" && <div>
                        <div className="title">Std. dev</div>
                        <label className="amount form-label">{strings.percentSignFormatter(backtestData?.backtestStats?.stdDev)}</label>
                    </div>}
                </Col>
                <Col xs={4}>
                    {mode === "Backtest" && <div>
                        <div className="title">Sharp ratio</div>
                        <label className="amount form-label">{strings.decimal(backtestData?.backtestStats?.sharpRatio)}</label>
                    </div>}
                </Col>
                <Col xs={4}>
                    {mode === "Backtest" && <div>
                        <div className="title">Calmar ratio</div>
                        <label className="amount form-label">{strings.decimal(backtestData?.backtestStats?.calmarRatio)}</label>
                    </div>}
                </Col>
            </Row>
        </>
    )
}

export default BacktestPerformance;