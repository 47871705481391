import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_BOT_INFO, JUST_RUN_BACKTEST } from "../../../api/graphql";
import { useMyStore } from "./ReactFlowBuilder/store";
import moment from "moment";
import BacktestChart from "./BacktestChart";
import BacktestFullview from "./BacktestFullview";
import BacktestPreview from "./BacktestPreview";


const BacktestCard = ({ botData, editMode, mode = "full" }) => {
    const [createBacktestMutationLink] = useMutation(JUST_RUN_BACKTEST);

    const { useSaveBot, getMergedFlow, interval, isRunningBacktest, setIsRunningBacktest } = useMyStore();
    const save = useSaveBot()
    const fullBacktests = botData?.backtests;
    const lastFull = fullBacktests?.[fullBacktests?.length - 1];

    const [formData, setFormData] = useState({
        start: lastFull ? new Date(lastFull?.start) : new Date(moment().subtract(1, "years")),
        end: lastFull ? new Date(lastFull?.end) : new Date(),
        initialFunds: lastFull ? lastFull.initialFunds : 100000
    });

    useEffect(() => {
        if (lastFull) {
            setFormData({
                start: new Date(lastFull?.start),
                end: new Date(lastFull?.end),
                initialFunds: lastFull.initialFunds,
            })
        }
    }, [lastFull])

    const onClickRunBacktest = async ({ event }) => {
        event && event?.preventDefault();
        setIsRunningBacktest(true);
        await save();
        let submittedData = {
            interval: {
                label: interval.label,
                value: interval.value
            },
            initialFunds: formData.initialFunds,
            start: formData.start,
            end: formData.end,
            mode: "full",
            botId: botData?._id,
            flow: getMergedFlow(),
        };

        try {
            const { errors, data: createBacktestData } = await createBacktestMutationLink({
                variables: {
                    input: {
                        backtest: submittedData,
                    }
                },
                refetchQueries: [
                    {
                        query: GET_BOT_INFO,
                        variables: { id: botData?._id }
                    }
                ]
            },
            );
            setIsRunningBacktest(false);
        } catch (error) {
            console.error(error);
            setIsRunningBacktest(false);
        }
    }


    if (mode === "full") {
        return (
            <BacktestFullview
                botData={botData}
                formData={formData}
                setFormData={setFormData}
                mode={mode}
                editMode={editMode}
                onClickRunBacktest={onClickRunBacktest}
                lastFull={lastFull}
            />
        )
    }
    else if (mode === "botCard") {
        return (
            <BacktestChart
                backtestMode={mode}
                botData={botData}
                backtestData={botData?.botStats?.backtest} />
        )
    }
    else if (mode === "preview") {
        return (
            <BacktestPreview
                botData={botData}
                mode={mode}
                editMode={editMode}
                onClickRunBacktest={onClickRunBacktest}
                lastFull={lastFull}
            />
        )
    }
}

export default BacktestCard;