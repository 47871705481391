import React, { useEffect, useState } from "react";
import { NSComponent, RSelect } from "../../../components/Component";

const sizeOptions = [
  {value: "percentOfFunds", label: "% Of funds"},
  {value: "amount", label: "Amount"},
]

const Size = ({sizeOptions, sizeType, setSizeType, sizeValue, setSizeValue}) => {
  return (
    <div className="form-group">
      <RSelect className="nodrag" value={sizeType} options={sizeOptions} onChange={setSizeType} />
      <NSComponent step={1} defaultVal={sizeValue} onChange={setSizeValue} />
      </div>
  );
};
export default Size;